import { regularExpressions } from "./regularExpressions";

export  const passwordPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,20}$/;
//
export const profileRegrex = {
  firstName: /^.{1,150}$/,
  lastName: /^.{1,150}$/,
  location: /^.{1,150}$/,
  description: /^.{0,1000}$/,
  roles: /^.{1,150}$/,
  platformLanguage:  /^.{1,150}$/
};

export const startCallFormRegrex = {
	name: /^.{1,150}$/,
	email: regularExpressions.email,
	phone: /^[0-9]{8,14}$/,
  countryCode: /^.{1,150}$/
};

export const scheduleCallFormRegrex = {
	clientName: /^.{1,150}$/,
	clientEmail: regularExpressions.email,
	clientPhone: /^[0-9]{8,14}$/,
  countryCode: /^.{1,150}$/
};

// The validation will be updated
export const integrationRegrex = {
  accessToken: /^.{1,150}$/,
  apiPath:/^.{1,150}$/,
  storeUrl:/^.{1,150}$/,
  apiKey:/^.{1,150}$/,
  adminApiAccessToken:/^.{1,150}$/,
  storeFrontAccessToken:/^.{1,150}$/,
  applicationKey:/^.{1,150}$/,
  applicationToken:/^.{1,150}$/,
  currency: /^.{1,150}$/
}



export const passwordModelsRegrex = {
  currentPassword: passwordPattern,
  newPassword: passwordPattern,
  newPasswordConfirmation: passwordPattern,
};
