import React from "react";
import PropTypes from "prop-types";
import backIcon from "assets/svg/navigation-back.svg";
import { useHistory } from "react-router-dom";
import messages from "constants/messages";
import { componentsName } from "config/client-api-status";


const BackButton = ({contents, from, goBackFromActionComponent, resetAvailabalityHanlder}) => {
 const history = useHistory();

  const mangeHistory = () => {
    if(resetAvailabalityHanlder) {
      resetAvailabalityHanlder();
    }
    if(from === componentsName.selectDateToScheduleCall) {
      return goBackFromActionComponent();
    }
    return history.goBack();
  }
  
  return (
    <div className="gb-back navigations">
      
      <div onClick={() => mangeHistory()} className="back-arrow">
        <div className="item back-logo" onClick={() => history.goBack()}>
          <img alt="" src={backIcon} />
        </div>
        <div className="item back-title">{contents?.btnBack ?? messages?.btnBack}</div>
      </div>
    </div>
  );
};

BackButton.propTypes = {
  history: PropTypes.object,
};

export default BackButton;
