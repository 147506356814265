import { post } from '../../../utils/Api';
import { createWebUrlLoading, createWebUrlSuccess, createWebUrlError } from '../../constants/reduxConstant';
import apiName from '../../../constants/apiName';
import udid from '../../../components/Common/udid';

const apiLoading = () => ({ type: createWebUrlLoading });
const apiSucceed = (payload) => ({ type: createWebUrlSuccess, payload });
const apiError = (payload) => ({ type: createWebUrlError, payload });

const createWebUrlA = ({ from, tenantName, uniqueIdentifier, industry, country, numberOfEmployees, logoFile, googleAnalyticsKey }) => async (dispatchEvent, getState) => {
   
    let body = {
        id: udid(),
        tenantName: tenantName,
        uniqueIdentifier: uniqueIdentifier,
        industry: industry,
        country: country,
        googleAnalyticsKey: googleAnalyticsKey,
        numberOfEmployees: numberOfEmployees,
        logoFile: logoFile
    };
    let action = null;
    if (from == 'createUrl') {
        action = apiName.createWebUrl
    }
    else if (from == 'updateCompanyProfile') {
        action = apiName.updateCompanyDetails
    }

    if (action == null) return
    dispatchEvent(apiLoading());
    try {
        post(action, body)
            .then(async (res) => {
                if (res && res.data && res.data.isSucceeded) {
                    dispatchEvent(apiSucceed(res));
                } else {
                    dispatchEvent(apiError(res));
                }
            })
            .catch((error) => {
                dispatchEvent(apiError(error));
            });
    } catch (e) {
        dispatchEvent(apiError(e));
    }
}

export default createWebUrlA