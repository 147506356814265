import { put, post } from '../../../utils/Api';
import { updateProfileLoading, updateProfileSuccess, updateProfileError } from '../../constants/reduxConstant';
import { updateProfileInLoading, updateProfileInSuccess, updateProfileInError } from '../../constants/reduxConstant';
import apiName from '../../../constants/apiName';

const apiLoading = () => ({ type: updateProfileLoading });
const apiSucceed = (payload) => ({ type: updateProfileSuccess, payload });
const apiError = (payload) => ({ type: updateProfileError, payload });

const apiLoadingOne = () => ({ type: updateProfileInLoading });
const apiSucceedOne = (payload) => ({ type: updateProfileInSuccess, payload });
const apiErrorOne = (payload) => ({ type: updateProfileInError, payload });

const updateProfileA = ({ from, body }) => async (dispatchEvent, getState) => {
    // let body = new FormData();
    // body.append('body', body)

    if (from == 'expCompleteProfile2' || from == 'adminExperts') {
        dispatchEvent(apiLoading());
        try {
            let api = from == 'expCompleteProfile2' ? apiName.updateProfile : apiName.updateExpertProfile
            post(api, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    }
    else if (from == 'profile') {
        dispatchEvent(apiLoadingOne());
        try {
            post(apiName.updateProfile, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceedOne(res));
                    } else {
                        dispatchEvent(apiErrorOne(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiErrorOne(error));
                });
        } catch (e) {
            dispatchEvent(apiErrorOne(e));
        }
    }
}

export default updateProfileA