import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getBeeIcon from '../../../assets/images/logo-icon.svg';
import ReactStars from 'react-stars'
import Toast from 'light-toast';
import { handleShowBuyerProducts, goToSelectedStep, cleanShoppingReducer } from "../../../redux/action/shopping";
import messages from "constants/messages";
import { clientHasVistedVideoCall } from "config/Config";

const BuyerCompletedScreen = ({ logo, leaveChannel }) => {
  const { preferedLanguageContent: { contents } } = useSelector((state) => state);
  const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus)
  const [rating, setRating] = useState(0)
  const [reportAbuse, setReportAbuse] = useState(false)
  const connection = useSelector(state => state.shoppingReducer.shoppingConnection)
  const ratingChanged = (newRating) => {
    
    setRating(newRating)
  }

 
  const dispatch = useDispatch()

  const onSubmitRating = async (tenant) => {
    if (rating && connection) {
      const payload = {
        rating: rating,
        sessionId: sessionStatus.sessionId
      }

      const result = await connection.invoke("rateSession", payload)
      
      Toast.success(contents?.ratingSubmittes ?? messages?.ratingSubmittes, 1000, async () => {
        dispatch(cleanShoppingReducer())
        // do something after the toast disappears
        // history.push(`/${tenant}/client-home`)
        dispatch(goToSelectedStep(1));
        dispatch(handleShowBuyerProducts(false));
        localStorage.setItem(clientHasVistedVideoCall, 1);
        await leaveChannel()
      });

    }
  }

  const onSubmitComplaint = () => {
    if (reportAbuse) {
      setReportAbuse(false)
    }
  }



  return (

    <div class="w-full flex flex-wrap h-screen s-client">
      <div class={` w-full flex flex-col s-completed-call`} >
        <div class="call-ended-flex-legacy flex justify-center items-center pt-10 md:pl-12 md:-mb-24 bg-main">
          <img class=" text-white font-bold text-xl p-4 company-size-logo-big" src={logo} />
        </div>
        
        {reportAbuse ? <div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
          <p class="text-center text-3xl s-client__title">{contents?.reportAbuse ?? messages?.reportAbuse} </p>
          <div class="flex flex-col pt-3 md:pt-8" >
            <div class="flex flex-col pt-4">
            </div>
            <div class="flex flex-col pt-4">
              <label>{contents?.describeIssue ?? messages?.describeIssue}</label>
              <input type="text" placeholder="" />
            </div>

            <div>
              <button type="submit" className="s-client__btn" onClick={() => onSubmitComplaint()}><p>{contents?.submit ?? messages?.submit}</p></button>
              <button type="submit" className="s-client__btn s-client__btn--transparent " onClick={() => setReportAbuse(false)}><p>{contents?.btnCancel ?? messages?.btnCancel}</p></button>
            </div>
          </div>

        </div> : <div class="flex flex-col s-completed-call__rating justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
          <p class="text-center text-3xl s-client__title">{contents?.rateExperienceWith ?? messages?.rateExperienceWith}  <br /> {Object.keys(sessionStatus).length > 0 && sessionStatus.expertInfo && sessionStatus.expertInfo.name} </p>
         
         
         
          <div className="mt-4 flex justify-center ">
            <ReactStars
              count={5}
              value={rating}
              onChange={ratingChanged}
              size={40}
              half={false}
              color2={'#fdcc0d'} />
          </div>

          <div class="flex flex-col pt-2 md:pt-4" >
            <button type="submit" className="btn btn--large" onClick={() => onSubmitRating(sessionStatus && sessionStatus.tenant)}><p>{contents?.submit ?? messages?.submit}</p></button>
          </div>
        </div>}

        <footer className="flex justify-center items-center left-0 right-0">
          <div className="container-fluid">
            <a href="javascript:void(0);" className="footer-logo-item">
             
            </a>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default BuyerCompletedScreen