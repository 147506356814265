export const ADD_TO_CART = "ADD_TO_CART";
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const LOADING_SHOPPING = "LOADING_SHOPPING";
export const ADD_TO_RECOMMENDATION = "ADD_TO_RECOMMENDATION";
export const REMOVE_FROM_RECOMMENDATION = "REMOVE_FROM_RECOMMENDATION";
export const ACTIVE_STEP = "ACTIVE_STEP";
export const SHOPPING_CONNECTION = "SHOPPING_CONNECTION";
export const SHOW_BUYER_PRODUCTS = "SHOW_BUYER_PRODUCTS";
export const SHOW_CATALOG = "SHOW_CATALOG";
export const SHOW_INVITATION_SCREEN = "SHOW_INVITATION_SCREEN";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const REMOVE_QUANTITY = "REMOVE_QUANTITY";
export const CLEAR_ITEM_FROM_PRODUCTS = "CLEAR_ITEM_FROM_PRODUCTS";
export const SHOW_VIDEO_SCREEN = "SHOW_VIDEO_SCREEN"
export const GET_PRODUCTS_COLLECTIONS = "GET_PRODUCTS_COLLECTIONS";
export const GET_PRODUCTS_COLLECTIONS_ERROR = "GET_PRODUCTS_COLLECTIONS_ERROR";
export const SESSION_STATUS = "SESSION_STATUS"
export const STORE_INTEGRATION = "STORE_INTEGRATION"
export const REQUEST_CONNECTION = "REQUEST_CONNECTION";
export const GET_AGORA_USER = "GET_AGORA_USER";
export const CLEAN_SHOPPING = "CLEAN_SHOPPING";