import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from "./reducer/Index";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  whitelist: ["authReducer", "userDetailReducer"],
};

const middleware = [thunk]

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));