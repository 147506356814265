import { imgUploadError, imgUploadSuccess, imgUploadLoading, SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'
import { imgUploadExpError, imgUploadExpSuccess, imgUploadExpLoading } from '../../constants/reduxConstant'
import { imgUploadInError, imgUploadInSuccess, imgUploadInLoading } from '../../constants/reduxConstant'

export function imgUploadR(state = {}, action) {
    switch (action.type) {
        case imgUploadLoading:
            return Object.assign({}, state, { status: LOADING });
        case imgUploadSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case imgUploadError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}

export function imgUploadExpR(state = {}, action) {
    switch (action.type) {
        case imgUploadExpLoading:
            return Object.assign({}, state, { status: LOADING });
        case imgUploadExpSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case imgUploadExpError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}

export function imgUploadInR(state = {}, action) {
    switch (action.type) {
        case imgUploadInLoading:
            return Object.assign({}, state, { status: LOADING });
        case imgUploadInSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case imgUploadInError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}