import ReactDOM from "react-dom";
import {
  BrowserRouter, Switch
} from "react-router-dom";
import "./index.css";

import "./assets/css/responsive.css";
import "./assets/css/style.scss";
import reportWebVitals from "./reportWebVitals";

import { ToastContainer } from 'react-toastify';
import App from "./App";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <>
      <App />
      <ToastContainer />
      </>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
