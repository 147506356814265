import { useEffect, useState } from "react";

const adminSign = ["Admin", "Expert"];

export default function useIsAdmin() {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Copy previsouse tenant 
    const { role } = JSON.parse(localStorage.getItem("userData")) || null;

    if (JSON.stringify(role) === JSON.stringify(adminSign)) {
      setIsAdmin(true);
    }
  }, []);
  return { isAdmin };
}
