// import strs from '../../constants/staticString'
import { showToast } from './Common';

//***** Function for showing alert messages for 5 sec in whole app */
export function errorHandle(obj, messages) {
    var error = ''
    if (obj.error && obj.error.data && obj.error.data.status) {
        error = obj.error.data.status
    }
    console.log('error string before sending', error)
    showToast((messages && messages[`err_${error}`]) || error)
}