import apiName from '../../../constants/apiName';
import { get } from '../../../utils/Api';
import { userInfoError, userInfoLoading, userInfoSuccess } from '../../constants/reduxConstant';

const apiLoading = () => ({ type: userInfoLoading });
export const apiSucceed = (payload) => ({ type: userInfoSuccess, payload });
const apiError = (payload) => ({ type: userInfoError, payload });

const userInfoA = ({ from, tenant }) => async (dispatchEvent, getState) => {
    if (from === 'adminHome') {
        dispatchEvent(apiLoading());
        try {
            get(apiName.userInfo + '?tenant=' + tenant)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    };
}

export default userInfoA