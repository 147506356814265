import messages from "constants/messages";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Loader from "../../components/Common/loader";
import defaultA from "../../redux/action/defaultA";
import tenantInfoA from "../../redux/action/tenantInfoA";
import { ERROR, LOADING, SUCCESS } from '../../redux/constants/reduxConstant';
import getBeeIcon from './../../assets/images/logo-icon.svg';
import sideIcon from './../../assets/images/side-img-1.png';

const Home = (props) => {
  const params = useParams();
  const history = useHistory();
  const { preferedLanguageContent: { contents } } = useSelector((state) => state);
  const [state, setState] = useState({
    loading: false,
    tenantInfo: ''
  });
  
  const getStarted = () => {
    const path = `/${params.tenant}/client-home`
    history.push({ pathname: path, state: { tenantInfo: state.tenantInfo } })
  }
  useEffect(() => {
    localStorage.removeItem('userData')

    let formData = {
      tenant: params.tenant
    };
    props.tenantInfoRequest({ from: 'client-home', body: formData })
  }, []);

  useEffect(() => {
    const tenantInfoPath = props.ScreenProps.tenantInfoR;
    // console.log("tenantInfo Path", tenantInfoPath.value);
    if (tenantInfoPath.status == LOADING) {
      setState({ ...state, loading: true });
    }
    else if (tenantInfoPath.status == SUCCESS) {
      console.log("Info ====>", tenantInfoPath.value.data);
      setState({ ...state, loading: false, tenantInfo: tenantInfoPath.value.data });
      props.defaultRequest();
    }
    else if (tenantInfoPath.status == ERROR) {
      console.log('tenantInfo error res ', tenantInfoPath);
      setState({ ...state, loading: false });
      //errorHandle(tenantInfoPath)
      props.defaultRequest();
      const path = `/`
      history.replace({ pathname: path })
    }
  }, [props.ScreenProps]);

  return (
    <>
      <ToastContainer />
      <div className="wraper">
        <header id="fixed-header" className="header-main">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="nav-items item-center">
              <a className="navbar-brand" href="index.php">
                <img src={state.tenantInfo && state.tenantInfo.logoUrl ? state.tenantInfo.logoUrl : ''} />
              </a>
            </div>
          </nav>
        </header>
      </div>
      <div className="wrapper-inner">
        <div className="main-block-section">
          <div className="row m-0 justify-content-between align-items-center">
            <div className="col-md-5 p-0">
              <div className="main-img-block">
                <figure>
                  <img src={sideIcon} />
                </figure>
              </div>
            </div>
            <div className="col-md-6 offset-md-1 p-0">
              <div className="main-content-block">
                <h4 className="mb-4">{contents?.personalShopping ?? messages?.personalShopping}</h4>
                <p className="mb-4">
                  {contents?.oneOnOneAssistance ?? messages?.oneOnOneAssistance}
                </p>
                <ul className="mb-4">
                  <li>{contents?.videoCallRealPerson ?? messages?.videoCallRealPerson}</li>
                  <li>{contents?.getBestAdvice ?? messages?.getBestAdvice}</li>
                  <li>{contents?.findProductLookingFor ?? messages?.findProductLookingFor}</li>
                </ul>
                <button className="btn btn-primary btn-lg btn-min" onClick={getStarted}>
                {contents?.getStarted ?? messages?.getStarted}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer footer-logo">
        <div className="container-fluid">
          <a href="javascript:void(0);" className="footer-logo-item">{contents?.poweredBy ?? messages?.poweredBy} <img src={getBeeIcon} /><span>GETBEE</span></a>
        </div>
      </footer>
      {state.loading && <Loader />}
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ScreenProps: state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    tenantInfoRequest: ({ from, body }) => {
      dispatch(tenantInfoA({ from, body }));
    },
    defaultRequest: () => {
      dispatch(defaultA());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);