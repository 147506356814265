import React from "react";
import InvalidFeedback from "components/ErrorHandler/InvalidFeedback";
import Translate from "components/Common/Translate";
import PropTypes from "prop-types";

const UserInput = ({
  error,
  translatedContent,
  fallbacktranslatedContent,
  ...props
}) => {
  return (
    <>
      <input {...props} />

      {error && (
        <InvalidFeedback
          message={
            <Translate
              translatedContent={translatedContent}
              fallbacktranslatedContent={fallbacktranslatedContent}
            />
          }
        />
      )}
    </>
  );
};

UserInput.propTypes = {};

export default UserInput;
