import { expertsTeamError, expertsTeamSuccess, expertsTeamLoading, SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'

export default function expertsTeamR(state = {}, action) {
    // console.log("Inside expertsTeamR reducer", action)
    switch (action.type) {
        case expertsTeamLoading:
            return Object.assign({}, state, { status: LOADING });
        case expertsTeamSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case expertsTeamError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}