import { useEffect, useState } from 'react';
import { get } from './Api';

export const useTranslation = () => {
    const [messages, setMessages] = useState(null)
    const selectedLanguage = localStorage.getItem("selectedLanguage") ?? "en"
    useEffect(() => {
        const getData = async () => {
            try {
                if (localStorage.getItem(selectedLanguage))
                    setMessages(JSON.parse(localStorage.getItem(selectedLanguage)))
                let result = await get(`https://testbeestorage.blob.core.windows.net/translations/${selectedLanguage}.json`)
                localStorage.setItem(selectedLanguage, JSON.stringify(result.data))
                setMessages(result.data)
            }
            catch (error) {
                let result = await get(`https://testbeestorage.blob.core.windows.net/translations/en.json`)
                setMessages(result.data)
                localStorage.setItem("en", result.data);
            }
        }
        getData()
    }, [selectedLanguage]);

    return messages
}

export const useLanguages = () => {
    const [languages, setLanguages] = useState(null)
    const allLanguages = localStorage.getItem("allLanguages")
    useEffect(() => {
        const getData = async () => {
            if (allLanguages)
                setLanguages(JSON.parse(allLanguages))
            let result = await get(`https://testbeestorage.blob.core.windows.net/translations/languages.json`)
            localStorage.setItem("allLanguages", JSON.stringify(result.data))
            setLanguages(result.data)
        }
        getData()
    }, [])
    return languages
}

