import axios from "axios";
import { clientConnectionToken, getUserData } from "./utils.js";

export const post = async (url, formData, type = 0) => {
  const userData = await getUserData();
  const clientToken = await clientConnectionToken();
  return new Promise(function (resolve, reject) {
    axios({
      method: "post",
      url: url,
      timeout: 1000 * 60,
      data: formData,
      headers: {
        "Content-Type": type ? "multipart/form-data" : "application/json",
        "GETBEE-AUTH-TOKEN":
          userData && userData.sessionId ? userData.sessionId : clientToken,
      },
    })
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
        }
        reject(error.response);
      });
  });
};

export const put = async (url, formData, type = 0) => {
  const userData = await getUserData();
  return new Promise(function (resolve, reject) {
    axios({
      method: "put",
      url: url,
      timeout: 1000 * 60,
      data: formData,
      headers: {
        "Content-Type": type ? "multipart/form-data" : "application/json",
        "GETBEE-AUTH-TOKEN":
          userData && userData.sessionId ? userData.sessionId : "",
      },
    })
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};
export const get = async (url, type = 0) => {
  const userData = await getUserData();
  return new Promise(function (resolve, reject) {
    axios({
      method: "get",
      url: url,
      timeout: 1000 * 60,
      headers: {
        "Content-Type": type ? "multipart/form-data" : "application/json",
        "GETBEE-AUTH-TOKEN":
          userData && userData.sessionId ? userData.sessionId : "",
      },
    })
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};
