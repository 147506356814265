import React, { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { showToast } from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import { defaultInputWithIcon as DefaultInputWithIcon } from "../../components/Common/Input";
import udid from "../../components/Common/udid";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import resetPasswordA from "../../redux/action/common/resetPasswordA";
import defaultA from "../../redux/action/defaultA";
import { ERROR, LOADING, SUCCESS } from '../../redux/constants/reduxConstant';
import { Validate as validate } from "../../utils/validate_wrapper";

const ExpForgetPassSet = (props) => {
    const history = useHistory()
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);;
    const [state, setState] = useState({
        loading: false,
        email: '',
        code: '',
        password: '',
        showModal: false
    });

    const submit = (e) => {
        e.preventDefault()
        clearError()
        if (!checkValidation()) {
            return;
        }

        let formData = {
            email: state.email,
            token: state.code,
            password: state.password,
            id: udid(),
        }
        // console.log("formData for api",formData)

        props.resetPasswordRequest({ from: 'expResetPassword', body: formData })
        showToast(SUCCESS, 'success');
    };

    // validate form data
    const checkValidation = () => {
        var passwordErr = validate(contents, 'password', state.password);
        var confirm_passwordErr = validate(contents, 'confirm_password', state.password, state.confirm_password);

        // console.log('output of confirm pass validation ', state.password, state.confirm_password, confirm_passwordErr)

        if (passwordErr || confirm_passwordErr) {
            setState({
                ...state,
                passwordErr: passwordErr,
                confirm_passwordErr: confirm_passwordErr,
            })
            return false;
        } else {
            return true;
        }
    };

    // clean error state
    const clearError = () => {
        setState({ ...state, passwordErr: '', confirm_passwordErr: '' })
    }

    useEffect(() => {
        const url = new URL(decodeURIComponent(window.location.href));
        let temp = extractUrlValue("Email", url.search)
        let email = temp && temp.replace("%27", "'")
        let code = extractUrlValue("code", url.search)
        setState({ ...state, email: email, code: code })
        // email && props.verifyEmailRequest({ from: 'dashboard7', email: email, code: code })
        // props.industryRequest({ from: 'dashboard7' })
        //  console.log('email and code are like ', email, code)
    }, []);

    const extractUrlValue = (key, url) => {
        if (typeof (url) === 'undefined')
            url = decodeURIComponent(window.location.href);
        var match = url.match('[?&]' + key + '=([^&]+)');
        return match ? match[1] : null;
    }

    useEffect(() => {
        const resetPasswordPath = props.ScreenProps.resetPasswordR;
        console.log("resetPassword Path", resetPasswordPath);
        if (resetPasswordPath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (resetPasswordPath.status == SUCCESS) {
            console.log('resetPasswordRes succcess res ', resetPasswordPath.value.data)
            setState({ ...state, loading: false, showModal: true });
            //showToast(SUCCESS, 'success');
            props.defaultRequest();
            // resetPasswordPath.value.data.role = 'expert';
            // localStorage.setItem('userData', JSON.stringify(resetPasswordPath.value.data))
            // history.push({
            //     pathname: '/login'
            // })
        }
        else if (resetPasswordPath.status == ERROR) {
            console.log('resetPassword error res ', resetPasswordPath);
            setState({ ...state, loading: false });
            errorHandle(resetPasswordPath, contents)
            props.defaultRequest();
        }
    }, [props.ScreenProps]);

    const showSetPass = () => {
        setState({...state, showPass: !state.showPass});
    }

    const showSetConPass = () => {
        setState({...state, showConPass: !state.showConPass});
    }

    const letGo = () => {
        history.replace('/login');
    }

    return (
        <>
            <ToastContainer />
            <div className="expert-content-block-section">
                <div className="first-section">
                    <div className="form-content-blk">
                        <Header />
                        <div className="expert-content-block justify-center items-center m-auto">
                            <div className="w-full">
                                <h4>{contents?.createNewPassword ?? messages?.createNewPassword}</h4>
                                <p className="linkCautuin">{contents?.passwordInstructions ?? messages?.passwordInstructions}</p>
                                <form onSubmit={submit} className="password-form mb-3">
                                    <DefaultInputWithIcon
                                        type={state.showPass ? "text" : "password"}
                                        placeholder={contents?.newPassword ?? messages?.newPassword}
                                        name={"password"}
                                        icon={state.showPass ? Images.eyeClose : Images.eyeOpen}
                                        value={state.password}
                                        onChange={(e) => setState({ ...state, password: e.target.value, passwordErr: validate(contents, 'password', e.target.value) })}
                                        error={state.passwordErr}
                                        clickIcon={showSetPass}
                                    />

                                    <DefaultInputWithIcon
                                        type={state.showConPass ? "text" : "password"}
                                        placeholder={contents?.confirmPassword ?? messages?.confirmPassword}
                                        name={"confirm_password"}
                                        icon={state.showConPass ? Images.eyeClose : Images.eyeOpen}
                                        value={state.confirm_password}
                                        onChange={(e) => setState({ ...state, confirm_password: e.target.value, confirm_passwordErr: validate(contents, 'confirm_password', state.password, e.target.value) })}
                                        // onBlur={() => props.setFieldTouched("password", true)}
                                        error={state.confirm_passwordErr}
                                        clickIcon={showSetConPass}
                                    />
                                    <div className="bg-black btn-block">
                                        <button type="submit" className="btn btn-black btn-lg btn-min w-full">{contents?.btnResetPassword ?? messages?.btnResetPassword}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-section right-img new-pass">
                    <img src={Images.newPass} />
                </div>
            </div>
            {state.showModal &&
                <div className="modal viewmodal-profile fade  show show-model-box" id="viewmodal-profile"
                    role="dialog" aria-labelledby="viewmodal-profile"
                    aria-hidden="true">
                    <div className="modal-overlay"></div>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{contents?.success ?? messages?.success}</h5>
                                {/* <button onClick={() => setState({ ...state, showModal: false })} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src={Images.closeIcon} /></span>
                                </button> */}
                            </div>
                            <div className="modal-body">
                                <div className="exper-profile-add text-center">
                                    <p className="mb-4">{contents?.resetPasswordMsg ?? messages?.resetPasswordMsg}</p>
                                    <div className="full-width">
                                        <button onClick={() => { letGo() }} className="btn btn-black btn-lg btn-min w-full">{contents?.letGo ?? messages?.letGo}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetPasswordRequest: ({ from, body }) => {
            dispatch(resetPasswordA({ from, body }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpForgetPassSet)