import CloseIcon from "assets/svg/close.svg";
import React from "react";
import ReactDOM from "react-dom";
import Base from "./BaseModel";
import "./model.scss";

const Model = ({
  closeBtn,
  reasonToCancelForm,
  title,
  setCancelModelActive,
  actionButtons,
  afterTitle,
  helpText,
}) => {
  const model = (
    <Base>
      <div className="first-group">
        <div className="row cancel close">
          <div className="col text"> {title} </div>
          {closeBtn && (
            <div
              className="col icon"
              onClick={() => setCancelModelActive(false)}
            >
              <img src={CloseIcon} alt="" />
            </div>
          )}
        </div>

        <div className="row schedule-date">
          <p className="date">{afterTitle}</p>
          <p className="help-text">{helpText}</p>
        </div>

        {reasonToCancelForm}
      </div>
      
      {actionButtons}
    </Base>
  );

  return ReactDOM.createPortal(model, document.getElementById("model"));
};

const MemoModel = React.memo(Model);

export default MemoModel;
