import React, { useState, useEffect, useRef } from "react";
import { showToast } from "../../components/Common/Common";
import { connect } from 'react-redux';
import defaultA from "../../redux/action/defaultA";
import { SUCCESS, ERROR, LOADING } from '../../redux/constants/reduxConstant';
import { errorHandle } from "../../components/Common/errorHandle";
import Loader from "../../components/Common/loader";
import Images from "../../constants/Images";
import AvatarEditor from 'react-avatar-editor'
import { Range, getTrackBackground } from 'react-range';
import imgUploadA from "../../redux/action/common/imgUploadA";
import { useTranslation } from "../../utils/useTranslation";


const ImageUpload = (props) => {
  const editorRef = useRef(null);
  let randomKey = Math.random().toString(36)
  const messages = useTranslation()
  const [state, setState] = useState({
    loading: false,
    profileImg: '',
    uploadImg: '',
    rangeValue: [0.5],
  })

  // handle api response
  useEffect(() => {
    const imgUploadPath = props.ScreenProps[props.requestProp];
    if (imgUploadPath.status == LOADING) {
      props.onImageUploadLoad();
    }
    else if (imgUploadPath.status == SUCCESS) {
      props.onImageUploadSuccess(imgUploadPath.value.data.url)
      showToast(SUCCESS, 'success');
      props.defaultRequest();
    }
    else if (imgUploadPath.status == ERROR) {
      props.onImageUploadError();
      errorHandle(imgUploadPath, messages)
      props.defaultRequest();
    }
  }, [props.ScreenProps]);

  const cropImage = () => {
    if (editorRef && props.image) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL()
      if (editorRef) {
        fetch(canvas)
          .then(res => res.blob())
          .then(blob => {
            setState({ ...state, uploadImg: window.URL.createObjectURL(blob) })
            props.imgUploadRequest({ from: props.from || "expCompleteProfile", image: blob })
            randomKey = Math.random().toString(36)
          });
      }
    }
  }
  return (
    <>
      {state.loading && <Loader />}
      <div className="modal viewmodal-profile fade  show show-model-box" id="viewmodal-profile"
        role="dialog" aria-labelledby="viewmodal-profile"
        aria-hidden="true">
        <div className="modal-overlay"></div>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-left" id="exampleModalLongTitle">{messages?.cropPhoto}</h5>
              <button onClick={props.closeUploadModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><img src={Images.closeIcon} /></span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div style={{ width: '170px', margin: 'auto' }}>
                  <AvatarEditor
                    ref={editorRef}
                    image={props.image}
                    width={350}
                    height={350}
                    border={0}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={state.rangeValue[0]}
                    rotate={0}
                    borderRadius={100}
                    {...props.avatarProps}
                  />
                </div>
                <div className="profile-range">
                  <Range
                    step={0.02}
                    min={0.5}
                    max={5}
                    values={state.rangeValue}
                    onChange={(rangeValue) => {
                      console.log('rangeValue', rangeValue)
                      setState({ ...state, rangeValue: rangeValue })
                    }}
                    renderTrack={({ props, children }) => (
                      <div
                        ref={props.ref}
                        style={{
                          height: '3px',
                          width: '60%',
                          borderRadius: '4px',
                          margin: '16px auto 48px',
                          background: getTrackBackground({
                            values: state.rangeValue,
                            colors: ['#1a1a1c', '#c3c5cA'],
                            min: 1,
                            max: 5,
                            // rtl
                          }),
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div {...props} />
                    )}
                  />
                </div>
                <div className="btn-group">
                  <button onClick={props.closeUploadModal} className="btn btn-white btn-lg btn-min w-50 bg-white">{messages?.btnCancel}</button>
                  <button onClick={() => cropImage()} className="btn btn-black btn-lg btn-min w-50">{messages?.btnSave}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ScreenProps: state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    imgUploadRequest: ({ from, image }) => {
      dispatch(imgUploadA({ from, image }));
    },
    defaultRequest: () => {
      dispatch(defaultA());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);