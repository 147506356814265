import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

const GridSkletonTemplate = ({ count }) => {
  const grids = new Array(count).fill(0);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        wrap="nowrap"
        justifyContent={"center"}
        display={"flex"}
        
        gap={1}
      >
        {" "}
        {grids.map((item, i) => (
          <Grid item xs={4} gap={16} key={i}>
            <Skeleton
              variant="rectangular"
              height={392}
              key={i}
              
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

GridSkletonTemplate.propTypes = {};

export default GridSkletonTemplate;
