export const clientAPIStatus = {
  TenantIsNotExist: "TenantIsNotExist",
  UserNotFound: "UserNotFound",
  InternalError: "InternalError"
};

export const componentsName = {
  expertProfile: "expertProfile",
  initialPage: "initialPage",
  selectDateToScheduleCall:"selectDateToScheduleCall",
  speakWithSpecificExpert: "speakWithSpecificExpert"
}

export const pageSize = 20;
