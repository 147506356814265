import React from "react";
import ReactDOM from "react-dom";
import "./model.scss";

const BaseMode = (props) => {
  const model = (
    <div className="gb-model-container">
      <div className="gb-model-v1">
        {props.children}
      </div>
    </div>
  );

  return ReactDOM.createPortal(model, document.getElementById("model"));
};

BaseMode.propTypes = {};

export default BaseMode;
