export const tabIds = {
  homePage: "homepage-li",
  teamSalesData: "team-sales-data-li",
  companyDatails: "company-details-li",
  teamMembers: "team-members-li",
  subscription: "subscription-li",
  personalize: "personalize-li",
  integrations: "integrations-li",
  mySalesData: "my-sales-data",
  meetings: "meetings",
  reporting: "reporting",
};

export const tabs = {
  allMembers: "tab__item-1",
  pending: "tab__item-2",
};

export const sideBarTabsId = {
  personalInfo: "tab__item-1",
  additionalInfo: "tab__item-2",
  security: "tab__item-3",
};

export const reportingTabIds = {
  teamData: "team-data",
  myData: "my-data",
};

export const languages = ["English", "Russian", "German", "Spanish"];

export const storeType = {
  bigCommerce: "Bigcommerce",
  shopify: "Shopify",
  vTex: "Vtex",
  magento: "Magento",
  salesforce: "Salesforce Commerce",
};

export const schedulingComponentsNames = [
  "calendar",
  "time",
  "enterInformation",
];
export const schedulingComponentsNamesMap = {
  calendar: "calendar",
  time: "time",
  enterInformation: "enterInformation",
};

export const typeOfCalls = {
  direct: "direct",
  random: "random",
};
