import React from "react";
import PropTypes from "prop-types";
import MessageTemplate from "./MessageTemplate";

const NotFound = (props) => {
  return <MessageTemplate>Not Found</MessageTemplate>;
};

NotFound.propTypes = {};

export default NotFound;
