import { post } from '../../../utils/Api';
import apiName from '../../../constants/apiName';
import udid from '../../../components/Common/udid';
import { deleteExpertProfileLoading, deleteExpertProfileSuccess, deleteExpertProfileError } from '../../constants/reduxConstant';

const apiLoading = () => ({ type: deleteExpertProfileLoading });
const apiSucceed = (payload) => ({ type: deleteExpertProfileSuccess, payload });
const apiError = (payload) => ({ type: deleteExpertProfileError, payload });

const deleteExpertProfileA = ({ tenant, expert }) => async (dispatchEvent) => {
    dispatchEvent(apiLoading());
    try {
        let body = {
            id: udid(), 
            tenant: tenant ,
            expert: expert
        }
        post(apiName.deleteExpertProfile, body)
            .then(async (res) => {
                if (res && res.data && res.data.isSucceeded) {
                    dispatchEvent(apiSucceed(res));
                } else {
                    dispatchEvent(apiError(res));
                }
            })
            .catch((error) => {
                dispatchEvent(apiError(error));
            });
    } 
    catch (e) {
        dispatchEvent(apiError(e));
    }
}

export default deleteExpertProfileA