/**
 * This component is used in various component in the user journey 
 * Such as scheduling call, direct call etc
 * If user is refreshing the page then getting the tenant default language 
 * Setting from the api and setting this to the local storage
 * If user is selecting different language then in that case leaving 
 * The selected language as it is and saving the data 
 * 
 **/
import { preferredLanguage } from "components/Config/languages";
import { localStorageClientLanguageKey } from "config/Config";
import { getLngRowByLngCode } from "pureFunctions/getLngRowByLngCode";
import { isJsonString } from "pureFunctions/isJSONString";
import { useEffect, useMemo, useState } from "react";
import useTenantClientAppSettings from "./useTenantClientAppSettings";


export default function useSetGetLanguageForClient() {
  
  const [showLngMenu, setShowLngMenu] = useState(false);

  const { clientAppSetting } = useTenantClientAppSettings();

  const tenantAvailableClientLanguages = useMemo(() => {
    return clientAppSetting.availableClientLanguages;
  }, [clientAppSetting]);

  const clientDefaultLanguage = useMemo(() => {
    return clientAppSetting.clientDefaultLanguage
  }, [clientAppSetting]);

  const [selectedLanguage, setSelectedLanguage] = useState({});
  
  useEffect(() => {
    const value = localStorage.getItem(localStorageClientLanguageKey);
    const getLng =
      getLngRowByLngCode(preferredLanguage, isJsonString(value)) || "en";
    setSelectedLanguage(getLng);
  }, [tenantAvailableClientLanguages]);

  

  useEffect(() => {
    window.onbeforeunload = function() {
      // Check here if the session is for expert then dont set the local storage 
      // If session is for the client then and then only set this local storage
      localStorage.setItem(localStorageClientLanguageKey, JSON.stringify(clientAppSetting.clientDefaultLanguage));
  };

  return () => {
      window.onbeforeunload = null;
  };
  }, [clientAppSetting.clientDefaultLanguage]);

  return { selectedLanguage, setSelectedLanguage, showLngMenu, setShowLngMenu, clientDefaultLanguage };
}
