import { inviteExpError, inviteExpSuccess, inviteExpLoading, SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'
import { inviteExpAdminHomeError, inviteExpAdminHomeSuccess, inviteExpAdminHomeLoading } from '../../constants/reduxConstant'

export function inviteExpR(state = {}, action) {
    switch (action.type) {
        case inviteExpLoading:
            return Object.assign({}, state, { status: LOADING });
        case inviteExpSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case inviteExpError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}

export function inviteExpAdminHomeR(state = {}, action) {
    switch (action.type) {
        case inviteExpAdminHomeLoading:
            return Object.assign({}, state, { status: LOADING });
        case inviteExpAdminHomeSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case inviteExpAdminHomeError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}
