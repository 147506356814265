import backIcon from "assets/svg/navigation-back.svg";
import messages from "constants/messages";
import React from "react";

const BackNavigation = ({ history, contents }) => {
  return (
    <div className="navigations">
      <div onClick={() => history.goBack()} className="back-arrow">
        <div className="item back-logo" onClick={() => history.goBack()}>
          <img src={backIcon} alt="" />
        </div>
        <div className="item back-title">{contents.btnBack ?? messages.btnBack}</div>
      </div>
    </div>
  );
};

BackNavigation.propTypes = {};

export default BackNavigation;
