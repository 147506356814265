import React from "react";
import Catalog from "./Catalog";
import SingleProduct from "./SingleProduct";
import { useSelector } from "react-redux";
import CartScreen from "./CartScreen";
import VariantScreen from "./VarientScreen";
import CheckoutCompleted from "./CheckoutCompleted";
const ExpertScreen = () => {
  const activeStep = useSelector(state => state.shoppingReducer.activeStep)
  
  return (
    <React.Fragment>
      {activeStep === 0 && (
        <Catalog />
      )}
      {activeStep === 2 && (
        <SingleProduct />
      )}
      {activeStep === 3 && (
        <CartScreen />
      )}
      {activeStep === 4 && (
        <VariantScreen />
      )}
      {activeStep === 5 && (
        <CheckoutCompleted />
      )}
    </React.Fragment>
  )
}

export default ExpertScreen