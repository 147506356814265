import { SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'
import { revokeInvitationLoading, revokeInvitationError, revokeInvitationSuccess } from '../../constants/reduxConstant';

export default function revokeInvitationR(state = {}, action) {
    // console.log("Inside createWebUrl reducer", action)
    switch (action.type) {
        case revokeInvitationLoading:
            return Object.assign({}, state, { status: LOADING });
        case revokeInvitationSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case revokeInvitationError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}