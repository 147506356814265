import React, { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { showToast } from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import { defaultInput as DefaultInput } from "../../components/Common/Input";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import createWebUrlA from "../../redux/action/admin/createWebUrlA";
import defaultA from "../../redux/action/defaultA";
import { ERROR, LOADING, SUCCESS } from '../../redux/constants/reduxConstant';
import { getUserData } from "../../utils/utils";
import { Validate as validate } from "../../utils/validate_wrapper";

const AdminDashboardStep11 = (props) => {
    const location = useLocation();
    const history = useHistory();
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);
    const [state, setState] = useState({
        loading: false,
        companyUrl: ''
    })
    useEffect(() => {
        if (location && location.state && location.state.country) {
            setState({ ...state, companyData: location.state })
        }
    }, []);
    useEffect(() => {
        const createWebUrlPath = props.ScreenProps.createWebUrlR;

        if (createWebUrlPath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (createWebUrlPath.status == SUCCESS) {
            console.log('createWebUrl success res ', createWebUrlPath);
            setState({ ...state, loading: false });
            showToast(createWebUrlPath.value.data.status, 'success');
            updateLocalStorage(createWebUrlPath.value.data)
        }
        else if (createWebUrlPath.status == ERROR) {
            console.log('createWebUrl error res ', createWebUrlPath);
            setState({ ...state, loading: false });
            errorHandle(createWebUrlPath, contents)
            props.defaultRequest();
        }
    }, [props.ScreenProps]);

    const updateLocalStorage = async (data) => {
        const userData = await getUserData()
        userData.sessionId = data.sessionId
        userData.tenant = data.tenant
        userData.tenants = data.tenants
        userData.role = data.tenants
            .filter(x => x.tenantIdentifier == userData.tenant)[0].roles
        localStorage.setItem('userData', JSON.stringify(userData))
        props.defaultRequest();
        setTimeout(() => {
            history.push({ pathname: '/invite-team' })
        }, 200);
        // const userData = await getUserData()
        // userData.tenant = state.companyUrl.toLowerCase()
        // userData.role = ["Admin", "Expert"]
        // localStorage.setItem('userData', JSON.stringify(userData))
        // props.defaultRequest();
        // setTimeout(() => {
        //     history.push({ pathname: '/invite-team' })
        // }, 200);
    }

    const submit = (e) => {
        e.preventDefault()
        clearError()
        if (!checkValidation()) {
            return;
        }
        props.createWebUrlRequest({
            from: 'createUrl',
            tenantName: state.companyData && state.companyData.companyName,
            uniqueIdentifier: state.companyUrl,
            industry: state?.companyData?.industry.key,
            country: state?.companyData?.country?.value,
            numberOfEmployees: state?.companyData?.empNo?.label,
            logoFile: state.companyData && state.companyData.logo,
        })
    };

    const checkValidation = () => {
        var companyUrlErr = validate(contents, 'companyUrl', state.companyUrl);

        if (companyUrlErr) {
            setState({
                ...state,
                companyUrlErr: companyUrlErr,
            })
            return false;
        } else {
            return true;
        }
    };

    const clearError = () => {
        setState({
            ...state,
            companyUrlErr: '',
        })
    }


    return (
        <>
            <ToastContainer />
            <div className="expert-content-block-section">
                <div className="first-section">
                    <div className="form-content-blk">
                        <Header />
                        <div className="expert-content-block justify-center items-center overflow-block m-auto back-btn-block">
                            <div className="backbtn-progress-block w-full">
                                <button onClick={() => {
                                    // go back
                                    history.push({
                                        pathname: '/company-setup',
                                        state: {
                                            companyName: state.companyData.companyName,
                                            industry: state.companyData.industry,
                                            country: state.companyData.country,
                                            empNo: state.companyData.empNo,
                                            logo: state.companyData.logo
                                        },
                                    })
                                }} className="expert-back-btn">
                                    <img src={Images.vector} className="vector-icon" />
                                    <span className="back-text pl-2">{contents?.btnBack ?? messages?.btnBack}</span>
                                </button>
                                <div className="progress-block">
                                    <div className="progress-bg-line"></div>
                                    <div className="progress-line col-8"></div>
                                    <span className="index">2 {contents?.outOf ?? messages?.outOf} 3</span>
                                </div>
                                <h4>{contents?.companyDomain ?? messages?.companyDomain}</h4>
                                <p className="linkCautuin">{contents?.linkCautuin ?? messages.linkCautuin}</p>

                                <form onSubmit={submit} className="mb-3">
                                    <div className="subdomain-input">
                                        <span>https://app.getbee.com/</span>
                                        <DefaultInput
                                            label={contents?.companySubdomain ?? messages?.companySubdomain}
                                            placeholder={contents?.companySubdomain ?? messages?.companySubdomain}
                                            type={"text"}
                                            name={"companyUrl"}
                                            maxLength={100}
                                            value={state.companyUrl}
                                            onChange={(e) => setState({ ...state, companyUrl: e.target.value ? e.target.value.trimRight() : '', companyUrlErr: validate(contents, 'companyUrl', e.target.value) })}
                                            error={state.companyUrlErr}
                                        />
                                    </div>
                                    <div className="bg-black btn-block">
                                        <button
                                            type="submit"
                                            className="btn btn-black btn-lg btn-min w-full"
                                            disabled={state.loading}>
                                            {contents?.btnContinue ?? messages?.btnContinue}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-section right-img">
                    <img src={Images.companyDomain} />
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createWebUrlRequest: ({ from, tenantName, uniqueIdentifier, industry, country, numberOfEmployees, logoFile, code }) => {
            dispatch(createWebUrlA({ from, tenantName, uniqueIdentifier, industry, country, numberOfEmployees, logoFile, code }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardStep11);
