import {
  fetchTenantClientAppTextSettings,
  fetchTenantClientAppTextSettingsLoading,
  fetchTenantClientAppTextSettingsError,
} from "redux/constants/reduxConstant";

export const fetchTenantClientAppTextSettingsAction = (payload) => {
  return {
    type: fetchTenantClientAppTextSettings,
    payload,
  };
};

/**
 * @param  {fetchTenantClientAppSettingsLoading}}} =>{return{type
 * @returns fetchTenantClientAppSettingsLoading
 */
export const fetchTenantClientAppTextSettingsLoadingAction = () => {
  return {
    type: fetchTenantClientAppTextSettingsLoading,
  };
};
/**
 * @param  {} payload
 * @param  {fetchTenantClientAppSettingsError} =>{return{type
 * @returns fetchTenantClientAppSettingsError
 */
export const fetchTenantClientAppTextSettingsErrorAction = (payload) => {
  return {
    type: fetchTenantClientAppTextSettingsError,
    payload,
  };
};
