import messages from "constants/messages";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from 'react-highlight-words';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Validate as validate } from "utils/validate_wrapper";
import ErrorText, { showToast } from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import ImageUpload from "../../components/Common/ImageUpload";
import { DefaultDropDown, defaultInput as DefaultInput } from "../../components/Common/Input";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import imgUploadA from "../../redux/action/common/imgUploadA";
import industryA from "../../redux/action/common/industryA";
import defaultA from "../../redux/action/defaultA";
import nameExistsA from "../../redux/action/expert/nameExistsA";
import verifyEmailA from "../../redux/action/verifyEmailA";
import { AlreadyVerified, ERROR, LOADING, OldVerificationCode, SUCCESS } from '../../redux/constants/reduxConstant';

const AdminDashboardStep7 = (props) => {
    const history = useHistory()
    const location = useLocation()
    const inputRef = useRef(null)
    let randomKey = Math.random().toString(36)
    const { preferedLanguageContent: { contents, countriesList } } = useSelector((state) => state);;
    const [state, setState] = useState({
        loading: false,
        selectedCountry: '',
        isVerified: true,
        status: contents?.verifyProgress ?? messages?.verifyProgress,
        industryArr: [],
        selectedIndustry: '',
        empNoArr: [
            { value: '1', label: '1-10' },
            { value: '2', label: '11-50' },
            { value: '3', label: '51-100' },
            { value: '4', label: '101-200' },
            { value: '5', label: '201-500' },
            { value: '6', label: '501-1000' },
            { value: '7', label: '1000-5000' },
            { value: '8', label: '5001-10000' },
            { value: '9', label: '10000+' },
        ],
        selectedEmpNo: '',
        companyLogo: '',
        uploadImg: '',
        rangeValue: [0.5],
        showModal: false,
    })

    useEffect(() => {
        const url = new URL(decodeURIComponent(window.location.href));
        let temp = extractUrlValue("Email", url.search)
        let email = temp && temp.replace("%27", "'")
        let code = extractUrlValue("code", url.search)

        if (temp && code) {
            let userData = JSON.parse(localStorage.getItem('userData'))
            if (!userData) userData = {}
            userData.email = email
            localStorage.setItem('userData', JSON.stringify(userData))
            email && props.verifyEmailRequest({ from: 'dashboard7', email: email, code: code })
            props.industryRequest({ from: 'dashboard7' })
        }
        let confirmed = extractUrlValue("confirmed", url.search)
        if (confirmed && localStorage.getItem('userData')) {
            props.industryRequest({ from: 'dashboard7' })
        }
        //check location state
        if (location.state) {
            props.industryRequest({ from: 'dashboard7' })
            setState({
                ...state,
                companyName: location.state.companyName,
                selectedIndustry: location.state.industry,
                selectedCountry: location.state.country,
                selectedEmpNo: location.state.empNo,
                companyLogo: location.state.logo
            })
        }
    }, []);

    

    const extractUrlValue = (key, url) => {
        if (typeof (url) === 'undefined')
            url = decodeURIComponent(window.location.href);
        var match = url.match('[?&]' + key + '=([^&]+)');
        return match ? match[1] : null;
    }
    
    useEffect(() => {
        const verifyEmailPath = props.ScreenProps.verifyEmailR;
        const industryPath = props.ScreenProps.industryR;
        const nameExistsPath = props.ScreenProps.nameExistsR;
        if (verifyEmailPath.status === LOADING) {
            setState({ ...state, loading: true });
        }
        else if (verifyEmailPath.status === SUCCESS) {
            console.log('verifyEmail success res ', verifyEmailPath);
            setState({ ...state, loading: false, isVerified: true });
            localStorage.setItem('userData', JSON.stringify(verifyEmailPath.value.data))
            localStorage.setItem("selectedLanguage", JSON.stringify(verifyEmailPath.value.data.selectedLanguage));
            
            props.defaultRequest();
            props.history.replace(`/company-setup`);
            props.industryRequest({ from: 'dashboard7' })
        }
        else if (verifyEmailPath.status === AlreadyVerified) {
            //localStorage.removeItem('userData');
            localStorage.clear();
            props.history.replace(`/login`);
        }
        else if (verifyEmailPath.status === OldVerificationCode) {
            let userData = JSON.parse(localStorage.getItem('userData'))
            history.push({
                pathname: '/email-sent',
                state: {
                    oldCodeUsed: true,
                    email: userData.email
                },
            })
        }
        else if (verifyEmailPath.status === ERROR) {
            console.log('verifyEmail error res ', verifyEmailPath);
            setState({ ...state, loading: false, status: (contents?.verifyFail ?? messages?.verifyFail) });
            errorHandle(verifyEmailPath, contents)
            props.defaultRequest();
            props.history.replace(`/company-setup`);
        }
        else if (industryPath.status === LOADING) {
            // setState({ ...state, loading: true });
        }
        else if (industryPath.status === SUCCESS) {
            console.log('industrypath success res ', industryPath);
            let temp = industryPath.value.data.result
            temp.map(i => {
                i.label = i.value
            })
            setState({ ...state, loading: false, industryArr: temp });
            // showToast(industryPath.value.data.status, 'success');
            props.defaultRequest();
        }
        else if (industryPath.status === ERROR) {
            console.log('industrypath error res ', industryPath);
            setState({ ...state, loading: false });
            // errorHandle(industryPath)
            props.defaultRequest();
        }
        else if (nameExistsPath.status === LOADING) {
            setState({ ...state, loading: true });
        }
        else if (nameExistsPath.status === SUCCESS) {
            console.log('nameExistspath success res ', nameExistsPath);
            let temp = nameExistsPath.value.data
            setState({ ...state, loading: false });
            if (temp.exists) {
                showToast(contents?.alreadyExist ?? messages?.alreadyExist);
            }
            else {
                pushToNextScreen()
            }
            props.defaultRequest();
        }
        else if (nameExistsPath.status === ERROR) {
            console.log('nameExistspath error res ', nameExistsPath);
            setState({ ...state, loading: false });
            errorHandle(nameExistsPath, contents)
            props.defaultRequest();
        }
    }, [props.ScreenProps]);
    const [options, setOptions] = useState([])
    useEffect(() => {
        if(countriesList && Object.keys(countriesList).length > 0)
            setOptions(Object.keys(countriesList).map(x => ({ value: x, label: countriesList[x] })))
    }, [countriesList])

    // handle submit
    const submit = (e) => {
        e.preventDefault()
        clearError()
        if (!checkValidation()) {
            return;
        }
        props.nameExistsRequest({ from: 'dashboard7', name: state.companyName })
    };

    const pushToNextScreen = () => {
        history.push({
            pathname: '/create-web-address',
            state: {
                companyName: state.companyName,
                industry: state.selectedIndustry,
                country: state.selectedCountry,
                empNo: state.selectedEmpNo,
                logo: state.companyLogo
            },
        })
    }

    // Validate form data
    const checkValidation = () => {
        var companyLogoErr = validate(contents, 'image', state.companyLogo);
        var companyErr = validate(contents, 'companyName', state.companyName);
        var countryErr = validate(contents, 'blank', state.selectedCountry);
        var industryErr = validate(contents, 'blank', state.selectedIndustry);
        var empNoErr = validate(contents, 'blank', state.selectedEmpNo);

        if (companyLogoErr || companyErr || countryErr || industryErr || empNoErr) {
            setState({
                ...state,
                companyLogoErr,
                companyErr: companyErr,
                countryErr: countryErr,
                industryErr: industryErr,
                empNoErr: empNoErr,
            })
            return false;
        } else {
            return true;
        }
    };

    // clean error state
    const clearError = () => {
        setState({
            ...state,
            companyErr: '',
            countryErr: '',
            industryErr: '',
            employErr: '',
            companyLogoErr: '',
            uploadImg: ''
        })
    }

    // open image picker
    const chooseFile = () => {
        inputRef.current.click();
    }

    const deleteFile = () => {
        setState({ ...state, companyLogo: '', uploadImg: '' })
    }

    const formatOptionLabel = ({ label }, { inputValue }) => {
        return (
            <Highlighter
                highlightClassName="option-highlight"
                searchWords={[inputValue]}
                textToHighlight={label}
            />
        );
    }

    console.log('state', state)

    return (
        <>
            <ToastContainer />
            {state.isVerified ?
                <div className="expert-content-block-section">
                    <div className="first-section">
                        <div className="form-content-blk">
                            <Header />
                            <div className="expert-content-block justify-center items-center overflow-block m-auto">
                                <div className="w-full">
                                    <div className="progress-block">
                                        <div className="progress-bg-line"></div>
                                        <div className="progress-line col-4"></div>
                                        <span className="index">
                                            {}
                                            {contents.d1 ?? messages.d1} {" "}
                                            {contents.outOf ?? messages.outOf} {" "}
                                            {contents.d3 ?? messages.d3}
                                            </span>
                                    </div>
                                    <h4>
                                        {contents?.companyInfo ?? messages?.companyInfo}
                                    </h4>
                                    <div className="expert-profile-photo company-info-block overflow-auto">
                                        <div className="float-start p-0">
                                            <figure className="img-div company-logo-size-big">
                                                {state.companyLogo ?
                                                    <img className="profile-img" src={state.companyLogo} />
                                                    :
                                                    <img className="upload-img" src={Images.upload} />
                                                }
                                            </figure>
                                        </div>
                                        <div className="float-end text-left p-0">
                                            <div className="mb-1 position-relative">
                                                <button className="btn btn-grey btn-lg w-50 pb-2 upload-btn">
                                                {contents?.btnUpload ?? messages?.btnUpload}
                                                </button>
                                                <button onClick={deleteFile} className="btn btn-white btn-lg w-50 pb-2 upload-btn">
                                                {contents?.btnDelete ?? messages?.btnDelete}
                                                </button>
                                                <input
                                                    onChange={e => {
                                                        // console.log('file of image is ', e)
                                                        setState({ ...state, image: e.target.files[0], showModal: true })
                                                    }}
                                                    id="select-file"
                                                    key={randomKey}
                                                    type="file"
                                                    accept="image/*"
                                                    ref={inputRef}
                                                    className="w-50"
                                                />
                                            </div>
                                            <p className="photo-para mb-0">
                                                {contents?.profilePictureCaution2 ?? messages?.profilePictureCaution2}
                                            </p>
                                        </div>
                                    </div>
                                    {state.companyLogoErr && <ErrorText className="errorText center" title={state.companyLogoErr} />}
                                    <form onSubmit={submit} className="mb-3">
                                        <DefaultInput
                                            placeholder={contents?.lblCompanyName ?? messages?.lblCompanyName}
                                            type={"text"}
                                            name={"companyName"}
                                            maxLength={200}
                                            value={state.companyName}
                                            onChange={(e) => setState({ ...state, companyName: e.target.value ? e.target.value.trimLeft() : "", companyErr: validate(contents, 'companyName', e.target.value) })}
                                            error={state.companyErr}
                                        />
                                        <DefaultDropDown
                                            placeholder={contents?.lblIndustry ?? messages?.lblIndustry}
                                            name="Industry"
                                            options={state.industryArr}
                                            value={state.selectedIndustry}
                                            onChange={(value) => setState({ ...state, selectedIndustry: value, industryErr: validate(contents, 'blank', value) })}
                                            error={state.industryErr}
                                            className="single-select"
                                            formatOptionLabel={formatOptionLabel}
                                        />
                                        <DefaultDropDown
                                            placeholder={contents?.lblCountry ?? messages?.lblCountry}
                                            name="country"
                                            options={options}
                                            value={state.selectedCountry}
                                            onChange={(value) => setState({ ...state, selectedCountry: value, countryErr: validate(contents, 'blank', value) })}
                                            error={state.countryErr}
                                            className="single-select"
                                            formatOptionLabel={formatOptionLabel}
                                        />
                                        <DefaultDropDown
                                            placeholder={contents?.lblEmployeesCount ?? messages?.lblEmployeesCount}
                                            name="empNo"
                                            options={state.empNoArr}
                                            // type={"text"}
                                            value={state.selectedEmpNo}
                                            onChange={(value) => setState({ ...state, selectedEmpNo: value, empNoErr: validate(contents, 'blank', value) })}
                                            error={state.empNoErr}
                                            className="single-select"
                                            formatOptionLabel={formatOptionLabel}
                                        />
                                        <div className="bg-black btn-block company-btn">
                                            <button
                                                type="submit"
                                                className="btn btn-black btn-lg btn-min w-full"
                                                disabled={state.loading}>
                                                {contents?.btnContinue ?? messages?.btnContinue}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="second-section right-img">
                        <img src={Images.companyInfo} />
                    </div>
                </div>
                :
                <div className="admin-wrapper-inner admin-form-blk">
                    <div className="danger-box-block">
                        <h4 className="mb-4">{state.status}</h4>
                    </div>
                </div>
            }
            {state.showModal && <ImageUpload
                image={state.image}
                from="dashboard7"
                requestProp="imgUploadR"
                avatarProps={{
                    image: state.image,
                    width: 150,
                    height: 50,
                    border: 0,
                    color: [255, 255, 255, 0.6],
                    rotate: 0,
                    borderRadius: 0,
                }}
                closeUploadModal={() => setState({ ...state, showModal: false })}

                onImageUploadSuccess={(companyLogo) => {
                       setState({ ...state, loading: false, companyLogo, showModal: false, companyLogoErr: '' })
                }}
                onImageUploadError={() => setState({ ...state, loading: false })}
                onImageUploadLoad={() => setState({ ...state, loading: true })}

            />}
        </>
    )
};


const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        verifyEmailRequest: ({ from, email, code }) => {
            dispatch(verifyEmailA({ from, email, code }));
        },
        imgUploadRequest: ({ from, image }) => {
            dispatch(imgUploadA({ from, image }));
        },
        nameExistsRequest: ({ from, name }) => {
            dispatch(nameExistsA({ from, name }));
        },
        industryRequest: ({ from }) => {
            dispatch(industryA({ from }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardStep7);