import React  from "react";
import PropTypes from "prop-types";
import GlobeSVGIcon from "assets/svg/globe.svg";
import ArrowDownBlackIcon from "assets/svg/arrow-down-black.svg";
import CheckedSVGICon from "assets/svg/check-mark.svg";
import { preferredLanguage } from "components/Config/languages";
import { getFilteredLanguages } from "pureFunctions/getLocalLanguagesObject";

const ClientLanguageDropDown = React.forwardRef(
  (
    {
      setShowLngMenu,
      showLngMenu,
      selectedLanguage,
      tenantAvailableClientLanguages,
      selectLanguageChangeHandler,
    },
    ref
  ) => {
    const getStaticLanguages = () => {
       return getFilteredLanguages(preferredLanguage, tenantAvailableClientLanguages);
    }

    return (
      <div className="client-lng" ref={ref}>
        <div
          className="row selection"
          onClick={() => setShowLngMenu(!showLngMenu)}
        >
          <span className="glob">
            <img src={GlobeSVGIcon} alt="" />
          </span>
          <span className="selected-lng">{selectedLanguage.label}</span>
          <span className="arrow-down">
            <img src={ArrowDownBlackIcon} alt="" />
          </span>
        </div>

        <div
          className={`lng-el ${
            showLngMenu ? "show-lng-list" : "hide-lng-list"
          }`}
        >
          <div className="col">
            {getStaticLanguages().map((language, key) => (
              <div
                key={key}
                className="row lng"
                onClick={() => selectLanguageChangeHandler(language)}
              >
                <span>{language.label}</span>{" "}
                {selectedLanguage.value === language.value && (
                  <img src={CheckedSVGICon} alt="" />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
);

ClientLanguageDropDown.propTypes = {
  //   ref: PropTypes.oneOfType([
  //         // Either a function
  //         PropTypes.func,
  //         // Or the instance of a DOM native element (see the note about SSR)
  //         PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  //       ]),
  //   setShowLngMenu: PropTypes.function,
  //   showLngMenu: PropTypes.bool,
  //   selectedLanguage: PropTypes.object,
  //   tenantAvailableClientLanguages: PropTypes.object,
  //   selectLanguageChangeHandler: PropTypes.func,
};
export default ClientLanguageDropDown;
