/*
Some event which described for this component
/hubs/ClientHub/ChatMessage
  // once state is updated from sendMessageCommon or buttonClickHandler
  // to send the message then simply run this use effect
  // As soon as message is sent then simply update that state to false
  // So that it will not run again
  // While type no message will be sent because shouldSendMessage state
  // Will remain false it will be only updated some they either click to
  // The button or click enter key

// Fetch all chat history in initial
Session/GetSessionChatHistory

// this will be used when client will send the message
ClientHub/SendChatMessage
// This is be used when expert sends the message
ExpertHub/SendChatMessage

*/


import { CircularProgress } from "@mui/material";
import activeButton from "assets/svg/chat-active-button.svg";
import ChatSendButton from "assets/svg/chat-send-btn.svg";
import CloseIcon from "assets/svg/close.svg";
import { getError } from "components/ErrorHandler/getError";
import { newMessageIsAvailableLocalStorage } from "config/Config";
import { SendChatMessage } from "config/events/listen";
import { Client, Connected, Expert } from "config/signalRStatus";
import apiName from "constants/apiName";
import messages from "constants/messages";
import { useEffect, useReducer, useRef, useState } from "react";
import { request } from "utils/request";
import "./chat-model.scss";
import ChatMessages from "./ChatMessages";

function reducer(state, action) {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        chatHistory: [...state.chatHistory, ...action.payload],
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default function ChatModel({
  showModel,
  setShowModel,
  localConnection,
  sessionStatus,
  sessionId,
  role,
  expertInfo,
  clientInfo,
  contents,
  setNewMessageAvailble
}) {
  const [message, setMessage] = useState("");
  const [shouldSendMessage, setShouldSendMessage] = useState(false);
  const [{ loading, chatHistory }, dispatch] = useReducer(reducer, {
    loading: true,
    error: null,
    chatHistory: [],
  });
  console.log("ssss")
  // Add class
  let messageBoxHeight;
  if (chatHistory.length <= 4) {
    messageBoxHeight = "inherit";
  }

  useEffect(() => {
    const watchEvent = async () => {
      if (!message || message.trim().length < 1) {
        setShouldSendMessage(false);
        return;
      }

      if (localConnection.current?._connectionState !== Connected) {
        return;
      }

      if (!shouldSendMessage) {
        return;
      }
      const sender = role === Expert ? Expert : Client;
      const messageModel = {
        message,
        sessionId,
        sender,
      };
      try {
        
        setShouldSendMessage(false);
        await localConnection.current.invoke(SendChatMessage, messageModel);

        dispatch({ type: "FETCH_SUCCESS", payload: [messageModel] });
      } catch (err) {
        console.error(err);
        throw new Error(err);
      }

      setMessage("");
    };
    watchEvent();
  }, [
    localConnection,
    sessionStatus,
    shouldSendMessage,
    sessionId,
    message,
    role,
    setShouldSendMessage,
    setMessage,
  ]);

  // Fetching the message intially when component loads and update
  useEffect(() => {
    const fetchMessage = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const response = await request({
          url: `${apiName.getSessionChatHistory}/?sessionID=${sessionId}`,
          method: "get",
        });
        if (response.isSucceeded) {
          dispatch({ type: "FETCH_SUCCESS", payload: response.chatHistory });
        } else {
          dispatch({ type: "FETCH_SUCCESS", payload: response.error });
        }
      } catch (err) {
        dispatch({ type: "FETCH_SUCCESS", payload: getError(err) });
        console.error(err);
      }
    };
    fetchMessage();
  }, [sessionId]);

  // Initially set the local storage for newMessageIsAvailableLocalStorage
  
  useEffect(() => {
    // Lets listen the message fired by the event
    const messagePublished = async () => {
      //ChatMessage
      localConnection.current.on("ChatMessage", (res) => {
        setNewMessageAvailble(true);
        localStorage.setItem(newMessageIsAvailableLocalStorage, '1');
        dispatch({ type: "FETCH_SUCCESS", payload: [res] });
      });
    };
    messagePublished();
  }, [localConnection, setNewMessageAvailble]);

  const sendMessageCommon = () => {
    setShouldSendMessage(true);
  };

  const buttonClickHandler = (btnSubmit) => {
    sendMessageCommon();
  };

  const onEnterKeyHandler = (e) => {
    const keyCode = e.which;
    if (keyCode === 13 && !e.shiftKey) {
      sendMessageCommon();
    }
  };

  
  var getConversationDiv = document.getElementById("gb-conversation");
  var messageBox = document.getElementById("conversation");
  var cH = getConversationDiv?.clientHeight;

  useEffect(() => {
    if (getConversationDiv && messageBox) {
      messageBox.scrollTop = cH;
    }
  }, [getConversationDiv, cH, messageBox]);

  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = "17px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [message]);

  return (
    <div
      className={`side-model-chat model-container meetings-model ${
        showModel ? "show-model-container" : "hide-model-container"
      }`}
    >
      <div className="model">
        <div className="main-contain">
          <div className="row row-title">
            <div className="title">{contents.chat ?? messages.chat}</div>
            <div className="close-icon" onClick={() => {
              setShowModel(false);
              setNewMessageAvailble(false);
              localStorage.setItem(newMessageIsAvailableLocalStorage, '0');
            }}>
              <img src={CloseIcon} alt="" />
            </div>
          </div>
          <div className="row session-end">
            <div className="text">
              {contents.chatMsgDelete ?? messages.chatMsgDelete}
            </div>
          </div>
        </div>

        <div className="conversation" id="conversation">
          {loading && (
            <div className="message-loader">
              <CircularProgress color="inherit" />
            </div>
          )}

          {!loading && (
            <div
              className="messages"
              id="gb-conversation"
              style={{ height: messageBoxHeight }}
            >
              {chatHistory.map((message, key) => (
                <ChatMessages
                  key={key}
                  message={message}
                  myRole={sessionStatus?.myRole}
                  expertInfo={expertInfo}
                  clientInfo={clientInfo}
                  contents={contents}
                />
              ))}
            </div>
          )}
        </div>

        <div className="form">
          <div className="form-elements">
            {/* <div className="col attachment">
              <img src={AttachmentIcon} alt="" />
            </div> */}
            <div className="col input">
              <textarea
                ref={textareaRef}
                value={message}
                className="text-sm md:text-base"
                placeholder={contents?.startTyping ?? messages?.startTyping}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={onEnterKeyHandler}
              />
            </div>
            <div className="col" onClick={buttonClickHandler}>
              <img src={!message ? ChatSendButton : activeButton} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
