import Translate from "components/Common/Translate";

const MainContentClientNotification = ({ tenantInfo, contents }) => {
  return (
    <>
      <div className="description">
        <Translate
          translatedContent={contents?.notification}
          fallbacktranslatedContent={contents?.notification ?? ""}
        />{" "}
        <br />
      </div>
      
    </>
  );
};

export default MainContentClientNotification;
