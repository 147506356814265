const colors = {
    white: 'white',
    black: 'black',
    red: 'red',
    green: 'green',
    lightGray: 'lightgray',
    gray: 'gray',
    primary: '#81D8D0',
    secondary: '#C1AC51',
    transparent: 'transparent',
    semiTransparent: 'rgba(255,255,255,0.5)',
    semiTransparent1: 'rgba(255,255,255,0.9)',
    semiTransparent2: 'rgba(0,0,0,0.1)',
    semiTransparent3: 'rgba(255,255,255,0.7)',
    semiTransparent4: 'rgba(192,192,192, 0.9)',
    borderBlue: '#86b7fe'
};
export default colors;
