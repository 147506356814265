import React, { useEffect, useState } from "react";
import like from "../../assets/images/like.png"
import likeGreen from "../../assets/images/like-green.png"
import cart from "../../assets/images/cart.png"
import { useDispatch, useSelector } from "react-redux";
import { goToProduct, addToRecommendation, removeFromRecommendation, goToSelectedStep, decreaseQuantityProductLists, increaseQuantityProductLists } from "../../redux/action/shopping";
import ReactTooltip from 'react-tooltip';
import Toast from "light-toast";
import messages from "constants/messages";
import { showToast } from "components/Common/Common";
const Card = ({ product, isRecommended, recommendedProducts }) => {
  const [recommend, setRecommend] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const dispatch = useDispatch()
  const { preferedLanguageContent: { contents } } = useSelector((state) => state)
  const connection = useSelector(state => state.shoppingReducer.shoppingConnection)
  const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus)
  const url = new URL(window.location.href);
  const extractUrlValue = (key, url) => {
    if (typeof (url) === 'undefined')
      url = window.location.href;
    var match = url.match('[?&]' + key + '=([^&]+)');
    return match ? match[1] : null;
  }
  useEffect(() => {
    if(contents)
    {
      setForceUpdate((oldVal) => !oldVal)
      ReactTooltip.rebuild();
    }
  }, [contents])
  let sessionId = extractUrlValue("session_id", url.sessionId)
  const recommendProduct = async (item) => {
    try {
      if (connection && Object.keys(sessionStatus).length > 0) {
        const payload = {
          sessionId: sessionId,
          tenant: sessionStatus.tenant,
          email: sessionStatus.expertInfo.email,
          product: {
            productInCartId: item.productId,
            productId: item.productId,
            rawData: item,
          },
        }
        const result = await connection.invoke("AddProductToRecommendationList", payload)
        console.log(result, "ADD RECOMMEND")
        if (result && result.isSucceeded) {
          setRecommend(true)
          showToast(contents?.productRecommended ?? messages?.productRecommended, "success");
          
          dispatch(addToRecommendation(item))
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  const deleteRecomendedProduct = async (item) => {
    try {
      if (connection && Object.keys(sessionStatus).length > 0) {
        const payload = {
          sessionId: sessionId,
          tenant: sessionStatus.tenant,
          productInCartId: item.productId,
          // email: userInfoR && userInfoR.data && userInfoR.data.email,
          productId: item.productId
        }
        console.log(payload, "REMOVE")
        const result = await connection.invoke("RemoveProductFromRecommendationList", payload)
        console.log(result, "RESULT")
        if (result && result.isSucceeded) {
          setRecommend(false)
          dispatch(removeFromRecommendation(item.productId))
        }
      }
    } catch (err) {
      console.log("Client SignalR Error =>", err);
    }
  }
  const handleGoToProductPage = (product) => {
    dispatch(goToProduct(product))
    setTimeout(() => {
      dispatch(goToSelectedStep(2))
    }, 200);
  }
  const handleChooseVariant = (product) => {
    dispatch(goToProduct(product))
    setTimeout(() => {
      dispatch(goToSelectedStep(4))
    }, 200);
  }
  return (
    <div class="w-full mx-auto  s-card">
      <div class="flex items-center">
        <div class="  s-card__img cursor-pointer s-card__left" onClick={() => handleGoToProductPage(product)}>
          <img class="w-full object-cover" src={product.images[0]} alt="A cat" />
        </div>
        <div class="flex flex-col s-card__right">
          <div className="flex items-center justify-between w-full cursor-pointer" onClick={() => handleGoToProductPage(product)}>
            <h2 class=" s-card__title w-1/2"><div dangerouslySetInnerHTML={{ __html: product.title }} /></h2>
            <p href="#" class="block mt-1 text-lg leading-tight font-medium text-black hover:underline s-card__price w-1/2 mr-auto">{Object.keys(sessionStatus).length > 0 && sessionStatus.storeInfo && sessionStatus.storeInfo.currencyInfo && sessionStatus.storeInfo.currencyInfo.currencySymbol} {product.variants[0].price}</p>
          </div>
          <div className={`flex items-center s-card__bottom absolute w-full bottom-0`}>
            {isRecommended ? <button className="s-card__bottom__btn  bg-white flex items-center justify-center s-card__bottom__title" key={product.title+"undo"} data-tip={contents?.clickToUndo ?? messages?.clickToUndo} onClick={() => deleteRecomendedProduct(product)}>
              <img src={likeGreen} /> <span className="ml-2">{contents?.recommend ?? messages?.recommend}</span>
            </button> : <>  {recommendedProducts && recommendedProducts.length > 0 && recommendedProducts.some(item => item.productId === product.productId) ? <button data-tip={contents?.clickToUndo ?? messages?.clickToUndo} className="s-card__bottom__btn bg-white flex items-center justify-center s-card__bottom__title" onClick={() => deleteRecomendedProduct(product)}>
              <img src={likeGreen} /> <span className="ml-2">{contents?.recommend ?? messages?.recommend}</span>
            </button> : <button key={product.title+"recommend"} data-tip={contents?.clickToRecommend ?? messages?.clickToRecommend} className="s-card__bottom__btn flex items-center justify-center s-card__bottom__title" onClick={() => recommendProduct(product)}>
              <img src={like} /> <span className="ml-2">{contents?.recommend ?? messages?.recommend}</span>
            </button>} </>}
            <button className="s-card__bottom__btn s-card__bottom__btn--add flex items-center justify-center" onClick={() => handleChooseVariant(product)}>
              <img src={cart} alt="add" /> <span className="ml-2" >{contents?.add ?? messages?.add}</span>
            </button>
          </div>
        </div>
      </div>
      <ReactTooltip />
    </div>
  )
}
export default Card;