import { updateProfileError, updateProfileSuccess, updateProfileLoading, SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'
import { updateProfileInError, updateProfileInSuccess, updateProfileInLoading } from '../../constants/reduxConstant'

export function updateProfileR(state = {}, action) {
    switch (action.type) {
        case updateProfileLoading:
            return Object.assign({}, state, { status: LOADING });
        case updateProfileSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case updateProfileError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}

export function updateProfileInR(state = {}, action) {
    switch (action.type) {
        case updateProfileInLoading:
            return Object.assign({}, state, { status: LOADING });
        case updateProfileInSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case updateProfileInError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}