import React from "react";

const MessageTemplate = ({children}) => {
  return (
    <div className="gb-loading--container">
      <div className="loader-img">
        {children}
      </div>
    </div>
  );
};

MessageTemplate.propTypes = {};

export default MessageTemplate;
