import React, { useState, useEffect } from "react";
import check from "../../assets/images/check.png";
import cart from "../../assets/images/cart-white.png";
import { useSelector } from "react-redux";
import { optionsHasAllSelectedVariants } from "../../utils/utils";
import messages from "constants/messages";
const BuyerCardItem = ({ product }) => {
  const {
    preferedLanguageContent: { contents },
  } = useSelector((state) => state);
  const [alreadyInCart, setAlreadyInCart] = useState(false);
  const [finalVariant, setFinalVariant] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [outOfStock, setStock] = useState(false);
  const cartItems = useSelector((state) => state.cartReducer.cartItems);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [showVariant, setShowVariant] = useState(true);
  const [showDescription, setShowDescription] = useState(true);
  const [quantity, setQuantity] = useState(0);
  const sessionStatus = useSelector(
    (state) => state.shoppingReducer.sessionStatus
  );
  const connection = useSelector(
    (state) => state.shoppingReducer.shoppingConnection
  );
  const url = new URL(window.location.href);
  const extractUrlValue = (key, url) => {
    if (typeof url === "undefined") url = window.location.href;
    var match = url.match("[?&]" + key + "=([^&]+)");
    return match ? match[1] : null;
  };
  let sessionId = extractUrlValue("session_id", url.sessionId);
  useEffect(() => {
    if (product && product.quantity) {
      setQuantity(product.quantity);
    }
  }, []);
  const handleAddVariant = (category, option) => {
    if (
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant.some((item) => item.option === option)
    ) {
      console.log(category, "CATEGORY ITEM");
      console.log(option);
      const filteredArray = selectedVariant.filter(
        (item) => item.option !== option
      );
      setSelectedVariant(filteredArray);
    } else if (
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant.some((v) => v.category === category)
    ) {
      //Find index of specific object using findIndex method.
      let prevArray = selectedVariant.map((el) => {
        if (el.category === category)
          return Object.assign({}, el, { option: option });
        return el;
      });
      setSelectedVariant(prevArray);
    } else {
      setSelectedVariant([
        ...selectedVariant,
        { category: category, option: option },
      ]);
    }
  };
  const addToCartHandler = async (product) => {
    if (connection && Object.keys(sessionStatus).length > 0) {
      product.quantity = 1;
      product.category = selectedVariant;
      product.selectedVariant = finalVariant;
      product.variantId = finalVariant[0].variantId;
      const payload = {
        sessionId: sessionId,
        tenant: sessionStatus.tenant && sessionStatus.tenant,
        email: sessionStatus.clientInfo.email,
        product: {
          productId: product.productId.toString(),
          quantity: 1,
          variantId: finalVariant[0].variantId,
          rawData: product,
          previousQuantity: 0,
          previousRawData: product,
        },
      };
      console.log(payload);
      const result = await connection.invoke("AddProductToCart", payload);
      console.log(result);
    }
  };
  useEffect(() => {
    let finalArr;
    if (
      selectedVariant &&
      product &&
      product.options &&
      selectedVariant.length === product.options.length
    ) {
      finalArr = product.variants.filter((item) =>
        optionsHasAllSelectedVariants(item.options, selectedVariant)
      );
      setFinalVariant(finalArr);
      if (finalArr && finalArr.length > 0 && finalArr[0].quantity > 0) {
        setDisabled(false);
        setStock(false);
      } else if (
        finalArr &&
        finalArr.length > 0 &&
        finalArr[0].quantity === 0
      ) {
        setStock(true);
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [selectedVariant, product]);
  useEffect(() => {
    if (Object.keys(product).length > 0 && product.options.length === 1) {
      product.options.map((item) =>
        item.values.map((value) =>
          handleAddVariant(item.name, value, product.variants)
        )
      );
    }
  }, [product]);
  useEffect(() => {
    if (
      finalVariant &&
      finalVariant.length > 0 &&
      cartItems &&
      cartItems.length > 0
    ) {
      if (
        cartItems.filter(
          (product) =>
            product.variantId && product.variantId === finalVariant[0].variantId
        ).length
      ) {
        setDisabled(true);
        setAlreadyInCart(true);
      } else {
        setAlreadyInCart(false);
      }
    }
  }, [cartItems, finalVariant]);
  return (
    <div class="w-full mx-auto   s-buyer">
      <h1 className="s-card__header w-11/12 mx-auto">
        <div dangerouslySetInnerHTML={{ __html: product.title }} />
      </h1>
      
      {/* <button
        disabled={disabled}
        className="s-card__bottom__btn s-card__bottom__btn--add flex items-center justify-center"
        onClick={() => addToCartHandler(product)}
      >
        <img src={cart} alt="add" />{" "}
        <span className="ml-2">
          {outOfStock
            ? contents?.outOfStock ?? messages?.outOfStock
            : alreadyInCart
            ? contents?.alreadyInCart ?? messages?.alreadyInCart
            : contents?.addToCart ?? messages?.addToCart}
        </span>
      </button> */}
      <p className="mt-4 s-card__priceDescription text-left w-11/12 mx-auto">
        {sessionStatus?.storeInfo?.currencyInfo?.currencySymbol}{" "}
        {finalVariant && finalVariant.length > 0
          ? finalVariant[0].price
          : product.variants[0].price}
      </p>
      <br />
      <br />
      {/* disabled && `opacity-50` */}
      <br/>
      <div className={`flex  ${disabled && `opacity-50`}`}>
        <button
          disabled={disabled}
          className={`ml-1rem w-50 btn btn--primary`}
          onClick={() => addToCartHandler(product)}
        >
          <img src={cart} alt="add" />{" "}
          <span className="ml-2">
            {outOfStock
              ? contents?.outOfStock ?? messages?.outOfStock
              : alreadyInCart
              ? contents?.alreadyInCart ?? messages?.alreadyInCart
              : contents?.addToCart ?? messages?.addToCart}
          </span>
        </button>
      </div>

      {product.options &&
        product.options.length > 0 &&
        product.options[0].name !== "Title" && (
          <div
            className="s-card__section mt-4 flex justify-between items-center w-11/12 mx-auto"
            onClick={() => setShowVariant(!showVariant)}
          >
            <h4 className="s-card__section__title">
              {contents?.variants ?? messages?.variants}
            </h4>
            {showVariant ? (
              <p className="s-card__section__minus"></p>
            ) : (
              <p className="text-2xl text-black cursor-pointer">+</p>
            )}
          </div>
        )}
      
      {product.options &&
        showVariant &&
        product.options.length > 0 &&
        product.options[0].name !== "Title" &&
        product.options.map((item) => (
          <div className="mt-4 s-card__variant w-11/12 mx-auto">
            <p className="s-card__variant__title">{item.name}</p>
            {item.name === "Color" || item.name === "Colour" ? (
              <div className="mt-2 flex items-center flex-wrap">
                {item.values.map((value) => (
                  <button
                    style={
                      selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value)
                        ? {
                            fontWeight: "bold"
                          }
                        : {}
                    }
                    className={`${
                      selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value) &&
                      `selected-color`
                    } s-variant__item flex items-center`}
                    onClick={() => handleAddVariant(item.name, value)}
                  >
                    <span className="mr-2">{value}</span>
                    {selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value) && (
                        <img className="ml-2" src={check} alt="Check Icon" />
                      )}
                  </button>
                ))}
              </div>
            ) : (
              <div className="mt-2 flex items-center flex-wrap">
                {item.values.map((value) => (
                  <button
                    style={
                      item.name === "Color"
                        ? { background: value.toLowerCase() }
                        : null
                    }
                    className={`${
                      selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value) &&
                      `selected-item`
                    } s-variant__item flex items-center`}
                    onClick={() => handleAddVariant(item.name, value)}
                  >
                    <span>{value}</span>{" "}
                    {selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value) && (
                        <img className="ml-2" src={check} alt="Check Icon" />
                      )}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
        
      <section className="w-11/12 mx-auto">
        <div
          className="s-card__section mt-4 flex justify-between items-center"
          onClick={() => setShowDescription(!showDescription)}
        >
          <h4 className="s-card__section__title ">
            {contents?.description ?? messages?.description}
          </h4>
          {showDescription ? (
            <p className="s-card__section__minus"></p>
          ) : (
            <p className="text-2xl text-black cursor-pointer">+</p>
          )}
        </div>
        <p className="mt-4" style={{ overflow: "hidden" }}>
          {showDescription && (
            <div
              style={{ minHeight: "60vh" }}
              dangerouslySetInnerHTML={{
                __html: product.description
                  ? product.description
                  : contents?.noDescription ?? messages?.noDescription,
              }}
            />
          )}
        </p>
      </section>

      <div
        className={`flex items-center s-card__bottom ${
          disabled && `opacity-50`
        }`}
      >
        {/* <button disabled={disabled} className="s-card__bottom__btn s-card__bottom__btn--add flex items-center justify-center" onClick={() => addToCartHandler(product)}>
          <img src={cart} alt="add" />  <span className="ml-2">{outOfStock ? (contents?.outOfStock ?? messages?.outOfStock) : alreadyInCart ? (contents?.alreadyInCart ?? messages?.alreadyInCart) : (contents?.addToCart ?? messages?.addToCart)}</span>
        </button> */}
      </div>
    </div>
  );
};
export default BuyerCardItem;
