import { put, post, get } from '../../../utils/Api';
import axios from 'axios';
import { industryLoading, industrySuccess, industryError } from '../../constants/reduxConstant';
import { industryInLoading, industryInSuccess, industryInError } from '../../constants/reduxConstant';
import apiName from '../../../constants/apiName';
import udid from '../../../components/Common/udid';

const apiLoading = () => ({ type: industryLoading });
export const apiSucceed = (payload) => ({ type: industrySuccess, payload });
const apiError = (payload) => ({ type: industryError, payload });

const apiLoadingOne = () => ({ type: industryInLoading });
export const apiSucceedOne = (payload) => ({ type: industryInSuccess, payload });
const apiErrorOne = (payload) => ({ type: industryInError, payload });

const industryA = ({ from }) => async (dispatchEvent, getState) => {
    let language = JSON.parse(localStorage.getItem("selectedLanguage") ?? '"en"')
    let url = `${apiName.industry}?language=${language}`
    if (from === 'dashboard7') {
        dispatchEvent(apiLoading());
        try {
            get(url)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        console.log('This dashboard7 ran')
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    }
    else if (from === 'company') {
        dispatchEvent(apiLoadingOne());
        try {
            get(url)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        
                        dispatchEvent(apiSucceedOne(res));
                    } else {
                        dispatchEvent(apiErrorOne(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiErrorOne(error));
                });
        } catch (e) {
            dispatchEvent(apiErrorOne(e));
        }
    }
}

export default industryA