import { Skeleton } from "@mui/material";
import shareImage from "assets/svg/share-expert.svg";
import { showToast } from "components/Common/Common";
import { copyLink } from "components/Common/copyLink";
import { getError } from "components/ErrorHandler/getError";
import { clientAPIStatus, componentsName } from "config/client-api-status";
import apiName from "constants/apiName";
import messages from "constants/messages";
import useSetGetLanguageForClient from "hooks/setGetLanguageForClient";
import useTenantInfo from "hooks/tenantInfo";
import useFetchClientAppSettings from "hooks/useFetchClientAppSettings";
import useFetchTenantInfo from "hooks/useFetchTenantInfo";
import useLanguage from "hooks/useLanguage";
import { isoTwoDigitiToName } from "pureFunctions/isoTwoDigitiToName";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import callFetchClientAppTextSettings from "redux/call-action/callFetchClientAppTextSetting";
import { langArr } from "redux/constants/StaticArr";
import { request } from "utils/request";
import BackNavigation from "views/client/common/BackNavigation";
import ScheduleAndCallButtons from "views/client/common/ScheduleAndCallButtons";
import ClientLanguageDropDown from "../common/ClientLanguageDropDown";
import "./expert-profile.scss";
import ExpertAvatar from "./ExpertAvatar";
import useSignalR from "hooks/useSignalR";
import callFetchExpertsForClient from "redux/call-action/callFetchExpertsForClient";

const getLanguage = (spoken) => {
  return isoTwoDigitiToName(langArr, spoken) || [];
};

const ExpertProfile = (props) => {
  const {
    preferedLanguageContent: { contents, countriesList },
    tenantExperts: { value },
    tenantClientAppSettings,
  } = useSelector((state) => state);
  const { value: clientAppSetting } = tenantClientAppSettings;
  const { experts } = value || {};

  const [expertDetails, setExpertDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let { tenant, email } = useParams();

  const { tenantInfo } = useTenantInfo();
  const history = useHistory();
  const dispatch = useDispatch();
  useFetchTenantInfo({ tenant, tenantInfo });

  useFetchClientAppSettings({ tenant, clientAppSetting });

  const {
    numberOfAvailableExperts
  } = useSignalR();
  const fetchExpertsForClient = useCallback( async()=> {
    await dispatch(callFetchExpertsForClient(tenant));
  },[tenant,dispatch]);
  useEffect(() => {
    if (tenant) {
      fetchExpertsForClient();
    }
  }, [tenant, fetchExpertsForClient,numberOfAvailableExperts]);

  useEffect(() => {
    const fetchExpertProfile = async () => {
      setIsLoading(true);
      try {
        const data = await request({
          url: `${
            apiName.getExpertInfo
          }?Tenant=${tenant}&Email=${encodeURIComponent(window.atob(email))}`,
          method: "get",
        });
        if (data.status === clientAPIStatus.UserNotFound) {
          showToast("Info, Unable to find the user", "warn");
        }
        if (data.status === clientAPIStatus.InternalError) {
          showToast("Error, Inernal server error", "warn");
        }
        setExpertDetails({ ...data.details });
        setIsLoading(false);
      } catch (err) {
        showToast("Error", getError(err));
        console.error(err);
        setIsLoading(false);
      }
    };
    fetchExpertProfile();
  }, [email, tenant, experts]);

  const isLoadingOrData = (isLoading, data) => {
    if (isLoading) {
      return <Skeleton variant="text" />;
    }
    if (!isLoading) {
      return data;
    }
  };

  const navigateToScheduleCallWithExpert = (email) => {
    return (e) => {
      e.stopPropagation();
      history.push(
        `/${tenant}/${window.btoa(email)}/select-date-to-schedule-call`
      );
    };
  };

  const profileUrl = window.location.href;


  const selectLanguageChangeHandler = async(lng) => {
    setSelectedLanguage(lng);
    setShowLngMenu(false);
    await dispatch(callFetchClientAppTextSettings(tenant, lng.value));
  };

  const { selectedLanguage, setSelectedLanguage, showLngMenu, setShowLngMenu } =
    useSetGetLanguageForClient();

  const getSelectedLanguage = useMemo(() => {
    return selectedLanguage;
  }, [selectedLanguage]);

 
  useLanguage({
    lng: getSelectedLanguage?.value,
    shouldFetchLanguage: true,
  });

  const ref = useRef();

  const tenantAvailableClientLanguages = useMemo(() => {
    return clientAppSetting.availableClientLanguages;
  }, [clientAppSetting]);

  return (
    <div className="client-container">
      <div className="gb-pubilc-wrapper">
        <section className="gb-public-container">
          <div className="logo-row">
            <img src={tenantInfo?.logoUrl} alt="" />
           {/* {getHomePageURL} */}

           {tenantAvailableClientLanguages?.length > 0 && (
            <ClientLanguageDropDown
              ref={ref}
              setShowLngMenu={setShowLngMenu}
              showLngMenu={showLngMenu}
              selectedLanguage={selectedLanguage}
              tenantAvailableClientLanguages={tenantAvailableClientLanguages}
              selectLanguageChangeHandler={selectLanguageChangeHandler}
            />
          )}
          </div>

          <BackNavigation history={history} contents={contents} />

          <div className="details">
            <>
              <div className="col left">
                <div className="profile-image">
                  <ExpertAvatar
                    isLoading={isLoading}
                    expertDetails={expertDetails}
                  />
                </div>
              </div>

              <div className="col right">
                <div className="item about-expert">
                  <div className="name-available">
                    <h1 className="name">
                      {isLoading && <Skeleton variant="text" width={"100%"} />}
                      {!isLoading && expertDetails?.name}
                    </h1>
                    <span
                      className={`avaibilities ${
                        expertDetails?.isOnline ? "online" : "offline"
                      }`}
                    ></span>
                  </div>

                  <div className="share-p">
                    <span
                      className="share"
                      onClick={() => copyLink(contents, profileUrl)}
                    >
                      {contents?.shareProfile ?? messages.shareProfile}
                    </span>
                    <span className="share-img">
                      <img src={shareImage} alt="" />
                    </span>
                  </div>
                </div>

                <div className="item about-expert-mobile">
                  <div className="col avatar">
                    <ExpertAvatar
                      isLoading={isLoading}
                      expertDetails={expertDetails}
                    />
                  </div>
                  <div className="col">
                    <div className="name-available">
                      <h1 className="name">
                        {isLoading && (
                          <Skeleton variant="text" width={"100%"} />
                        )}
                        {!isLoading && expertDetails?.name}
                      </h1>
                      <span
                        className={`avaibilities ${
                          expertDetails?.isOnline ? "online" : "offline"
                        }`}
                      ></span>
                    </div>

                    <div className="share-p">
                      <span
                        className="share"
                        onClick={() => copyLink(contents, profileUrl)}
                      >
                        {contents?.shareProfile ?? messages.shareProfile}
                      </span>
                      <span className="share-img">
                        <img src={shareImage} alt="" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="item paragrap">
                  {isLoading && (
                    <>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </>
                  )}
                  {!isLoading && (
                    <>
                      <p>{expertDetails?.description.trim() || (contents?.default_description ?? messages?.default_description)}</p>
                    </>
                  )}
                </div>

                <div className="item location-lng">
                  <div className="col">
                    <div className="row title">
                      {isLoading && <Skeleton variant="text" />}
                      {!isLoading && <>{contents.based ?? messages.based}</>}
                    </div>
                    <div className="row value">
                      {isLoading && <Skeleton variant="text" />}
                      {(!isLoading &&
                        countriesList[
                          expertDetails?.location?.toUpperCase()
                        ]) ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="col">
                    <div className="row title">
                      {isLoadingOrData(
                        isLoading,
                        contents.languages ?? messages.languages
                      )}
                    </div>
                    <div className="row value">
                      {isLoadingOrData(
                        isLoading,
                        getLanguage(expertDetails?.spokenLanguages).map(
                          (item) => (
                            <span className="lng" key={item}>
                              {item}
                            </span>
                          )
                        )
                      )}
                      {expertDetails?.spokenLanguages?.length === 0 && "N/A"}
                    </div>
                  </div>
                </div>

                <ScheduleAndCallButtons
                  classSlug="flex-cols-buttons"
                  from={componentsName.expertProfile}
                  email={expertDetails?.email}
                  uniqueIdentifier={tenant}
                  displayCallButton={expertDetails?.isOnline}
                  clientAppSetting={clientAppSetting}
                  contents={contents}
                  navigateToScheduleCallWithExpert={
                    navigateToScheduleCallWithExpert
                  }
                  numberOfOnlineExperts={expertDetails?.isOnline ? 1 : 0}
                />
              </div>
            </>
          </div>
        </section>
      </div>
    </div>
  );
};

ExpertProfile.propTypes = {};

export default ExpertProfile;
