import { expertsTeamPendingInvitesError, expertsTeamPendingInvitesSuccess, expertsTeamPendingInvitesLoading, SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'

export default function expertsTeamPendingInvitesR(state = {}, action) {
    switch (action.type) {
        case expertsTeamPendingInvitesLoading:
            return Object.assign({}, state, { status: LOADING });
        case expertsTeamPendingInvitesSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case expertsTeamPendingInvitesError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}