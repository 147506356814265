import { put, post } from '../../../utils/Api';
import axios from 'axios';
import { resetPasswordLoading, resetPasswordSuccess, resetPasswordError } from '../../constants/reduxConstant';
// import { resetPasswordExpLoading, resetPasswordExpSuccess, resetPasswordExpError } from '../../constants/reduxConstant';
import apiName from '../../../constants/apiName';
import udid from '../../../components/Common/udid';

const apiLoading = () => ({ type: resetPasswordLoading });
const apiSucceed = (payload) => ({ type: resetPasswordSuccess, payload });
const apiError = (payload) => ({ type: resetPasswordError, payload });

// const apiLoadingOne = () => ({ type: resetPasswordExpLoading });
// const apiSucceedOne = (payload) => ({ type: resetPasswordExpSuccess, payload });
// const apiErrorOne = (payload) => ({ type: resetPasswordExpError, payload });

const resetPasswordA = ({ from, body }) => async (dispatchEvent, getState) => {
    // let body = new FormData();
    // body.append('body', body)

    if (from == 'expResetPassword') {
        dispatchEvent(apiLoading());
        try {
            post(apiName.resetPassword, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    }
    // else if (from == 'expCompleteProfile') {
    //     dispatchEvent(apiLoadingOne());
    //     try {
    //         put(apiName.resetPassword, body)
    //             .then(async (res) => {
    //                 if (res && res.data && res.data.isSucceeded) {
    //                     dispatchEvent(apiSucceedOne(res));
    //                 } else {
    //                     dispatchEvent(apiErrorOne(res));
    //                 }
    //             })
    //             .catch((error) => {
    //                 dispatchEvent(apiErrorOne(error));
    //             });
    //     } catch (e) {
    //         dispatchEvent(apiErrorOne(e));
    //     }
    // }
}

export default resetPasswordA