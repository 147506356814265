import defaultClientBackground from "assets/img/default-client-background-image.png";
import { componentsName } from "config/client-api-status";
import { localStorageLanguageKey } from "config/Config";
import messages from "constants/messages";
import useSetGetLanguageForClient from "hooks/setGetLanguageForClient";
import useLanguage from "hooks/useLanguage";
import useOnClickOutside from "hooks/useOnClickOutside";
import PropTypes from "prop-types";
import { isValidURL } from "pureFunctions/validURL";
import { useCallback, useMemo, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import callFetchClientAppSettings from "redux/call-action/callFetchClientAppSetting";
import callFetchClientAppTextSettings from "redux/call-action/callFetchClientAppTextSetting";
import BackButton from "views/client/common/BackButton";
import ClientLanguageDropDown from "../ClientLanguageDropDown";
import "./scheduling-template.scss";
import { isJsonString } from "pureFunctions/isJSONString";
import { localStorageClientLanguageKey } from "config/Config";

const SchedulingTemplate = ({
  main,
  actionButtons,
  tenantInfo,
  clientAppSetting,
  contents,
  backButton,
  from,
  goBackFromActionComponent,
  rowClass,
  resetAvailabalityHanlder,
  tenantClientAppTextSetting,
}) => {

  // console.log("content from scheduling template", contents)
  const getValidURL = useCallback(() => {
    if (isValidURL(clientAppSetting?.image)) return clientAppSetting?.image;

    return defaultClientBackground;
  }, [clientAppSetting]);

  const tenantAvailableClientLanguages = useMemo(() => {
    return clientAppSetting.availableClientLanguages;
  }, [clientAppSetting]);

  const rightStyle = useMemo(() => {
    return {
      backgroundImage: `url(${getValidURL()})`,
      backgroundSize: "cover",
    };
  }, [getValidURL]);

  // Will be removed in future version
  const getHomePageURL = useMemo(() => {
    if (!clientAppSetting?.logoRedirectUrl) {
      return null;
    }
    return (
      <a
        href={clientAppSetting?.logoRedirectUrl}
        target="_blank"
        rel="noreferrer"
        id="gb-vs-344"
      >
        {" "}
        {contents?.visitOurWebsite ?? messages?.visitOurWebsite}
      </a>
    );
  }, [contents, clientAppSetting?.logoRedirectUrl]);

  const isFlex = useMemo(() => {
    const avoidFlex = [
      componentsName.speakWithSpecificExpert,
      componentsName.selectDateToScheduleCall,
    ];
    return avoidFlex.indexOf(from) === -1 ? "d-flex-gb" : null;
  }, [from]);

  const ref = useRef();
  const dispatch = useDispatch();

  useOnClickOutside(ref, () => setShowLngMenu(false));

  const { tenant } = useParams();

  const selectLanguageChangeHandler = async (lng) => {
    setSelectedLanguage(lng);
    setShowLngMenu(false);
    await dispatch(callFetchClientAppTextSettings(tenant, lng.value));
  };

  const { selectedLanguage, setSelectedLanguage, showLngMenu, setShowLngMenu } =
    useSetGetLanguageForClient();

  const getSelectedLanguage = useMemo(() => {
    return selectedLanguage;
  }, [selectedLanguage]);

  useLanguage({
    lng: getSelectedLanguage?.value,
    shouldFetchLanguage: true,
    fromClient: true
  });

  const isLanguageKeyExists = isJsonString(localStorage.getItem(localStorageClientLanguageKey)) ;

   useEffect(() => {
    const fethcClientAppTextSettings = async() => {
      await dispatch(callFetchClientAppTextSettings(tenant, isLanguageKeyExists))
    }

    if(Object.entries(tenantClientAppTextSetting).length === 0) {
       fethcClientAppTextSettings();
    }
    
  }, [tenant, dispatch, tenantClientAppTextSetting, isLanguageKeyExists]);




  return (
    <div className="gb-light-background">
      <section className="gb-initial-page">
        <div className="left item">
          {/* <div className="visit-our"></div> */}
          <div className={`logo-row ${isFlex}`}>
            <div className={`row d-flex-gb`}>
              <img src={clientAppSetting?.logoUrl} alt="" />
      
              {tenantAvailableClientLanguages?.length > 0 && (
                <ClientLanguageDropDown
                  ref={ref}
                  setShowLngMenu={setShowLngMenu}
                  showLngMenu={showLngMenu}
                  selectedLanguage={selectedLanguage}
                  tenantAvailableClientLanguages={
                    tenantAvailableClientLanguages
                  }
                  selectLanguageChangeHandler={selectLanguageChangeHandler}
                />
              )}

              {/* {getHomePageURL} */}
            </div>

            {backButton && (
              <BackButton
                contents={contents}
                from={from}
                goBackFromActionComponent={goBackFromActionComponent}
                resetAvailabalityHanlder={resetAvailabalityHanlder}
              />
            )}
          </div>

          <div className={`rows ${rowClass}`}>
            <div className="row">
              {/* {backButton && <BackButton contents={contents} from={from} goBackFromActionComponent={goBackFromActionComponent}/>}
               */}
              <div className="child-row">
                <div className="title">
                  {tenantClientAppTextSetting?.title ??
                    contents?.tenantTitle ??
                    messages?.tenantTitle}
                </div>
                {main}
              </div>
              {actionButtons}
            </div>
          </div>
        </div>

        <div className="right item">
          <div className="banner" style={rightStyle}>
            {/* <img src={getValidURL()} alt="" /> */}
          </div>
        </div>
      </section>
    </div>
  );
};

SchedulingTemplate.propTypes = {
  actionButtons: PropTypes.object,
  main: PropTypes.object,
  children: PropTypes.object,
  tenantInfo: PropTypes.shape({
    uniqueIdentifier: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.string,
    industry: PropTypes.string,
    numberOfEmployees: PropTypes.string,
    url: PropTypes.string,
    logoUrl: PropTypes.string,
    numberOfOnlineExperts: PropTypes.number,
    numberOfAvailableExperts: PropTypes.number,
    storeType: PropTypes.string,
    googleAnalyticsKey: PropTypes.string,
    avialableClientLanguages: PropTypes.arrayOf(PropTypes.string),
    clientDefaultLanguage: PropTypes.string,
    isSucceeded: PropTypes.bool,
    status: PropTypes.string,
    error: PropTypes.any,
  }),
};

export default SchedulingTemplate;
