import searchIcon from "assets/svg/search.svg";

const SearchFrom = ({ placeholder, setValue, value, closeIcon, closeIconChangeHandler, customClass }) => {
  return (
    <div className={`col search-form ${customClass ? customClass : ""}`}>
      <img src={searchIcon} alt="" className="search-icon" />
      <input
        type="text"
        placeholder={placeholder}
        className="search-input"
        onChange={(e) =>  {
          setValue(e.target.value);
        }}
        value={value}
      />
      {closeIcon && <span className="cross-icon" onClick={closeIconChangeHandler}>X</span>}
    </div>
  );
};

SearchFrom.propTypes = {};

export default SearchFrom;
