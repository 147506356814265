import axios from "axios";
import { localStorageLanguageKey, userData } from "config/Config";
import { login } from "config/redirectURIs";
import setAuthToken from "./setAuthToken";

const handleSuccess = (response) => {
  return response;
};

const handleError = (error) => {
  return Promise.reject(error);
};

export const request = async ({ url, method, body }) => {
  setAuthToken();
  // Interceptor response
  let service = axios.create({});
  service.interceptors.response.use(handleSuccess, handleError);
  try {
    const response = await axios[method](url, { ...body });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      localStorage.removeItem(userData);
      localStorage.removeItem(localStorageLanguageKey);
      window.location.href = `/${login}`;
    }
    return Promise.reject(err);
  }
};
