import udid from "../../components/Common/udid";
import apiName from "../../constants/apiName";
import { post } from "../../utils/Api";
import {
  logoutError,
  logoutLoading,
  logoutSuccess,
} from "../constants/reduxConstant";
const apiLoading = () => ({ type: logoutLoading });
export const apiSucceed = (payload) => ({ type: logoutSuccess, payload });
const apiError = (payload) => ({ type: logoutError, payload });
const logoutCall = (sessionId) => async (dispatchEvent) => {
  try {
    dispatchEvent(apiLoading());
    let body = {
      sessionId: sessionId,
      id: udid(),
    };
    post(apiName.logout, body)
      .then(async (res) => {
        if (res && res.data && res.data.isSucceeded) {
          dispatchEvent(apiSucceed(res));
        } else {
          dispatchEvent(apiError(res));
        }
      })
      .catch((error) => {
        dispatchEvent(apiError(error));
      });
  } catch (e) {
    dispatchEvent(apiError(e));
  }
};
export default logoutCall;
