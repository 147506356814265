import { useHistory } from "react-router";
import Images from "../../constants/Images";
import { useState, useEffect } from "react";
import messages from "../../constants/messages";
import { useSelector } from "react-redux";

export const Header = (props) => {
 const globalState = useSelector((state) => state);
  const {
    preferedLanguageContent: { contents },
  } = globalState;

  const history = useHistory();

  const [state, setState] = useState({
    imgSrc: "",
  });

  useEffect(() => {
    if (
      history.location.pathname === "/expert-create-password" ||
      history.location.pathname === "/expert-complete-profile" ||
      history.location.pathname === "/expert-complete-profile-step2"
    ) {
      setState({ imgSrc: props.imgSrc });
    } else {
      setState({ imgSrc: Images.adminLogo });
    }
  }, [props]);

  return (
    <header className="header-old-454545">
      <div
        className={
          state.imgSrc !== Images.adminLogo
            ? "flex justify-center"
            : "flex justify-between"
        }
      >
        <div>
          <img
            alt=""
            className="log-width-gb cursor-pointer"
            src={state.imgSrc}
            // onClick={() => history.push("/register")}
          />
        </div>
        <div className="desktop">
          {(history.location.pathname === "/2682e0be-d4b2-4836-98b2-5d7dcd04dab6" ||
            history.location.pathname === "/expert-register") && (
            <div className="flex items-center">
              <span className="mr-1 inter-font">
                {contents.alreadyHaveAccount ?? messages.alreadyHaveAccount}
              </span>
              <a href="/login" alt="" className="underline border-b">
                {contents.signIn ?? messages.signIn}
              </a>
            </div>
          )}
          {/* {history.location.pathname === "/login" && (
            <div className="flex items-center">
              <span className="mr-1 inter-font">
                {contents.dontHaveAccount ?? messages.dontHaveAccount}
              </span>
              <a
                href="/register"
                alt=""
                className="underline border-b inter-font"
              >
                {contents.createAccount ?? messages.createAccount}
              </a>
            </div>
          )} */}
        </div>
      </div>
    </header>
  );
};
