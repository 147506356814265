//schedule-call-with-specific-expert
import calendarLightIcon from "assets/svg/calendar-light.svg";
import CountrySelect from "components/Common/CountrySelect";
import "views/client/ScheduleCallWithSpecificExpert/schedule-call-with-specific-expert.scss";
import SchedulingTemplate from "../common/SchedulingTemplate";

const Main = () => {
  return (
    <section className="expert-intro">
      <div className="row">
        <div className="selected-date">Friday, May 12th 2022</div>
      </div>
      <div className="row client-time">
        <div className="col selected-time">09:30 - 10:00 pm</div>
        <div className="timezone">Client-side timezone name</div>
      </div>
      <div className="row">
        <div className="please-enter">
          Please enter your contact information to receive notifications
        </div>
      </div>
      <div className="row">
        <form className="schedule-call">
          <div className="form-group">
            <input
              name="firstName"
              type="text"
              id="firstName"
              placeholder="Name"
            />
          </div>

          <div className="form-group">
            <input name="email" type="email" id="email" placeholder="Email" />
          </div>

          <div className="form-group">
            <div className="col country-code">
              <CountrySelect />
            </div>
            <div className="col phone">
              <input
                name="phone"
                type="text"
                id="phone"
                placeholder="Phone number"
              />
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <div className="actions">
          <button className="btn btn--primary schedule-btn">
            <span>
              <img src={calendarLightIcon} alt="" />
            </span>
            <span>Schedule a call</span>
          </button>
        </div>
      </div>
    </section>
  );
};

const ScheduleCallWithSpecificExpert = (props) => {
  return <SchedulingTemplate main={<Main />}></SchedulingTemplate>;
};

ScheduleCallWithSpecificExpert.propTypes = {};

export default ScheduleCallWithSpecificExpert;
