import { post } from "../../utils/Api";
import {
  registerLoading,
  registerSuccess,
  registerError,
} from "../constants/reduxConstant";
import {
  expCreatePassLoading,
  expCreatePassSuccess,
  expCreatePassError,
} from "../constants/reduxConstant";
import apiName from "../../constants/apiName";

const apiLoading = () => ({ type: registerLoading });
const apiSucceed = (payload) => ({ type: registerSuccess, payload });
const apiError = (payload) => ({ type: registerError, payload });

const apiLoadingOne = () => ({ type: expCreatePassLoading });
const apiSucceedOne = (payload) => ({ type: expCreatePassSuccess, payload });
const apiErrorOne = (payload) => ({ type: expCreatePassError, payload });

const registerA =
  ({ from, body }) =>
  async (dispatchEvent, getState) => {
    if (from === "register") {
      dispatchEvent(apiLoading());
      try {
        post(apiName.adminRegister, body)
          .then(async (res) => {
            if (res && res.data && res.data.isSucceeded) {
              dispatchEvent(apiSucceed(res));
            } else {
              dispatchEvent(apiError(res));
            }
          })
          .catch((error) => {
            dispatchEvent(apiError(error));
          });
      } catch (e) {
        dispatchEvent(apiError(e));
      }
    } else if (from == "expCreatePass") {
      dispatchEvent(apiLoadingOne());
      try {
        post(apiName.adminRegister, body)
          .then(async (res) => {
            if (res && res.data && res.data.isSucceeded) {
              dispatchEvent(apiSucceedOne(res));
            } else {
              dispatchEvent(apiErrorOne(res));
            }
          })
          .catch((error) => {
            dispatchEvent(apiErrorOne(error));
          });
      } catch (e) {
        dispatchEvent(apiErrorOne(e));
      }
    }
  };

export default registerA;
