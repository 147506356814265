import { CHANGE_PAGE, CONNECTION, AUTH_USER } from "../constants/Auth";

export const authentication = (actionType, actionData) => {
  return {
    type: actionType,
    payload: actionData,
  };
};

export const onChangePage = (page) => {
  return {
    type: CHANGE_PAGE,
    payload: page
  }
}

export const updateSignalRConnection = (connection) => {
  return {
    type: CONNECTION,
    payload: connection
  }
}

export const authUser = (payload) => {
  return {
    type: AUTH_USER,
    payload
  }
}