import { SHOW_BUYER_PRODUCTS, GET_AGORA_USER, CLEAN_SHOPPING, SESSION_STATUS, REQUEST_CONNECTION, GET_PRODUCTS_COLLECTIONS, SHOW_VIDEO_SCREEN, CLEAR_ITEM_FROM_PRODUCTS, SHOW_CATALOG, ADD_TO_CART, SELECTED_PRODUCT, GET_PRODUCTS, LOADING_SHOPPING, ADD_TO_RECOMMENDATION, REMOVE_FROM_RECOMMENDATION, ACTIVE_STEP, SHOPPING_CONNECTION, UPDATE_PRODUCTS, REMOVE_QUANTITY, GET_PRODUCTS_COLLECTIONS_ERROR, GET_PRODUCTS_ERROR, SHOW_INVITATION_SCREEN, STORE_INTEGRATION } from "../constants/Index";
import { get, post } from "../../utils/Api";
import Toast from "light-toast";
export const addToCart = (selectedProduct) => {
  return {
    type: ADD_TO_CART,
    payload: selectedProduct
  };
};
export const cleanShoppingReducer = () => {
  return {
    type: CLEAN_SHOPPING,
  }
}
export const goToProduct = (selectedProduct) => {
  return {
    type: SELECTED_PRODUCT,
    payload: selectedProduct,
  };
};
export const switchToVideoScreen = (state) => {
  return {
    type: SHOW_VIDEO_SCREEN,
    payload: state
  }
}
export const increaseQuantityProductLists = item => ({
  type: UPDATE_PRODUCTS,
  payload: item
});
export const clearItemFromProducts = item => ({
  type: CLEAR_ITEM_FROM_PRODUCTS,
  payload: item
})
export const decreaseQuantityProductLists = item => ({
  type: REMOVE_QUANTITY,
  payload: item
});
export const getProducts = (baseUrl, tenant, collectionId, searchText, productsPage, products) => async dispatch => {
  dispatch({ type: LOADING_SHOPPING, payload: true })
  const url =  `${baseUrl}Store/${tenant}/products`
  const pageSize = 5
  let skip = pageSize * (productsPage - 1)
  const payload = {
    collectionId: collectionId ? collectionId: null,
    query: searchText ? searchText : '', 
    skip: skip, 
    take: pageSize
  }
  try {
    // get(url)
    post(url, payload)
      .then(async (res) => {
        if(res.data.isSucceeded)
        {
          let newArr = res.data.products.map(function (entry) {
            entry.quantity = 0;
            return entry;
          });
          if(products && products.length > 0)
          {
            newArr = [...products, ...newArr]
          }
          
          dispatch({
            type: GET_PRODUCTS, payload: {
              products: newArr,
              totalCount: res.data.totalCount
            }
          })
          dispatch({ type: LOADING_SHOPPING, payload: false })
        }
        else
        {
          dispatch({ type: GET_PRODUCTS_ERROR, payload: res.data.status })
          dispatch({ type: LOADING_SHOPPING, payload: false })
        }
      })
      .catch((error) => {
        dispatch({ type: GET_PRODUCTS_ERROR, payload: error })
        dispatch({ type: LOADING_SHOPPING, payload: false })
      });
  } catch (e) {
    dispatch({ type: LOADING_SHOPPING, payload: false })
  }
}
export const getAgoraUser = (client) => {
  return {
    type: GET_AGORA_USER,
    payload: client
  };
};
export const getSessionStatus = (baseUrl, payload) => async dispatch => {
  try {
    post(`${baseUrl}Session/sessionInfo`, payload)
      .then((res) => {
        dispatch({ type: SESSION_STATUS, payload: res.data.sessionInfo })
      })
      .catch((error) => {
        console.log(error)
      });
  } catch (e) {
    console.log(e)
  }
};
export const getPFSetting = (baseUrl, tenant) => async dispatch => {
  try {
    post(`${baseUrl}tenant/getStoreIntegration`, {
      tenant
    })
      .then((res) => {
        console.log(res.data.storeIntegration)
        dispatch({ type: STORE_INTEGRATION, payload: res.data.storeIntegration })
      })
      .catch((error) => {
        console.log(error)
      });
  } catch (e) {
    console.log(e)
  }
}
export const setDefaultPFSetting = (baseUrl, pfObj) => async dispatch => {
  console.info(pfObj);
  try {
    post(`${baseUrl}tenant/updateStoreIntegration`, pfObj)
      .then((res) => {
        console.log(res)
        if (res?.data?.isSucceeded)
          Toast.success("Saved Successfully");
        else
          Toast.error(res?.data?.status);
      })
      .catch((error) => {
        console.log(error)
      });
  } catch (e) {
    console.log(e)
  }
}
export const sendPaymentLink = (baseUrl, payload, messages) => async dispatch => {
  try {
    post(`${baseUrl}session/sendPaymentLink`, payload)
      .then((res) => {
        console.log(res)
        if (res?.data?.isSucceeded)
          Toast.success(messages?.mailSent);
        else
          Toast.error(messages[`err_${res?.error?.data?.status}`] || res?.error?.data?.status);
      })
      .catch((error) => {
        console.log(error)
      });
  } catch (e) {
    console.log(e)
  }
}
export const sendInvitationSession = (baseUrl, payload, messages) => async dispatch => {
  try {
    post(`${baseUrl}session/sendSessionInvitation`, payload)
      .then((res) => {
        console.log(res)
        if (res?.data?.isSucceeded)
          Toast.success(messages?.mailSent);
        else
          Toast.error(messages[`err_${res?.error?.data?.status}`] || res?.error?.data?.status);
      })
      .catch((error) => {
        console.log(error)
      });
  } catch (e) {
    console.log(e)
  }
}
export const requestConnectionToken = (baseUrl, payload, user) => async dispatch => {
  try {
    post(`${baseUrl}${user}/requestConnectionToken`, payload)
      .then((res) => {
        dispatch({ type: REQUEST_CONNECTION, payload: res.data.connectionToken })
      })
      .catch((error) => {
        console.log(error)
      });
  } catch (e) {
    console.log(e)
  }
};
export const getCollections = (baseUrl, tenant) => async dispatch => {
  try {
    get(`${baseUrl}Store/${tenant}/collections`)
      .then(async (res) => {
        if (res.data.isSucceeded) {
          const newCollection = {
            title: "All Products"
          }
          res.data.collections.unshift(newCollection)
          dispatch({ type: GET_PRODUCTS_COLLECTIONS, payload: res.data.collections })
        }
        else {
          dispatch({ type: GET_PRODUCTS_COLLECTIONS_ERROR, payload: res.data.status })
          dispatch({ type: LOADING_SHOPPING, payload: false })
        }
      })
      .catch((error) => {
        dispatch({ type: GET_PRODUCTS_COLLECTIONS_ERROR, payload: error })
        dispatch({ type: LOADING_SHOPPING, payload: false })
      });
  } catch (e) {
    dispatch({ type: LOADING_SHOPPING, payload: false })
  }
};
export const addToRecommendation = (product) => {
  return {
    type: ADD_TO_RECOMMENDATION,
    payload: product
  }
}
export const removeFromRecommendation = (product) => {
  return {
    type: REMOVE_FROM_RECOMMENDATION,
    payload: product
  }
}
export const showShoppingLoader = (value) => {
  return {
    type: LOADING_SHOPPING,
    payload: value
  }
}
export const goToSelectedStep = (step) => {
  return {
    type: ACTIVE_STEP,
    payload: step
  }
}
export const createShopppingConnection = (connection) => {
  return {
    type: SHOPPING_CONNECTION,
    payload: connection
  }
}
export const handleShowCatalog = (value) => {
  return {
    type: SHOW_CATALOG,
    payload: value
  }
}
export const handleShowInvitationScreen = (value) => {
  return {
    type: SHOW_INVITATION_SCREEN,
    payload: value
  }
}
export const handleShowBuyerProducts = (value) => {
  return {
    type: SHOW_BUYER_PRODUCTS,
    payload: value
  }
}