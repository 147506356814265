import React from "react";
import PropTypes from "prop-types";
import shareImage from "assets/svg/share-expert.svg";
import avatarDefault from "assets/svg/avatar-default.svg";
import messages from "constants/messages";
const ExpertAvatarName = ({contents,  ...props }) => {
  return (
    <div className="row intro">
      <div className="col expert-avatar">
        <img src={props?.profileImage || avatarDefault} alt="" />
      </div>
      <div className="col name-share">
        <div className="name-link">
          <div className="name-avaibilities">
            <div className="name">{props?.name ?? ""}</div>
            <span
          className={`online-status ${
            props.isOnline ? "avaibilities" : "unavailable"
          }`}
        ></span>
            
            
          </div>
        </div>
        <div className="share-p" onClick={props.shareProfileHandler}>
          <div className="share-profile">{contents?.shareProfile ?? messages.shareProfile}</div>
          <span className="share-img">
            <img src={shareImage} alt="" />
          </span>
        </div>
      </div>
      <div className="col gb-action">
        <button className="btn btn--secoundary-1"
        onClick={props.onCancelHandler}
        >
          {contents?.btnCancel ?? messages?.btnCancel}
        </button>
      </div>
    </div>
  );
};

ExpertAvatarName.propTypes = {
  props: PropTypes.shape({
    name: PropTypes.string,
    profileImage: PropTypes.string,
    shareProfileHandler: PropTypes.func,
    onCancelHandler: PropTypes.func
  })
};

export default ExpertAvatarName;
