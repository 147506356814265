import React, { useState, useEffect } from "react"
import cartWhite from "../../assets/images/cart-white.png"
import cart from "../../assets/images/cart.png";
import cartGreen from "../../assets/images/cart-green.png";
import vector from "../../assets/images/vector.png";
import check from "../../assets/images/check.png"
import { useSelector, useDispatch } from "react-redux";
import { goToSelectedStep, } from "../../redux/action/shopping";
import useSize from "../../utils/useSize";
import logo from "../../assets/images/GetBee-Logo.png";
import MyTimer from "../../components/Common/MyTimer";
import { optionsHasAllSelectedVariants } from "../../utils/utils";
import messages from "constants/messages";
const BuyerVariantScreen = () => {
  const dispatch = useDispatch()
  const [selectedVariant, setSelectedVariant] = useState([])
  const { preferedLanguageContent: { contents } } = useSelector((state) => state)
  const cartItems = useSelector(state => state.cartReducer.cartItems)
  const product = useSelector(state => state.shoppingReducer.product)
  const [disabled, setDisabled] = useState(true)
  const [outOfStock, setStock] = useState(false)
  const [alreadyInCart, setAlreadyInCart] = useState(false)
  const [finalVariant, setFinalVariant] = useState([])
  const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus)
  const connection = useSelector(state => state.shoppingReducer.shoppingConnection)
  const url = new URL(window.location.href);
  const extractUrlValue = (key, url) => {
    if (typeof (url) === 'undefined')
      url = window.location.href;
    var match = url.match('[?&]' + key + '=([^&]+)');
    return match ? match[1] : null;
  }
  let sessionId = extractUrlValue("session_id", url.sessionId)
  const handleAddVariant = (category, option) => {
    if (selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === option)) {
      console.log(category, "CATEGORY ITEM")
      console.log(option)
      const filteredArray = selectedVariant.filter(item => item.option !== option)
      setSelectedVariant(filteredArray)
    } 
    else if (selectedVariant && selectedVariant.length > 0 && selectedVariant.some(v => v.category === category)) {
      //Find index of specific object using findIndex method.
      let prevArray = selectedVariant.map(el => {
        if (el.category === category)
          return Object.assign({}, el, { option: option })
        return el
      });
      setSelectedVariant(prevArray)
    } 
    else {
      setSelectedVariant([...selectedVariant, { category: category, option: option }])
    }
  }
  useEffect(() => {
    let finalArr
    if (selectedVariant && product && product.options && selectedVariant.length === product.options.length) {
      finalArr = product.variants.filter( item => optionsHasAllSelectedVariants(item.options, selectedVariant) )
      setFinalVariant(finalArr)
      if (finalArr && finalArr.length > 0 && finalArr[0].quantity > 0) {
        setDisabled(false)
        setStock(false)
      } 
      else if (finalArr && finalArr.length > 0 && finalArr[0].quantity === 0) {
        setStock(true)
        setDisabled(true)
      }
    } 
    else {
      setDisabled(true)
    }
  }, [selectedVariant, product])
  const addToCartHandler = async product => {
    product.category = selectedVariant
    product.quantity = 1
    product.category = selectedVariant
    product.selectedVariant = finalVariant
    product.variantId = finalVariant[0].variantId
    if (connection && Object.keys(sessionStatus).length > 0) {
      const payload = {
        sessionId: sessionId,
        tenant: sessionStatus.tenant,
        email: sessionStatus.clientInfo.email,
        product: {
          productId: product.productId.toString(),
          quantity: 1,
          variantId: finalVariant[0].variantId,
          rawData: product,
          previousQuantity: 0,
          previousRawData: product
        },
        id: "f71ff433-dbd1-402e-ad36-d7b7a4538c18"
      }
      console.log(payload)
      const result = await connection.invoke("AddProductToCart", payload)
      if (result && result.product && result.isSucceeded) {
        // dispatch(addToCart(product));
        dispatch(goToSelectedStep(1))
      }
    }
  };
  useEffect(() => {
    if (Object.keys(product).length > 0 && product.options.length === 1) {
      product.options.map(item => (
        item.values.map(value => (
          handleAddVariant(item.name, value, product.variants)
        ))
      ))
    }
  }, [product])
  useEffect(() => {
    if (finalVariant && finalVariant.length > 0 && cartItems && cartItems.length > 0) {
      if (cartItems.filter(product => product.variantId && product.variantId === finalVariant[0].variantId).length) {
        setDisabled(true)
        setAlreadyInCart(true)
      } 
      else 
      {
        setAlreadyInCart(false)
      }
    }
  }, [cartItems, finalVariant])
  const screen = useSize()
  return (
    <section className="s-home s-variant  relative h-full w-full mx-auto mx-auto  mx-auto flex items-center flex-col">
      {/* TOP PART */}
      {/* SEARCH PART */}
      {screen.size === "sm" ? <div className="flex items-center pl-2 pr-2 pt-4 pb-4 w-full justify-between bg-white">
        <div className="flex items-center">
          <div className="s-video__liveDot" />
          <span className="session-time">{Object.keys(sessionStatus).length > 0 && sessionStatus.startTime && <MyTimer expiryTimestamp={sessionStatus.startTime} />}</span>
        </div>
        <button class="relative  s-home__floatingBtn flex items-center" onClick={() => dispatch(goToSelectedStep(1))}>
          <img src={vector} alt="search icon" />
          <p className="text-black ml-2">{contents?.btnBack ?? messages?.btnBack}</p>
        </button>
        <div>
          <img src={logo} alt="getbee-logo" />
        </div>
        <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(7))}>
          <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
      </div>
        : <div className="flex justify-between items-center mt-8 w-11/12 mx-auto border-b s-product-view__topBar">
          <button class="relative  s-home__input flex items-center" onClick={() => dispatch(goToSelectedStep(1))}>
            <img src={vector} alt="search icon" />
            <p className="text-black ml-2">{contents?.btnBack ?? messages?.btnBack}</p>
          </button>
          <div className="flex mt-2 items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(7))}>
            <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
        </div>}
      <div className="s-variant__title">
        <h1 className="s-card__header"><div dangerouslySetInnerHTML={{ __html: product.title }} /></h1>
    
      </div>
      
      <section className="mt-4">
        {
          product.options && product.options.length > 0 && product.options[0].name !== "Title" && product.options.map(item => (
            <div className="mt-4 s-card__variant">
              
              <p className="s-card__variant__title">{item.name.toUpperCase()}</p>
              {/* {item.name === "Color" || item.name === "Colour" ? <div className="mt-2 flex items-center flex-wrap">
                {item.values.map(value => (
                  <button style={selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) ? { border: "3px solid grey", width: "48px", height: "48px", background: value } : { background: value, width: "48px", height: "48px" }} className={`${selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) && `selected-color`} s-variant__item flex items-center`} onClick={() => handleAddVariant(item.name, value)}>
                    <span className="mr-2"></span>
                  </button>
                ))}
              </div> : <div className="mt-2 flex items-center flex-wrap">
                {item.values.map(value => (
                  <button style={item.name === "Color" ? { background: value.toLowerCase() } : null} className={`${selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) && `selected-item`} s-variant__item flex items-center`} onClick={() => handleAddVariant(item.name, value)}>
                    <span >{value}</span> {selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) && <img className="ml-2" src={check} alt="Check Icon" />}
                  </button>
                ))}
              </div>} */}
              {item.name === "Color" || item.name === "Colour" ? (
              <div className="mt-2 flex items-center flex-wrap">
                {item.values.map((value) => (
                  <button
                    style={
                      selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value)
                        ? {
                            fontWeight: "bold"
                          }
                        : {}
                    }
                    className={`${
                      selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value) &&
                      `selected-color`
                    } s-variant__item flex items-center`}
                    onClick={() => handleAddVariant(item.name, value)}
                  >
                    <span className="mr-2">{value}</span>
                    {selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value) && (
                        <img className="ml-2" src={check} alt="Check Icon" />
                      )}
                  </button>
                ))}
              </div>
            ) : (
              <div className="mt-2 flex items-center flex-wrap">
                {item.values.map((value) => (
                  <button
                    style={
                      item.name === "Color"
                        ? { background: value.toLowerCase() }
                        : null
                    }
                    className={`${
                      selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value) &&
                      `selected-item`
                    } s-variant__item flex items-center`}
                    onClick={() => handleAddVariant(item.name, value)}
                  >
                    <span>{value}</span>{" "}
                    {selectedVariant &&
                      selectedVariant.length > 0 &&
                      selectedVariant.some((item) => item.option === value) && (
                        <img className="ml-2" src={check} alt="Check Icon" />
                      )}
                  </button>
                ))}
              </div>
            )}
            </div>
          ))
        }
      </section>
      <button disabled={disabled} style={disabled ? { backgroundColor: "#7C7C7C" } : null} className="s-variant__btn flex items-center justify-center" onClick={() => addToCartHandler(product)}>
        <img src={cartWhite} alt="cart icon" />  <p className="ml-2">{outOfStock ? (contents?.outOfStock ?? messages?.outOfStock) : alreadyInCart ? (contents?.alreadyInCart ?? messages?.alreadyInCart) : (contents?.addToCart ?? messages?.addToCart)}</p>
      </button>
    </section>
  )
}
export default BuyerVariantScreen