export default function parseURLAddAnchorTag(str) {
  if (!str) return null;

  const urlRegex =
    /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/g;
  const matches = str.match(urlRegex);
  const reg = /(\n)/gi;
  let output = str;
  output = output.replace(reg, "<br/>");

  if (!matches) return output;

  for (let match of matches) {
    output = output.replace(
      match,
      `<a class ="chat-msg-link" href="${match}" target="_blank">${match}</a>`
    );
  }

  return output;
}
