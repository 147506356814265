import React from "react";
import cart from "../../assets/images/cart.png"
import { useDispatch, useSelector } from "react-redux";
import { goToProduct, goToSelectedStep, decreaseQuantityProductLists, increaseQuantityProductLists } from "../../redux/action/shopping";
const BuyerCard = ({ product, isRecommended, recommendedProducts }) => {
  const dispatch = useDispatch()
  const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus)
  const handleGoToProductPage = (product) => {
    dispatch(goToProduct(product))
    setTimeout(() => {
      dispatch(goToSelectedStep(5))
    }, 200);
  }
  const handleChooseVariant = (product) => {
    dispatch(goToProduct(product))
    setTimeout(() => {
      dispatch(goToSelectedStep(6))
    }, 200);
  }
  return (
    <div class="w-full mx-auto  s-card s-buyer">
      <div class="flex items-center">
        <div class="  s-card__img cursor-pointer s-card__left" onClick={() => handleGoToProductPage(product)}>
          <img class="w-full object-cover" src={product.images[0]} alt="A cat" />
        </div>
        <div class="flex flex-col s-card__right">
          <div className="flex items-center justify-between w-full cursor-pointer" onClick={() => handleGoToProductPage(product)}>
            <h2 class=" s-card__title w-1/2"><div dangerouslySetInnerHTML={{ __html: product.title }} /></h2>
            <p href="#" class="block mt-1 text-lg leading-tight font-medium text-black hover:underline s-card__price w-1/2 mr-auto">{Object.keys(sessionStatus).length > 0 && sessionStatus.storeInfo && sessionStatus.storeInfo.currencyInfo && sessionStatus.storeInfo.currencyInfo.currencySymbol} {product.variants[0].price}</p>
          </div>
          <div className="flex items-center s-card__bottom absolute bottom-0 ">
            <button className="s-card__bottom__btn s-card__bottom__btn--add w-full flex items-center justify-center" onClick={() => handleChooseVariant(product)}>
              <img src={cart} alt="add" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BuyerCard;