import { get, post } from '../../utils/Api';
import { tenantCustomizationLoading, tenantCustomizationSuccess, tenantCustomizationError } from '../constants/reduxConstant';
import apiName from '../../constants/apiName';

const apiLoading = () => ({ type: tenantCustomizationLoading });
const apiSucceed = (payload) => ({ type: tenantCustomizationSuccess, payload });
const apiError = (payload) => ({ type: tenantCustomizationError, payload });

const tenantCustomizationA = ({ body }) => async (dispatchEvent) => {
    dispatchEvent(apiLoading());
    console.log(body)
    try {
        get(`${apiName.tenantCustomization}?tenant=${body.tenant}`)
            .then(async (res) => {
                if (res && res.data && res.data.isSucceeded) {
                    dispatchEvent(apiSucceed(res));
                } else {
                    dispatchEvent(apiError(res));
                }
            })
            .catch((error) => {
                dispatchEvent(apiError(error));
            });
    } catch (e) {
        dispatchEvent(apiError(e));
    }
}

export default tenantCustomizationA