import Message from "./Message";
// Change
const ChatMessages = ({message, myRole, expertInfo, clientInfo}) => {
  return (
    <>
    <Message 
    {...message}
    myRole={myRole}
    expertInfo={expertInfo}
    clientInfo={clientInfo}
    
    />
    </>
  );
};

ChatMessages.propTypes = {};

export default ChatMessages;
