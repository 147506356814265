import {
  fetchTenantInfo,
  fetchTenantInfoLoading,
  fetchTenantInfoError,
  NONE,
  SUCCESS,
  ERROR,
  LOADING,
} from "redux/constants/reduxConstant";

const initialState = { STATUS: NONE, value: {} };

const tenantInfo = (state = initialState, action) => {
  switch (action.type) {
    case fetchTenantInfoLoading:
      return { ...state, STATUS: LOADING };
    case fetchTenantInfo:
      return { ...state, STATUS: SUCCESS, value: action.payload };
    case fetchTenantInfoError:
      return { ...state, STATUS: ERROR };
    default:
      return state;
  }
};

export default tenantInfo;
