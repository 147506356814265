import { errorHandle } from "components/Common/errorHandle";
import {
  defaultInput as DefaultInput,
  defaultInputWithIcon as DefaultInputWithIcon
} from "components/Common/Input";
import { Header } from "components/Header/Header";
import Images from "constants/Images";
import messages from "constants/messages";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { onChangePage } from "redux/action/Auth";
import defaultA from "redux/action/defaultA";
import loginA from "redux/action/loginA";
import {
  ERROR,
  LOADING, SUCCESS
} from "redux/constants/reduxConstant";
import { Validate as validate } from "utils/validate_wrapper";
import styles from "../style/dashboardStep";
import apiName from "constants/apiName";
import axios from "axios";

const ExpLogin = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { 
    preferedLanguageContent: { contents },
    loginR
 } = useSelector((state) => state);
  const [state, setState] = useState({
    loading: false,
    email: "",
    password: "",
    showModal: false,
    tenantArr: [],
    showPass: false,
  });

  const submit = (e) => {
    e.preventDefault();
    clearError();
    if (!checkValidation()) {
      return;
    }

    let formData = {
      email: state.email,
      password: state.password,
    };
    props.loginRequest({ from: "login", body: formData })

  };

  // validate form data
  const checkValidation = () => {
    var emailErr = validate(contents, "email", state.email);
    var passwordErr = validate(contents, "blank", state.password);

    if (emailErr || passwordErr) {
      setState({
        ...state,
        emailErr: emailErr,
        passwordErr: passwordErr,
      });
      return false;
    } else {
      return true;
    }
  };

  // clean error state
  const clearError = () => {
    setState({
      ...state,
      emailErr: "",
      passwordErr: "",
    });
  };


  const renderTenant = (i, expIndex) => (
    <div className="tenant-list" key={expIndex}>
      <ul>
        <li>
          <div
            onClick={() => selectTenant(i)}
            className="clickable"
            style={styles.tenCon}
            key={i.tenantName}
          >
            <div className="tenant-detail">
              <figure>
                <img src={i.tenantLogo} alt="img" />
              </figure>
              <figcaption>
                <h4 className="name">
                  {i.tenantName == null
                    ? ""
                    : i.tenantName.length > 25
                    ? i.tenantName.slice(0, 25) + ".."
                    : i.tenantName}
                </h4>
                <h5 className="designation">
                  {i.roles.map((i, index) => (index >= 1 ? ", " + i : i))}
                </h5>
              </figcaption>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );

  const setTenant = (url, formData, token) => {
    return new Promise(function (resolve, reject) {
      axios({
        method: "post",
        url: url,
        timeout: 1000 * 60,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          "GETBEE-AUTH-TOKEN": token
        },
      })
        .then(async (response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
          }
          reject(error.response);
        });
    });
  }

  const selectTenant = async (i) => {
    const sessionId = loginR?.value?.data?.sessionId;
    
    let loginRes = state.loginRes;
    loginRes.role = i.roles;
    loginRes.tenant = i.tenantIdentifier;
    loginRes.email = state.email;
    const selectedLanguage = loginRes.tenants.filter((x) => x.tenantIdentifier === i.tenantIdentifier)[0].preferredLanguage;
    console.log('selectedLanguage', selectedLanguage)
    localStorage.setItem("userData", JSON.stringify(loginRes));
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedLanguage));
    
    const body = {
      tenant: i.tenantIdentifier,
      devicePlatform: "web"
    }

    setTenant(apiName.setTenant, body, sessionId).then(res=> {
      if(res?.data?.isSucceeded) {
        history.push({
          pathname: "/admin-home",
        });
      }
    })
  };
  useEffect(() => {
    const loginPath = props.ScreenProps.loginR;

    if (loginPath.status === LOADING) {
      setState({ ...state, loading: true });
    } else if (loginPath.status === SUCCESS) {
      
      let userData = loginPath.value.data;
      userData.email = state.email;
      dispatch(onChangePage("home"));
      if (loginPath.value.data.tenants.length === 0) {
        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("selectedLanguage", JSON.stringify(userData.preferredLanguage));
        history.push({
          pathname: "/admin-home",
        });
        props.defaultRequest();
      } else {
        setState({
          ...state,
          loading: false,
          loginRes: loginPath.value.data,
          showModal: true,
          tenantArr: loginPath.value.data.tenants,
        });
        // showToast(loginPath.value.data.status, 'success');
        props.defaultRequest();
      }
      // loginPath.value.data.role = 'expert';
      // localStorage.setItem('userData', JSON.stringify(loginPath.value.data))
      // history.push({
      //     pathname: '/admin-home'
      // })
    } else if (loginPath.status === ERROR) {
      
      setState({ ...state, loading: false });
      errorHandle(loginPath, contents);
      props.defaultRequest();
      if (
        loginPath.error &&
        loginPath.error.data &&
        loginPath.error.data.status === "EmailNotConfirmed"
      ) {
        history.push({
          pathname: "/email-sent",
          state: { email: state.email },
        });
      }
    }
  }, [props.ScreenProps]);

  const showSetPass = () => {
    setState({ ...state, showPass: !state.showPass });
  };

  return (
    <>
      <ToastContainer />
      <div className="expert-content-block-section">
        <div className="first-section register-block">
          <div className="form-content-blk">
            <Header />
            <div className="expert-content-block justify-center items-center overflow-block back-btn-block m-auto">
              <div className="w-full">
                <button
                  onClick={() => props.history.goBack()}
                  className="expert-back-btn"
                >
                  <span className="back-text pl-2"></span>
                </button>
                <h4 className="signin-txt tensor-font">{contents?.signIn ?? messages?.signIn}</h4>
                
                {/* <div className="signin mobile">
                  <span className="pr-2 tensor-font">{contents?.dontHaveAccount ?? messages?.dontHaveAccount}</span>
                  <a href="/register" className="text-black border-b">
                    {contents?.createCompanyAccount ?? messages?.createCompanyAccount}
                  </a>
                </div> */}

                <form onSubmit={submit} className="register-form mb-3">
                  <DefaultInput
                    type={"email"}
                    placeholder={contents?.emailAddress ?? messages?.emailAddress}
                    name={"email"}
                    value={state.email}
                    onChange={(e) =>
                      setState({
                        ...state,
                        email: e.target.value,
                        emailErr: validate(contents, "email", e.target.value),
                      })
                    }
                    error={state.emailErr}
                  />
                  <DefaultInputWithIcon
                    type={state.showPass ? "text" : "password"}
                    placeholder={contents?.password ?? messages?.password}
                    name={"password"}
                    icon={state.showPass ? Images.eyeClose : Images.eyeOpen}
                    value={state.password}
                    onChange={(e) => setState({ ...state, password: e.target.value, passwordErr: validate(contents, 'blank', e.target.value) })}
                    error={state.passwordErr}
                    clickIcon={showSetPass}
                  />
                  <div className="form-btn-link inter-font">
                    <Link to="/forget-password" className="form-link">
                      {contents?.forgetPassword ?? messages?.forgetPassword}
                    </Link>
                  </div>
                  <div className="bg-black btn-block">
                    <button
                      type="submit"
                      disabled={props.isSubmitting}
                      className="btn btn-black btn-lg btn-min w-full">
                      {contents?.signIn ?? messages?.signIn}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="second-section sales-data">
          <img src={Images.salesData} alt="salesData" />
        </div>
      </div>
      {state.showModal && (
        <div
          className="modal viewmodal fade  show show-model-box"
          id="viewmodal-invite"
          role="dialog"
          aria-labelledby="viewmodal-invite"
          aria-hidden="true"
        >
          <div className="modal-overlay"></div>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {contents?.selectTenant ?? messages?.selectTenant}
                </h5>
                <button
                  onClick={() => setState({ ...state, showModal: false })}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <img src={Images.closeIcon} alt="closeIcon" />
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="invite-team-form-blk">
                  {state.tenantArr.map((i, index) => renderTenant(i, index))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ScreenProps: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: ({ from, body }) => {
      dispatch(loginA({ from, body }));
    },
    defaultRequest: () => {
      dispatch(defaultA());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpLogin);
