import messages from "constants/messages";
import TranslateToString from "./TranslateToString";

const NothingFound = ({contents}) => {
  return (
    <div className="nothing-found">
      <div className="content">{TranslateToString(contents?.nothingFound, messages?.nothingFound) }</div>
    </div>
  );
};

export default NothingFound;
