import { useEffect } from "react";
import { store } from "redux/Store";
import { languageAPI } from "constants/apiName";
import { getError } from "components/ErrorHandler/getError";
import { localStorageClientLanguageKey, localStorageLanguageKey } from "config/Config";
import en from 'data/countries/en.json'
import { isJsonString } from "pureFunctions/isJSONString";

import {
  fetchLanguageAction,
  fetchLanguageLoadingAction,
  fetchLanguageErrorAction,
  changeCountriesLanguageAction,
} from "redux/action/admin/language";


export default function useLanguage({ lng, shouldFetchLanguage = true, fromClient }) {

  const value = lng || isJsonString(localStorage.getItem(localStorageLanguageKey));

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        store.dispatch(fetchLanguageLoadingAction());
        if (value) {
          languageAPI.current = value;
          const response = await fetch(decodeURI(languageAPI.getAPIName));
          const json = await response.json();

          store.dispatch(fetchLanguageAction({ json, lng: value }));
          import(`data/countries/${value}.json`)
          .then((res) => { 
            store.dispatch(changeCountriesLanguageAction({ countries: res.default }))
          })
          .catch(ex => {
            store.dispatch(changeCountriesLanguageAction({ countries: en }))
          })
          
          if(fromClient) {
            localStorage.setItem(localStorageClientLanguageKey, JSON.stringify(value));
          } else {
            localStorage.setItem(localStorageLanguageKey, JSON.stringify(value));
          }
        }
      } catch (err) {
        store.dispatch(fetchLanguageErrorAction(getError(err)));
        console.error(err);
      }
    };
    if (shouldFetchLanguage) {
      fetchLanguages();
    }
  }, [value, shouldFetchLanguage, fromClient]);
}
