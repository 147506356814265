export const getLocalLanguagesObject = (languages, spokenLanguages) => {
  const filter = languages.filter((item) => {
    if (spokenLanguages.indexOf(item.value) !== -1) {
      return true;
    }
    return false;
  });
  return filter;
};

export const getFilteredLanguages = (preferredLanguage, tenantLanguage) => {
  if (!tenantLanguage || tenantLanguage.length < 1) {
    return [];
  }

  // Filter then
  const filterPreferredLanguage = preferredLanguage.filter(
    (language) => tenantLanguage.indexOf(language.value) !== -1
  );
  return filterPreferredLanguage;
};
