/* eslint-disable react-hooks/exhaustive-deps */
import * as signalR from "@microsoft/signalr";
import { useEffect, useRef, useState, useMemo, Fragment } from "react";
import OneSignal from "react-onesignal";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProfileImage from "../../assets/images/icon-video-wh.svg";
import { showToast } from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import IncommingModel from "../../components/Common/IncommingModel";
import udid from "../../components/Common/udid";
import { OneSignalAppId } from "../../config/OneSignalConfig";
import apiName, { Environment } from "../../constants/apiName";
import messages from "../../constants/messages";
import inviteExpA from "../../redux/action/admin/inviteExpA";
import oneSignalRegister from "../../redux/action/common/oneSignalRegister";
import userInfoA from "../../redux/action/common/userInfoA";
import defaultA from "../../redux/action/defaultA";
import requestExpertConnectionA from "../../redux/action/requestExpertConnectionA";
import {
  changeExpertInCallStatus,
  changeExpertStatus,
} from "../../redux/action/sessionConnections";
import tenantInfoA from "../../redux/action/tenantInfoA";
import Model from "components/TabContents/Meetings/model/Model";
import useExpertTenantInfo from "hooks/useExpertTenantInfo";

import { ERROR, LOADING, SUCCESS } from "../../redux/constants/reduxConstant";
import { getUserData } from "../../utils/utils";
import ringUrl from "./../../assets/sound/call.wav";
import { request } from "utils/request";
import { useCallback } from "react";
import axios from "axios";

const AdminHome = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    preferedLanguageContent: { contents },
  } = useSelector((state) => state);
  const expertOnlineStatus = useSelector(
    (state) => state.sessionConnectionsReducer.expertOnlineStatus
  );
  const { tenantInfo } = useExpertTenantInfo();

  const { uniqueIdentifier } =
    useMemo(() => {
      return tenantInfo;
    }, [tenantInfo]) || {};

  const ringAudio = useRef();
  const [localConnection, setLocalConnection] = useState("");
  const [model, setModel] = useState(false);
  const [showModel, setShowModel] = useState(false);

  const [state, setState] = useState({
    loading: false,
    signalRConnected: false,
    tenantInfo: "",
    showModal: true,
    selectedRole: { value: "Expert", label: "Expert" },
    defaultRole: { value: "Expert", label: "Expert" },
    email: "",
    expertArr: [],
    expIndex: 1,
    callData: {},
    isFirst: true,
    role: "",
  });

  const [activeSession, setActiveSession] = useState({});
  useEffect(() => {
    const prepOneSignal = async () => {
      OneSignal.init({ appId: OneSignalAppId[Environment] }).then(() => {
        OneSignal.showNativePrompt();
        OneSignal.on("subscriptionChange", function (isSubscribed) {
          if (isSubscribed) {
            OneSignal.getUserId(function (userId) {
              dispatch(oneSignalRegister(userId));
            });
          }
        });
      });
    };
    prepOneSignal();
  }, []);

  useEffect(() => {
    const fetchTenantInfo = async () => {
      const tenantInfoPath = props.ScreenProps.tenantInfoR;
      const userInfoPath = props.ScreenProps.userInfoR;

      if (tenantInfoPath.status === SUCCESS) {
        setState({
          ...state,
          loading: false,
          tenantInfo: tenantInfoPath.value.data,
        });

        props.defaultRequest();
      } else if (tenantInfoPath.status === ERROR) {
        setState({ ...state, loading: false });
        errorHandle(tenantInfoPath, contents);
        props.defaultRequest();
      } else if (userInfoPath.status === SUCCESS) {
        if (userInfoPath.value && userInfoPath.value.data) {
          let temp = userInfoPath.value.data;
          if (!temp.firstName || !temp.lastName) {
            let userData = await getUserData();
            history.replace({
              pathname: "/expert-complete-profile",
              state: {
                tenant: userData.tenant,
                logo: userData.tenants.filter(
                  (x) => x.tenantIdentifier === userData.tenant
                )[0].tenantLogo,
              },
            });
          }
          if (userInfoPath.value.data) {
            setState({
              ...state,
              userInfo: JSON.parse(JSON.stringify(userInfoPath.value.data)),
            });
          }
        }
        props.defaultRequest();
      } else if (userInfoPath.status === ERROR) {
        props.defaultRequest();
      }
    };
    fetchTenantInfo();
  }, [props.ScreenProps, contents, history, props, state]);

  useEffect(async () => {
    if (localConnection && localConnection.state === "Disconnected") {
      console.info("Signal R Reconnection");
      await localConnection.start();
    }
    let wasConnected = localStorage.getItem("online");
    let userData = await getUserData();
    let signalRConnected =
      localConnection && localConnection.state === "Connected";
    if (userData && userData.tenant && signalRConnected && wasConnected)
      dispatch(changeExpertStatus(true));
  }, [localConnection, localConnection.state]);

  const makeExpertOnline = async () => await connectExpert();
  const makeExpertOffline = async () => await disconnectExpert(true);

  useEffect(() => {
    if (!state.loading) {
      setState({ ...state, loading: true });
      if (expertOnlineStatus) makeExpertOnline();
      else makeExpertOffline();
    }
  }, [expertOnlineStatus]);

  useEffect(() => {
    getTenantInfo();
    getRoles();
  }, []);

  useEffect(async () => {
    const userData = await getUserData();
    let formData = {
      email: userData.email,
      tenant: userData.tenant,
      id: udid(),
    };
    props.requestExpertConnection({ from: "admin-home", body: formData });
  }, []);

  useEffect(() => {
    const fethcSignalRToken = async () => {
      const requestExpertConnectionPath =
        props.ScreenProps.requestExpertConnectionR;

      if (requestExpertConnectionPath.status === LOADING) {
        setState({ ...state, loading: true });
      } else if (requestExpertConnectionPath.status === SUCCESS) {
        props.defaultRequest();

        var signalRToken = await requestExpertConnectionPath.value.data;

        var localConnection = await new signalR.HubConnectionBuilder()
          .withUrl(
            `${apiName.signalR}expertHub?GETBEE-AUTH-TOKEN=${signalRToken.connectionToken}`,
            {
              skipNegotiation: true,
              transport: signalR.HttpTransportType.WebSockets,
            }
          )
          .withAutomaticReconnect()
          .configureLogging(signalR.LogLevel.Information)
          .build();

        await localConnection.start();

        localConnection.onclose(function () {
          const currentDate = new Date();
          localStorage["disconnectedTime"] = "" + currentDate.getTime();
        });

        localConnection.on("CallStarted", (message) => {
          ringAudio.current.pause();

          localStorage.setItem("userRole", "expert");
          localStorage.setItem("tenant", message.tenant);
          localStorage.setItem("signalR", signalRToken.connectionToken);
          localStorage.removeItem("mediaState");
          const path = `/${message.tenant}/video-call`;
          var searchparam = `?session_id=${message.sessionId}`;
          window.location.href = `${window.location.origin}${path}${searchparam}`;

          // history.push({ pathname: path, search: searchparam, state: { tenantInfo: state.tenantInfo } })
        });

        // This event will envoked when client submit the form and get the token
        // Finally user will invoke RequestCall event is connected
        localConnection.on("Calling", (message) => {
          dispatch(changeExpertInCallStatus(true));
          setState({ ...state, callData: message });
          setModel((model) => true);
          ringAudio.current.currentTime = 0;
          ringAudio.current.play();
        });

        localConnection.on("StopCalling", (message) => {
          ringAudio.current.pause();
          dispatch(changeExpertInCallStatus(false));
          setModel((model) => false);
          console.info("set user online");
          if (!expertOnlineStatus) dispatch(changeExpertStatus(true));
        });

        localConnection.on("ExpertOffline", (message) => {
          dispatch(changeExpertStatus(false));
        });

        localConnection.on("ExpertOnline", (message) => {
          dispatch(changeExpertStatus(true));
        });

        localConnection.on("SessionOtherTab", (message) => {
          ringAudio.current.pause();
          dispatch(changeExpertInCallStatus(false));
          setModel((model) => false);
          showToast(messages?.callAnsweredOnAnotherDevice, "info");
        });
        setLocalConnection(localConnection);
      } else if (requestExpertConnectionPath.status === ERROR) {
        // setState({ ...state, loading: false });
        errorHandle(requestExpertConnectionPath, contents);
        props.defaultRequest();
      }
    };
    fethcSignalRToken();
  }, [props.ScreenProps]);

  const acceptCall = async () => {
    let formData = {
      sessionId: state.callData.sessionId,
      tenant: state.callData.tenant,
      id: udid(),
    };

    var x = await localConnection.invoke("AcceptCall", formData);

    if (x.isSucceeded === true) {
      localStorage.removeItem("online");
      showToast(x.status, "success");
    } else {
      showToast(x.status, "error");
    }
  };

  const getRoles = async () => {
    const userData = await getUserData();
    let tempRole = userData && userData.role;
    let role = "Expert";
    tempRole !== undefined &&
      (tempRole.length === 2 ? (role = "Both") : (role = tempRole[0]));
    setState({ ...state, role: role });
  };

  const getTenantInfo = async () => {
    const userData = await getUserData();

    userData &&
      userData.tenant &&
      props.tenantInfoRequest({
        from: "admin-home",
        body: { tenant: userData.tenant, id: udid() },
      });
    userData &&
      userData.tenant &&
      props.userInfoRequest({ from: "adminHome", tenant: userData.tenant });
  };

  const connectExpert = async () => {
    let userData = await getUserData();
    if (userData && userData.tenant && localConnection) {
      var x = await localConnection.invoke("GoToOnline", {
        tenant: userData.tenant,
        id: udid(),
      });
      if (x.isSucceeded === true) {
        localStorage.setItem("online", true);
        setState({ ...state, loading: false });
        // showToast(x.status, 'success');
      } else if (x.status === "ExpertInSessionCannotOnline") {
        showToast(
          contents.alreadyInRunningSession ?? messages?.alreadyInRunningSession,
          "error"
        );
        dispatch(changeExpertStatus(false));
        setState({ ...state, loading: false });
      } else {
        showToast(x.status, "error");
        dispatch(changeExpertStatus(false));
        setState({ ...state, loading: false });
      }
    }
  };

  const disconnectExpert = async (removeOnlineFlag = true) => {
    let userData = await getUserData();
    if (userData && userData.tenant && localConnection) {
      var x = await localConnection.invoke("GoToOffline", {
        tenant: userData.tenant,
        id: udid(),
      });
      if (x.isSucceeded === true) {
        if (removeOnlineFlag) localStorage.removeItem("online");
        setState({ ...state, loading: false });
      } else {
        console.error("DISCONNECT EXPERT FAILED");
        showToast(x.status, "error");
        dispatch(changeExpertStatus(false));
        setState({ ...state, loading: false });
      }
    }
  };

  //DISCONNECTING THE USER ON CLOSE TAB
  useEffect(() => {
    const onOnlineHandler = async () => {
      if (localConnection._connectionState === "Disconnected") {
        await localConnection.start().then(() => {
          if (localStorage.getItem("online")) {
            dispatch(changeExpertStatus(true));
          }
        });
      }
    };

    const onBeforeUnloadHandler = () => {
      const currentDate = new Date();
      localStorage["disconnectedTime"] = "" + currentDate.getTime();
      disconnectExpert(false);
      // dispatch(changeExpertStatus(false))
    };

    window.addEventListener("online", onOnlineHandler);
    window.addEventListener("beforeunload", onBeforeUnloadHandler);

    return () => {
      window.removeEventListener("online", onOnlineHandler);
      window.removeEventListener("beforeunload", onBeforeUnloadHandler);
    };
  }, []);

  useEffect(() => {
    let timer = setInterval(async () => {
      if (expertOnlineStatus) {
        await connectExpert();
      } else {
        await disconnectExpert();
      }
    }, 30000);
    return () => {
      clearInterval(timer);
    };
  });

  const updateStateModel = async () => {
    ringAudio.current.pause();
    setModel((model) => false);
  };

  const [cancelModelActive, setCancelModelActive] = useState(false);

  const onChangeHandlercomment = () => {};

  const joinCallHandler = useCallback(() => {
    if (uniqueIdentifier) {
      history.push(
        `${uniqueIdentifier}/video-call?session_id=${activeSession.sessionId}`
      );
      return;
    }

    return null;
  }, [activeSession, uniqueIdentifier, history]);

  const endCallHandler = useCallback(async () => {
    setCancelModelActive(false);

    const payload = {
      clientEmail: activeSession?.clientInfo?.email,
      tenant: uniqueIdentifier,
      sessionId: activeSession?.sessionId,
    };

    try {
      const endTheCall = await localConnection.invoke("CompleteCall", payload);
      if (endTheCall.isSucceeded) {
        showToast(
          contents.callEndedSuccess ?? messages.callEndedSuccess,
          "success"
        );
        setActiveSession({});
      }
    } catch (err) {
      console.error("Could not end the call", err);
    }
  }, [
    localConnection,
    activeSession,
    uniqueIdentifier,
    contents.callEndedSuccess,
  ]);

  const selectedMeeting = {};

  const actionButtons = useMemo(() => {
    return (
      <div className="row action-button">
        <button className="btn btn--secondary" onClick={() => endCallHandler()}>
          {contents.endCall ?? messages.endCall}
        </button>
        <button className="btn btn--primary" onClick={() => joinCallHandler()}>
          {contents.joinCall ?? messages.joinCall}
        </button>
      </div>
    );
  }, [joinCallHandler, endCallHandler]);

  // Fetch the active session for the user
  useEffect(() => {
    const fetchActiveSession = async () => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const isClient = localStorage.getItem("clientToken");

      try {
        // Dont set the token if it is
        if (!isClient) {
          axios.defaults.headers.common["GETBEE-AUTH-TOKEN"] =
            userData.sessionId;
        }

        const response = await request({
          url: `${apiName.getActiveSessions}?tenant=${uniqueIdentifier}`,
          method: "get",
        });

        if (response?.sessions?.length > 0) {
          // There is active session
          setActiveSession(response?.sessions[0]);
        }
        if (response?.sessions?.length === 0) {
          // There is no active session
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (uniqueIdentifier) {
      fetchActiveSession();
    }
  }, [uniqueIdentifier]);

  const isThereActiveSession = useMemo(() => {
    // return false;
    return Object.entries(activeSession).length > 0;
  }, [activeSession]);

  const getTitle = useMemo(() => {
    const firstLine =
      contents?.yourVideoCallWith ?? messages?.yourVideoCallWith;
    const secoundLine = contents?.isInProcess ?? messages?.isInProcess;
    return `${firstLine} ${activeSession?.clientInfo?.name} ${secoundLine}`;
  }, [contents?.yourVideoCallWith, contents?.isInProcess, activeSession]);

  return (
    <>
      {isThereActiveSession && (
        <Model
          setCancelModelActive={setCancelModelActive}
          onChangeHandlercomment={onChangeHandlercomment}
          selectedMeeting={selectedMeeting}
          title={contents?.callInProgress ?? messages.callInProgress}
          closeBtn={false}
          actionButtons={actionButtons}
          afterTitle={getTitle}
          helpText={contents?.pleaseJoin ?? messages.pleaseJoin}
        ></Model>
      )}
      <div>
        <ToastContainer />
        <audio ref={ringAudio} src={ringUrl} />
        {model ? (
          <IncommingModel
            profileImage={ProfileImage}
            handler={updateStateModel}
            callData={state.callData}
            acceptCall={acceptCall}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ScreenProps: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestExpertConnection: ({ from, body }) => {
      dispatch(requestExpertConnectionA({ from, body }));
    },

    tenantInfoRequest: ({ from, body }) => {
      dispatch(tenantInfoA({ from, body }));
    },
    inviteExpRequest: ({ from, tenant, experts }) => {
      dispatch(inviteExpA({ from, tenant, experts }));
    },
    userInfoRequest: ({ from, tenant }) => {
      dispatch(userInfoA({ from, tenant }));
    },
    defaultRequest: () => {
      dispatch(defaultA());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
