import { requestCallError, requestCallSuccess, requestCallLoading, SUCCESS, ERROR, LOADING, NONE } from '../constants/reduxConstant'

export default function requestCallR(state = {}, action) {
    // console.log("Inside register reducer", action)
    switch (action.type) {
        case requestCallLoading:
            return Object.assign({}, state, { status: LOADING });
        case requestCallSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case requestCallError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}