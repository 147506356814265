export function amOrPM(slot) {
  const time = parseFloat(slot);

  if (time < 12) {
    return "AM";
  }

  return "PM";
}

export const removeSecound = (str) => {
  return str.substring(0, str.length - 3);
};