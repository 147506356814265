import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loaders from "react-loader-spinner";

const Loader = (props) => {
    const { loading } = props;

    return (
        <div className="modal viewmodal-profile fade  show show-model-box" id="viewmodal-profile"
            role="dialog" aria-labelledby="viewmodal-profile"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ justifyContent: 'center' }}>
                <Loaders
                    type="Puff"
                    color="#afbb20"
                    height={100}
                    width={100}
                // timeout={3000} //3 secs
                />
            </div>
        </div>
    );
};

export default Loader;
