export const baseURL = "https://getbeeidentity.azurewebsites.net/api";
export const appId = "803a8f6f78794262bd7c49e704b83df5";
export const appCertificate = '849e819a890b4b809943fe1c5c72cf15';
export const devbeeGAKey = "UA-117761298-5";
export const getbeeGAKey = "UA-117761298-6";
export const localStorageLanguageKey = "selectedLanguage";
export const instrumentationKey = "2afd665d-b1eb-4693-84cd-b3f1a81d17a6";
export const userRoleLocalStorageKey = "userRole"
export const userRoles = {expert: "expert"};
export const localStorageClientLanguageKey = "localStorageClientLanguageKey";
export const clientHasVistedVideoCall = "clientHasVistedVideoCall";
export const newMessageIsAvailableLocalStorage = "newMessageIsAvailableLocalStorage";
export const userData = "userData";