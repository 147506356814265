import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Images from "../../constants/Images";
import { connect, useSelector } from 'react-redux';
import defaultA from "../../redux/action/defaultA";
import { SUCCESS, ERROR, LOADING } from '../../redux/constants/reduxConstant';
import { errorHandle } from "../../components/Common/errorHandle";
import ImageUpload from "../../components/Common/ImageUpload";
import Loader from "../../components/Common/loader";
import { ToastContainer } from 'react-toastify';
import { getUserData } from "../../utils/utils";
import udid from "../../components/Common/udid";
import messages from "../../constants/messages";
import { DefaultInputForm, DefaultDropDownForm, InputAreaForm } from "../../components/Common/Input";
import { Validate as validate } from "../../utils/validate_wrapper";
import userInfoA from "../../redux/action/common/userInfoA";
import { langArr } from "../../redux/constants/StaticArr";
import updateProfileA from "../../redux/action/common/updateProfileA";
import styles from "../style/dashboardStep";
import imgUploadA from "../../redux/action/common/imgUploadA";

const Profile = (props) => {
    const inputRef = useRef(null)
    let randomKey = Math.random().toString(36)
    const { preferedLanguageContent: { contents, countriesList } } = useSelector((state) => state);
    // get country list
    const [options, setOptions] = useState([])
    useEffect(() => {
        if(countriesList && Object.keys(countriesList).length > 0)
            setOptions(Object.keys(countriesList).map(x => ({ value: x, label: countriesList[x] })))
    }, [countriesList])

    const [state, setState] = useState({
        loading: false,
        userInfo: "",
        firstName: '',
        lastName: '',
        email: '',
        profile_image: '',
        role: '',
        bio: '',
        selectedCountry: [],
        selectedLanguage: [],
        showModal: false,
        rangeValue: [1],
    });

    useEffect(() => {
        let temp = props.ScreenProps.userInfoR && props.ScreenProps.userInfoR.value && props.ScreenProps.userInfoR.value.data
        // console.log('data received in userinfo is ', temp)
        // debugger
        if (temp != state.userInfo && temp != undefined) {
            let location = []
            let languages = []
            langArr.map(i => {
                if (temp.languages != null && temp.languages != "" && temp.languages.length != 0) {
                    temp.languages.map(j => {
                        if (i.value == j)
                            languages.push(i)
                    })
                }
            })

            console.log('data received in userinfo is ', temp)

            options.map(i => {
                if (i.value == temp.location) {
                    location.push(i)
                }
            })

            setState({
                ...state,
                userInfo: temp,
                firstName: temp.firstName,
                lastName: temp.lastName,
                email: temp.email,
                selectedLanguage: languages,
                selectedCountry: location.length > 0 ? location[0] : [],
                bio: temp.description,
                profile_image: temp.profileImage,
            })
        }
    });

    useEffect(() => {
        const updateProfilePath = props.ScreenProps.updateProfileInR;
        const imgUploadPath = props.ScreenProps.imgUploadInR;

        if (updateProfilePath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (updateProfilePath.status == SUCCESS) {
            console.log('updateProfile In success res ', updateProfilePath);
            setState({ ...state, loading: false });
            // showToast(updateProfilePath.value.data.status, 'success');
            updateUserInfo()
            props.defaultRequest();
        }
        else if (updateProfilePath.status == ERROR) {
            console.log('updateProfile In error res ', updateProfilePath);
            setState({ ...state, loading: false });
            errorHandle(updateProfilePath, contents)
            props.defaultRequest();
        }
    }, [props.ScreenProps]);

    useEffect(() => {
        getRoles()
    }, []);

    const chooseFile = () => {
        inputRef.current.click();
    }

    const updateUserInfo = async () => {
        const userData = await getUserData()
        userData && userData.tenant && props.userInfoRequest({ from: 'adminHome', tenant: userData.tenant })
    }

    const getRoles = async () => {
        const userData = await getUserData()
        let tempRole = userData && userData.role
        let role = "Expert"
        tempRole.length == 2 ? (role = 'Both') : (role = tempRole[0])
        setState({ ...state, tenant: userData.tenant, role: role })
    }

    const submit = (e) => {
        e.preventDefault()
        clearError()
        if (!checkValidation()) {
            return;
        }

        let languages = []
        state.selectedLanguage.map(i => {
            languages.push(i.label)
        })

        console.log('state.selectedcountry is ', state.selectedCountry)

        let formData = {
            firstName: state.firstName,
            lastName: state.lastName,
            location: state.selectedCountry && state.selectedCountry.label,
            languages: languages,
            // description: state.bio,
            tenant: state.tenant,
            id: udid()
        };
        state.bio && (formData.description = state.bio)
        state.profile_image != null && state.profile_image != '' && (formData.imageUrl = state.profile_image)
        console.log('data before sending for update ', formData)
        props.updateProfileRequest({ from: 'profile', body: formData })
    };



    // validate form data
    const checkValidation = () => {
        var firstNameErr = validate(contents, 'common', state.firstName);
        var lastNameErr = validate(contents, 'common', state.lastName);
        var countryErr = validate(contents, 'blank', state.selectedCountry);
        var languageErr = validate(contents, 'blank', state.selectedLanguage);
        if (countryErr || languageErr || firstNameErr || lastNameErr) {
            setState({
                ...state,
                firstNameErr: firstNameErr,
                lastNameErr: lastNameErr,
                countryErr: countryErr,
                languageErr: languageErr,
            })
            return false;
        } else {
            return true;
        }
    };

    // clean error state
    const clearError = () => {
        setState({
            ...state,
            firstNameErr: '',
            lastNameErr: '',
            countryErr: '',
            languageErr: '',
        })
    }

    return (
        <>
            <ToastContainer />
            <>
                {/* <Header showInvite={(state.role == 'Both' || state.role == 'Admin') ? false : true} /> */}
                {/* <div className="dashboard-side-menu">
                    <SideBar
                        screen='profile'
                    />
                </div> */}
                <div className="dashboard-right-menu expert-profile-res">
                    <div className="container-fluid">
                        <div className="row expert-profile-res-blk">
                            <div className="col-md-8">
                                <div className="expert-dashboard-profile">
                                    <div className="expert-db-profile-head">
                                        <h4>{messages.personalDetails}</h4>
                                        <span className="file-action"><i className="ri-checkbox-circle-fill"></i> Changes Saved</span>
                                    </div>
                                    <p>{messages.companyPictureCaution}</p>
                                    {/* <form className="mb-4"> */}
                                    <form className="mb-5" onSubmit={submit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <DefaultInputForm
                                                    label={messages.firstName}
                                                    placeholder={messages.firstName}
                                                    type={"text"}
                                                    name={"firstName"}
                                                    maxLength={80}
                                                    value={state.firstName}
                                                    onChange={(e) => setState({ ...state, firstName: e.target.value && e.target.value.trimLeft(), firstNameErr: validate(contents, 'common', e.target.value) })}
                                                    error={state.firstNameErr}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <DefaultInputForm
                                                    label={messages.lastName}
                                                    placeholder={messages.lastName}
                                                    type={"text"}
                                                    name={"lastName"}
                                                    maxLength={80}
                                                    value={state.lastName}
                                                    onChange={(e) => setState({ ...state, lastName: e.target.value && e.target.value.trimLeft(), lastNameErr: validate(contents, 'common', e.target.value) })}
                                                    error={state.lastNameErr}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <DefaultInputForm
                                                    label={messages.companyMailAddress}
                                                    // type={"email"}
                                                    placeholder={messages.companyMailAddress}
                                                    name={"email"}
                                                    maxLength={200}
                                                    disabled={true}
                                                    value={state.email}
                                                    onChange={(e) => setState({ ...state, email: e.target.value })}
                                                    error={state.emailErr}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <DefaultDropDownForm
                                                    label={messages.whereLoc}
                                                    placeholder={messages.lblCountry}
                                                    name="country"
                                                    options={options}
                                                    value={state.selectedCountry}
                                                    onChange={(value) => setState({ ...state, selectedCountry: value, countryErr: validate(contents, 'blank', value) })}
                                                    error={state.countryErr}
                                                />
                                                <DefaultDropDownForm
                                                    label={messages.whatLang}
                                                    placeholder={messages.languages}
                                                    name="language"
                                                    options={langArr}
                                                    isMulti={true}
                                                    closeMenuOnSelect={false}
                                                    value={state.selectedLanguage}
                                                    onChange={(value) => { setState({ ...state, selectedLanguage: value, languageErr: validate(contents, 'blank', value) }); console.log('multiselect value is ', value) }}
                                                    error={state.languageErr}
                                                />
                                                <InputAreaForm
                                                    label={messages.tellCust}
                                                    placeholder={messages.shortBio}
                                                    type={"text"}
                                                    name={"bio"}
                                                    maxLength={1500}
                                                    value={state.bio}
                                                    onChange={(e) => setState({ ...state, bio: e.target.value && e.target.value.trimLeft(), bioErr: validate(contents, 'valid', e.target.value) })}
                                                    error={state.bioErr}
                                                />
                                            </div>

                                        </div>
                                        <button type="submit" disabled={state.loading} className="btn btn-primary btn-lg d-block btn-min mt-5">{messages.Save}</button>
                                    </form>
                                    {/* <button className="btn btn-primary btn-lg d-block btn-min">Save</button> */}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="expert-profile-update-blk">
                                    <h4>{messages.uploadProfilePicture}</h4>

                                    <div className="expert-profile">
                                        <figure>
                                            {/* <img src={(state.profile_image == null || state.profile_image == '') ? Images.dummy : state.profile_image} /> */}
                                            {/* {state.profileImg ?
                                                <img src={state.profileImg} />
                                                :
                                                <div className="profile-txt">Add Profile Picture</div>
                                            } */}
                                            {state.profile_image ? <img src={state.profile_image} /> :
                                                <div id="avatar-area" class="avatar-area default" style={{ height: '120px', width: '120px' }} />
                                                }
                                        </figure>
                                        <button onClick={chooseFile} className="btn edit-btn waves-effect waves-light" >
                                            <label style={styles.camIcon}><i className="fas fa-camera"></i></label>
                                            <input
                                                onChange={e => {
                                                    console.log('file of image is ', e)
                                                    setState({ ...state, image: e.target.files[0], showModal: true })
                                                }}
                                                key={randomKey}
                                                id="select-file"
                                                type="file"
                                                accept="image/*"
                                                ref={inputRef}
                                            />
                                        </button>
                                    </div>
                                    {/* {state.profileImgErr && <ErrorText className="errorText center" title={state.profileImgErr} />} */}

                                    <p>{messages.profilePictureCaution}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            {/* <div className="modal viewmodal-profile fade" id="viewmodal-profile" tabIndex="-1" role="dialog" aria-labelledby="viewmodal-profile" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Darrell‘s profile photo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><img src={Images.closeIcon} /></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="exper-profile-add text-center">
                                <div className="expert-profile">
                                    <figure>
                                        <img src={Images.user2} />
                                    </figure>
                                    <button className="btn edit-btn waves-effect waves-light">
                                        <input type="file" id="file" />
                                        <label htmlFor="file"><i className="fas fa-camera"></i></label>
                                    </button>
                                </div>
                                <p>Zoom</p>
                                <div className="profile-range">
                                </div>
                                <p className="mb-4">Drag and zoom to reposition photo</p>
                                <div className="btn-group">
                                    <button className="btn btn-primary btn-lg btn-min">Save</button>
                                    <button className="btn btn-primary trans-btn btn-lg btn-min">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {state.showModal && <ImageUpload
                image={state.image}
                from="expCompleteProfile"
                requestProp="imgUploadExpR"
                avatarProps={{
                    image: state.image,
                    width: 150,
                    height: 150,
                    border: 0,
                    color: [255, 255, 255, 0.6],
                    rotate: 0,
                    borderRadius: 100,
                }}
                closeUploadModal={() => setState({ ...state, showModal: false })}

                onImageUploadSuccess={(profile_image) => {
                    setState({ ...state, loading: false, profile_image, showModal: false })
                }}
                onImageUploadError={() => setState({ ...state, loading: false })}
                onImageUploadLoad={() => setState({ ...state, loading: true })}

            />}
            {state.loading && <Loader />}
        </>
    )
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateProfileRequest: ({ from, body }) => {
            dispatch(updateProfileA({ from, body }));
        },
        userInfoRequest: ({ from, tenant }) => {
            dispatch(userInfoA({ from, tenant }));
        },
        imgUploadRequest: ({ from, image }) => {
            dispatch(imgUploadA({ from, image }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
