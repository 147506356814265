export const getRequiredFields = (settings) => {
  if (!settings) return [];

  let requiredFields = [];

  for (let [key, value] of Object.entries(settings)) {
    if (value.isRequired) {
      requiredFields.push(key);
    }
  }
  return requiredFields;
};
