import { put, post } from '../../../utils/Api';
import axios from 'axios';
import { imgUploadLoading, imgUploadSuccess, imgUploadError } from '../../constants/reduxConstant';
import { imgUploadExpLoading, imgUploadExpSuccess, imgUploadExpError } from '../../constants/reduxConstant';
import { imgUploadInLoading, imgUploadInSuccess, imgUploadInError } from '../../constants/reduxConstant';
import apiName from '../../../constants/apiName';
import udid from '../../../components/Common/udid';
import { imgSize } from '../../constants/StaticArr';
import { getUserData } from "../../../utils/utils";

const apiLoading = () => ({ type: imgUploadLoading });
const apiSucceed = (payload) => ({ type: imgUploadSuccess, payload });
const apiError = (payload) => ({ type: imgUploadError, payload });

const apiLoadingOne = () => ({ type: imgUploadExpLoading });
const apiSucceedOne = (payload) => ({ type: imgUploadExpSuccess, payload });
const apiErrorOne = (payload) => ({ type: imgUploadExpError, payload });

const apiLoadingTwo = () => ({ type: imgUploadInLoading });
const apiSucceedTwo = (payload) => ({ type: imgUploadInSuccess, payload });
const apiErrorTwo = (payload) => ({ type: imgUploadInError, payload });

const imgUploadA = ({ from, image }) => async (dispatchEvent, getState) => {
    let userData = await getUserData();
    let tenant = userData.tenant;
    let body = new FormData();
    body.append('image', image)

    if (from === 'dashboard7') {
        body.append('height', imgSize.logoHeight)
        body.append('width', imgSize.logoWidth)
        dispatchEvent(apiLoading());
        try {
            put(apiName.imgUpload+tenant, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    }
    else if (from === 'expCompleteProfile') {
        body.append('height', imgSize.profileHeight)
        body.append('width', imgSize.profileWidth)
        dispatchEvent(apiLoadingOne());
        try {
            put(apiName.imgUpload+tenant, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceedOne(res));
                    } else {
                        dispatchEvent(apiErrorOne(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiErrorOne(error));
                });
        } catch (e) {
            dispatchEvent(apiErrorOne(e));
        }
    }
    else if (from === 'profile') {
        body.append('height', imgSize.profileHeight)
        body.append('width', imgSize.profileWidth)
        dispatchEvent(apiLoadingTwo());
        try {
            put(apiName.imgUpload+tenant, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceedTwo(res));
                    } else {
                        dispatchEvent(apiErrorTwo(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiErrorTwo(error));
                });
        } catch (e) {
            dispatchEvent(apiErrorTwo(e));
        }
    }
}

export default imgUploadA