import {
  NONE,
  fetchTenantClientAppTextSettings,
  fetchTenantClientAppTextSettingsLoading,
  fetchTenantClientAppTextSettingsError,
  SUCCESS,
  LOADING,
  ERROR,
} from "redux/constants/reduxConstant";

const initialState = { STATUS: NONE, value: {} };

const tenantClientAppTextSettings = (state = initialState, action) => {
  switch (action.type) {
    case fetchTenantClientAppTextSettingsLoading:
      return { ...state, STATUS: LOADING };
    case fetchTenantClientAppTextSettings:
      return { ...state, STATUS: SUCCESS, value: action.payload };
    case fetchTenantClientAppTextSettingsError:
      return { ...state, STATUS: ERROR, value: action.payload };
    default:
      return state;
  }
};

export default tenantClientAppTextSettings;
