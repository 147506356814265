import React from "react";
import { useSelector } from "react-redux";
import BuyerCheckoutComplete from "./buyer/BuyerCheckoutComplete";
import BuyerCartScreen from "./BuyerCartScreen";
import BuyerSingleProduct from "./BuyerSingleProduct";
import BuyerVariantScreen from "./BuyerVariantSceen";
import Shop from "./Shop";

const BuyerScreen = () => {
  const activeStep = useSelector(state => state.shoppingReducer.activeStepBuyer)
  
  return (
    <React.Fragment>
      {activeStep === 1 && (
        <Shop />
      )}
      {activeStep === 5 && (
        <BuyerSingleProduct />
      )}
      {activeStep === 6 && (
        <BuyerVariantScreen />
      )}
      {activeStep === 7 && (
        <BuyerCartScreen />
      )}
      {activeStep === 8 && (
        <BuyerCheckoutComplete />
      )}
    </React.Fragment>
  )
}

export default BuyerScreen