import Box from "@mui/material/Box";
import React from "react";
import MessageTemplate from "./MessageTemplate";

const LoadingBee = (props) => {
  return (
    <MessageTemplate>
      <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
        {/* <h2>Please wait....</h2>
        <CircularProgress /> */}
      </Box>
    </MessageTemplate>
  );
};

LoadingBee.propTypes = {};

export default LoadingBee;
