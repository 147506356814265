import Skeleton from "@mui/material/Skeleton";
import { showToast } from "components/Common/Common";
import { getError } from "components/ErrorHandler/getError";

import { copyLink } from "components/Common/copyLink";
import { scheduleCallFormRegrex } from "components/ErrorHandler/regrex";
import { validateFormAtOnce } from "components/ErrorHandler/validateFormAtOnce";
import { componentsName } from "config/client-api-status";
import {
  schedulingComponentsNames,
  schedulingComponentsNamesMap,
} from "config/TabConfig";
import apiName from "constants/apiName";
import messages from "constants/messages";
import useFetchExpertsForClient from "hooks/fetchExpertsForClient";
import useFetchClientAppSettings from "hooks/useFetchClientAppSettings";
import moment from "moment-timezone";
import { amOrPM, removeSecound } from "pureFunctions/amOrPM";
import { getDateToString } from "pureFunctions/utcToISODate";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { request } from "utils/request";
import ExpertAvatarName from "views/client/SelectDateToScheduleCall/ExpertAvatarName";
import StaticDatePickerLandscape from "../common/DateLandscapeOrientation/StaticDatePickerLandscape";
import SchedulingTemplate from "../common/SchedulingTemplate";
import EnterInformationForSchedule from "./EnterInformationForSchedule";
import "./select-date-to-schedule.scss";

const Main = ({
  experts,
  selectedTimeZone,
  setSelectedTimeZone,
  loading,
  timezones,
  selectedDay,
  setSelectedDay,
  monthChangeHandler,
  availabilitiesDays,
  setAvalabilitiesDays,
  expertAvailabilities,
  availabilitiesLoading,
  contents,
  activeComponent,
  setActiveComponent,
  goBackFromActionComponent,
}) => {
  const { email, tenant } = useParams();
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedExpert, setSelectedExpert] = useState({});
  const [isScheduling, setIsScheduling] = useState(false);
  const [formState, setFormState] = useState({
    clientName: "",
    clientEmail: "",
    clientPhone: "",
    countryCode: "1",
  });
  const history = useHistory();

  const [formErrors, setformErrors] = useState({});

  useLayoutEffect(() => {
    if (!experts) {
      return;
    }
    setSelectedExpert(
      ...experts.filter((expert) => expert.email === window.atob(email))
    );
  }, [experts, email]);

  const onChangeEventHandler = (e) => {
    const { name, value } = e.target;

    setFormState({ ...formState, [name]: value });
  };

  const confirmTimeButtonHandler = () => {
    setActiveComponent(schedulingComponentsNamesMap.enterInformation);
  };

  const ConfirmButton = () => {
    return (
      <button
        className="btn btn--primary col-2"
        onClick={confirmTimeButtonHandler}
      >
        {contents.confirm ?? messages.confirm}
      </button>
    );
  };

  const selectedTimeHandler = (value) => {
    setSelectedTime(value);
  };

  const getAvailableTimeUI = useMemo(() => {
    if (!selectedDay) return null;

    const getSelectedTimeFrames = expertAvailabilities.filter(
      (availabilitie) => availabilitie.date.indexOf(selectedDay) === 0
    );

    if (getSelectedTimeFrames.length === 0) {
      return null;
    }

    const getUITimeSelection = getSelectedTimeFrames[0]?.timeframes?.map(
      (item, i) => {
        // remove the date and only get the time
        const startTime = removeSecound(item.startTime.split("T")[1]);
        const endTime = removeSecound(item.endTime.split("T")[1]);

        const startAndEndTime = `${startTime} ${amOrPM(
          startTime
        )} - ${endTime} ${amOrPM(endTime)}`;
        return (
          <div
            key={i}
            className="item"
            onClick={() => selectedTimeHandler(JSON.stringify(item))}
          >
            <div
              className={`time ${
                selectedTime === JSON.stringify(item) ? "col-2" : "col-1"
              }`}
            >
              {startAndEndTime}
            </div>
            {selectedTime === JSON.stringify(item) && <ConfirmButton />}
          </div>
        );
      }
    );

    return getUITimeSelection;
  }, [selectedTime, selectedDay, expertAvailabilities]);

  const scheduleACallSubmitHandler = async () => {
    setformErrors({});
    const requiredFileds = [
      "clientName",
      "clientEmail",
      "clientPhone",
      "countryCode",
    ];
    // Validate form
    const errors = await validateFormAtOnce(
      formState,
      scheduleCallFormRegrex,
      requiredFileds
    );

    if (Object.entries(errors).length !== 0) {
      setformErrors(errors);
      return;
    }
    const { appointmentId } = JSON.parse(selectedTime);
    const { clientEmail, clientName } = formState;

    // fomr the payload
    const body = {
      tenant,
      clientEmail,
      clientName,
      clientPhone: `+${formState.countryCode}${formState.clientPhone}`,
      expertEmail: window.atob(email),
      appointmentId,
      comment: "",
      timeZoneId: selectedTimeZone,
    };
    setIsScheduling(true);
    try {
      const response = await request({
        url: apiName.book,
        method: "post",
        body,
      });

      if (response.isSucceeded) {
        showToast(
          contents.bookedMeetingSuccessMsg ?? messages.bookedMeetingSuccessMsg,
          "success"
        );
        history.push(`/${tenant}`);
      }

      if (!response.isSucceeded) {
        showToast(response.error, "error");
      }
    } catch (err) {
      console.error(err);
      showToast(getError(err));
    }
    setIsScheduling(false);
  };

  const getStringBasedOnActiveComponent = useMemo(() => {
    if (activeComponent === schedulingComponentsNamesMap.calendar) {
      return contents.day ?? messages.day;
    }
    if (activeComponent === schedulingComponentsNamesMap.time) {
      return contents.time ?? messages.time;
    }
    return null;
  }, [activeComponent, contents.day, contents.time]);

  const shareProfileHandler = () => {
    const url = `${window.location.host}/${tenant}/expert-profile/${email}`;
    copyLink(contents, url);
  };

  const onCancelHandler = () => {
    history.push(`/${tenant}/client-start-consultation`);
  };

  return (
    <section className="expert-intro">
      <ExpertAvatarName
        contents={contents}
        {...selectedExpert}
        shareProfileHandler={shareProfileHandler}
        onCancelHandler={onCancelHandler}
      />

      {activeComponent === schedulingComponentsNamesMap.time && (
        <div className="selected-date">{getDateToString(selectedDay)}</div>
      )}

      {activeComponent !== schedulingComponentsNamesMap.enterInformation && (
        <>
          <div className="row">
            <div className="select-day">
              {contents.selectA ?? messages.selectA}{" "}
              {getStringBasedOnActiveComponent}
            </div>
          </div>
          {loading && <Skeleton />}

          {!loading && timezones.length > 0 && (
            <div className="row">
              <label htmlFor="time-zone" className="time-zone">
                {contents.timeZone ?? messages.timeZone}
              </label>
              <select
                name="timezone"
                id="timezone"
                value={selectedTimeZone}
                onChange={(e) => {
                  setSelectedTimeZone(e.target.value);
                }}
              >
                <option value="">
                  {contents.selectTimZone ?? messages.selectTimeZone}
                </option>
                {timezones.map((timezone, i) => (
                  <option value={timezone.id} key={timezone.id}>
                    {timezone.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>
      )}

      <div className="row">
        {activeComponent === schedulingComponentsNamesMap.time && (
          <>
            <div className="list-available-time">{getAvailableTimeUI}</div>
          </>
        )}

        {activeComponent === schedulingComponentsNamesMap.calendar && (
          <>
            <StaticDatePickerLandscape
              setter={setSelectedDay}
              getter={selectedDay}
              monthChangeHandler={monthChangeHandler}
              availabilitiesDays={availabilitiesDays}
              setActiveComponent={setActiveComponent}
            />
          </>
        )}

        {activeComponent === schedulingComponentsNamesMap.enterInformation && (
          <EnterInformationForSchedule
            selectedDay={selectedDay}
            selectedTimeZone={selectedTimeZone}
            selectedTime={selectedTime}
            onChangeEventHandler={onChangeEventHandler}
            formState={formState}
            formErrors={formErrors}
            scheduleACallSubmitHandler={scheduleACallSubmitHandler}
            contents={contents}
            setFormState={setFormState}
            setformErrors={setformErrors}
            isScheduling={isScheduling}
          />
        )}
      </div>
    </section>
  );
};

const SelectDateToScheduleCall = (props) => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timezones, setTimeZones] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [activeComponent, setActiveComponent] = useState("calendar");
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [expertAvailabilities, setExpertAvailabilities] = useState({
    availabilities: [],
    loading: false,
  });

  const [availabilitiesDays, setAvalabilitiesDays] = useState([]);
  const [availabilitiesLoading, setAvailabilitiesLoading] = useState(false);

  const getDateInSelectedTimeZone = useMemo(() => {
    return moment().tz(selectedTimeZone).format();
  }, [selectedTimeZone]);

  const getLastDayOfCurrentMonth = useMemo(() => {
    return moment().tz(selectedTimeZone).endOf("month").format();
  }, [selectedTimeZone]);

  const [dateRange, setDateRange] = useState({
    startDate: moment().tz(selectedTimeZone).format(),
    endDate: moment().tz(selectedTimeZone).endOf("month").format(),
  });

  const history = useHistory();

  const goBackFromActionComponent = (from) => {
    if (activeComponent === schedulingComponentsNamesMap.calendar) {
      history.goBack();
    }
    if (activeComponent !== schedulingComponentsNamesMap.enterInformation) {
      setSelectedDay(null);
      setSelectedTime({});
    }

    const indexOfActiveComponent =
      schedulingComponentsNames.indexOf(activeComponent);
    setActiveComponent(schedulingComponentsNames[indexOfActiveComponent - 1]);
  };

  const { tenant, email } = useParams();

  useEffect(() => {
    const fetchTimeZone = async () => {
      setLoading(true);
      try {
        const data = await request({
          url: apiName.timezone,
          method: "get",
        });
        setTimeZones(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    fetchTimeZone();
  }, []);

  
  

  const {
    tenantExperts: { value },
    preferedLanguageContent: { contents },
    tenantClientAppSettings,
    tenantClientAppTextSettings
  } = useSelector((state) => state);

  const { value: clientAppSetting } = tenantClientAppSettings;
  const {value: tenantClientAppTextSetting} = tenantClientAppTextSettings;
  useFetchClientAppSettings({
    tenant,
    clientAppSetting,
  });

  useFetchExpertsForClient({
    tenant,
    experts: value,
  });

  const getAvailableDate = (availability) => {
    return availability.map((av) => new Date(av.date.split("T")[0]).getDate());
  };

  useEffect(() => {
    const fetchExpertAvaibilities = async () => {
      setAvailabilitiesLoading(true);
      try {
        const body = {
          tenant,
          expertEmail: window.atob(email),
          timeZoneId: selectedTimeZone,
          startDate: moment(dateRange.startDate).tz(selectedTimeZone).format(),
          endDate: moment(dateRange.endDate).tz(selectedTimeZone).format(),
        };

        const response = await request({
          url: apiName.getExpertAvailabilities,
          method: "post",
          body,
        });

        if (response.isSucceeded) {
          const { availability } = response;
          const availableDays = getAvailableDate(availability);
          setAvalabilitiesDays(availableDays);
          setExpertAvailabilities(availability);
        }

        if (!response.isSucceeded) {
          showToast(response.error, "error");
        }
      } catch (err) {
        console.error(err);
        showToast(getError(err), "error");
      }
      setAvailabilitiesLoading(false);
    };
    if (dateRange.startDate || dateRange.endDate) {
      fetchExpertAvaibilities();
    }
  }, [
    getLastDayOfCurrentMonth,
    selectedTime,
    email,
    selectedTimeZone,
    tenant,
    getDateInSelectedTimeZone,
    dateRange,
  ]);

  const monthChangeHandler = useCallback(
    (month) => {
      const currentMonth = moment().month();
      const monthCurrent = moment(month).month();
      let startDate;

      if (currentMonth === monthCurrent) {
        startDate = moment().tz(selectedTimeZone).format();
      } else {
        startDate = moment(month).tz(selectedTimeZone).format();
      }

      const endDate = moment(month).endOf("month").format();
      setDateRange({ startDate, endDate });
    },
    [selectedTimeZone]
  );

  const { experts } = value || {};
  let mainProps = { experts };

  const resetAvailabalityHanlder = useCallback(() => {
    if (activeComponent === schedulingComponentsNamesMap.time) {
      setDateRange({
        startDate: moment().tz(selectedTimeZone).format(),
        endDate: moment().tz(selectedTimeZone).endOf("month").format(),
      });
    }
  }, [selectedTimeZone, activeComponent]);
  return (
    <SchedulingTemplate
      main={
        <Main
          {...mainProps}
          selectedTimeZone={selectedTimeZone}
          setSelectedTimeZone={setSelectedTimeZone}
          loading={loading}
          timezones={timezones}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          monthChangeHandler={monthChangeHandler}
          availabilitiesDays={availabilitiesDays}
          setAvalabilitiesDays={setAvalabilitiesDays}
          expertAvailabilities={expertAvailabilities}
          availabilitiesLoading={availabilitiesLoading}
          contents={contents}
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
          goBackFromActionComponent={goBackFromActionComponent}
        />
      }
      backButton={true}
      resetAvailabalityHanlder={resetAvailabalityHanlder}
      from={componentsName.selectDateToScheduleCall}
      goBackFromActionComponent={goBackFromActionComponent}
      clientAppSetting={clientAppSetting}
      tenantClientAppTextSetting={tenantClientAppTextSetting}
      contents={contents}
      rowClass={"gb-auto-height-12"}
    ></SchedulingTemplate>
  );
};

SelectDateToScheduleCall.propTypes = {};

export default SelectDateToScheduleCall;
