import React, { useState, useEffect, useMemo, useRef } from "react";
import { connect, useSelector } from "react-redux"
import { errorHandle } from "../../components/Common/errorHandle";
import { SUCCESS, ERROR, LOADING } from "../../redux/constants/reduxConstant";
import { getUserData } from "../../utils/utils";
import { Validate as validate} from "../../utils/validate_wrapper";
import expertsTeamA from "../../redux/action/admin/expertsTeamA";
import expertsTeamPendingInvitesA from "../../redux/action/admin/expertsTeamPendingInvitesA";
import { DefaultInputForm, DefaultDropDownForm, DefaultInputWithIconForm, InputAreaForm } from "../../components/Common/Input";
import defaultA from "../../redux/action/defaultA";
import messages from "../../constants/messages";
import ImageUpload from "../../components/Common/ImageUpload";
import udid from "../../components/Common/udid";
import countryList from 'react-select-country-list'
import { roles, langArr } from "../../redux/constants/StaticArr";
import deleteExpertProfileA from "../../redux/action/admin/deleteExpertProfileA";
import { showToast } from "../../components/Common/Common";
import styles from "../style/dashboardStep";
import updateProfileA from "../../redux/action/common/updateProfileA"
import imgUploadA from "../../redux/action/common/imgUploadA"
import revokeInvitationA from "../../redux/action/admin/revokeInvitationA";
import Images from "../../constants/Images";
import inviteExpA from "../../redux/action/admin/inviteExpA";

const ExpertList = (props) => {
    const editorRef = useRef(null)
    const inputRef = useRef(null)
    let randomKey = Math.random().toString(36)
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);
    const [state, setState] = useState({
        loading: false,
        isEdit: false,
        team: [],
        showInviteExpertsModal: false,
        pendingInvitations: [],
        selectedRole: { value: 'Expert', label: 'Expert' },
		defaultRole: { value: 'Expert', label: 'Expert' },
        rangeValue: [1],
        image: '',
        expertArr: [],
		expIndex: 1,
    });

    const [selectedExpert, setSelectedExpert] = useState({
        email: '',
        firstName: '',
        lastName: '',
        name: '',
        location: '',
        profileImage: '',
        selectedRole: 'Expert',
        selectedCountry: '',
        selectedLanguages: [],
        languages: []
    })

    const countries = useMemo(() => countryList().getData(), [])

    const teamList = async () => {
        const userData = await getUserData();
        props.expertsTeamRequest({ from: "expertsList", tenant: userData.tenant });
    };

    const pendingList = async () => {
        const userData = await getUserData();
        props.expertsTeamPendingInvitesRequest({ from: "expertsList", tenant: userData.tenant });
    };

    const chooseFile = () => {
        inputRef.current.click();
    }

    const clearError = () => {
        setSelectedExpert({
            ...selectedExpert,
            countryErr: '',
            languageErr: '',
        })
    }

    const checkValidation = () => {
        var countryErr = validate(contents, 'blank', selectedExpert.selectedCountry);
        var languageErr = validate(contents, 'blank', selectedExpert.selectedLanguage);
        if (countryErr || languageErr) {
            setSelectedExpert({
                ...selectedExpert,
                countryErr: countryErr,
                languageErr: languageErr,
            })
            return false;
        } else {
            return true;
        }
    };

    const updateProfile = async () => {
        clearError()
        if (!checkValidation()) {
            return;
        }
        let languages = []
        selectedExpert.selectedLanguage.map(i => {
            languages.push(i.label)
        })
        const userData = await getUserData();
        
        let formData = {
            email: selectedExpert.email,
            firstName: selectedExpert.firstName,
            lastName: selectedExpert.lastName,
            location: selectedExpert.selectedCountry.label,
            languages: languages,
            tenant: userData.tenant,
            description: selectedExpert.description,
            imageUrl: selectedExpert.profileImage,
            role: selectedExpert.selectedRole.label,
            id: udid()
        };
        
        props.updateProfileRequest({ from: 'adminExperts', body: formData })
    }

    useEffect(async () => {
        const expertsTeamPath = props.ScreenProps.expertsTeamR;
        const expertsTeamPendingInvitesPath = props.ScreenProps.expertsTeamPendingInvitesR;
        const deleteExpertPath = props.ScreenProps.deleteExpertProfileR;
        const updateProfilePath = props.ScreenProps.updateProfileR;
        const imgUploadPath = props.ScreenProps.imgUploadInR;
        const inviteExpPath = props.ScreenProps.inviteExpAdminHomeR;
        const revokeInvitationPath = props.ScreenProps.revokeInvitationR;

        if (expertsTeamPath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (expertsTeamPath.status == SUCCESS) {
            console.log("expertsTeamRes succcess res ", expertsTeamPath.value.data);
            setState({ ...state, loading: false, team: expertsTeamPath.value.data, isEdit: false });
            props.defaultRequest();
        }
        else if (expertsTeamPath.status == ERROR) {
            console.log("expertsTeam error res ", expertsTeamPath);
            setState({ ...state, loading: false });
            errorHandle(expertsTeamPath, contents);
            props.defaultRequest();
        }
        else if (expertsTeamPendingInvitesPath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (expertsTeamPendingInvitesPath.status == SUCCESS) {
            console.log("expertsTeamPendingInvitesRes succcess res ", expertsTeamPendingInvitesPath.value.data);
            setState({ ...state, loading: false, pendingInvitations: expertsTeamPendingInvitesPath.value.data });
            //   showToast(expertsTeamPendingInvitesPath.value.data.status, "success");
            props.defaultRequest();
        }
        else if (expertsTeamPendingInvitesPath.status == ERROR) {
            console.log("expertsTeamPendingInvites error res ", expertsTeamPendingInvitesPath);
            setState({ ...state, loading: false });
            errorHandle(expertsTeamPendingInvitesPath, contents);
            props.defaultRequest();
        }
        else if (updateProfilePath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (updateProfilePath.status == SUCCESS) {
            console.log('updateProfile exp success res ', updateProfilePath);
            setState({ ...state, loading: false });
            showToast(updateProfilePath.value.data.status, 'success');
            await teamList()
            props.defaultRequest();
        }
        else if (updateProfilePath.status == ERROR) {
            console.log('updateProfile exp error res ', updateProfilePath);
            setState({ ...state, loading: false });
            errorHandle(updateProfilePath, contents)
            props.defaultRequest();
        }
        else if (deleteExpertPath.status == LOADING){
            setState({ ...state, loading: true });
        }
        else if (deleteExpertPath.status == SUCCESS)
        {
            console.log('deleteExpertPath exp success res ', updateProfilePath);
            setState({ ...state, loading: false });
            showToast(deleteExpertPath.status, 'success');
            state.isEdit = false;
            await teamList()
            props.defaultRequest();
        }
        else if (deleteExpertPath.status == ERROR)
        {
            console.log('deleteExpertPath exp error res ', deleteExpertPath);
            setState({ ...state, loading: false });
            errorHandle(deleteExpertPath, contents)
            props.defaultRequest();
        }
        else if (revokeInvitationPath.status == LOADING){
            setState({ ...state, loading: true });
        }
        else if (revokeInvitationPath.status == SUCCESS)
        {
            console.log('deleteExpertPath exp success res ', updateProfilePath);
            setState({ ...state, loading: false });
            showToast(revokeInvitationPath.status, 'success');
            await pendingList()
            props.defaultRequest();
        }
        else if (revokeInvitationPath.status == ERROR)
        {
            console.log('deleteExpertPath exp error res ', deleteExpertPath);
            setState({ ...state, loading: false });
            errorHandle(deleteExpertPath, contents)
            props.defaultRequest();
        }
		else if (inviteExpPath.status == LOADING) {
			setState({ ...state, loading: true });
		}
		else if (inviteExpPath.status == SUCCESS) {
			console.log('inviteExp success res ', inviteExpPath);
			setState({ ...state, loading: false, expertArr: [], email: '' });
			showToast(inviteExpPath.value.data.status, 'success');
			props.defaultRequest();
		}
		else if (inviteExpPath.status == ERROR) {
			console.log('inviteExp error res ', inviteExpPath);
            setState({ ...state, loading: false, expertArr: [], email: '' });
            if(inviteExpPath?.error?.data?.status == "OneOrMoreExpertsAlreadyRegistered")
				showToast(messages.oneOrMoreExpertsAlreadyRegistered, 'warn');
			else
				errorHandle(inviteExpPath, contents)
			props.defaultRequest();
		}

    }, [props.ScreenProps]);

    const deleteExpertProfile = async() => {
        const userData = await getUserData();
        props.deleteExpertProfileRequest({ tenant: userData.tenant, expert: selectedExpert.email })
    }
    
    const revokeInvitation = async (invitationId) => {
        const userData = await getUserData();
        props.revokeInvitationRequest({ tenant: userData.tenant, invitationId })
    }

    useEffect(async () => {
        await teamList();
    }, [])

    const addExpert = (expIndex) => {
		let emailErr = validate(contents, 'email', state.email);
		let duplicateEmail = ""

		if (emailErr == "") {
			let temp = state.expertArr
			temp.map(i => {
				i.email == state.email && (duplicateEmail = messages.duplicateEmail)
			})
		}

		if (emailErr || duplicateEmail) {
			setState({ ...state, emailErr: emailErr + " " + duplicateEmail })
		}
		else {
			let temp = state.expertArr
			temp.push({ email: state.email, role: state.defaultRole, id: expIndex })
			setState({ ...state, expertArr: temp, email: "", emailErr: "", expIndex: state.expIndex + 1 })
		}
	}
    const updateRole = (expIndex, value) => {
		let temp = state.expertArr
		temp.map(i => {
			if (i.id == expIndex) {
				console.log('the role of current user is ', i.role.value)
				value.value == 'Expert' ? (i.role = roles[1]) : (i.role = roles[0])
			}
		})
		setState({ ...state, expertArr: temp })
	}
    const deleteExpert = (expIndex) => {
		let temp = state.expertArr
		temp.splice(temp.findIndex(a => a.id === expIndex), 1)
		console.log('delete item is ', temp)
		setState({ ...state, expertArr: temp })
	}

    const sendInvitation = async () => {
        setState({ ...state, showInviteExpertsModal: false })
        let userData = await getUserData()
        let temp = state.expertArr
        let experts = []
        temp.map(i => {
            experts.push({ email: i.email, role: i.role.value })
        })
        console.log('userdata and tenant are ', userData.tenant, experts)
        {
            userData && userData.tenant &&
                props.inviteExpRequest({
                    from: 'adminHome',
                    tenant: userData.tenant,
                    experts: experts
                })
        }
    };
    const renderExpert = (i, expIndex, index) => (
		<div className="row" key={Math.random().toString(36)} style={{ marginTop: '10px'}} >
			<div className="col-md-8">
				<DefaultInputWithIconForm
					placeholder={"name@example.com"}
					value={i.email}
					onChange={(e) => setState({ ...state, email: e.target.value ? e.target.value.trim() : "" })}
					txt={messages.btnDelete}
					disabled={true}
					icon={Images.closeIcon}
					clickIcon={() => deleteExpert(expIndex, index)}
					txtClass="del"
					btnClass="input-btn del"
				/>
			</div>
			<div className="col-md-4">
				<DefaultDropDownForm
					options={roles}
					defaultValue={state.defaultRole}
					value={i.role}
					onChange={(value) => { updateRole(expIndex, value) }}
					error={state.roleErr}
				/>
			</div>
		</div>
	)

    return (
        <>
            <div className="dashboard-right-menu">
                <div className="container-fluid expert-profile-res">
                    <div className="row expert-profile-res-blk experts-list-blk">
                        <div className="col-md-8">
                            <div className="custom-tabs-blk">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            id="team-tab"
                                            data-toggle="tab"
                                            href="#team"
                                            role="tab"
                                            aria-controls="team"
                                            aria-selected="true"
                                            onClick={teamList}>
                                            {messages.team}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link"
                                            id="invitations-tab"
                                            data-toggle="tab"
                                            href="#invitations"
                                            role="tab"
                                            aria-controls="invitations"
                                            aria-selected="false"
                                            onClick={pendingList}>
                                            {messages.pendingInvitations}
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="team"
                                        role="tabpanel"
                                        aria-labelledby="team-tab">
                                        {state?.team?.experts?.map((expert) => {
                                            return (
                                                <div key={Math.random().toString(36)} className="expert-info-item mb-4">
                                                    <div className="expert-info-blk">
                                                        <figure>
                                                            <img src={expert?.profileImage} />
                                                        </figure>
                                                        <div className="expert-info-nm-blk">
                                                            <h4>{expert?.name}</h4>
                                                            <h5>{expert?.roles?.map((role) => role + ' ')}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="expert-info-email">
                                                        <p>{expert?.email}</p>
                                                    </div>
                                                    <div className="expert-info-actions">
                                                        <button className="btn" onClick={() => {
                                                            var tmbExpert = JSON.parse( JSON.stringify(expert))
                                                            var role = expert.roles.indexOf('Admin') > -1 ?
                                                                roles.filter(x => x.value == 'Admin')[0] :
                                                                roles.filter(x => x.value == 'Expert')[0]
                                                            var country = countries.filter(x => x.value == expert.location)[0]
                                                            var languages = langArr.filter(x => expert.languages.indexOf(x.value) > -1)
                                                            setSelectedExpert({ ...tmbExpert, selectedRole: role, 
                                                                                           selectedCountry: country,
                                                                                           selectedLanguage: languages });
                                                            setState({ ...state, isEdit: true })
                                                        }}>{messages.btnEdit}</button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="invitations"
                                        role="tabpanel"
                                        aria-labelledby="invitations-tab">
                                        {state?.pendingInvitations?.experts?.map((expert) => {
                                            console.log("expert", expert);
                                            return (
                                                <div key={Math.random().toString(36)} className="expert-info-item mb-4">
                                                    <div className="expert-info-blk">
                                                        <figure>
                                                            <img src={expert?.profileImage} />
                                                        </figure>
                                                        <div className="expert-info-nm-blk">
                                                            <h4>{expert?.name}</h4>
                                                            <h5>{expert?.roles?.map((role) => role + ' ')}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="expert-info-email">
                                                        <p>{expert?.email}</p>
                                                    </div>
                                                    <div className="expert-info-actions">
                                                        <button className="btn" onClick={() => {
                                                            revokeInvitation(expert?.invitationId)
                                                        }}>{messages.btnCancel}</button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {state.isEdit ? (
                            <div className="col-md-4 mt-5">
                                <div className="expert-profile-update-blk">
                                    <div className="text-center">
                                        <label>{selectedExpert.email}</label>
                                    </div>
                                    <div className="expert-profile">
                                        <figure>
                                            <img src={selectedExpert.profileImage} />
                                        </figure>
                                        <button onClick={chooseFile} className="btn edit-btn waves-effect waves-light" >
                                            <label style={styles.camIcon}><i className="fas fa-camera"></i></label>
                                            <input
                                                onChange={e => {
                                                    console.log('file of image is ', e)
                                                    setState({ ...state, image: e.target.files[0], showModal: true })
                                                }}
                                                key={randomKey}
                                                id="select-file"
                                                type="file"
                                                accept="image/*"
                                                ref={inputRef}
                                            />
                                        </button>
                                    </div>
                                    <div className="text-left">
                                        <div className="form-group">
                                            <DefaultInputForm
                                                label={messages.firstName}
                                                placeholder={messages.firstName}
                                                type={"text"}
                                                name={"firstName"}
                                                maxLength={80}
                                                value={selectedExpert.firstName}
                                                onChange={(e) => setSelectedExpert({ ...selectedExpert, firstName: e.target.value && e.target.value.trimLeft(), firstNameErr: validate(contents, 'common', e.target.value) })}
                                                error={selectedExpert.firstNameErr}
                                            />
                                        </div>
                                            <DefaultInputForm
                                                label={messages.lastName}
                                                type={"text"}
                                                placeholder={messages.lastName}
                                                name={"lastName"}
                                                maxLength={80}
                                                value={selectedExpert.lastName}
                                                onChange={(e) => setSelectedExpert({ ...selectedExpert, lastName: e.target.value && e.target.value.trimLeft(), lastNameErr: validate(contents, 'common', e.target.value) })}
                                                error={selectedExpert.lastNameErr}
                                            />
                                            <DefaultDropDownForm
                                                label={messages.role}
                                                options={roles}
                                                defaultValue='Expert'
                                                value={selectedExpert.selectedRole}
                                                onChange={(e) => setSelectedExpert({ ...selectedExpert, selectedRole: e })}
                                                error={selectedExpert.roleErr}
                                            />

                                            <DefaultDropDownForm
                                            label={messages.lblCountry}
                                                name="country"
                                                options={countries}
                                                placeholder={messages.whereLoc}
                                                value={selectedExpert.selectedCountry}
                                                onChange={(value) => setSelectedExpert({ ...selectedExpert, selectedCountry: value, country: value.value, countryErr: validate(contents, 'blank', value) })}
                                                error={selectedExpert.countryErr}
                                            />
                                                <DefaultDropDownForm
                                                    label={messages.languages}
                                                    name="language"
                                                    options={langArr}
                                                    isMulti={true}
                                                    placeholder={messages.whatLang}
                                                    closeMenuOnSelect={false}
                                                    value={selectedExpert.selectedLanguage}
                                                    onChange={(value) => { setSelectedExpert({ ...selectedExpert, selectedLanguage: value, languageErr: validate(contents, 'blank', value) }); console.log('multiselect value is ', value) }}
                                                    error={selectedExpert.languageErr}
                                                />
                                            <InputAreaForm
                                                label={messages.description}
                                                type={"text"}
                                                placeholder={messages.tellCust}
                                                name={"bio"}
                                                maxLength={1500}
                                                value={selectedExpert.description}
                                                onChange={(e) => setSelectedExpert({ ...selectedExpert, description: e.target.value && e.target.value.trimLeft(), descriptionErr: validate(contents, 'valid', e.target.value) })}
                                                error={selectedExpert.descriptionErr}
                                            />
                                        <a
                                            href="javascript:void(0);"
                                            className="delete-expert-btn"
                                            onClick={deleteExpertProfile}
                                        >
                                            {messages.deleteExpert}
                                        </a>
                                        <div className="btn-group">
                                            <button className="btn btn-primary btn-lg d-block btn-min m-auto" onClick={() => updateProfile()}>
                                                {messages.btnSave}
                                            </button>
                                            <button className="btn btn-danger btn-lg d-block btn-min m-auto" style={{ backgroundColor: '#dc3545', color: 'white' }} onClick={() => setState({ ...state, isEdit: false })}>
                                                {messages.btnCancel}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-md-4 pt-5">
                                <div className="expert-profile-update-blk">
                                    <h4>{messages.inviteYourTeam}</h4>
                                    <button
                                        className="btn btn-primary btn-lg d-block btn-min btn-rd w-75 m-auto"
                                        data-toggle="modal"
                                        data-target="#viewmodal-invite"
                                            onClick={() => { setState({ ...state, showInviteExpertsModal: true }) }}>
                                        {messages.inviteTeam}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {state.showModal && <ImageUpload
                image={state.image}
                from="expCompleteProfile"
                requestProp="imgUploadExpR"
                avatarProps={{
                    image: state.image,
                    width: 150,
                    height: 150,
                    border: 0,
                    color: [255, 255, 255, 0.6],
                    rotate: 0,
                    borderRadius: 100,
                }}
                closeUploadModal={() => setState({ ...state, showModal: false })}
                onImageUploadSuccess={(profileImage) => {
                    setState({ ...state, loading: false, profileImage, showModal: false })
                    setSelectedExpert({ ...selectedExpert, profileImage: profileImage })
                }}
                onImageUploadError={() => setState({ ...state, loading: false })}
                onImageUploadLoad={() => setState({ ...state, loading: true })} />}

            {state?.showInviteExpertsModal &&
                <div className="modal viewmodal fade show show-model-box" id="viewmodal-invite-2" role="dialog" aria-labelledby="viewmodal-invite-2" aria-hidden="true">
                    <div className="modal-overlay"></div>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{messages.inviteYourTeam}</h5>
                                <button onClick={() => setState({ ...state, showInviteExpertsModal: false })} type="button" className="close" data-dismiss="viewmodal-invite-2" aria-label="Close">
                                    <span aria-hidden="true"><img src={Images.closeIcon} /></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="invite-team-form-blk">
                                    <p className="mb-5">{messages.enterMailsToInvite}</p>
                                    <form className="mb-5" >
                                        <DefaultInputForm
                                            placeholder={"name@example.com"}
                                            value={state.email}
                                            onChange={(e) => { setState({ ...state, email: e.target.value ? e.target.value.trim() : "", emailErr: "" }) }}
                                            error={state.emailErr}
                                            txt="Add"
                                        />
                                        <a href="javascript:void(0)" onClick={() => addExpert(state.expIndex)}>Add User</a>
                                        {state.expertArr.map((i, index) => (
                                            renderExpert(i, i.id, index)
                                        ))}
                                        <div className="text-center multi-item">
                                            <button type="button" disabled={state.loading} className="btn btn-primary btn-lg btn-min marginTop" onClick={() => { sendInvitation(); }}>{messages.inviteTeam}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        expertsTeamRequest: ({ from, tenant }) => {
            dispatch(expertsTeamA({ from, tenant }));
        },
        expertsTeamPendingInvitesRequest: ({ from, tenant }) => {
            dispatch(expertsTeamPendingInvitesA({ from, tenant }));
        },
        deleteExpertProfileRequest: ({ tenant, expert }) => {
            dispatch(deleteExpertProfileA({ tenant, expert }));
        },
        revokeInvitationRequest: ({ tenant, invitationId }) => {
            dispatch(revokeInvitationA({ tenant, invitationId }));
        },
        updateProfileRequest: ({from, body}) => {
            dispatch(updateProfileA({ from, body }));
        },
        imgUploadRequest: ({ from, image }) => {
            dispatch(imgUploadA({ from, image }));
        },
        inviteExpRequest: ({ from, tenant, experts }) => {
			dispatch(inviteExpA({ from, tenant, experts }));
		},
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpertList);
