import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import ImageUpload from "../../components/Common/ImageUpload";
import { defaultInput as DefaultInput } from "../../components/Common/Input";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import imgUploadA from "../../redux/action/common/imgUploadA";
import defaultA from "../../redux/action/defaultA";
import { Validate as validate } from "../../utils/validate_wrapper";

const ExpCompleteProfile = (props) => {
    const location = useLocation();
    const history = useHistory()
    const inputRef = useRef(null)
    let randomKey = Math.random().toString(36)
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);;
    const [state, setState] = useState({
        loading: false,
        inviteCode: '',
        showModal: false,
        profileImg: '',
        uploadImg: '',
        rangeValue: [0.5],
    })


    useEffect(() => {
        if (location && location.state && location.state.tenant) {
            console.log('the profile step 1 is ', location.state)
            let temp = location.state
            setState({ ...state, tenant: temp.tenant, logo: temp.logo })
        }
    }, []);

    const submit = (e) => {
        e.preventDefault()
        clearError()
        if (!checkValidation()) {
            return;
        }
        
        history.replace({
            pathname: '/expert-complete-profile-step2',
            state: {
                tenant: state.tenant,
                firstName: state.firstName,
                lastName: state.lastName,
                profileImg: state.profileImg,
				logo: state.logo
            },
        })

        // let formData = {
        //     email: state.email,
        //     password: state.password,
        //     invitationId: state.inviteCode,
        //     id: udid()
        // };
        // props.registerRequest({ from: 'expCreatePass', body: formData })
    };

    // validate form data
    const checkValidation = () => {
        var firstNameErr = validate(contents, 'common', state.firstName);
        var lastNameErr = validate(contents, 'common', state.lastName);

        if (firstNameErr || lastNameErr ) {
            setState({
                ...state,
                firstNameErr: firstNameErr,
                lastNameErr: lastNameErr,
            })
            return false;
        } else {
            return true;
        }
    };

    // clean error state
    const clearError = () => {
        setState({
            ...state,
            firstNameErr: '',
            lastNameErr: '',
            profileImg: '',
            uploadImg: ''
        })
    }

    const chooseFile = () => {
        inputRef.current.click();
    }

    const deleteFile = () => {
        setState({...state, profileImg: '', uploadImg: ''})
    }


    return (
        <>
            <ToastContainer />
            <div className="expert-content-block-section">
                <div className="col-xl-7 m-auto">
                    <div className="form-content-blk">
                        <Header imgSrc={state.logo} />
                        <div className="expert-content-block justify-center items-center overflow-block m-auto">
                            <div className="w-full">
                                <div className="progress-block">
                                    <div className="progress-bg-line"></div>
                                    <div className="progress-line col-8"></div>
                                    <span className="index">2 {contents?.outOf ?? messages?.outOf} 3</span>
                                </div>
                                <h4 className="exp-create-account">{contents?.personalDetails ?? messages?.personalDetails}</h4>

                                <div className="expert-profile-progress progressdiv" data-percent="30">                                    
                                    <div className="expert-profile-photo overflow-auto">
                                        <div className="float-start">
                                            <figure className="img-div rounded-circle img-div-circle">
                                                {state.profileImg ?
                                                    <img className="profile-img rounded-circle" src={state.profileImg} />
                                                    :
                                                    <img className="upload-img" src={Images.upload} />
                                                }
                                            </figure>
                                        </div>
                                        <div className="float-end text-left">
                                            <div className="mb-1 position-relative">
                                                <button className="btn btn-grey btn-lg w-50 pb-2 upload-btn">
                                                    {contents?.btnUpload ?? messages?.btnUpload}<span className='pl-1'>photo</span>
                                                </button>
                                                <button onClick={deleteFile} className="btn btn-white btn-lg w-50 pb-2 upload-btn">{contents?.btnDelete ?? messages?.btnDelete}</button>
                                                <input
                                                    onChange={e => {
                                                        // console.log('file of image is ', e)
                                                        setState({ ...state, image: e.target.files[0], showModal: true })
                                                    }}
                                                    id="select-file"
                                                    key={randomKey}
                                                    type="file"
                                                    accept="image/*"
                                                    ref={inputRef}
                                                    className="w-50"
                                                />
                                            </div>
                                            <p className="photo-para">
                                                {contents?.imageSizeCaution ?? messages?.imageSizeCaution}
                                            </p>
                                        </div>
                                    </div>
                                    {/* {state.profileImgErr && <ErrorText className="errorText center" title={state.profileImgErr} />} */}
                                </div>
                                <form onSubmit={submit} className="mb-3">
                                    <DefaultInput
                                        placeholder={contents?.firstName ?? messages?.firstName}
                                        type={"text"}
                                        name={"firstName"}
                                        maxLength={80}
                                        value={state.firstName}
                                        onChange={(e) => setState({ ...state, firstName: e.target.value && e.target.value.trimLeft(), firstNameErr: validate(contents, 'common', e.target.value) })}
                                        error={state.firstNameErr}
                                    />
                                    <DefaultInput
                                        type={"text"}
                                        placeholder={contents?.lastName ?? messages?.lastName}
                                        name={"lastName"}
                                        maxLength={80}
                                        value={state.lastName}
                                        onChange={(e) => setState({ ...state, lastName: e.target.value && e.target.value.trimLeft(), lastNameErr: validate(contents, 'common', e.target.value) })}
                                        error={state.lastNameErr}
                                    />
                                    <div className="bg-black btn-block">
                                        <button type="submit" disabled={state.loading} className="btn btn-black btn-lg btn-min w-full">{contents?.Continue ?? messages?.Continue}</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {state.showModal && <ImageUpload
                image={state.image}
                from="expCompleteProfile"
                requestProp="imgUploadExpR"
                avatarProps={{
                    image: state.image,
                    width: 150,
                    height: 150,
                    border: 0,
                    color: [255, 255, 255, 0.6],
                    rotate: 0,
                    borderRadius: 100,
                }}
                closeUploadModal={() => setState({ ...state, showModal: false })}

                onImageUploadSuccess={(profileImg) => {
                    setState({ ...state, loading: false, profileImg, showModal: false })
                }}
                onImageUploadError={() => setState({ ...state, loading: false })}
                onImageUploadLoad={() => setState({ ...state, loading: true })}

            />}
        </>
    )
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        imgUploadRequest: ({ from, image }) => {
            dispatch(imgUploadA({ from, image }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpCompleteProfile);