import { put, post, get } from '../../../utils/Api';
import axios from 'axios';
import { expertsTeamPendingInvitesLoading, expertsTeamPendingInvitesSuccess, expertsTeamPendingInvitesError } from '../../constants/reduxConstant';
import apiName from '../../../constants/apiName';
import udid from '../../../components/Common/udid';

const apiLoading = () => ({ type: expertsTeamPendingInvitesLoading });
const apiSucceed = (payload) => ({ type: expertsTeamPendingInvitesSuccess, payload });
const apiError = (payload) => ({ type: expertsTeamPendingInvitesError, payload });

const expertsTeamPendingInvitesA = ({ from, tenant }) => async (dispatchEvent, getState) => {
    
    if (from == 'expertsList') {
        dispatchEvent(apiLoading());
        try { 
            get(apiName.expertsTeamPending   + `/${tenant}`)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    };
}

export default expertsTeamPendingInvitesA