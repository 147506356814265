import React, { useState, useEffect } from "react";
import messages from "../../constants/messages";
import { getUserData } from "../../utils/utils";
import { defaultInput as DefaultInput, defaultInputWithIcon as DefaultInputWithIcon, DefaultDropDown } from "../../components/Common/Input";
import { Validate as validate} from "../../utils/validate_wrapper";
import { roles } from "../../redux/constants/StaticArr";
import Images from "../../constants/Images";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../../components/Common/loader";
import defaultA from "../../redux/action/defaultA";
import inviteExpA from "../../redux/action/admin/inviteExpA";
import { connect, useSelector } from 'react-redux';
import { SUCCESS, ERROR, LOADING, NONE, } from '../../redux/constants/reduxConstant';
import { errorHandle } from "../../components/Common/errorHandle";
import { showToast } from "../../components/Common/Common";

function Header(props) {
	const { preferedLanguageContent: { contents } } = useSelector((state) => state);
	const [state, setState] = useState({
		loading: false,
		online: true,
		tenantInfo: '',
		showModal: false,
		selectedRole: { value: 'Expert', label: 'Expert' },
		defaultRole: { value: 'Expert', label: 'Expert' },
		email: '',
		expertArr: [],
		expIndex: 1,
	});

	useEffect(() => {
		const inviteExpPath = props.ScreenProps.inviteExpAdminHomeR;

		if (inviteExpPath.status == LOADING) {
			setState({ ...state, loading: true });
		}
		else if (inviteExpPath.status == SUCCESS) {
			console.log('inviteExp success res ', inviteExpPath);
			setState({ ...state, loading: false, expertArr: [], email: '', showModal: false });
			showToast(inviteExpPath.value.data.status, 'success');
			props.defaultRequest();
		}
		else if (inviteExpPath.status == ERROR) {
			console.log('inviteExp error res ', inviteExpPath);
            if(inviteExpPath?.error?.data?.status == "OneOrMoreExpertsAlreadyRegistered")
			{
				setState({ ...state, loading: false, expertArr: [], email: '', showModal: false });
				showToast(messages.oneOrMoreExpertsAlreadyRegistered, 'warn');
			}
			else
			{
				setState({ ...state, loading: false });
				errorHandle(inviteExpPath, contents)
			}
			props.defaultRequest();
		}
	}, [props.ScreenProps]);

	useEffect(() => {
		let temp = props.ScreenProps.tenantInfoR && props.ScreenProps.tenantInfoR.value && props.ScreenProps.tenantInfoR.value.data
		if (temp != state.tenantInfo && temp != undefined) {
			setState({
				...state,
				tenantInfo: temp,
				logo: temp.logoUrl,
			})
		}
	});

	const addExpert = (expIndex) => {
		let emailErr = validate(contents, 'email', state.email);
		let duplicateEmail = ""

		if (emailErr == "") {
			let temp = state.expertArr
			temp.map(i => {
				i.email == state.email && (duplicateEmail = messages.duplicateEmail)
			})
		}

		if (emailErr || duplicateEmail) {
			setState({ ...state, emailErr: emailErr + " " + duplicateEmail })
		}
		else {
			let temp = state.expertArr
			temp.push({ email: state.email, role: state.defaultRole, id: expIndex })
			setState({ ...state, expertArr: temp, email: "", emailErr: "", expIndex: state.expIndex + 1 })
		}
	}

	const renderExpert = (i, expIndex, index) => (
		<div className="row" key={Math.random().toString(36)} style={{ marginTop: '10px'}} >
			<div className="col-md-8">
				<DefaultInputWithIcon
					placeholder={"name@example.com"}
					className="form-control input-lg input-icon delete"
					value={i.email}
					onChange={(e) => setState({ ...state, email: e.target.value ? e.target.value.trim() : "" })}
					
					txt={messages.btnDelete}
					disabled={true}
					
					icon={Images.closeIcon}
					clickIcon={() => deleteExpert(expIndex, index)}
					txtClass="del"
					btnClass="input-btn del"
				/>
			</div>
			<div className="col-md-4">
				<DefaultDropDown
					className="form-control"
					options={roles}
					defaultValue={state.defaultRole}
					value={i.role}
					onChange={(value) => { updateRole(expIndex, value) }}
					error={state.roleErr}
				/>
			</div>
		</div>
	)

	const updateRole = (expIndex, value) => {
		let temp = state.expertArr
		temp.map(i => {
			if (i.id == expIndex) {
				console.log('the role of current user is ', i.role.value)
				value.value == 'Expert' ? (i.role = roles[1]) : (i.role = roles[0])
			}
		})
		setState({ ...state, expertArr: temp })
	}

	const deleteExpert = (expIndex) => {
		let temp = state.expertArr
		temp.splice(temp.findIndex(a => a.id === expIndex), 1)
		console.log('delete item is ', temp)
		setState({ ...state, expertArr: temp })
	}

	const submit = async (e) => {
		e.preventDefault()
		clearError()
		if (!checkValidation()) {
			return;
		}
		let userData = await getUserData()
		let temp = state.expertArr
		let experts = []
		temp.map(i => {
			experts.push({ email: i.email, role: i.role.value })
		})
		console.log('userdata and tenant are ', userData.tenant, experts)
		{
			userData && userData.tenant &&
				props.inviteExpRequest({
					from: 'adminHome',
					tenant: userData.tenant,
					experts: experts
				})
		}
	};

	const checkValidation = () => {
		var emailErr = validate(contents, 'emailFormat', state.email)
		var expErr = ""
		emailErr == "" ? (expErr = state.expertArr.length == 0 ? messages.addTeam : "") : (expErr = "")
		if (emailErr || expErr) {
			setState({
				...state,
				emailErr: emailErr + " " + expErr,
			})
			return false;
		} else {
			return true;
		}
	};

	const clearError = () => {
		setState({
			...state,
			emailErr: '',
		})
	}

	return (
		<div className="wraper">
			<ToastContainer />
			<header id="fixed-header" className="header-main">
				<nav className="navbar navbar-expand-lg navbar-light">
					<div className="nav-items item-center">
						<div className="brand-img-blk">
							{props.showGB ?
								<a className="navbar-brand" href="#">
									<img src={Images.adminLogo} />
								</a>
								:
								<figure>
									{state.logo && <img src={state.logo} />}
								</figure>
							}
						</div>
					</div>
					{!props.showInvite &&
						<div className="nav-items item-right"><button onClick={() => setState({ ...state, showModal: true })} className="btn btn-action invite-member">{messages.inviteMember}</button></div>
					}
				</nav>
			</header>

			{state.showModal &&
				<div className="modal viewmodal fade  show show-model-box" id="viewmodal-invite" role="dialog" aria-labelledby="viewmodal-invite" aria-hidden="true">
					<div className="modal-overlay"></div>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">{messages.inviteYourTeam}</h5>
								<button onClick={() => setState({ ...state, showModal: false })} type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true"><img src={Images.closeIcon} /></span>
								</button>
							</div>
							<div className="modal-body">
								<div className="invite-team-form-blk">
									<p className="mb-5">{messages.enterMailsToInvite}</p>
									<form className="mb-5" onSubmit={submit}>
										<DefaultInputWithIcon
											// label={"Team member’s email"}
											placeholder={"name@example.com"}
											className="form-control input-lg clickable"
											value={state.email}
											onChange={(e) => { setState({ ...state, email: e.target.value ? e.target.value.trim() : "", emailErr: "" }) }}
											error={state.emailErr}
											txt="Add"
											
											/>
											<a href="javascript:void(0)" onClick={() => addExpert(state.expIndex)}>Add User</a>
											{/* icon={Images.add} */}
                                        	{/* clickIcon={() => addExpert(state.expIndex)} */}
											{/* handleRight={() => addExpert(state.expIndex)} */}
										{state.expertArr.map((i, index) => (
											renderExpert(i, i.id, index)
										))}
										<div className="text-center multi-item">
											<button type="submit" disabled={state.loading} className="btn btn-primary btn-lg btn-min marginTop">{messages.inviteTeam}</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			{state.loading && <Loader />}
		</div>
	);
}

const mapStateToProps = (state /*, ownProps*/) => {
	return {
		ScreenProps: state,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		inviteExpRequest: ({ from, tenant, experts }) => {
			dispatch(inviteExpA({ from, tenant, experts }));
		},
		defaultRequest: () => {
			dispatch(defaultA());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
