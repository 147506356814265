import React, { useEffect, useState } from "react";


export default function MyTimer({expiryTimestamp}) {
    const futureDate = new Date();
const getDateDiff = (date1, date2) => {
  const diff = new Date(date2.getTime() - date1.getTime());
  return {
     hour: diff.getUTCHours(),
    minute: diff.getUTCMinutes(),
    second: diff.getUTCSeconds()
  };
};

  const [diff, setDiff] = useState({});

  useEffect(() => {
      setTimeout(() => {
        setDiff(getDateDiff(new Date(expiryTimestamp), futureDate));
      }, 1000);
  });

  const addLeadingZero = (num) => {
    return ('0' + num).slice(-2)
  }

  return (
      <span>
     {diff.hour >= 1 && `${addLeadingZero(diff.hour)}:`}{addLeadingZero(diff.minute)}:{addLeadingZero(diff.second)}
      </span>
  );
}