const messages = {
  verifyFail: "Email verification failed",
  verifyProgress: "Email verification in progress",
  emailNFound: "Email not found",
  valid: "Please enter valid data",
  fieldReq: "This field is required",
  max2_200: "Min 2 - Max 200 characters allowed",
  max3_100: "Min 3 - Max 100 characters allowed",
  img: "Please upload your company logo",
  email: "Please enter your email",
  vEmail: "Please enter a valid email",
  addTeam: "Please add users to invite",
  duplicateEmail: "This email is already used. Please use different email",
  congrats: "Congratulations! You’re all set up",
  congratsDes: "",
  letGo: "Let’s go",
  inviteMember: "Invite member",
  selectTenant: "Select company",
  urlCopy: "URL copied to clipboard",
  termCon: "Terms & conditions",
  conPassReq: "Confirm your password",
  conPassSame: "Passwords must match",
  inviteTitle: "Enter your invitation code",
  inviteDes: "Please enter the code you received via email",
  codeNotFound: "Something went wrong! Please follow the email instructions",
  invitationCancelled: "Your invitation has been cancelled",
  invitationAccepted: "The invitation has already been accepted",
  invalidInvitationCode: "The invitation code is incorrect, please try again",
  iAgreeTo: "Agree to ",
  AgreeTo: "Agree to ",
  forgetPassword: "Forgot password?",
  sendPasswordInstructions:
    "Enter your email address and we’ll send you instructions to reset your password",
  termsTxt: "Terms & Conditions",
  agreeTerm: "Please agree to Terms & Conditions",
  createAccount: "Create your account",
  nameReq: "Please enter your name",
  nameFormat: "Name should not be more than 80 characters",
  Continue: "Continue",
  savePro: "Save profile",
  companyMailAddress: "Company email",
  whereLoc: "Where are you based?",
  whatLang: "What languages do you speak?",
  tellCust: "Tell customers about yourself",
  shortBio: "Tell customers about yourself",
  noProfileImg: "Profile image not available",
  alreadyExist: "This company name is already used",
  offlineTxt:
    "You are available to receive calls from clients. Make sure your video camera and microphone are working properly before  accepting any calls",
  onlineTxt: "Go online to receive calls from clients",
  Save: "Save",
  videoSettings: "Video settings",
  companyLogoDes:
    "Please be aware that this logo will be visible on your platform",
  inviteTxt: "Invitation code",
  leaveTheCall: "Leave the call?",
  endCallCantBeUndone: "This will end the call and cannot be undone",
  btnLeave: "Leave",
  btnStay: "Stay",
  btnBack: "Back",
  cart: "Cart",
  btnSave: "Save",
  btnSkip: "Skip",
  btnFinish: "Finish",
  btnLogin: "Log in",
  btnCreateAccount: "Create account",
  btnResetPassword: "Reset password",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  createNewPassword: "Create a new password",
  passwordInstructions:
    "Choose a strong password to keep your account safe and secure. Use 8 or more characters with a mix of letters, numbers, and symbols.",
  btnSendInstructions: "Send instructions",
  password: "Password",
  btnResend: "Resend email",
  verifyMail: "Verify your e-mail",
  wrongCode: "Wrong code",
  cropPhoto: "Crop photo",
  additionalInfo: "Additional information",
  imageSizeCaution: "At least 168x168px png or jpg file",
  weSentMailTo: "We’ve sent an email to",
  weSentAnotherMailTo: "We’ve sent the last mail to",
  checkMailClickActivationLink:
    "Please check your email and click the activation link to start using Getbee",
  clickLinkResend: "Click the button below if your haven’t received the link",
  companyDomain: "Company domain",
  btnContinue: "Continue",
  recommend: "Recommend",
  recommended: "Recommended",
  recommending: "Recommended",
  checkoutReqSent: "Checkout request sent",
  askToCheckout: "Ask to checkout",
  btnCancel: "Cancel",
  rateExperienceWith: "Rate your experience with",
  btnEndConversation: "End Conversation",
  calls: "Calls",
  answer: "Answer",
  alreadyHaveAccount: "Already have an account?",
  signIn: "Log in",
  dontHaveAccount: "Don’t have an account?",
  checkCameraAndAudio: "Check camera & audio",
  haveInvitationCode: "Have an invitation code?",
  joinYourTeam: "Join your team",
  createCompanyAccount: "Create company account",
  btnDelete: "Delete",
  btnEdit: "Edit",
  camera: "Camera",
  microphone: "Microphone",
  audioOutput: "Audio output",
  applyVideoSettings: "Apply video settings",
  welcome: "Welcome",
  inviteYourTeam: "Invite your team",
  invitedExperts: "Invited experts",
  editProfile: "Edit profile",
  dashboardSubTitle1: "Your overview dashboard is ready! ",
  dashboardSubTitle2:
    "You can access the detail of your sessions and other statistics.",
  availability: "Availability",
  enterMailsToInvite:
    "Enter the email addresses of the members you want to invite. They will receive a unique link to join your team",
  youCanInviteLater:
    "You will access the list of your experts in the team members section",
  inviteTeam: "Invite team",
  teamMembers: "Team members",
  addOneMore: "Add one more +",
  areYouSure: "Are you sure?",
  wantToLogout: "",
  yes: "Yes",
  add: "Add",
  noThingFound: "Nothing found",
  excludesTaxesDelivery: "*Might excludes taxes & delivery",
  paymentSuccess: "Payment successful",
  orderNo: "Order number",
  catalog: "Products",
  goToCatalog: "Go to products",
  team: "Team",
  pendingInvitations: "Pending invitations",
  shareYourPlatform: "Share your platform",
  copyUrl: "Copy URL",
  youAre: "You are",
  logout: "Logout",
  online: "Online",
  offline: "Offline",
  goOffline: "Go offline",
  goOnline: "Go online",
  firstName: "First name",
  lastName: "Last name",
  role: "Role",
  admin: "Admin",
  expert: "Expert",
  client: "Client",
  emailAddress: "Email address",
  languages: "Languages",
  homepage: "Homepage",
  description: "Description",
  noDescription: "No description available",
  varinats: "Variants",
  descriptionPlaceholder: "Tell customers about yourself",
  deleteExpert: "Delete expert",
  personalDetails: "Personal details",
  lblCompanyMailAddress: "Company email address",
  mnuHome: "Home",
  mnuProfile: "Profile",
  mnuAdmin: "Admin",
  mnuExpert: "Expert",
  mnuCompany: "Company",
  companyDetails: "Company details",
  pfSettings: "Integrations",
  changesSaved: "Changes saved",
  savedSuccessfully: "Saved successfully",
  youAnsweredOnAnotherTab: "You answered the call on another device",
  loginSuccess: "Login successful",
  productAddedToCart: "Product successfully added to cart",
  productRemovedFromCart: "Product successfully removed from cart",
  productRecommended: "Product successfully recommended",
  productRemovedFromRecommendations:
    "Product successfully removed from recommendation",
  companyPictureCaution:
    "Please be aware that this logo will be visible on your platform",
  lblCompanyName: "Company name",
  speakPersonalConsultant: "Speak to your own personal consultant",
  lblIndustry: "Industry",
  lblEmployeesCount: "Number of employees",
  lblCountry: "Country",
  lblUniqueUrl: "Your unique URL",
  changeCompanyLogo: "Change company logo",
  dragCropImage: "Drag and zoom to reposition photo",
  uploadProfilePicture: "Upload your profile picture",
  upload: "Upload",
  companyInfo: "Company information",
  companySubdomain: "Company subdomain",
  profilePictureCaution:
    "Please be aware that this photo will be visible on your profile",
  profilePictureCaution2:
    "We recommend using a rectangular photo with transparent background (size, 150 x 50)",
  linkCautuin:
    "This link will be accessible to all your clients to start calling your team",
  btnUpload: "Upload",
  btnUploadPhoto: "Upload photo",
  success: "Success",
  poweredBy: "POWERED BY",
  ok: "Ok",
  no: "No",
  findConsultant: "Find consultant",
  availableConsultants: "Available consultants",
  getStarted: "Get started",
  conversationEnded: "Conversation ended",
  toHomePage: "To homepage",
  submit: "Submit",
  ratingSubmittes: "Rating successfully submitted",
  reportAbuse: "",
  describeIssue: "",
  allConsultantsBusy:
    "All our team members are busy at the moment. Please try again later",
  lookingForConsultants: "Looking for an available expert",
  connecting: "Connecting…",
  noAvailableDevices: "No devices available to perform a video call",
  callAlreadyCompleted: "This call has already been completed",
  callEndedClientDisconnection:
    "Due to the client disconnection, the call ended",
  callEndedExpertDisconnection:
    "Due to the expert disconnection, the call ended",
  clientDisconnected:
    "Client has been disconnected. Please wait for reconnection",
  expertDisconnected:
    "Expert has been disconnected. Please wait for reconnection",
  cartReadyForCheckout: "Your cart is ready for checkout",
  cancelCall: "Cancel call",
  receiveByMail: "Receive via email",
  proceedToCheckout: "Proceed to checkout",
  goToRecommended: "Go back to recommended",
  cameraNotConnected:
    "Your camera is not connected. You will be connected with audio only",
  sessionActiveOtherTab: "The session is active on another tab",
  resetPasswordMsg: "You reset new password successfully. Go to log in",
  oneOrMoreExpertsAlreadyRegistered:
    "One or more experts from the list is already registered. You can change the role from the experts screen",
  alreadyInCart: "Already in cart",
  addToCart: "Add to cart",
  sendByMail: "Send via email",
  outOfStock: "Out of stock",
  lblGoogleAnalyticsKey: "Google Analytics Key",
  preferredLanguage: "Preferred Language",
  micNotConnected: "Your microphone is not connected",
  redirectingYou: "Redirecting you …",
  connectingDevices: "Connecting your audio and video devices",
  ratingSubmitted: "Rating successfully submitted",
  emailInvalid: "The email that you entered is invalid",
  hideCatalog: "Hide Catalog",
  showCatalog: "Show Catalog",
  recommendedProducts: "Recommended Products",
  willBeShownHere: "Will Be Shown Here",
  send: "Send",
  name: "Name",
  mail: "Email",
  alreadyInRunningSession: "You're already on a running session",
  callAnsweredOnAnotherDevice: "You answered the call on another device",
  anErrorOccured: "An error has occurred",
  enterFirstName: "Please enter first name",
  enterLastName: "Please enter last name",
  enterEmail: "Please enter email",
  enterPassword: "Please enter password",
  yourPageLink: "Your page link",
  backToSignIn: "Back to sign-in",
  checkYour: "Check your",
  passwordInstructionsSent:
    "Don't worry, instructions to reset your password have been sent to",
  checkTheSubmittedMailCorrect:
    "If no email is received within five minutes, check that the submitted address is correct.",
  hideProducts: "Hide Products",
  prodcuts: "Products",
  allProducts: "All Products",
  addUser: "Add",
  addNewMember: "Invite new member",
  outOf: "out of",
  Error_33: "This cart comes from an invalid session. Please try again",
  Error_29:
    "Your cart is empty. Please make sure you have items in your cart to continue with the checkout.",
  Error_51: "This order is already paid",
  Error_14: "There was an internal error. Please try again ",
  Error_53: "The item in your cart run out of stock ",
  itemIsNoLongerAvailable:
    "Some of the items in your cart are no longer available",
  err_Unknown: "Unknown error ",
  err_NotInitialized: "",
  err_Created: "",
  err_Updated: "",
  err_Deleted: "",
  err_NotFound: "Can't reach out to the expert at the moment",
  err_UsernameAlreadyExists:
    "This email is already used. Please use a different one",
  err_WrongPassword: "Wrong password",
  err_WrongPhoneNumber: "Wrong phone number ",
  err_WrongUser: "Wrong user",
  err_Unauthorized: "Unauthorized user",
  err_InvalidRole: "The selected role is invalid ",
  err_EmailNotConfirmed: "The email is not confirmed ",
  err_InternalError: "Internal error. Please try again",
  err_StoreApiVerificationError: "The API verification failed",
  err_WebhookError: "Webhook error ",
  err_StoreApiError: "Store API error ",
  err_InvalidCommand: "Invalid command. Please try again ",
  err_UserNotFound: "The user is not found",
  err_WrongEmailOrPassword: "Wrong email or password ",
  err_WrongCode:
    "The code you used is wrong. Please make sure you use the right code sent in the invitation email",
  err_CultureNotFound: "Culture not found ",
  err_InvalidInvite: "The invitation is not valid ",
  err_TenantAlreadyExist: "This name is already used",
  err_TenantIsNotExist: "This company does not exist",
  err_AlreadyConfirmed: "Your account is already confirmed",
  err_WrongTenant: "The company is wrong ",
  err_ExpertAlreadyAssigned: "An expert was already assigned for this call ",
  err_ThereAreNoExpertsOnline:
    "There are not experts available at the moment. Please try again later",
  err_ExpertWasNotSelectedForThisCall:
    "There is no expert selected for this call",
  err_InvalidToken: "The token is invalid ",
  err_CartIsEmpty: "Your cart is empty ",
  err_StoreIsNotConnected: "Your store is not connected ",
  err_ProductNotFound: "The product was not found ",
  err_ProductAlreadyExist: "The product already exists",
  err_SessionNotFound: "The session is not found ",
  err_WrongVariant: "Wrong variant ",
  err_ThereIsNoExpertInTheSession: "The session does not have an expert ",
  err_ThereIsNoClientInTheSession: "The session does not have a client ",
  err_FileGenerationError: "File error ",
  err_WrongSessionState: "The session state is wrong ",
  err_AlreadyRated: "The ratings were already submitted ",
  err_WrongEmailAddress: "The email address is wrong ",
  err_StateNotAllowed: "This state is not allowed ",
  err_InviteAcceptedAlready:
    "Your invitation was already accepted. Please login to continue ",
  err_InvitationCancelled: "This invitation has been canceled",
  err_InvalidInviteCode: "The invitation code is incorrect",
  err_OneOrMoreExpertsAlreadyRegistered:
    "One or more experts from the list is already registered. You can change the role from the experts screen",
  err_IncorrectPassword: "The password is incorrect ",
  err_AccountDeleted: "The account is deleted ",
  err_OldSignalrConnection: "Old SignalR connection ",
  err_SessionActiveOnOtherTab: "The session is active on another tab",
  err_ExpertInSessionCannotOnline:
    "You are already in an active session and can not change your online state",
  err_AlreadyPaid: "The order is paid already",
  err_WrongConfiguration: "The configuration is wrong ",
  err_OutOfStock: "Out of stock",
  clickToRecommend: "Click to recommend product",
  clickToUndo: "Click to undo",
  mailSent: "Mail sent successfully",
  search: "Search",
  select: "Select",
  userSalesData: "User sales data",
  cancel: "Cancel",
  changes: "Changes",
  settings: "settings",
  saveUserSettings: "Save user settings",
  inviteMembers: "Invite Members",
  pleaseEnter: "Please enter",
  formLanguageError: "Please select at lease one language",
  confirmNewPassword: "Confirm new password",
  updatePhoto: "Update photo",
  personalInfo: "Personal Info",
  comingSoon: "Coming soon",
  emailDashAddress: "E-mail address",
  connectIntegration: "Connect integration",
  connectMyStore: "Connect my store",
  phoneNumber: "Phone Number",
  incorrectPhoneNumber: "Phone number is not correct",
  currentPassword: "Current password",
  changePassword: "Change password",
  deleteUser: "Delete User",
  security: "Security",
  based: "Based",
  loadMore: "Load more",
  someone: "Someone",
  saveChanges: "Save Changes",
  cancelChanges: "Cancel Changes",
  myAccount: "My Account",
  duplicatesEmailErrorMsg:
    "Please check the emails, Duplicate emails is not allowed",
  allFieldsErrorMsg: "Please check you have filled all the data and try again",
  whereUBasedErr: "Please select where you are based",
  d1: "1",
  d2: "2",
  d3: "3",
  InterfaceLangauge: "Interface Language",
  passwordDidNotMatched: "Both password did not matched",
  allMembers: "All members",
  editUserProfile: "Edit user profile",
  salesData: "Sales data",
  cancelInvitation: "Cancel invitation",
  notAvailable: "Not available ",
  nothingFound: "Nothing found",
  deleteThisUser: "Delete the user",
  profileUpdateSucessMsg: "Profile sucessfully updated",
  passwordUpdateSucessMsg: "Password updated sucessfully",
  companyUpdateSucessMsg: "Compay details sucessfully updated",
  teamUpdateSucessMsg: "Team member sucessfully updated",
  teamInviteSucessMsg: "Team members has been sucessfully invited",
  invitationCancelMsg: "Invitation sucessfully canceled",
  teamDeleteSucessMsg: "Team member deleted sucessfully",
  teamDeleteErrorMsg: "Team member could not be deleted",
  sucessfullyUpdated: "Sucessfully updated",
  pcu: "Profile sucessfully updated",
  passwordUpdateMsg: "Success, Password updated sucessfully",
  noResultFound: "No Result Found",
  addMore: "Add More", 
  integrations: "Integrations",
  connectECommerce: "Connect your e-commerce platform with Getbee to display products during the calls.",
  shopifyDescription: "Shopify is a global commerce company, providing trusted tools to start, grow, market, and manage a retail business.",
  magentoDescription: "Magento empowers thousands of retailers and brands with the best eCommerce platforms and flexible cloud solutions.",
  bigCommerceDescription: "BigCommerce is an ecommerce solution that allows business owners to set up an online store and sell their products.",
  salesForceDescription: "Salesforce's leading B2C ecommerce solution maximizes conversions across all digital channels — online, mobile, social.",
  vetexDescription: "VTEX is the Enterprise Digital Commerce Platform for brands and retailers looking to build a future-proof commerce strategy.",
  connect: "Connect",
  connected: "Connected",
  integrationsUpdated: "Success, Integration sucessfully updated",
  howToConnectIntegration: "How to connect integration",
  currency: "Currency",
  pleaseSelect: "Please select",
  enterStoreUrl: "Please enter valid store url",
  storeURL: "Store URL",
  fileUploaded: "File has been sucessfully uploaded",
  expertApproved: "The expert approved your request",
  sessionFull: "The Session is full",
  comeBackTryLater: "Please come back later or try again",
  tryAgain: "Try again",
  hello: "Hello, ",
  sentNotificationsToSpecialist: "We sent a notification to the specialist and waiting for response",
  participants: "Participants",
  inviteParticipants: "Invite Participants",
  shareLinkWithPersons: "Or share a link with the person you want to invite",
  inThisCall: "In this Call",
  you: "You",
  pendingRequests: "Pending Requests",
  noPendingRequests: "No pending requests",
  deny: "Deny",
  approve: "Approve",
  callNow: "Call Now",
  knowYourSpecialist: "Know your specialist?",
  speakWithOurSpecialists: "Speak with our specialists",
  currentlyOnlineText: "specialists are currently online We’ll connect you with one of them.",
  connectWithSpe: "Connect with a specific specialist",
  specialistAreOnlien: "specialists are currently online",
  weWillConnect: "We’ll connect you with one of them.",
  scheduleACall: "Schedule a call",
  specialists: "Specialists",
  searchByName: "Search by name",
  invitation: "Invitation",
  sendInvite: "Send Invite",
  enterPersonMailToInvite: "Please enter email of the person you want to invite",
  copyLinkToShare: "Copy and share link below with the person you want to meet",
  meetingLink: "Meeting Link",
  readyToJoin: "Ready to join?",
  joinCall: "Join call",
  noName: "No Name",
  tenantTitle: "Speak with our specialists",
  selectPhoneCode: "Please select a phone code",
  shareProfile: "Share profile",
  startCall:"Start Call",
  contactingAvailableExpert: "Contacting available advisors",
  findSpecialist: "Find specialist",
  contacting:"Contacting",
  newMeeting: "New meeting",
  startNow: "Start Now",
  createLink: "Create Link",
  invalidFormSubmitted: "Please check the invalid form is submitted",
  makeSureAllFieldsFilled: "Make sure all fields are filled",
  startTimeSlotLessThenEnd: "Start time slot must be before end time slot",
  startTimeSlotNotEqualToEnd: "Start time slot and end time slot must not be same",
  timeSlotError: "Next slot start time must be greater then or equals to previous time slot end time. eg slot 1(02:00 - 4:00) slot 2 (4:00 - 5:00) or (5:00 - 10:00)",
  timeZone: "Timezone",
  bookedMeetingSuccessMsg:  "Scheduled successfully. Please check your email.",
  selectTimezone: "Please select timzone",
  availabilityUpdated: "Availability sucessfully updated",
  noUpComingMeeting: "No upcoming meetings",
  unavailable: "Unavailable",
  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",
  sat: "Sat",
  sun: "Sun",
  bookingNotification: "Please enter your contact information to receive notifications",
  confirm: "confirm",
  meetings: "Meetings",
  scheduledSession: "Scheduled session",
  with: "with",
  join: "Join",
  selectADay: "select a day",
  selectA: "Select a",
  day: "day",
  time: "time",
  selectTimeZone: "Select timezone",
  provideReason: "Please add explanation why you want to cancel this meeting.",
  dontCancel: "No, Dont' cancel",
  yesCancel:  "Yes, cancel",
  reasonToCancel: "Reason to cancel",
  countryCode: "Country Code",
  callEndedSuccess: "Call ended successfully",
  callInProgress: "Call in progress",
  yourVideoCallWith: "Your video call with",
  isInProcess: "is in process",
  endCall: "End Call",
  pleaseJoin: "Please join to continue the conversation or end this call to connect in differet way",
  default_description: "Welcome to my profile. Start a call or book an appointment with me here.",
  cancelMeeting: "Cancel meeting?",
  chatMsgDelete: "When session ends messages will be deleted.",
  startTyping: "Start type your message....",
  chat: "Chat",
  visitOurWebsite: "Visit our website",
  leaveCall: "Leave Call",
  specialistsBusyMessage: "All our specialists are busy at the moment", 
  scheduleLater: "Please schedule a call or try again later",
  busyAtTheMoment: "is busy at the moment",
  teamData: "Team Data",
	myData: "My Data",
	searchForTheExpert: "Search for a specific expert....",
	apply: "Apply",
  reporting: "Reporting",
  results: "Results",
  totalNumberOfCall: "Total number of calls",
	clientStatisfactionRate: "Clients satisfaction rate",
	completed: "Completed",
	missed:"Missed",
  all: "All",
  state: "State",
  clientInfo: "Client Info",
	duration: "Duration",
	rating: "Rating",
	type: "Type",
	details: "Details",
  export: "Export",
  reportingProps: true,
  profileCall: "Profile Call",
  directCall: "Direct Call",
  clientCall: "Client Call",
  expertCall: "Expert Call",
  profileAppointment: "Profile Appointment", 
  reportingPropsEnd: true,
  
};
export default messages;
