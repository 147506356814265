import PropTypes from "prop-types";

const Translate = ({ translatedContent, fallbacktranslatedContent }) => {
  return translatedContent ? translatedContent : fallbacktranslatedContent;
};

Translate.propTypes = {
  translatedContent: PropTypes.oneOfType([PropTypes.string]),
  fallbacktranslatedContent: PropTypes.string.isRequired,
};

export default Translate;
