import React from "react";
import messages from "constants/messages";
import { useSelector } from "react-redux";
import ellipse from "../../assets/images/ellipse-round.png";

// md:absolute
const NoResults = () => {
  const { preferedLanguageContent: { contents } } = useSelector((state) => state);
  return (
    <div className="flex items-center left-0 bottom-0 right-0 justify-center flex-col h-full">
      <img src={ellipse} alt="Loader Img" />
      <p className="text-gray-500 absolute">{contents?.noThingFound ?? messages.noThingFound}</p>
    </div>
  )
}

export default NoResults