import { registerError, registerSuccess, registerLoading, SUCCESS, ERROR, LOADING, NONE } from '../constants/reduxConstant'
import { expCreatePassError, expCreatePassSuccess, expCreatePassLoading } from '../constants/reduxConstant'

export function registerR(state = {}, action) {
    // console.log("Inside register reducer", action)
    switch (action.type) {
        case registerLoading:
            return Object.assign({}, state, { status: LOADING });
        case registerSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case registerError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}


export function expCreatePassR(state = {}, action) {
    switch (action.type) {
        case expCreatePassLoading:
            return Object.assign({}, state, { status: LOADING });
        case expCreatePassSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case expCreatePassError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}
