import { AUTH_USER, CONNECTION } from "../constants/Auth";
import { TOKEN, CHANGE_PAGE } from "../constants/Index";
const initialState = {
  token: null,
  userId: null,
  currentPage: "home",
  connection: null,
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOKEN:
      return {
        ...state,
        token: action.payload.token,
        userId: action.payload.userId,
      };
    case CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case CONNECTION:
      return {
        ...state,
        connection: action.payload.connection,
      };
    case AUTH_USER:
      return {
        ...state,
        tenants: action.payload,
      };
    default:
      return state;
  }
};
export default authReducer;
