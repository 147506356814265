export const validateFormAtOnce = async(formData, regrex, requiredFileds) => {
  const errors = {};

  requiredFileds.forEach((key) => {
    if (!formData[key] || !regrex[key].test(formData[key])) {
      errors[key] = `Please enter valid ${key}`;
    } else {
      delete errors[key];
    }
  });
  return errors;
};
