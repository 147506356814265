import { createContext, useState } from "react";
import * as signalR from "@microsoft/signalr";
import apiName from "constants/apiName";

const SignalRContext = createContext({});

export const SignalRProvider = ({ children }) => {
  const [signalRConnection, setSignalRConnection] = useState(null);
  const [signalRPublicConnection, setSignalRPublicConnection] = useState();
  

  const [numberOfAvailableExperts, setNumberOfAvailableExperts] = useState(null);

  const [pingInterval, setPingInterval] = useState(null);

  const registerEvents = (connection) => {
    console.log("register private events on", connection);
    connection.on("CallStarted", (message) => {
      console.log("Call Started", message);
    });
    connection.on("CallCancelled", (message) => {
      console.log("Call Cancelled", message);
    });
    connection.on("ChatMessage", (message) => {
      console.log("Chat message", message);
    });
  };

  const registerPublicEvents = (connection) => {
    console.log("register public events on", connection);
    connection.on("ExpertOnlineCountChanged", (message) => {
      const { numberOfAvailableExperts, numberOfOnlineExperts} = message;
      console.log("numberOfAvailableExperts",numberOfAvailableExperts);
      console.log("numberOfOnlineExperts",numberOfOnlineExperts);
      setNumberOfAvailableExperts(numberOfAvailableExperts);
    });
  };

  const initSignalR = async (token) => {
    try {
      let newconnection = await new signalR.HubConnectionBuilder()
        .withUrl(`${apiName.signalR}clientHub?GETBEE-AUTH-TOKEN=${token}`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect()
        .build();
      await newconnection.start();
      if (newconnection.state === "Connected") {
        registerEvents(newconnection);
        setSignalRConnection(newconnection);
      }
      return newconnection;
    } catch (err) {
      console.log(err);
    }
  };

  const triggerForOnlineOffline = async (connection,tenant) => {
    await connection.invoke("RegisterForExpertUpdates", {
      tenant: tenant
    });
  };

  const pingGetTenantInfo = async (connection, tenant) => {
    await connection.invoke("GetTenantInfo", {
      tenant: tenant,
    });
  };

  const initPublicSignalR = async (tenant) => {
    try {
      let newconnection = await new signalR.HubConnectionBuilder()
        .withUrl(`${apiName.signalR}clientHub?GETBEE-AUTH-TOKEN=anonymous`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect()
        .build();
      await newconnection.start();
      if (newconnection) {
        newconnection.onclose(function () {
          console.log("Connection disconnected");
          initPublicSignalR();
        });
      }
      if (newconnection.state === "Connected") {
        triggerForOnlineOffline(newconnection, tenant)
        registerPublicEvents(newconnection);
        setSignalRPublicConnection(newconnection);
        if (pingInterval) clearInterval(pingInterval);
        let newInterval = setInterval(() => {
          pingGetTenantInfo(newconnection, tenant);
        }, 60000);
        setPingInterval(newInterval);
      }
      return newconnection;
    } catch (err) {
      console.log(err);
    }
  };

  const cleanUpSignalR = () => {
    if(signalRConnection) {
      signalRConnection.stop().then(res => {
        setSignalRConnection(null);
      })
    }
  }

  return (
    <SignalRContext.Provider
      value={{ signalRConnection, signalRPublicConnection, initPublicSignalR, initSignalR, numberOfAvailableExperts, cleanUpSignalR, setNumberOfAvailableExperts }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export default SignalRContext;
