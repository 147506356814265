import {
  fetchExpertsForClient,
  fetchExpertsForClientLoading,
  fetchExpertsForClientError,
  expertProfileUpdatedEvent
} from "redux/constants/reduxConstant";

/** @typedef {object} json
 * @property {object[]} experts
 * @property {string} experts.name
 * @property {string} experts.firstName
 * @property {string} experts.lastName
 * @property {string} experts.email
 * @property {string|null} experts.profileImage
 * @property {string[]} experts.roles
 * @property {string} experts.location
 * @property {string|null} experts.platformLanguage
 * @property {string[]|} experts.spokenLanguages
 * @property {string|null} experts.description
 * @property {string} experts.userIdentifier
 * @property {boolean} experts.isOnline
 * @property {boolean} isSucceeded
 * @property {string} status
 * @property {null} error
 */

export const fetchExpertsForClientAction = (payload) => {
  return {
    type: fetchExpertsForClient,
    payload,
  };
};
/**
 * @param  {fetchExpertsForClientLoading};}} =>{return{type
 * @returns fetchExpertsForClientLoading
 */
export const fetchExpertsForClientLoadingAction = () => {
  return {
    type: fetchExpertsForClientLoading,
  };
};
/**
 * @param  {} payload
 * @param  {fetchExpertsForClientError} =>{return{type
 * @returns fetchExpertsForClientError
 */
export const fetchExpertsForClientErrorAction = (payload) => {
  return {
    type: fetchExpertsForClientError,
    payload,
  };
};
/**
 * @param  {} payload
 * @property {object} expert
 */
export const expertProfileUpdatedEventAction = (payload) => {
  return {
    type: expertProfileUpdatedEvent,
    payload
  }
} 