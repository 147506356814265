import messages from "constants/messages";
import Toast from "light-toast";
import { useSelector } from "react-redux";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import CopyIcon from "../../assets/svg/copy-icon.svg";
import NoAvatarSVG from "../../assets/svg/no-avatar.svg";
import "./invitation-sidebar.scss";

const InvitationSidebar = ({ waitingList, expertData, clientData, respondToClientJoinRequest, inviteUrl, showInvitePopup }) => {
  const { preferedLanguageContent: { contents } } = useSelector((state) => state);
  return (
    <section className={`mx-auto flex flex-col invitation-screen`}>
      <div className="w-1/2">
        <div className="s-cart__btnContainer flex-col w-full flex items-center">
          <button className="s-cart__btn--black ml-1 flex flex-row items-center justify-center p13 invite-btn" 
          onClick={() => showInvitePopup(true) }>
            <span className="plus" >+</span> 
            <span className="txt">{contents?.inviteParticipants ?? messages?.inviteParticipants}</span>
          </button>
        </div>
      </div>
      <div className="w-12/12 relative flex flex-col my-4">
        <p className="mb-6 p13" >
          {contents?.shareLinkWithPersons ?? messages?.shareLinkWithPersons}
        </p>
        <div className="justify-between flex flex-row pb-3 url" >
          <div className="p10"> {inviteUrl} </div>
          <div className="copy cursor-pointer" onClick={() => {
            navigator.clipboard.writeText(inviteUrl);
            Toast.info(contents.urlCopy ?? messages?.urlCopy, 1000, () => {});
          }}>
            <img src={CopyIcon} alt="copy-btn" />
          </div>
        </div>
        <h1 className="mb-6 mt-10 title">
          {contents?.inThisCall ?? messages?.inThisCall}
        </h1>
        <div className="flex flex-row items-center container">
          <div className={`avatar user--active`}>
            <img src={expertData?.avatar} alt="" />
          </div>
          <div className="name-role p-1">
            <div className="name">
              {expertData?.name} ({contents?.you ?? messages?.you})
            </div>
            <div className="role">
              {contents?.expert ?? messages?.expert}
            </div>
          </div>
        </div>
        { clientData && <div className="flex flex-row items-center container">
          <div className={`avatar user--active`}>
            <img src={NoAvatarSVG} alt="" />
          </div>
          <div className="name-role p-1">
            <div className="name">
              {clientData.name ? clientData.name : (contents?.noName ?? messages?.noName)}
            </div>
            <div className="role">
              {clientData.emailAutoGenerated ? "N/A" : clientData.email}
            </div>
          </div>
        </div>}
        <h1 className="mb-6 mt-10 title">
          {contents?.pendingRequests ?? messages?.pendingRequests}
        </h1>
        {waitingList.map((user) => (
          <div key={user.email} className="flex flex-row justify-between">
            <div className="row user flex flex-row container">
              <div className={`avatar user--active`}>
                <img src={NoAvatarSVG} alt="" />
              </div>
              <div className="name-role p-1">
                <div className="name">
                  {user.name ? user.name : (contents?.noName ?? messages?.noName)}
                </div>
                <div className="role">
                  {user.emailAutoGenerated ? "N/A" : user.email}
                </div>
              </div>
            </div>
            <div className="buttons flex gap-2">
              <button className="deny" onClick={() => respondToClientJoinRequest(user.email, false)}>{contents?.deny ?? messages?.deny}</button>
              <button className="s-cart__btn--black" onClick={() => respondToClientJoinRequest(user.email, true)}>{contents?.approve ?? messages?.approve}</button>
            </div>
          </div>))}
        {(!waitingList || waitingList.length === 0) &&
          <span className="p10 pl-5">{contents?.noPendingRequests ?? messages?.noPendingRequests}</span>
        }
      </div>

    </section>
  )
}
export default InvitationSidebar