import React from "react";
import loaderSvg from "../../assets/images/ellipsis-buyer.png";
import { useSelector } from "react-redux";
import messages from "constants/messages";

// md:absolute
const BuyerLoader = () => {
  const state = useSelector((state) => state);

  const {
    preferedLanguageContent: { contents },
  } = state;

  return (
    <div className="flex items-center left-0 bottom-0 right-0 justify-center flex-col h-full">
      <p className="text-black md:text-xs md:mb-8 mb-4 font-bold text-center">
        {contents.recommendedProducts ?? messages.recommendedProducts}
        <br />
        {contents.willBeShownHere ?? messages.willBeShownHere}
        
      </p>
      <img src={loaderSvg} alt="Loader Img" />
    </div>
  );
};

export default BuyerLoader;
