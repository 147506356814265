import { Skeleton } from "@mui/material";
import backIcon from "assets/svg/navigation-back.svg";
import NothingFound from "components/Common/NothingFound";
import SearchFrom from "components/Common/SearchFrom";
import messages from "constants/messages";
import useSetGetLanguageForClient from "hooks/setGetLanguageForClient";
import useFetchClientAppSettings from "hooks/useFetchClientAppSettings";
import useFetchRandomSingleRToken from "hooks/useFetchRandomSingleRToken";
import useFetchTenantInfo from "hooks/useFetchTenantInfo";
import useLanguage from "hooks/useLanguage";
import useSignalR from "hooks/useSignalR";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import callFetchClientAppTextSettings from "redux/call-action/callFetchClientAppTextSetting";
import callFetchExpertsForClient from "redux/call-action/callFetchExpertsForClient";
import { LOADING } from "redux/constants/status";
import ClientLanguageDropDown from "views/client/common/ClientLanguageDropDown";
import GridSkletonTemplate from "../common/GridSkletonTemplate";
import GridItem from "./GridItem";
import "./list-experts.scss";


const ListExperts = (props) => {
  const [search, setSearch] = useState("");
  
  const history = useHistory();
  const {
    tenantClientAppSettings,
    tenantExperts: { value, STATUS },
    tenantInfo,
    preferedLanguageContent: { contents },
    randomSingalRConnectionToken: {
      value: { connectionToken } = {},
    },
  } = useSelector((state) => state);
  const { experts } = value || {};
  const { value: clientAppSetting } = tenantClientAppSettings;
  const { tenant } = useParams();
  const dispatch = useDispatch();

  useFetchClientAppSettings({ tenant, clientAppSetting });

  // If we dont use use Memo then It cause many render
  const tInfo = useMemo(() => {
    return tenantInfo?.value;
  }, [tenantInfo?.value]);

  useFetchTenantInfo({ tenant, tenantInfo: tInfo });

  const {
    numberOfAvailableExperts
  } = useSignalR();
  const fetchExpertsForClient = useCallback( async()=> {
    await dispatch(callFetchExpertsForClient(tenant));
  },[tenant,dispatch]);
  useEffect(() => {
    if (tenant) {
      fetchExpertsForClient();
    }
  }, [tenant, fetchExpertsForClient,numberOfAvailableExperts]);

  // Fetch random signal R token
  useFetchRandomSingleRToken({
    tenant,
    connectionToken,
  });

  // Watching any event fired by singleR through the event
  // useWatchExpertUpdateEvent({
  //   connectionToken,
  //   tenant,
  //   connectionStatus,
  // });

  const navigateToToExpertProfile = (email) => {
    return (e) => {
      e.stopPropagation();
      history.push(`/${tenant}/expert-profile/${window.btoa(email)}`);
    };
  };
  const { uniqueIdentifier } = tenantInfo?.value;

  const navigateToScheduleCallWithExpert = (email) => {
    return (e) => {
      e.stopPropagation();
      history.push(
        `/${uniqueIdentifier}/${window.btoa(
          email
        )}/select-date-to-schedule-call`
      );
    };
  };

  const speakWithSpecificExpert = (email) => {
    // Here dispatch will happend as well
    return (e) => {
      e.stopPropagation(e);
      history.push(
        `/${uniqueIdentifier}/${window.btoa(email)}/speak-with-specific-expert`
      );
    };
  };
  const {
    value: { logoUrl, STATUS: tenantInfoLoading },
  } = tenantInfo;


  const tenantAvailableClientLanguages = useMemo(() => {
    return clientAppSetting.availableClientLanguages;
  }, [clientAppSetting]);

  const ref = useRef();

  const selectLanguageChangeHandler = async(lng) => {
    setSelectedLanguage(lng);
    setShowLngMenu(false);
    await dispatch(callFetchClientAppTextSettings(tenant, lng.value));
    
  };

  const { selectedLanguage, setSelectedLanguage, showLngMenu, setShowLngMenu } =
    useSetGetLanguageForClient();

  const getSelectedLanguage = useMemo(() => {
    return selectedLanguage;
  }, [selectedLanguage]);

  useLanguage({
    lng: getSelectedLanguage?.value,
    shouldFetchLanguage: true,
    fromClient: true
  });

  return (
    <div className="gb-public-view gb-light-background">
      <div className="gb-pubilc-wrapper gb-list-experts">
        <div className="logo-head">
          <div className="logo-row">
            {tenantInfoLoading === LOADING ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              <>
                <img src={logoUrl} alt="" />
                {/* {getHomePageURL} */}
              </>
            )}
          </div>
          {/* {getHomePageURL} */}
          <SearchFrom
            placeholder={`${
              contents?.searchByName ?? messages?.searchByName
            }...`}
            setValue={setSearch}
            value={search}
            customClass={"list-experts"}
          />
          {tenantAvailableClientLanguages?.length > 0 && (
            <ClientLanguageDropDown
              ref={ref}
              setShowLngMenu={setShowLngMenu}
              showLngMenu={showLngMenu}
              selectedLanguage={selectedLanguage}
              tenantAvailableClientLanguages={tenantAvailableClientLanguages}
              selectLanguageChangeHandler={selectLanguageChangeHandler}
            />
          )}
        </div>

        <div className="navigations">
          <div onClick={() => history.goBack()} className="back-arrow">
            <div className="item back-logo" onClick={() => history.goBack()}>
              <img src={backIcon} alt="" />
            </div>
            <div className="item back-title">
              {contents?.btnBack ?? messages.btnBack}
            </div>
          </div>

          <div className="item title">
            {contents?.specialists ?? messages.specialists}{" "}
          </div>
        </div>

        <section className="specialist list-container">
          <div className="mobile-search">
            <SearchFrom
              placeholder={`${
                contents?.searchByName ?? messages?.searchByName
              }...`}
              setValue={setSearch}
              value={search}
              customClass={"list-experts"}
            />
          </div>

          {STATUS !== LOADING && experts && experts?.length > 0 && (
            <div className="gb-grid">
              <div className="grid">
                {experts
                  .filter(
                    (expert) =>
                      expert.name
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                  )
                  .map((expert, i) => (
                    <GridItem
                      key={i}
                      expert={expert}
                      navigateToToExpertProfile={navigateToToExpertProfile}
                      speakWithSpecificExpert={speakWithSpecificExpert}
                      navigateToScheduleCallWithExpert={
                        navigateToScheduleCallWithExpert
                      }
                    />
                  ))}
              </div>
            </div>
          )}

          {experts && experts.length === 0 && STATUS !== LOADING && (
            <NothingFound />
          )}

          {STATUS === LOADING && <GridSkletonTemplate count={4} />}
        </section>
      </div>
    </div>
  );
};

ListExperts.propTypes = {};

export default ListExperts;
