export const preferredLanguage = [
  { label: "English", value: "en" },
  { label: "Português", value: "pt" },
  { label: "Français", value: "fr" },
  { label: "Italiano", value: "it" },
  { label: "العَرَبِيَّة", value: "ar" },
  { label: "Norsk", value: "no" },
  { label: "Español ", value: "es" },
  { label: "Dansk", value: "da" },
];

export const availableLanguagesArray = [
  "en",
  "pt",
  "fr",
  "it",
  "ar",
  "no",
  "es",
  "da"
];
