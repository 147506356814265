import React from "react";
import { useSelector } from "react-redux";
import BuyerCallCompleted from "./BuyerCallCompleted";
import ExpertCallCompleted from "./ExpertCallCompleted";

const Main = ({logo, leaveChannel}) => {
    const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus)

    return (
        <React.Fragment>      
            {Object.keys(sessionStatus).length > 0 && sessionStatus.myRole === "Expert" ? (
             <ExpertCallCompleted leaveChannel={leaveChannel} logo={logo} />) : 
            <BuyerCallCompleted leaveChannel={leaveChannel} logo={logo}/>}  
         </React.Fragment>
    )
}

export default Main