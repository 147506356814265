import {
  fetchTenantInfo,
  fetchTenantInfoLoading,
  fetchTenantInfoError,
} from "redux/constants/reduxConstant";

/**
 * @param  {} payload
 * @param  {fetchTenantInfo} =>{return{type
 * @returns fetchTenantInfo
 */
export const fetchTenantInfoAction = (payload) => {
  return {
    type: fetchTenantInfo,
    payload,
  };
};
/**
 * @param  {fetchTenantInfoLoading} =>{return{type
 * @returns fetchTenantInfoLoading
 */
export const fetchTenantInfoLoadingAction = () => {
  return {
    type: fetchTenantInfoLoading,
  };
};
/**
 * @param  {} payload
 * @param  {fetchTenantInfoError} =>{return{type
 * @returns fetchTenantInfoError
 */
export const fetchTenantInfoErrorAction = (payload) => {
    return {
        type: fetchTenantInfoError,
        payload
    }
}