import { tenantCustomizationLoading, tenantCustomizationSuccess, tenantCustomizationError, SUCCESS, ERROR, LOADING, NONE } from '../constants/reduxConstant'

export default function tenantCustomizationR(state = {}, action) {
    // console.log("Inside register reducer", action)
    switch (action.type) {
        case tenantCustomizationLoading:
            return Object.assign({}, state, { status: LOADING });
        case tenantCustomizationSuccess:
            if (action?.payload?.data?.googleAnalyticsKey)
                localStorage.setItem("gaKey", action?.payload?.data?.googleAnalyticsKey)
            else
                localStorage.removeItem("gaKey")
            if (localStorage["userData"])
                document.title = "GetBee - " + action?.payload?.data?.name
            else
                document.title = action?.payload?.data?.name
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case tenantCustomizationError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}