
import React, { Component } from "react";
import logoIcon from './../../assets/images/logo-icon.svg';

const footer = () =>  {
    return (
        <footer className="footer footer-logo">
        <div className="container-fluid">
            
        </div>
    </footer>
    );
}

export default footer;
