
import CloseIcon from "assets/svg/close.svg";
import messages from "constants/messages";
import InvitationSidebar from "views/instant-call/InvitationSidebar";
import "./participants-model.scss";


export default function ParticipantsModel({
  showModel,
  setShowModel,
  contents,
  inviteUrl,
  waitingList,
  clientData,
  expertData,
  showInvitePopup,
  respondToClientJoinRequest
}) {

  return (
    <div
      className={`side-model-chat model-container meetings-model ${
        showModel ? "show-model-container" : "hide-model-container"
      }`}
    >
      <div className="model">
        <div className="main-contain">
          <div className="row row-title">
            <div className="title">{contents?.participants ?? messages?.participants}</div>
            <div className="close-icon" onClick={() => {
              setShowModel(false);
            }}>
              <img src={CloseIcon} alt="" />
            </div>
          </div>
        </div>

        <div className="conversation" id="conversation">
        <InvitationSidebar
            inviteUrl={inviteUrl}
            waitingList={waitingList}
            clientData={clientData}
            expertData={expertData}
            showInvitePopup={showInvitePopup}
            respondToClientJoinRequest={respondToClientJoinRequest}
          />
        </div>
      </div>
    </div>
  );
}
