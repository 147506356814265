export function isoTwoDigitiToName(langArr, languages) {
  if (!languages || languages.length === 0) return null;
  return langArr
    .filter((item) => {
      return languages.indexOf(item.value) !== -1;
    })
    .map((language) => language.label);
}

export function findCountryNameFromISO2(countries, iso2) {
  if (!iso2 || iso2 === "") return null;
  return countries
    .filter((country) => country.alpha2 === iso2)
    .map((item) => item.name)
    .toString();
}
