export const addItemToCart = (cartItems, cartItemToAdd) => {
    console.log(cartItems, cartItemToAdd, "CART UTILS")
  const existingCartItem = cartItems.find(
    cartItem => cartItem.id === cartItemToAdd.id
  );

  if (existingCartItem) {
    return cartItems.map(cartItem =>
      cartItem.id === cartItemToAdd.id
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
  } else {
    return [...cartItems, { ...cartItemToAdd, quantity: 1 }];
  }
};

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find(
    cartItem => cartItem.id === cartItemToRemove.id
  );

  if (existingCartItem && existingCartItem.quantity && existingCartItem.quantity === 1) {
    return cartItems.filter(cartItem => cartItem.id !== cartItemToRemove.id);
  }
  return cartItems.map(cartItem =>
    cartItem.id === cartItemToRemove.id
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

export const addItemToCartClient = (cartItems, cartItemToAdd) => {
  console.log(cartItems, cartItemToAdd, "CART UTILS")
const existingCartItem = cartItems.find(
  cartItem => cartItem.id === cartItemToAdd.id
);

if (existingCartItem) {
  return cartItems.map(cartItem =>
    cartItem.id === cartItemToAdd.id
      ? { ...cartItem, quantity: cartItem.quantity + 1 }
      : cartItem
  );
} else {
  return [...cartItems, { ...cartItemToAdd, quantity: 1 }];
}
};

export const addItemToCartSession = (cartItems, cartItemToAdd) => {
 
  const existingCartItem = cartItems.find(
    cartItem => cartItem.id === cartItemToAdd.id
  );
  
  if (existingCartItem) {
    return cartItems.map(cartItem =>
      cartItem.id === cartItemToAdd.id
        ? { ...cartItem, quantity: cartItem.quantity }
        : cartItem
    );
  } else {
    return [...cartItems, { ...cartItemToAdd, quantity: cartItemToAdd.quantity }];
  }
}

export const removeItemFromCartSession = (cartItems, cartItemToRemove) => {
  
  const existingCartItem = cartItems.find(
    cartItem => cartItem.id === cartItemToRemove.id
  );

  if (existingCartItem && existingCartItem.quantity && existingCartItem.quantity === 1) {
    return cartItems.filter(cartItem => cartItem.id !== cartItemToRemove.id);
  }
  return cartItems.map(cartItem =>
    cartItem.id === cartItemToRemove.id
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};


export const updateItemFromCartQuantity =(cartItems, cartItemToUpdate) => {

  const existingCartItem = cartItems.find(
    cartItem => cartItem.id === cartItemToUpdate.id
  );
  console.log(existingCartItem, "EXISTING CART ITEMS")
  if (existingCartItem) {
    return cartItems.map(cartItem =>
      cartItem.id === cartItemToUpdate.id
        ? { ...cartItem, quantity: cartItemToUpdate.quantity}
        : cartItem
    );
  } else {
    return [...cartItems, { ...cartItemToUpdate, quantity: cartItemToUpdate.quantity }];
  }
}