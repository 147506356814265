import useLanguage from "hooks/useLanguage";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { useLanguages } from "utils/useTranslation";
import ErrorText, { showToast } from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import { DefaultDropDown, defaultInput as DefaultInput, defaultInputWithIcon as DefaultInputWithIcon } from "../../components/Common/Input";
import udid from "../../components/Common/udid";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import defaultA from "../../redux/action/defaultA";
import registerA from "../../redux/action/registerA";
import { ERROR, LOADING, SUCCESS } from '../../redux/constants/reduxConstant';
import { Validate as validate } from "../../utils/validate_wrapper";

const Register = (props) => {
  const history = useHistory();
  const { preferedLanguageContent: { contents } } = useSelector((state) => state);
  const platformLanguages = useLanguages()
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("selectedLanguage") || "en")
  useLanguage({lng: selectedLanguage, shouldFetchLanguage: true })
  const [state, setState] = useState({
    loading: false,
    showPass: false,
    firstNameErr: "",
    lastNameErr: "",
    emailErr: "",
    passwordErr: "",
    platformLanguageErr: "",
    acceptTermsError: true,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    preferredLanguage: "",
    selectedPreferredLanguage: "",
    showPassword: false,
  })
  // handle api response
  useEffect(() => {
    localStorage.removeItem('userData')
    const registerPath = props.RegisterRes.registerR;
    if (registerPath.status === LOADING) {
      setState({ ...state, loading: true });
    }
    else if (registerPath.status === SUCCESS) {
      console.log('register success res ', registerPath);
      setState({ ...state, loading: false });
      localStorage.setItem("selectedLanguage", JSON.stringify(state.selectedPreferredLanguage.value))
      setSelectedLanguage(state.selectedPreferredLanguage.value)
      showToast(registerPath.value.data.status, 'success');
      props.defaultRequest();
      history.push({
        pathname: '/email-sent',
        state: { email: state.email },
      })
    }
    else if (registerPath.status === ERROR) {
      console.log('register error res ', registerPath);
      setState({ ...state, loading: false });
      errorHandle(registerPath, contents)
      props.defaultRequest();
    }
  }, [history, props, state, props.RegisterRes]);
  const clearError = () => {
    setState({
      ...state,
      firstNameErr: "",
      lastNameErr: "",
      emailErr: "",
      passwordErr: "",
      platformLanguageErr: "",
      acceptTermsError: true
    })
  }
  const checkValidation = () => {
    var firstNameErr = validate(contents, 'common', state.firstName);
    var lastNameErr = validate(contents, 'common', state.lastName);
    var passwordErr = validate(contents, 'password', state.password);
    var emailErr = validate(contents, 'email', state.email);
    var acceptTermsError = state.acceptTerms ? '' : contents?.agreeTerm ?? messages?.agreeTerm
    var platformLanguageErr = validate(contents, 'blank', state.selectedPreferredLanguage)
    if (passwordErr || emailErr || firstNameErr || lastNameErr || platformLanguageErr) {
      setState({
        ...state,
        firstNameErr,
        lastNameErr,
        emailErr,
        passwordErr,
        platformLanguageErr,
        acceptTermsError
      })
      return false;
    } else {
      return true;
    }
  }
  // api call
  const submit = (e) => {
    e.preventDefault()
    clearError()
    if (!checkValidation()) {
        return;
    }

    let formData = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      password: state.password,
      preferredLanguage: state.selectedPreferredLanguage.value,
      id: udid()
    };
    props.registerRequest({ from: "register", body: formData })
  };
  const showSetPass = () => {
    setState({...state, showPass: !state.showPass});
  }

  return (
    <>
      <ToastContainer />
      <div className="expert-content-block-section">
        <div className="first-section register-block">
          <div className="form-content-blk">
            <Header />
            <div className="expert-content-block justify-center items-center overflow-block m-auto">
              <div className="w-full">
                <h4>
                  {contents?.createCompanyAccount ?? messages?.createCompanyAccount}
                </h4>
                <div className="signin mobile">
                  <span className="pr-2">{contents?.alreadyHaveAccount ?? messages?.alreadyHaveAccount}</span>
                  <a href="/login">{contents?.signIn ?? messages?.signIn}</a>
                </div>
                <form onSubmit={submit} className="register-form mb-3">
                  <DefaultInput
                    label={contents?.firstName ?? messages?.firstName}
                    type={"text"}
                    // placeholder={contents?.firstName ?? messages?.firstName}
                    name={"firstName"}
                    maxLength={80}
                    value={state.firstName}
                    onChange={(e) => setState({ ...state, firstName: e.target.value ? e.target.value.trimLeft() : "", firstNameErr: validate(contents,'common', e.target.value) })}
                    error={state.firstNameErr}
                  />
                  <DefaultInput
                    label={contents?.lastName ?? messages?.lastName}
                    type={"text"}
                    // placeholder={contents?.lastName ?? messages?.lastName}
                    name={"lastName"}
                    maxLength={80}
                    value={state.lastName}
                    onChange={(e) => setState({ ...state, lastName: e.target.value ? e.target.value.trimLeft() : "", lastNameErr: validate(contents,'common', e.target.value) })}
                    error={state.lastNameErr}
                  />
                  <DefaultInput
                   label={contents?.emailAddress ?? messages?.emailAddress}
                    type={"email"}
                    // placeholder={contents?.emailAddress ?? messages?.emailAddress}
                    name={"email"}
                    maxLength={200}
                    value={state.email}
                    onChange={(e) => setState({ ...state, email: e.target.value ? e.target.value.trimLeft() : "", emailErr: validate(contents,'email', e.target.value) })}
                    error={state.emailErr}
                  />
                  <DefaultInputWithIcon
                  label={contents?.password ?? messages?.password}
                    type={state.showPass ? "text" : "password"}
                    // placeholder={contents?.password ?? messages?.password}
                    name={"password"}
                    icon={state.showPass ? Images.eyeClose : Images.eyeOpen}
                    value={state.password}
                    onChange={(e) => setState({ ...state, password: e.target.value ? e.target.value : "", passwordErr: validate(contents,'password', e.target.value) })}
                    error={state.passwordErr}
                    clickIcon={showSetPass}
                  />
                  <DefaultDropDown
                    label={contents?.preferredLanguage ?? messages?.preferredLanguage}
                    // placeholder={contents?.preferredLanguage ?? messages?.preferredLanguage}
                    name="preferredLanugage"
                    options={platformLanguages}
                    value={state.selectedPreferredLanguage}
                    onChange={(value) => {
                      setState({ ...state, selectedPreferredLanguage: value, platformLanguageErr: validate(contents,'blank', value?.value) })
                      localStorage.setItem("selectedLanguage", JSON.stringify(value.value))
                      setSelectedLanguage(value.value)
                    }}
                    noResultFound={contents?.noResultFound ?? messages?.noResultFound}
                    error={state.platformLanguageErr}
                  />
                  <div className="form-group terms-condition">
                    {/* <label className="coustom-check-box">
                      <input type="checkbox" onChange={(e) => setState({ ...state, acceptTerms: !state.acceptTerms, acceptTermsError: (!state.acceptTerms ? '' : contents?.agreeTerm ?? messages?.agreeTerm) })} />
                      <span className="checkmark"></span>
                      <p>
                        {contents?.AgreeTo ?? messages?.AgreeTo}<a href="https://www.getbee.com/terms" target="_blank">{contents?.termsTxt ?? messages?.termsTxt}</a>.
                      </p>
                    </label> */}
                    {state.acceptTermsError && <ErrorText title={state.acceptTermsError} />}
                  </div>
                  <div className="bg-black btn-block">
                    <button
                      type="submit"
                      className="btn btn-black btn-lg btn-min w-full"
                      disabled={state.loading}>
                      {contents?.btnCreateAccount ?? messages?.btnCreateAccount}
                    </button>
                  </div>
                </form>
                {/* )}
                </Formik> */}
              </div>
            </div>
          </div>
        </div>
        <div className="second-section sales-data">
          <img src={Images.salesData} />
        </div>
      </div>
    </>
  )
};


const mapStateToProps = (state /*, ownProps*/) => {
  return {
    RegisterRes: state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    registerRequest: ({ from, body }) => {
      dispatch(registerA({ from, body }));
    },
    defaultRequest: () => {
      dispatch(defaultA());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
