import DefaultAvatar from "assets/svg/avatar-default.svg";
import { Client, Expert } from "config/signalRStatus";
import moment from "moment";
import PropTypes from "prop-types";
import parseURLAddAnchorTag from "pureFunctions/parseURLAddAnchorTag";

const Message = ({ sender, myRole, message, expertInfo, clientInfo }) => {
  let classPrifix;
  let avatar = DefaultAvatar;
  let name;

  if (
    (sender === Expert && myRole === Expert) ||
    (sender === Client && myRole === Client)
  ) {
    // Message is sender
    classPrifix = "received";
  } else {
    classPrifix = "sent";
  }

  if(sender === Expert) {
    avatar = expertInfo?.avatar || DefaultAvatar;
  }

  if(sender === Expert) {
    name = expertInfo?.name
  }

  if(sender === Client) {
    name = clientInfo?.name;
  }
  
  return (
    <div className={`message ${classPrifix}`}>
      <div className="col avatar">
        <img src={avatar} alt="" />
      </div>
      <div className={`msg msg-${classPrifix} col`}>
        <div className="name-time">
          <div className="name">{name}</div>
          <div className="time">
            {
                moment(message.createdAt).format("hh:mm a")
            }
        </div>
        </div>
        <div className="message">
        {/* <span dangerouslySetInnerHTML={{ __html: test }} /> */}
     
        <span dangerouslySetInnerHTML={{ __html: parseURLAddAnchorTag(message) }} />
            
        </div>
      </div>
    </div>
  );
};

Message.propTypes = {
  sender: PropTypes.oneOf(["Client", "Expert"]),
  myRole: PropTypes.oneOf(["Client", "Expert"]),
};

export default Message;
