import { useEffect } from "react";
import { useDispatch } from "react-redux";
import callFetchClientAppSettings from "redux/call-action/callFetchClientAppSetting";

export default function useFetchClientAppSettings({
  tenant,
  clientAppSetting,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    let fetchAppClientSettings = async () => {
      await dispatch(callFetchClientAppSettings(tenant));
    };

    if ( clientAppSetting && Object.entries(clientAppSetting).length === 0) {
      fetchAppClientSettings();
    }
  }, [tenant, dispatch, clientAppSetting]);
}
