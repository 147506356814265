import {
  userInfoError,
  userInfoSuccess,
  userInfoLoading,
  SUCCESS,
  ERROR,
  LOADING,
  NONE,
} from "../../constants/reduxConstant";
export default function userInfoR(state = {}, action) {
  switch (action.type) {
    case userInfoLoading:
      return Object.assign({}, state, { status: LOADING });
    case userInfoSuccess:
      return Object.assign({}, state, {
        status: SUCCESS,
        value: action.payload,
      });
    case userInfoError:
      return Object.assign({}, state, { status: ERROR, error: action.payload });
    default:
      return Object.assign({}, state, { status: NONE });
  }
}
