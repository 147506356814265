// import { getService } from '../../../../services/getServices';
// import { postService } from '../../../../services/postServices';
import { post } from '../../utils/Api';
import axios from 'axios';
import { useLocation, Route, Switch, Redirect, Link } from "react-router-dom";
import { verifyEmailLoading, verifyEmailSuccess, verifyEmailError, AlreadyVerified, OldVerificationCode } from '../constants/reduxConstant';
import apiName from '../../constants/apiName';
import udid from '../../components/Common/udid';

const apiLoading = () => ({ type: verifyEmailLoading });
const apiConfirmed = () => ({ type: AlreadyVerified });
const apiSucceed = (payload) => ({ type: verifyEmailSuccess, payload });
const apiError = (payload) => ({ type: verifyEmailError, payload });
const apiOldVerificationCode = () => ({ type: OldVerificationCode });

const verifyEmailA = ({ from, email, code }) => async (dispatchEvent, getState) => {
    let body = {
        email: email,
        code: code,
        id: udid()
    };
    if (from == 'dashboard7') {
        dispatchEvent(apiLoading());
        try {
            post(apiName.verifyEmail, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    }
                    else if(res && res.data && res.data.status == "AlreadyConfirmed")
                    {
                        dispatchEvent(apiConfirmed());
                    }
                    else if(res && res.data && res.data.status == "WrongCode")
                    {
                        dispatchEvent(apiOldVerificationCode());
                    }
                    else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    };
}

export default verifyEmailA