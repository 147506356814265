import Translate from "components/Common/Translate";

export default function NavItem({
  selectedLi,
  setSelectedLi,
  anchorOnClick,
  contents,
  icon,
  tabId,
  defaultTittle,
  contentsId
}) {

  return (
    <li
      className={`items ${
        selectedLi === tabId ? "selected-nav" : ""
      }`}
      onClick={() => setSelectedLi(tabId)}
      id={tabId}
    >
      <div className="item">
        <img src={icon} alt="" className="icon" />
      </div>
      <div className="item">
        <a href="!#" onClick={anchorOnClick}>
          <Translate
            translatedContent={contents?.[contentsId]}
            fallbacktranslatedContent={defaultTittle}
          />
        </a>
      </div>
    </li>
  );
}
