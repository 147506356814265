import { Component } from "react";

export default class ErrorBoundry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      ...this.state,
      error: error.toString,
      errorInfo: info.componentStack,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <h2>
          Opps, Something went wrong {this.state.error} {this.state.errorInfo}
        </h2>
      );
    }
    return this.props.children;
  }
}
