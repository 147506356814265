import { CHANGE_EXPERT_STATUS, CHANGE_INCALL_STATUS } from "../constants/Auth";

export const changeExpertStatus = (payload) => {
    return {
        type: CHANGE_EXPERT_STATUS,
        payload: payload
    };
};

export const changeExpertInCallStatus = (payload) => {
    return {
        type: CHANGE_INCALL_STATUS,
        payload: payload
    };
};
