import messages from "constants/messages";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import heart from "./../../assets/images/heart.png";
import getBeeIcon from "./../../assets/images/logo-icon.svg";
import useFetchTenantInfo from "hooks/useFetchTenantInfo";
import useTenantInfo from "hooks/tenantInfo";
import CalendarSVG from "assets/svg/calendar-dark.svg";
import { typeOfCalls } from "config/TabConfig";

const RequestCallScreen = ({
  setCallOptions,
  callOptions,
  callCancelled,
  connection,
  tenant,
  email,
  sessionId,
  typeOfCall,
  selectedExpert,
}) => {
  const {
    preferedLanguageContent: { contents },
  } = useSelector((state) => state);
  const [endCall, setEndCall] = useState(false);
  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(true);
  const history = useHistory();
  const { tenant: currentTenant } = useParams();
  const { tenantInfo } = useTenantInfo();

  useFetchTenantInfo({
    tenantInfo,
    tenant: currentTenant,
  });

  const setChannelMode = async (mode) => {
    try {
      if (mode === "endCall") {
        setEndCall(!endCall);
        //window.location.reload()
        history.push(`/${tenant}/`);
        setCallOptions({ ...callOptions, endCall: endCall });
      } else if (mode === "audio") {
        setAudio(!audio);
        setCallOptions({ ...callOptions, audio: !audio });
        localStorage.setItem("audio", !audio);
      } else if (mode === "video") {
        setVideo(!video);
        setCallOptions({ ...callOptions, video: !video });
        localStorage.setItem("video", !video);
      } else if (mode === "cancelCall") {
        const payload = {
          tenant,
          email,
          sessionId,
        };

        if (connection) {
          const result = await connection.invoke("CancelCall", payload);

          if (result && result.isSucceeded) {
            //window.location.reload()
            history.push(`/${tenant}/`);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { email: expertEmail } = useParams();
  const {
    tenantExperts: { value },
  } = useSelector((state) => state);
  const { experts } = value || {};

  // Email will be always there, the route which I have created
  // But the old client consultation page is not sending email to this
  const callWithExpertOrRandomExpert = useMemo(() => {
    if (!expertEmail || !experts) return null;
    // Get the selected expert name
    const { name } = experts.filter(
      (expert) => expert.email === window.atob(expertEmail)
    )?.[0] || { name: null };

    return name;
  }, [expertEmail, experts]);

  const getContactingMessage = useMemo(() => {
    let defaultMessage = contents?.contacting ?? messages.contacting;
    if (callWithExpertOrRandomExpert) {
      return `${defaultMessage} ${callWithExpertOrRandomExpert}`;
    }
    return (
      contents.contactingAvailableExpert ?? messages?.contactingAvailableExpert
    );
  }, [
    callWithExpertOrRandomExpert,
    contents?.contacting,
    contents.contactingAvailableExpert,
  ]);

  const getTitle = (contents, callType, callWithExpertOrRandomExpert) => {
    let message;
    if (callType === typeOfCalls.random) {
      message =
        contents?.specialistsBusyMessage ?? messages?.specialistsBusyMessage;
    }
    if (callType === typeOfCalls.direct) {
      message = `${callWithExpertOrRandomExpert} ${
        contents?.busyAtTheMoment ?? messages?.busyAtTheMoment
      }`;
    }
    return message;
  };
  
  const getExpertListOrExpertProfileLink = (tenant, callType, email) => {
      if(callType === typeOfCalls.direct) {
        return `/${tenant}/expert-profile/${email}`
      }
      if(callType === typeOfCalls.random) {
        return `/${tenant}/list-experts`;
      }
      return `/${tenant}`;
  }
  const notifyUserBasedOnCallType = useMemo(() => {
    // Message with be differet based on call type

    return (
      <>
        <div className="callEnded-gb">
          <div className="title">
            {" "}
            {getTitle(contents, typeOfCall, callWithExpertOrRandomExpert)}
          </div>
          <div className="schedule-latter">
            {contents?.scheduleLater ?? messages?.scheduleLater}
          </div>
          <button className="btn btn--light btn--round btn--book">
            <span>
              <img src={CalendarSVG} alt="" />
            </span>
            <span>
              <Link to={getExpertListOrExpertProfileLink(tenant, typeOfCall, expertEmail)}>
              {contents?.scheduleACall ?? messages?.scheduleACall}
              </Link>{" "}
              
            </span>
          </button>
        </div>
      </>
    );
  }, [contents, typeOfCall, callWithExpertOrRandomExpert]);

  return (
    <>
      <div className="s-modal-client">
        <div className="gb-logo-455">
          <a className="navbar-brand">
            <img
              src={
                tenantInfo && tenantInfo?.logoUrl ? tenantInfo?.logoUrl : null
              }
              alt=""
            />
          </a>
        </div>
        {/* Based on typeof call show different UI */}
        <div className="contact-finding">
          {
            callCancelled ? (
              // <div> Hey</div>
              <div className="s-modal-client__paragraph">
                {/* <img src={heart} alt="Loader" />
                <h3 className="text-center">
                  {contents?.no ?? messages?.no} <br />{" "}
                  {contents?.availableConsultants ??
                    messages?.availableConsultants}
                </h3>
                <p>
                  {contents?.allConsultantsBusy ?? messages?.allConsultantsBusy}
                </p> */}
                {/* <div className="callEnded-gb">
                  <div className="title">
                    {" "}
                    All our specialists are busy at the moment
                  </div>
                  <div className="schedule-latter">
                    Please schedule a call or try again later
                  </div>
                  <button className="btn btn--light btn--round btn--book">
                    <span>
                      <img src={CalendarSVG} alt="" />
                    </span>
                    <span>Schedule a call</span>
                  </button>
                </div> */}
                {notifyUserBasedOnCallType}
              </div>
            ) : (
              <div className="gb-contact-advisor">
                {getContactingMessage}...{" "}
              </div>
            ) //<img src={ellipseLoader} className="img-spin" alt="Loader" />
          }
        </div>
        {!callCancelled && (
          <div className="s-video__user flex items-center">
            {/* <p className="">{contents?.lookingForConsultants ?? messages?.lookingForConsultants}</p> */}
          </div>
        )}

        <div className="session-action-blk">
          <footer className="flex justify-center items-center">
            <div className="">
              <a href="!#" className="footer-logo-item"></a>
            </div>
          </footer>
          {callCancelled ? (
            <div className="s-modal-client__settings">
              <button onClick={() => setChannelMode("endCall")}>
                {contents?.leaveCall ?? messages?.leaveCall}
              </button>
            </div>
          ) : (
            <div className="s-modal-client__settings">
              <button
                onClick={() => {
                  setChannelMode("cancelCall");
                }}
              >
                {contents?.cancelCall ?? messages?.cancelCall}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestCallScreen;
