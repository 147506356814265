import { requestExpertConnectionError, requestExpertConnectionSuccess, requestExpertConnectionLoading, SUCCESS, ERROR, LOADING, NONE } from '../constants/reduxConstant'

export default function requestExpertConnectionR(state = {}, action) {
    // console.log("Inside register reducer", action)
    switch (action.type) {
        case requestExpertConnectionLoading:
            return Object.assign({}, state, { status: LOADING });
        case requestExpertConnectionSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case requestExpertConnectionError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}