import { checkInviteError, checkInviteSuccess, checkInviteLoading, SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'

export default function checkInviteR(state = {}, action) {
    // console.log("Inside checkInvite reducer", action)
    switch (action.type) {
        case checkInviteLoading:
            return Object.assign({}, state, { status: LOADING });
        case checkInviteSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case checkInviteError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}