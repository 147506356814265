// when there is no integration then the default value will be 
// As defined below
import {
  fetchSelectedIntegration,
  fetchSelectedIntegrationError,
  fetchSelectedIntegrationLoading,
} from "redux/constants/reduxConstant";

import { SUCCESS, LOADING, ERROR, NONE } from "redux/constants/status";


const inititalState = {
  STATUS: NONE,
  value: {
    config: null,
    storeType: "Unknown",
    storeUrl: null,
    currencyInfo: null,
  },
};

const integrationsReducer = (state = inititalState, action) => {
  switch (action.type) {
    case fetchSelectedIntegrationLoading:
      return { ...state, STATUS: LOADING };
    case fetchSelectedIntegration:
      return { ...state, STATUS: SUCCESS, value: action.payload };
    case fetchSelectedIntegrationError:
      return { ...state, STATUS: ERROR, value: action.payload };
    default:
      return state;
  }
};

export default integrationsReducer;
