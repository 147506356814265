import { fetchLanguage, fetchLanguageLoading, fetchLanguageError, changeCountriesList } from "redux/constants/reduxConstant"

export const fetchLanguageAction = (payload) => {
    return {
        type: fetchLanguage, 
        payload
    }
}

export const changeCountriesLanguageAction = (payload) => {
    return {
        type: changeCountriesList, 
        payload: payload.countries
    }
}

export const fetchLanguageLoadingAction = () => {
    return {
        type: fetchLanguageLoading
    }
}

export const fetchLanguageErrorAction =(payload) => {
    return {
        type: fetchLanguageError,
        payload
    }
}   
