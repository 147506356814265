import calendarLightIcon from "assets/svg/calendar-light.svg";
import CountrySelect from "components/Common/CountrySelect";
import Progressbar from "components/Common/Progressbar";
import UserInput from "components/Common/UserInput";
import messages from "constants/messages";
import { amOrPM, removeSecound } from "pureFunctions/amOrPM";
import { getDateToString } from "pureFunctions/utcToISODate";
import React from "react";
import "views/client/ScheduleCallWithSpecificExpert/schedule-call-with-specific-expert.scss";


const EnterInformationForSchedule = ({
  selectedDay,
  selectedTimeZone,
  selectedTime,
  onChangeEventHandler,
  formState,
  formErrors,
  contents,
  scheduleACallSubmitHandler,
  setFormState,
  setformErrors,
  isScheduling
}) => {
 
  const getSelectedTime = () => {
    const parseJSON = JSON.parse(selectedTime);
    const startTime = removeSecound(parseJSON.startTime.split("T")[1]);
    const endTime = removeSecound(parseJSON.endTime.split("T")[1]);

    return {
      startTime,
      endTime,
    };
  };
  return (
    <section className="expert-intro">
      <div className="row">
        <div className="selected-date">{getDateToString(selectedDay)}</div>
      </div>
      <div className="row client-time">
        <div className="col selected-time">
          {getSelectedTime().startTime} {amOrPM(getSelectedTime().startTime)} -{" "}
          {getSelectedTime().endTime} {amOrPM(getSelectedTime().endTime)}
          {/* 09:30 - 10:00 pm */}
        </div>
        <div className="timezone">{selectedTimeZone}</div>
      </div>
      <div className="row">
        <div className="please-enter">
          {contents.bookingNotification ?? messages.bookingNotification}
          
        </div>
      </div>
      <div className="row">
        <form className="schedule-call">
          <div className="form-group flex-col">
          <label for="clientName">{contents.name ?? messages.name}</label>
            <UserInput
              name="clientName"
              type="text"
              id="clientName"
              value={formState.clientName}
              onChange={onChangeEventHandler}
              translatedContent={contents?.nameReq}
              fallbacktranslatedContent="Please enter your name"
              error={formErrors?.clientName ? true : false}
            />
          </div>

          <div className="form-group flex-col">
           <label for="clientEmail">{contents.mail ?? messages.mail}</label>
            <UserInput
              name="clientEmail"
              type="email"
              id="clientEmail"
              className={formErrors?.clientEmail ? "invalid" : ""}
              translatedContent={contents?.enterEmail}
              fallbacktranslatedContent="Please enter email address"
              error={formErrors?.clientEmail ? true : false}
              onChange={onChangeEventHandler}
            />
          </div>

          <div className="form-group ">
            <div className="col country-code">
            <label for="clientEmail">{contents.countryCode ?? messages.countryCode}</label>
              <CountrySelect
                formData={formState}
                setFormData={setFormState}
                props="phone"
                formErrors={formErrors}
                setFormErrors={setformErrors}
              />
              {formErrors?.countryCode && (
                <>{contents?.selectPhoneCode || messages?.selectPhoneCode}</>
              )}
            </div>
            <div className="phone">
            <label for="clientPhone">{contents.phoneNumber ?? messages.phoneNumber}</label>
              <UserInput
                name="clientPhone"
                type="text"
                id="clientPhone"
                className={formErrors?.clientPhone ? "invalid" : ""}
                translatedContent={contents?.incorrectPhoneNumber}
                fallbacktranslatedContent="Please enter a valid phone number"
                error={formErrors?.clientPhone ? true : false}
                onChange={onChangeEventHandler}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <div className="actions">
          {!isScheduling && <button
            className="btn btn--primary schedule-btn"
            onClick={scheduleACallSubmitHandler}
          >
            <span>
              <img src={calendarLightIcon} alt="" />
            </span>
            <span>{contents.scheduleACall ?? messages.scheduleACall}</span>
          </button>}
          {isScheduling && <Progressbar/>}
        </div>
      </div>
    </section>
  );
};

EnterInformationForSchedule.propTypes = {};

export default EnterInformationForSchedule;
