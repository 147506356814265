import { Skeleton } from "@mui/material";
import avatarGrayPNG from "assets/images/avatar-gray-450.png";

const ExpertAvatar = ({ isLoading, expertDetails }) => {
  return (
    <>
      {isLoading && (
        <Skeleton
          variant="circular"
          width={"20.4375rem"}
          height={"20.4375rem"}
        />
      )}
      {!isLoading && (
        <img src={expertDetails?.profileImage || avatarGrayPNG} alt="" />
      )}
    </>
  );
};

export default ExpertAvatar;
