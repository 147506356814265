import axios from "axios";
import {getUserData, clientConnectionToken } from "utils/utils";

const setAuthToken = async () => {
  const userData = await getUserData();
  
  // alert(JSON.stringify(userData));
  const clientToken = await clientConnectionToken();
  if (userData || clientToken) {
    axios.interceptors.response.use(
      (response) => {
        if ([401, 403].indexOf(response.data.status) !== -1) {
          throw new Error("Unauthorized");
        }
        return response;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
    
    axios.defaults.headers.common["GETBEE-AUTH-TOKEN"] =
      userData && userData.sessionId ? userData.sessionId : clientToken;
  } else {
    delete axios.defaults.headers.common["GETBEE-AUTH-TOKEN"];
  }
};

export default setAuthToken;
