export const roles = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Expert', label: 'Expert' }
]
export const imgSize = {
    logoHeight: 50,
    logoWidth: 150,
    profileHeight: 450,
    profileWidth: 450,
    landingHeight: 150,
    landingWidth: 150,
}
export const langArr = [
	{
	  "value": "ab",
	  "label": "аҧсуа"
	},
	{
	  "value": "aa",
	  "label": "Afaraf"
	},
	{
	  "value": "af",
	  "label": "Afrikaans"
	},
	{
	  "value": "ak",
	  "label": "Akan"
	},
	{
	  "value": "sq",
	  "label": "Shqip"
	},
	{
	  "value": "am",
	  "label": "አማርኛ"
	},
	{
	  "value": "ar",
	  "label": "العربية"
	},
	{
	  "value": "an",
	  "label": "Aragonés"
	},
	{
	  "value": "hy",
	  "label": "Հայերեն"
	},
	{
	  "value": "as",
	  "label": "অসমীয়া"
	},
	{
	  "value": "av",
	  "label": "авар мацӀ, магӀарул мацӀ"
	},
	{
	  "value": "ae",
	  "label": "avesta"
	},
	{
	  "value": "ay",
	  "label": "aymar aru"
	},
	{
	  "value": "az",
	  "label": "azərbaycan dili"
	},
	{
	  "value": "bm",
	  "label": "bamanankan"
	},
	{
	  "value": "ba",
	  "label": "башҡорт теле"
	},
	{
	  "value": "eu",
	  "label": "euskara, euskera"
	},
	{
	  "value": "be",
	  "label": "Беларуская"
	},
	{
	  "value": "bn",
	  "label": "বাংলা"
	},
	{
	  "value": "bh",
	  "label": "भोजपुरी"
	},
	{
	  "value": "bi",
	  "label": "Bislama"
	},
	{
	  "value": "bs",
	  "label": "bosanski jezik"
	},
	{
	  "value": "br",
	  "label": "brezhoneg"
	},
	{
	  "value": "bg",
	  "label": "български език"
	},
	{
	  "value": "my",
	  "label": "ဗမာစာ"
	},
	{
	  "value": "ca",
	  "label": "Català"
	},
	{
	  "value": "ch",
	  "label": "Chamoru"
	},
	{
	  "value": "ce",
	  "label": "нохчийн мотт"
	},
	{
	  "value": "ny",
	  "label": "chiCheŵa, chinyanja"
	},
	{
	  "value": "zh",
	  "label": "中文 (Zhōngwén), 汉语, 漢語"
	},
	{
	  "value": "cv",
	  "label": "чӑваш чӗлхи"
	},
	{
	  "value": "kw",
	  "label": "Kernewek"
	},
	{
	  "value": "co",
	  "label": "corsu, lingua corsa"
	},
	{
	  "value": "cr",
	  "label": "ᓀᐦᐃᔭᐍᐏᐣ"
	},
	{
	  "value": "hr",
	  "label": "hrvatski"
	},
	{
	  "value": "cs",
	  "label": "česky, čeština"
	},
	{
	  "value": "da",
	  "label": "dansk"
	},
	{
	  "value": "dv",
	  "label": "ދިވެހި"
	},
	{
	  "value": "nl",
	  "label": "Nederlands, Vlaams"
	},
	{
	  "value": "en",
	  "label": "English"
	},
	{
	  "value": "eo",
	  "label": "Esperanto"
	},
	{
	  "value": "et",
	  "label": "eesti, eesti keel"
	},
	{
	  "value": "ee",
	  "label": "Eʋegbe"
	},
	{
	  "value": "fo",
	  "label": "føroyskt"
	},
	{
	  "value": "fj",
	  "label": "vosa Vakaviti"
	},
	{
	  "value": "fi",
	  "label": "suomi, suomen kieli"
	},
	{
	  "value": "fr",
	  "label": "français, langue française"
	},
	{
	  "value": "ff",
	  "label": "Fulfulde, Pulaar, Pular"
	},
	{
	  "value": "gl",
	  "label": "Galego"
	},
	{
	  "value": "ka",
	  "label": "ქართული"
	},
	{
	  "value": "de",
	  "label": "Deutsch"
	},
	{
	  "value": "el",
	  "label": "Ελληνικά"
	},
	{
	  "value": "gn",
	  "label": "Avañeẽ"
	},
	{
	  "value": "gu",
	  "label": "ગુજરાતી"
	},
	{
	  "value": "ht",
	  "label": "Kreyòl ayisyen"
	},
	{
	  "value": "ha",
	  "label": "Hausa, هَوُسَ"
	},
	{
	  "value": "he",
	  "label": "עברית"
	},
	{
	  "value": "hz",
	  "label": "Otjiherero"
	},
	{
	  "value": "hi",
	  "label": "हिन्दी, हिंदी"
	},
	{
	  "value": "ho",
	  "label": "Hiri Motu"
	},
	{
	  "value": "hu",
	  "label": "Magyar"
	},
	{
	  "value": "ia",
	  "label": "Interlingua"
	},
	{
	  "value": "id",
	  "label": "Bahasa Indonesia"
	},
	{
	  "value": "ie",
	  "label": "Originally called Occidental; then Interlingue after WWII"
	},
	{
	  "value": "ga",
	  "label": "Gaeilge"
	},
	{
	  "value": "ig",
	  "label": "Asụsụ Igbo"
	},
	{
	  "value": "ik",
	  "label": "Iñupiaq, Iñupiatun"
	},
	{
	  "value": "io",
	  "label": "Ido"
	},
	{
	  "value": "is",
	  "label": "Íslenska"
	},
	{
	  "value": "it",
	  "label": "Italiano"
	},
	{
	  "value": "iu",
	  "label": "ᐃᓄᒃᑎᑐᑦ"
	},
	{
	  "value": "ja",
	  "label": "日本語 (にほんご／にっぽんご)"
	},
	{
	  "value": "jv",
	  "label": "basa Jawa"
	},
	{
	  "value": "kl",
	  "label": "kalaallisut, kalaallit oqaasii"
	},
	{
	  "value": "kn",
	  "label": "ಕನ್ನಡ"
	},
	{
	  "value": "kr",
	  "label": "Kanuri"
	},
	{
	  "value": "ks",
	  "label": "कश्मीरी, كشميري‎"
	},
	{
	  "value": "kk",
	  "label": "Қазақ тілі"
	},
	{
	  "value": "km",
	  "label": "ភាសាខ្មែរ"
	},
	{
	  "value": "ki",
	  "label": "Gĩkũyũ"
	},
	{
	  "value": "rw",
	  "label": "Ikinyarwanda"
	},
	{
	  "value": "ky",
	  "label": "кыргыз тили"
	},
	{
	  "value": "kv",
	  "label": "коми кыв"
	},
	{
	  "value": "kg",
	  "label": "KiKongo"
	},
	{
	  "value": "ko",
	  "label": "한국어 (韓國語), 조선말 (朝鮮語)"
	},
	{
	  "value": "ku",
	  "label": "Kurdî, كوردی‎"
	},
	{
	  "value": "kj",
	  "label": "Kuanyama"
	},
	{
	  "value": "la",
	  "label": "latine, lingua latina"
	},
	{
	  "value": "lb",
	  "label": "Lëtzebuergesch"
	},
	{
	  "value": "lg",
	  "label": "Luganda"
	},
	{
	  "value": "li",
	  "label": "Limburgs"
	},
	{
	  "value": "ln",
	  "label": "Lingála"
	},
	{
	  "value": "lo",
	  "label": "ພາສາລາວ"
	},
	{
	  "value": "lt",
	  "label": "lietuvių kalba"
	},
	{
	  "value": "lu",
	  "label": "Katanga"
	},
	{
	  "value": "lv",
	  "label": "latviešu valoda"
	},
	{
	  "value": "gv",
	  "label": "Gaelg, Gailck"
	},
	{
	  "value": "mk",
	  "label": "македонски јазик"
	},
	{
	  "value": "mg",
	  "label": "Malagasy fiteny"
	},
	{
	  "value": "ms",
	  "label": "bahasa Melayu, بهاس ملايو‎"
	},
	{
	  "value": "ml",
	  "label": "മലയാളം"
	},
	{
	  "value": "mt",
	  "label": "Malti"
	},
	{
	  "value": "mi",
	  "label": "te reo Māori"
	},
	{
	  "value": "mr",
	  "label": "मराठी"
	},
	{
	  "value": "mh",
	  "label": "Kajin M̧ajeļ"
	},
	{
	  "value": "mn",
	  "label": "монгол"
	},
	{
	  "value": "na",
	  "label": "Ekakairũ Naoero"
	},
	{
	  "value": "nv",
	  "label": "Diné bizaad, Dinékʼehǰí"
	},
	{
	  "value": "nb",
	  "label": "Norsk bokmål"
	},
	{
	  "value": "nd",
	  "label": "isiNdebele"
	},
	{
	  "value": "ne",
	  "label": "नेपाली"
	},
	{
	  "value": "ng",
	  "label": "Owambo"
	},
	{
	  "value": "nn",
	  "label": "Norsk nynorsk"
	},
	{
	  "value": "no",
	  "label": "Norsk"
	},
	{
	  "value": "ii",
	  "label": "ꆈꌠ꒿ Nuosuhxop"
	},
	{
	  "value": "nr",
	  "label": "isiNdebele"
	},
	{
	  "value": "oc",
	  "label": "Occitan"
	},
	{
	  "value": "oj",
	  "label": "ᐊᓂᔑᓈᐯᒧᐎᓐ"
	},
	{
	  "value": "cu",
	  "label": "ѩзыкъ словѣньскъ"
	},
	{
	  "value": "om",
	  "label": "Afaan Oromoo"
	},
	{
	  "value": "or",
	  "label": "ଓଡ଼ିଆ"
	},
	{
	  "value": "os",
	  "label": "ирон æвзаг"
	},
	{
	  "value": "pa",
	  "label": "ਪੰਜਾਬੀ, پنجابی‎"
	},
	{
	  "value": "pi",
	  "label": "पाऴि"
	},
	{
	  "value": "fa",
	  "label": "فارسی"
	},
	{
	  "value": "pl",
	  "label": "polski"
	},
	{
	  "value": "ps",
	  "label": "پښتو"
	},
	{
	  "value": "pt",
	  "label": "Português"
	},
	{
	  "value": "qu",
	  "label": "Runa Simi, Kichwa"
	},
	{
	  "value": "rm",
	  "label": "rumantsch grischun"
	},
	{
	  "value": "rn",
	  "label": "kiRundi"
	},
	{
	  "value": "ro",
	  "label": "română"
	},
	{
	  "value": "ru",
	  "label": "русский язык"
	},
	{
	  "value": "sa",
	  "label": "संस्कृतम्"
	},
	{
	  "value": "sc",
	  "label": "sardu"
	},
	{
	  "value": "sd",
	  "label": "सिन्धी, سنڌي، سندھی‎"
	},
	{
	  "value": "se",
	  "label": "Davvisámegiella"
	},
	{
	  "value": "sm",
	  "label": "gagana faa Samoa"
	},
	{
	  "value": "sg",
	  "label": "yângâ tî sängö"
	},
	{
	  "value": "sr",
	  "label": "српски језик"
	},
	{
	  "value": "gd",
	  "label": "Gàidhlig"
	},
	{
	  "value": "sn",
	  "label": "chiShona"
	},
	{
	  "value": "si",
	  "label": "සිංහල"
	},
	{
	  "value": "sk",
	  "label": "slovenčina"
	},
	{
	  "value": "sl",
	  "label": "slovenščina"
	},
	{
	  "value": "so",
	  "label": "Soomaaliga, af Soomaali"
	},
	{
	  "value": "st",
	  "label": "Sesotho"
	},
	{
	  "value": "es",
	  "label": "español, castellano"
	},
	{
	  "value": "su",
	  "label": "Basa Sunda"
	},
	{
	  "value": "sw",
	  "label": "Kiswahili"
	},
	{
	  "value": "ss",
	  "label": "SiSwati"
	},
	{
	  "value": "sv",
	  "label": "svenska"
	},
	{
	  "value": "ta",
	  "label": "தமிழ்"
	},
	{
	  "value": "te",
	  "label": "తెలుగు"
	},
	{
	  "value": "tg",
	  "label": "тоҷикӣ, toğikī, تاجیکی‎"
	},
	{
	  "value": "th",
	  "label": "ไทย"
	},
	{
	  "value": "ti",
	  "label": "ትግርኛ"
	},
	{
	  "value": "bo",
	  "label": "བོད་ཡིག"
	},
	{
	  "value": "tk",
	  "label": "Türkmen, Түркмен"
	},
	{
	  "value": "tl",
	  "label": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"
	},
	{
	  "value": "tn",
	  "label": "Setswana"
	},
	{
	  "value": "to",
	  "label": "faka Tonga"
	},
	{
	  "value": "tr",
	  "label": "Türkçe"
	},
	{
	  "value": "ts",
	  "label": "Xitsonga"
	},
	{
	  "value": "tt",
	  "label": "татарча, tatarça, تاتارچا‎"
	},
	{
	  "value": "tw",
	  "label": "Twi"
	},
	{
	  "value": "ty",
	  "label": "Reo Tahiti"
	},
	{
	  "value": "ug",
	  "label": "Uyƣurqə, ئۇيغۇرچە‎"
	},
	{
	  "value": "uk",
	  "label": "українська"
	},
	{
	  "value": "ur",
	  "label": "اردو"
	},
	{
	  "value": "uz",
	  "label": "zbek, Ўзбек, أۇزبېك‎"
	},
	{
	  "value": "ve",
	  "label": "Tshivenḓa"
	},
	{
	  "value": "vi",
	  "label": "Tiếng Việt"
	},
	{
	  "value": "vo",
	  "label": "Volapük"
	},
	{
	  "value": "wa",
	  "label": "Walon"
	},
	{
	  "value": "cy",
	  "label": "Cymraeg"
	},
	{
	  "value": "wo",
	  "label": "Wollof"
	},
	{
	  "value": "fy",
	  "label": "Frysk"
	},
	{
	  "value": "xh",
	  "label": "isiXhosa"
	},
	{
	  "value": "yi",
	  "label": "ייִדיש"
	},
	{
	  "value": "yo",
	  "label": "Yorùbá"
	},
	{
	  "value": "za",
	  "label": "Saɯ cueŋƅ, Saw cuengh"
	}
  ];


// export const langArr = [
// 	{ value: "ab", label: "аҧсуа" },
// 	{ value: "aa", label: "Afar - Afaraf" },
// 	{ value: "af", label: "Afrikaans - Afrikaans" },
// 	{ value: "ak", label: "Akan - Akan" },
// 	{ value: "sq", label: "Albanian - Shqip" },
// 	{ value: "am", label: "Amharic - አማርኛ" },
// 	{ value: "ar", label: "Arabic - العربية" },
// 	{ value: "an", label: "Aragonese - Aragonés" },
// 	{ value: "hy", label: "Armenian - Հայերեն" },
// 	{ value: "as", label: "Assamese - অসমীয়া" },
// 	{ value: "av", label: "Avaric - авар мацӀ, магӀарул мацӀ" },
// 	{ value: "ae", label: "Avestan - avesta" },
// 	{ value: "ay", label: "Aymara - aymar aru" },
// 	{ value: "az", label: "Azerbaijani - azərbaycan dili" },
// 	{ value: "bm", label: "Bambara - bamanankan" },
// 	{ value: "ba", label: "Bashkir - башҡорт теле" },
// 	{ value: "eu", label: "Basque - euskara, euskera" },
// 	{ value: "be", label: "Belarusian - Беларуская" },
// 	{ value: "bn", label: "Bengali - বাংলা" },
// 	{ value: "bh", label: "Bihari - भोजपुरी" },
// 	{ value: "bi", label: "Bislama - Bislama" },
// 	{ value: "bs", label: "Bosnian - bosanski jezik" },
// 	{ value: "br", label: "Breton - brezhoneg" },
// 	{ value: "bg", label: "Bulgarian - български език" },
// 	{ value: "my", label: "Burmese - ဗမာစာ" },
// 	{ value: "ca", label: "Catalan; Valencian - Català" },
// 	{ value: "ch", label: "Chamorro - Chamoru" },
// 	{ value: "ce", label: "Chechen - нохчийн мотт" },
// 	{ value: "ny", label: "Chichewa; Chewa; Nyanja - chiCheŵa, chinyanja" },
// 	{ value: "zh", label: "Chinese - 中文 (Zhōngwén), 汉语, 漢語" },
// 	{ value: "cv", label: "Chuvash - чӑваш чӗлхи" },
// 	{ value: "kw", label: "Cornish - Kernewek" },
// 	{ value: "co", label: "Corsican - corsu, lingua corsa" },
// 	{ value: "cr", label: "Cree - ᓀᐦᐃᔭᐍᐏᐣ" },
// 	{ value: "hr", label: "Croatian - hrvatski" },
// 	{ value: "cs", label: "Czech - česky, čeština" },
// 	{ value: "da", label: "Danish - dansk" },
// 	{ value: "dv", label: "Divehi; Dhivehi; Maldivian; - ދިވެހި" },
// 	{ value: "nl", label: "Dutch - Nederlands, Vlaams" },
// 	{ value: "en", label: "English - English" },
// 	{ value: "eo", label: "Esperanto - Esperanto" },
// 	{ value: "et", label: "Estonian - eesti, eesti keel" },
// 	{ value: "ee", label: "Ewe - Eʋegbe" },
// 	{ value: "fo", label: "Faroese - føroyskt" },
// 	{ value: "fj", label: "Fijian - vosa Vakaviti" },
// 	{ value: "fi", label: "Finnish - suomi, suomen kieli" },
// 	{ value: "fr", label: "French - français, langue française" },
// 	{ value: "ff", label: "Fula; Fulah; Pulaar; Pular - Fulfulde, Pulaar, Pular" },
// 	{ value: "gl", label: "Galician - Galego" },
// 	{ value: "ka", label: "Georgian - ქართული" },
// 	{ value: "de", label: "German - Deutsch" },
// 	{ value: "el", label: "Greek, Modern - Ελληνικά" },
// 	{ value: "gn", label: "Guaraní - Avañeẽ" },
// 	{ value: "gu", label: "Gujarati - ગુજરાતી" },
// 	{ value: "ht", label: "Haitian; Haitian Creole - Kreyòl ayisyen" },
// 	{ value: "ha", label: "Hausa - Hausa, هَوُسَ" },
// 	{ value: "he", label: "Hebrew (modern) - עברית" },
// 	{ value: "hz", label: "Herero - Otjiherero" },
// 	{ value: "hi", label: "Hindi - हिन्दी, हिंदी" },
// 	{ value: "ho", label: "Hiri Motu - Hiri Motu" },
// 	{ value: "hu", label: "Hungarian - Magyar" },
// 	{ value: "ia", label: "Interlingua - Interlingua" },
// 	{ value: "id", label: "Indonesian - Bahasa Indonesia" },
// 	{ value: "ie", label: "Interlingue - Originally called Occidental; then Interlingue after WWII" },
// 	{ value: "ga", label: "Irish - Gaeilge" },
// 	{ value: "ig", label: "Igbo - Asụsụ Igbo" },
// 	{ value: "ik", label: "Inupiaq - Iñupiaq, Iñupiatun" },
// 	{ value: "io", label: "Ido - Ido" },
// 	{ value: "is", label: "Icelandic - Íslenska" },
// 	{ value: "it", label: "Italian - Italiano" },
// 	{ value: "iu", label: "Inuktitut - ᐃᓄᒃᑎᑐᑦ" },
// 	{ value: "ja", label: "Japanese - 日本語 (にほんご／にっぽんご)" },
// 	{ value: "jv", label: "Javanese - basa Jawa" },
// 	{ value: "kl", label: "Kalaallisut, Greenlandic - kalaallisut, kalaallit oqaasii" },
// 	{ value: "kn", label: "Kannada - ಕನ್ನಡ" },
// 	{ value: "kr", label: "Kanuri - Kanuri" },
// 	{ value: "ks", label: "Kashmiri - कश्मीरी, كشميري‎" },
// 	{ value: "kk", label: "Kazakh - Қазақ тілі" },
// 	{ value: "km", label: "Khmer - ភាសាខ្មែរ" },
// 	{ value: "ki", label: "Kikuyu, Gikuyu - Gĩkũyũ" },
// 	{ value: "rw", label: "Kinyarwanda - Ikinyarwanda" },
// 	{ value: "ky", label: "Kirghiz, Kyrgyz - кыргыз тили" },
// 	{ value: "kv", label: "Komi - коми кыв" },
// 	{ value: "kg", label: "Kongo - KiKongo" },
// 	{ value: "ko", label: "Korean - 한국어 (韓國語), 조선말 (朝鮮語)" },
// 	{ value: "ku", label: "Kurdish - Kurdî, كوردی‎" },
// 	{ value: "kj", label: "Kwanyama, Kuanyama - Kuanyama" },
// 	{ value: "la", label: "Latin - latine, lingua latina" },
// 	{ value: "lb", label: "Luxembourgish, Letzeburgesch - Lëtzebuergesch" },
// 	{ value: "lg", label: "Luganda - Luganda" },
// 	{ value: "li", label: "Limburgish, Limburgan, Limburger - Limburgs" },
// 	{ value: "ln", label: "Lingala - Lingála" },
// 	{ value: "lo", label: "Lao - ພາສາລາວ" },
// 	{ value: "lt", label: "Lithuanian - lietuvių kalba" },
// 	{ value: "lu", label: "Luba-Katanga - " },
// 	{ value: "lv", label: "Latvian - latviešu valoda" },
// 	{ value: "gv", label: "Manx - Gaelg, Gailck" },
// 	{ value: "mk", label: "Macedonian - македонски јазик" },
// 	{ value: "mg", label: "Malagasy - Malagasy fiteny" },
// 	{ value: "ms", label: "Malay - bahasa Melayu, بهاس ملايو‎" },
// 	{ value: "ml", label: "Malayalam - മലയാളം" },
// 	{ value: "mt", label: "Maltese - Malti" },
// 	{ value: "mi", label: "Māori - te reo Māori" },
// 	{ value: "mr", label: "Marathi (Marāṭhī) - मराठी" },
// 	{ value: "mh", label: "Marshallese - Kajin M̧ajeļ" },
// 	{ value: "mn", label: "Mongolian - монгол" },
// 	{ value: "na", label: "Nauru - Ekakairũ Naoero" },
// 	{ value: "nv", label: "Navajo, Navaho - Diné bizaad, Dinékʼehǰí" },
// 	{ value: "nb", label: "Norwegian Bokmål - Norsk bokmål" },
// 	{ value: "nd", label: "North Ndebele - isiNdebele" },
// 	{ value: "ne", label: "Nepali - नेपाली" },
// 	{ value: "ng", label: "Ndonga - Owambo" },
// 	{ value: "nn", label: "Norwegian Nynorsk - Norsk nynorsk" },
// 	{ value: "no", label: "Norwegian - Norsk" },
// 	{ value: "ii", label: "Nuosu - ꆈꌠ꒿ Nuosuhxop" },
// 	{ value: "nr", label: "South Ndebele - isiNdebele" },
// 	{ value: "oc", label: "Occitan - Occitan" },
// 	{ value: "oj", label: "Ojibwe, Ojibwa - ᐊᓂᔑᓈᐯᒧᐎᓐ" },
// 	{ value: "cu", label: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic - ѩзыкъ словѣньскъ" },
// 	{ value: "om", label: "Oromo - Afaan Oromoo" },
// 	{ value: "or", label: "Oriya - ଓଡ଼ିଆ" },
// 	{ value: "os", label: "Ossetian, Ossetic - ирон æвзаг" },
// 	{ value: "pa", label: "Panjabi, Punjabi - ਪੰਜਾਬੀ, پنجابی‎" },
// 	{ value: "pi", label: "Pāli - पाऴि" },
// 	{ value: "fa", label: "Persian - فارسی" },
// 	{ value: "pl", label: "Polish - polski" },
// 	{ value: "ps", label: "Pashto, Pushto - پښتو" },
// 	{ value: "pt", label: "Portuguese - Português" },
// 	{ value: "qu", label: "Quechua - Runa Simi, Kichwa" },
// 	{ value: "rm", label: "Romansh - rumantsch grischun" },
// 	{ value: "rn", label: "Kirundi - kiRundi" },
// 	{ value: "ro", label: "Romanian, Moldavian, Moldovan - română" },
// 	{ value: "ru", label: "Russian - русский язык" },
// 	{ value: "sa", label: "Sanskrit (Saṁskṛta) - संस्कृतम्" },
// 	{ value: "sc", label: "Sardinian - sardu" },
// 	{ value: "sd", label: "Sindhi - सिन्धी, سنڌي، سندھی‎" },
// 	{ value: "se", label: "Northern Sami - Davvisámegiella" },
// 	{ value: "sm", label: "Samoan - gagana faa Samoa" },
// 	{ value: "sg", label: "Sango - yângâ tî sängö" },
// 	{ value: "sr", label: "Serbian - српски језик" },
// 	{ value: "gd", label: "Scottish Gaelic; Gaelic - Gàidhlig" },
// 	{ value: "sn", label: "Shona - chiShona" },
// 	{ value: "si", label: "Sinhala, Sinhalese - සිංහල" },
// 	{ value: "sk", label: "Slovak - slovenčina" },
// 	{ value: "sl", label: "Slovene - slovenščina" },
// 	{ value: "so", label: "Somali - Soomaaliga, af Soomaali" },
// 	{ value: "st", label: "Southern Sotho - Sesotho" },
// 	{ value: "es", label: "Spanish; Castilian - español, castellano" },
// 	{ value: "su", label: "Sundanese - Basa Sunda" },
// 	{ value: "sw", label: "Swahili - Kiswahili" },
// 	{ value: "ss", label: "Swati - SiSwati" },
// 	{ value: "sv", label: "Swedish - svenska" },
// 	{ value: "ta", label: "Tamil - தமிழ்" },
// 	{ value: "te", label: "Telugu - తెలుగు" },
// 	{ value: "tg", label: "Tajik - тоҷикӣ, toğikī, تاجیکی‎" },
// 	{ value: "th", label: "Thai - ไทย" },
// 	{ value: "ti", label: "Tigrinya - ትግርኛ" },
// 	{ value: "bo", label: "Tibetan Standard, Tibetan, Central - བོད་ཡིག" },
// 	{ value: "tk", label: "Turkmen - Türkmen, Түркмен" },
// 	{ value: "tl", label: "Tagalog - Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
// 	{ value: "tn", label: "Tswana - Setswana" },
// 	{ value: "to", label: "Tonga (Tonga Islands) - faka Tonga" },
// 	{ value: "tr", label: "Turkish - Türkçe" },
// 	{ value: "ts", label: "Tsonga - Xitsonga" },
// 	{ value: "tt", label: "Tatar - татарча, tatarça, تاتارچا‎" },
// 	{ value: "tw", label: "Twi - Twi" },
// 	{ value: "ty", label: "Tahitian - Reo Tahiti" },
// 	{ value: "ug", label: "Uighur, Uyghur - Uyƣurqə, ئۇيغۇرچە‎" },
// 	{ value: "uk", label: "Ukrainian - українська" },
// 	{ value: "ur", label: "Urdu - اردو" },
// 	{ value: "uz", label: "Uzbek - zbek, Ўзбек, أۇزبېك‎" },
// 	{ value: "ve", label: "Venda - Tshivenḓa" },
// 	{ value: "vi", label: "Vietnamese - Tiếng Việt" },
// 	{ value: "vo", label: "Volapük - Volapük" },
// 	{ value: "wa", label: "Walloon - Walon" },
// 	{ value: "cy", label: "Welsh - Cymraeg" },
// 	{ value: "wo", label: "Wolof - Wollof" },
// 	{ value: "fy", label: "Western Frisian - Frysk" },
// 	{ value: "xh", label: "Xhosa - isiXhosa" },
// 	{ value: "yi", label: "Yiddish - ייִדיש" },
// 	{ value: "yo", label: "Yoruba - Yorùbá" },
// 	{ value: "za", label: "Zhuang, Chuang - Saɯ cueŋƅ, Saw cuengh" }
// ]