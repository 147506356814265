/* eslint-disable jsx-a11y/anchor-is-valid */
import chatActiveIcon from "assets/svg/chatActive.svg";
import chatDeactiveIcon from "assets/svg/chatDeactive.svg";
import shareActiveIcon from "assets/svg/share-active.svg";
import stopSharingIcon from "assets/svg/stop-sharing.svg";
import { sessionTypes } from "components/Config/session";
import Toast from "light-toast";
import { useSelector } from "react-redux";
import { useTranslation } from "../../utils/useTranslation";
import iconEndCall from "./../../assets/images/icon-endcall.svg";
import iconMicOff from "./../../assets/images/icon-mic-off.svg";
import iconMic from "./../../assets/images/icon-mic.svg";
import iconVideoOff from "./../../assets/images/icon-video-off.svg";
import iconVideo from "./../../assets/images/icon-video.svg";
import user from "./../../assets/images/user.png";
import { isTabletOrPhone } from "pureFunctions/isTablet";
import { isIpadOS } from "pureFunctions/isTablet";
import { useHistory } from "react-router-dom";

const Controls = ({
  invokeCallComplete,
  changeAudioStatus,
  sessionData,
  changeVideoStatus,
  mediaState,
  waitingList,
  audioChecked,
  videoChecked,
  leaveChannel,
  setLeaveChannel,
  showInvitationScreen,
  isExpert,
  shareScreenActive,
  setShareShreenActive,
  stopSharing,
  shareScreen,
  showChatMode,
  setShowChatMode,
  setShowParticipantsMode,
  newMessageAvailble, 
  setNewMessageAvailble
  
}) => {
  const state = useSelector((state) => state);
  let history = useHistory();
  const {
    preferedLanguageContent: { contents },
  } = state;

  const messages = useTranslation();
  const mute = async (type) => {
    if (!audioChecked && !videoChecked) {
      Toast.info(messages?.connectingDevices, 1000, () => {
        // do something after the toast disappears
      });
    } else {
      if (type === "audio") {
        changeAudioStatus(!mediaState.audio);
      } else if (type === "video") {
        changeVideoStatus(!mediaState.video);
      }
    }
  };

  const updateScreenSharingEvent = (name) => {
    return async (e) => {
      e.preventDefault();
      if (name === "active") {
        setShareShreenActive(false);
        stopSharing();
      }
      if (name === "inactive") {
        try {
          await shareScreen();
        } catch (err) {
          console.log(err);
        }
      }
    };
  };

  return (
    <>
      {leaveChannel ? (
        <>
          <button
            className="session-action-blk__btn session-action-blk__btn--white"
            onClick={() => setLeaveChannel(false)}
          >
            {contents?.btnCancel ?? messages?.btnCancel}
          </button>

          <button
            className="action-items session-action-blk__btn session-action-blk__btn--red"
            onClick={invokeCallComplete}
          >
            {contents?.btnEndConversation ?? messages?.btnEndConversation}
          </button>
        </>
      ) : (
        <>
          {sessionData?.sessionState !== "Pending" ? (
            <a
              className="action-items call"
              onClick={() => setLeaveChannel(true)}
            >
              <img src={iconEndCall} alt="iconEndCall" />
            </a>)
             : 
             (
             <a
             className="action-items call"
             onClick={() => {
              history.push("/");
              window.location.reload();
             }
            }
           >
             <img src={iconEndCall} alt="iconEndCall" />
           </a>
          )}

          {audioChecked && (
            <a className="action-items mic" onClick={() => mute("audio")}>
              {mediaState.audio ? (
                <img src={iconMic} alt="iconMic" />
              ) : (
                <img src={iconMicOff} alt="iconMicOff" />
              )}
            </a>
          )}

          {videoChecked && !shareScreenActive && (
            <a className="action-items video" onClick={() => mute("video")}>
              {mediaState.video ? (
                <img src={iconVideo} alt="iconVideo" />
              ) : (
                <img src={iconVideoOff} alt="iconVideoOff" />
              )}
            </a>
          )}

          {(!isTabletOrPhone() && !isIpadOS()) && (
             <>
             {!shareScreenActive && (
               <a
                 href="!#"
                 className="action-items"
                 onClick={updateScreenSharingEvent("inactive")}
               >
                 <img src={shareActiveIcon} alt="share the screen" />
               </a>
             )}

             {shareScreenActive && (
               <a
                 href="!#"
                 className="action-items gray-back-gb"
                 onClick={updateScreenSharingEvent("active")}
               >
                 <img src={stopSharingIcon} alt="stop screen sharing" />
               </a>
             )}
           </>
             
          )}
            
            {/* showChatMode,
  setShowChatMode */}
        
            <a id = "option-chat-icon" className= {`action-items ${showChatMode ? "chat-icon" : ""}`} onClick={() => setShowChatMode(prevState => !prevState)}>
              
              {newMessageAvailble && !showChatMode && <span className="notification"></span>}
              {!showChatMode ? (
                <img src={chatDeactiveIcon} alt="" onClick={() => setNewMessageAvailble(false)} />
              ) : (
                <img src={chatActiveIcon} alt=""/>
              )}
            </a>
          
          {sessionTypes.indexOf(sessionData?.sessionType) !== -1 && isExpert && (
            <div>
              <a className="action-items" onClick={() => setShowParticipantsMode(prevState => !prevState)}>
                <img src={user} alt="iconUser" />
              </a>
              {waitingList && waitingList.length > 0 && (
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    position: "relative",
                    bottom: "55px",
                    left: "45px",
                  }}
                ></div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Controls;
