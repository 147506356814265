import React from "react";
import { useSelector } from "react-redux";
import cart from "../../assets/images/cart-white.png";
import iconClose from "../../assets/images/X.png";
import { shorterProductTitle } from "pureFunctions/shortProductTitle";
const CartItem = ({ product }) => {
  const connection = useSelector(
    (state) => state.shoppingReducer.shoppingConnection
  );
  const sessionStatus = useSelector(
    (state) => state.shoppingReducer.sessionStatus
  );
  const url = new URL(window.location.href);
  const extractUrlValue = (key, url) => {
    if (typeof url === "undefined") url = window.location.href;
    var match = url.match("[?&]" + key + "=([^&]+)");
    return match ? match[1] : null;
  };
  let sessionId = extractUrlValue("session_id", url.sessionId);
  const increaseQuantity = async (product) => {
    try {
      if (connection && Object.keys(sessionStatus).length > 0) {
        const payload = {
          sessionId: sessionId,
          productInCartId: product.id,
          tenant: sessionStatus && sessionStatus.tenant,
          email:
            sessionStatus && sessionStatus.myRole === "Expert"
              ? sessionStatus.clientInfo.email
              : sessionStatus.expertInfo.email,
        };
        console.log(payload);
        const result = await connection.invoke("IncreaseQuantity", payload);
        console.log(result);
        if (result && result.isSucceeded) {
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const decreaseQuantity = async (product) => {
    try {
      if (connection && Object.keys(sessionStatus).length > 0) {
        const payload = {
          sessionId: sessionId,
          tenant: sessionStatus && sessionStatus.tenant,
          email:
            sessionStatus && sessionStatus.myRole === "Expert"
              ? sessionStatus.clientInfo.email
              : sessionStatus.expertInfo.email,
          productInCartId: product.id,
        };
        const result = await connection.invoke("DecreaseQuantity", payload);
        if (result && result.isSucceeded) {
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleDeleteItem = async (product) => {
    try {
      const payload = {
        productInCartId: product.id,
        sessionId: sessionId,
        tenant: sessionStatus.tenant,
      };
      const result = await connection.invoke("RemoveProductFromCart", payload);
      if (result && result.isSucceeded) {
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div class="w-full mx-auto  s-card">
      <div class="flex items-center">
        <div class="  s-card__img cursor-pointer s-card__left relative">
          <img
            class="w-full object-cover"
            src={product.images[0]}
            alt="A cat"
          />
          <button
            onClick={() => handleDeleteItem(product)}
            className="absolute s-cart__delete"
          >
            <img src={iconClose} alt="closeIcon" />
          </button>
        </div>
        <div class="flex flex-col s-card__right">
          <div className="flex items-center justify-between w-full cursor-pointer">
            <h2 class=" s-card__title w-1/2">
              <div
                dangerouslySetInnerHTML={{
                  __html: shorterProductTitle(product?.title),
                }}
              />
            </h2>
            <p
              href="#"
              class="block mt-1 text-lg leading-tight font-medium text-black hover:underline s-card__price w-1/2 mr-auto"
            >
              {Object.keys(sessionStatus).length > 0 &&
                sessionStatus.storeInfo &&
                sessionStatus.storeInfo.currencyInfo &&
                sessionStatus.storeInfo.currencyInfo.currencySymbol}{" "}
              {product &&
              product.selectedVariant &&
              product.selectedVariant.length > 0
                ? product.selectedVariant[0].price
                : product.variants[0].price}
            </p>
          </div>
          <div className="s-cart__variantContainer pl-2 mt-4 flex items-center">
            {product.category &&
              product.category.length > 0 &&
              product.category.map((item) => (
                <div
                  style={
                    item.category === "Color" || item.category === "Size"
                      ? {
                        borderRadius: "100px",
                        marginRight: "10px",
                        fontSize: "13px",
                        padding:"9px 16px",
                        color: "#1A1A1C"
                        }
                      : item.category === "Title"
                      ? { display: "none" }
                      : null
                  }
                  className={
                    `s-variant__item s-card__variants flex items-center mr-2`
                  }
                >
                  {/* {item.category !== "Color" ? item.option : null} */}
                  {item.option}
                </div>
              ))}
            {/* {product.category && product.category.map(item => 
      <span className={`mr-2 ${item.category === "Color" ? `s-cart__variantContainer`}`}></span>
      )} */}
          </div>
          <div className="flex absolute bottom-0 items-center s-card__bottom">
            {/* {isRecommended ?  <button className="s-card__bottom__btn s-card__bottom__active flex items-center justify-center" onClick={() => deleteRecomendedProduct(product)}>
        <img src={check}/>
       </button> : <>  {recommendedProducts && recommendedProducts.length > 0 && recommendedProducts.some(item => item.id === product.id) ?   <button className="s-card__bottom__btn s-card__bottom__active flex items-center justify-center" onClick={() => deleteRecomendedProduct(product)}>
        <img src={check}/>
       </button> :   <button className="s-card__bottom__btn flex items-center justify-center s-card__bottom__title" onClick={() => recommendProduct(product)}>
        Recommend
       </button>} </>  } */}
            <button className="w-full  s-card__bottom__btn s-cart__add  s-card__bottom__btn--cart flex justify-between items-center">
              <span
                className="text-white"
                onClick={() => decreaseQuantity(product)}
              >
                -
              </span>
              <span className="text-white flex items-center">
                <img src={cart} alt="cart icon" />{" "}
                <p className="ml-2">{product.quantity}</p>
              </span>
              <span
                className="text-white"
                onClick={() => increaseQuantity(product)}
              >
                +
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CartItem;
