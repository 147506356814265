import { getError } from "components/ErrorHandler/getError";
import apiName from "constants/apiName";
import {
  fetchTenantInfoAction,
  fetchTenantInfoErrorAction,
  fetchTenantInfoLoadingAction,
} from "redux/action/client/tenantInfo";
import { request } from "utils/request";

export default function callfetchTenantInfo({ tenant }) {
  return async (dispatch) => {
    function onSucess(payload) {
      dispatch(fetchTenantInfoAction(payload));
    }
    function isFetching() {
      dispatch(fetchTenantInfoLoadingAction());
    }

    function onError(err) {
      dispatch(fetchTenantInfoErrorAction(err));
    }
    try {
      isFetching();
      const data = await request({
        url: apiName.tenantInfo,
        method: "post",
        body: { tenant },
      });
      return onSucess(data);
    } catch (err) {
      console.log(err);
      return onError(getError(err));
    }
  };
}
