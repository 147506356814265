import Dashboard from "components/Dashboard";
import AdminHome from "./AdminHome";

const DashboardAdminExpert = (props) => {
  return (
    <>
      <div className="gb-container">
        <Dashboard  />
      </div>
      <AdminHome />
    </>
  );
};

DashboardAdminExpert.propTypes = {};

export default DashboardAdminExpert;
