import companyDetailsIcon from "assets/svg/company-details-icon.svg";
import HomeIcon from "assets/svg/home-icon.svg";
import HumBurgeIcon from "assets/svg/humburg.svg";
import IntegrationsIcon from "assets/svg/integrations-icon.svg";
import NoAvatarSVG from "assets/svg/no-avatar.svg";
import NoLogoAvailable from "assets/svg/no-logo-available.svg";
import TeamMembersIcon from "assets/svg/team-members.svg";
import Translate from "components/Common/Translate";
import { tabIds } from "config/TabConfig";
import useIsAdmin from "hooks/useIsAdmin";
import useOnClickOutside from "hooks/useOnClickOutside";
import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeExpertStatus } from "redux/action/sessionConnections";
import NavItem from "./nav-item";
import MeetingsIcon from "assets/svg/meetings-icon.svg";
import messages from "constants/messages";
import CloseIcon from "assets/svg/close-medium.svg";
import ReportingIcon from "assets/svg/reporting.svg";
const NavBar = ({ setSelectedLi, selectedLi, setShowModel }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { value: tenantValue } = state.tenantInfoR;
  const { data: tenantInfo } = tenantValue || { data: null };
  const { value } = state.userInfoR;
  const { data: userInfo } = value || { data: null };
  const [displayMenu, setDisplayMenu] = useState(false);
  const { isAdmin } = useIsAdmin();
  const {
    preferedLanguageContent: { contents },
  } = state;
  const expertOnlineStatus = useSelector(
    (state) => state.sessionConnectionsReducer.expertOnlineStatus
  );
  const expertInCall = useSelector(
    (state) => state.sessionConnectionsReducer.expertInCall
  );
  const switchOnChangeHander = () => {
    dispatch(changeExpertStatus(!expertOnlineStatus));
  };
  const ref = useRef();
  useOnClickOutside(ref, () => setDisplayMenu(false));
  const anchorOnClick = (e) => {
    setDisplayMenu(false);
    e.preventDefault();
    return false;
  };
  const getAdminNavBar = useMemo(() => {
    return (
      isAdmin && (
        <>
          {}
          <NavItem
            selectedLi={selectedLi}
            setSelectedLi={setSelectedLi}
            anchorOnClick={anchorOnClick}
            contents={contents}
            icon={companyDetailsIcon}
            tabId={tabIds.companyDatails}
            defaultTittle="Company details"
            contentsId="companyDetails"
          />
          <NavItem
            selectedLi={selectedLi}
            setSelectedLi={setSelectedLi}
            anchorOnClick={anchorOnClick}
            contents={contents}
            icon={TeamMembersIcon}
            tabId={tabIds.teamMembers}
            defaultTittle="Team members"
            contentsId="teamMembers"
          />
          <NavItem
            selectedLi={selectedLi}
            setSelectedLi={setSelectedLi}
            anchorOnClick={anchorOnClick}
            contents={contents}
            icon={IntegrationsIcon}
            tabId={tabIds.integrations}
            defaultTittle="Integrations"
            contentsId="integrations"
          />
          {}
        </>
      )
    );
  }, [isAdmin, contents, selectedLi, setSelectedLi]);
  return (
    <>
      <div className="humburg-menu">
        <img src={HumBurgeIcon} alt="" onClick={() => setDisplayMenu(true)} />
      </div>
      <aside
        ref={ref}
        className={`dashboard-sidebar ${
          displayMenu ? "show-sidebar" : "hide-sidebar"
        }`}
      >
        <div className="dashboard-sidebar-close-btn">
          <img src = {CloseIcon} alt="" onClick={() => setDisplayMenu(false)}/>
        </div>
        <div className="logo">
          <img src={tenantInfo?.logoUrl || NoLogoAvailable} alt="GetBee" />
        </div>
        <div className="admin">
          <div className="nav">
            <div className="nav-group">
              {}
              <ul className="nav-ul">
                <NavItem
                  selectedLi={selectedLi}
                  setSelectedLi={setSelectedLi}
                  anchorOnClick={anchorOnClick}
                  contents={contents}
                  icon={HomeIcon}
                  tabId={tabIds.homePage}
                  defaultTittle="Homepage"
                  contentsId="homepage"
                />
              <NavItem
                  selectedLi={selectedLi}
                  setSelectedLi={setSelectedLi}
                  anchorOnClick={anchorOnClick}
                  contents={contents}
                  icon={ReportingIcon}
                  tabId={tabIds.reporting}
                  defaultTittle="Reporting"
                  contentsId="reporting"
                />
                {getAdminNavBar}
                <NavItem
                  selectedLi={selectedLi}
                  setSelectedLi={setSelectedLi}
                  anchorOnClick={anchorOnClick}
                  contents={contents}
                  icon={MeetingsIcon}
                  tabId={tabIds.meetings}
                  defaultTittle={messages.meetings}
                  contentsId="meetings"
                />
                {}
                {}
              </ul>
            </div>
          </div>
        </div>
        <div className="status">
          {selectedLi !== tabIds.homePage && (
            <div className="row status--row">
              <div className="avaibilities">
                <div className="title-a">
                  <Translate
                    translatedContent={contents?.availability}
                    fallbacktranslatedContent="Availability"
                  />
                </div>
                <div className="check">
                  <Translate
                    translatedContent={contents?.checkCameraAndAudio}
                    fallbacktranslatedContent="Check camera & audio"
                  />
                </div>
              </div>
              
              <div className="switch-status ">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={switchOnChangeHander}
                    checked={expertOnlineStatus && !expertInCall}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          )}
          <div
            className={`row user ${selectedLi !== tabIds.homePage ? "gb-border-padding" : ""}`}
            onClick={() => {
              setSelectedLi(tabIds.homePage);
              setShowModel(true);
            }}
          >
            <div
              className={`${
                expertOnlineStatus
                  ? "avatar user--active"
                  : "avatar user--inactive"
              }`}
            >
              <img src={userInfo?.profileImage || NoAvatarSVG} alt="" />
            </div>
            <div className="name-role">
              <div className="name">
                {userInfo?.firstName || 0} {userInfo?.lastName || 0}
              </div>
              <div className="role">
                <Translate
                  translatedContent={
                    isAdmin ? contents?.admin : contents?.expert
                  }
                  fallbacktranslatedContent={isAdmin ? "Admin" : "Expert"}
                />
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
NavBar.prototype = {
  setSelectedLi: PropTypes.func.isRequired,
  selectedLi: PropTypes.bool.isRequired,
  activeTab: PropTypes.string,
};
export default NavBar;
