import { showToast } from "components/Common/Common";
import { errorHandle } from "components/Common/errorHandle";
import {
  DefaultDropDown,
  defaultInput as DefaultInput
} from "components/Common/Input";
import udid from "components/Common/udid";
import { Header } from "components/Header/Header";
import Images from "constants/Images";
import messages from "constants/messages";
import useLanguage from "hooks/useLanguage";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import defaultA from "redux/action/defaultA";
import checkInviteA from "redux/action/expert/checkInviteA";
import tenantInfoA from "redux/action/tenantInfoA";
import { ERROR, LOADING, SUCCESS } from "redux/constants/reduxConstant";
import { useLanguages } from "utils/useTranslation";
import { Validate as validate } from "../../utils/validate_wrapper";
const ExpRegister = (props) => {
  const history = useHistory();
  const platformLanguages = useLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("selectedLanguage") || "en")
  useLanguage({lng: selectedLanguage, shouldFetchLanguage: true })
  const { preferedLanguageContent: { contents } } = useSelector((state) => state);
  const [state, setState] = useState({
    loading: false,
    code: "",
    selectedPreferredLanguage: "en",
    codeArr: ["", "", "", "", "", ""],
  });
  useEffect(() => {
    const url = new URL(decodeURIComponent(window.location.href));
    let code = extractUrlValue("inviteId", url.search);
    let tenant = extractUrlValue("tenant", url.search);
    if (!code || !tenant) {
      localStorage.clear();
      const path = `/`;
      props.history.push({ pathname: path });
    } else {
      setState({
        inviteCode: code,
        tenantId: tenant,
        codeArr: ["", "", "", "", "", ""],
      });
      props.history.replace(`/expert-register`);
      console.log("code from url ", code, tenant);
      props.tenantInfoRequest({
        from: "admin-home",
        body: { tenant: tenant, id: udid() },
      });
    }
  }, []);
  useEffect(() => {
    const checkInvitePath = props.ScreenProps.checkInviteR;
    if (checkInvitePath.status == LOADING) {
      setState({ ...state, loading: true });
    } else if (checkInvitePath.status == SUCCESS) {
      var userData = checkInvitePath.value.data;
      userData.tenant = state.tenantId;
      userData.role = userData.tenants.filter(
        (x) => x.tenantIdentifier == userData.tenant
      )[0].roles;
      setState({
        ...state,
        loading: false,
        checkInviteRes: checkInvitePath.value.data,
      });
      props.defaultRequest();
      if (checkInvitePath.value.data.sessionId) {
        localStorage.setItem(
          "userData",
          JSON.stringify(checkInvitePath.value.data)
        );
        history.replace({
          pathname: "/expert-complete-profile",
          state: {
            tenant: checkInvitePath.value.data.tenant,
            logo: userData.logo,
          },
        });
      } else {
        props.history.replace({
          pathname: "/expert-create-password",
          state: {
            inviteCode: state.inviteCode,
            logo: userData.logo,
            email: checkInvitePath.value.data.email,
            tenant: checkInvitePath.value.data.tenant,
          },
        });
      }
    } else if (checkInvitePath.status == ERROR) {
      // // debugger
      console.log("checkInvite error res ", checkInvitePath);
      setState({ ...state, loading: false });
      if (
        checkInvitePath &&
        checkInvitePath.error &&
        checkInvitePath.error.data &&
        checkInvitePath.error.data.status
      ) {
        switch (checkInvitePath.error.data.status) {
          case "InviteAcceptedAlready":
            showToast(contents?.invitationAccepted ?? messages?.invitationAccepted);
            setTimeout(() => {
              localStorage.clear();
              const path = `/`;
              props.history.push({ pathname: path });
            }, 2000);
            break;
          default:
            errorHandle(checkInvitePath, contents);
            props.defaultRequest();
            break;
        }
      } else {
        errorHandle(checkInvitePath, contents);
        props.defaultRequest();
      }
    }
  }, [props.ScreenProps]);
  const extractUrlValue = (key, url) => {
    if (typeof url === "undefined") url = decodeURIComponent(window.location.href);
    var match = url.match("[?&]" + key + "=([^&]+)");
    return match ? match[1] : null;
  };
  const submit = (e) => {
    e.preventDefault();
    clearError();
    if (!checkValidation()) {
      return;
    }
    if (state.inviteCode)
      props.checkInviteRequest({
        from: "expRegister",
        code: state.code,
        tenant: state.tenantId,
        InviteId: state.inviteCode,
      });
    else {
      showToast(contents?.codeNotFound ?? messages?.codeNotFound);
      const path = `/`;
      history.replace({ pathname: path });
    }
  };
  // validate form data
  const checkValidation = () => {
    var codeErr = validate(contents, "blank", state.code);
    if (codeErr) {
      setState({
        ...state,
        codeErr: codeErr,
      });
      return false;
    } else {
      return true;
    }
  };
  // clean error state
  const clearError = () => {
    setState({
      ...state,
      codeErr: "",
      codeArr: ["", "", "", "", "", ""],
    });
  };
  const setCodeArr = (param, value) => {
    var codeArr = state.codeArr;
    codeArr[param] = value;
    var code = "";
    for (let i = 0; i < codeArr.length; i++) {
      code += codeArr[i];
    }
    setState({
      ...state,
      code: code,
      codeErr: validate(contents, "blank", value),
      codeArr: codeArr,
    });
    let index = value !== "" ? param + 1 : param - 1;
    if (0 <= index && index < 6) {
      document
        .getElementById("register-form")
        .childNodes[index].querySelector("input")
        .focus();
    }
  };
  const inputPaste = (e) => {
    let value = "";
    let valueArr = [];
    e.clipboardData.items[0].getAsString((text) => {
      value = text.substr(0, 6);
      for (let i = 0; i < text.length; i++) {
        valueArr.push(text.substr(i, 1));
      }
      setState({
        ...state,
        code: value,
        codeErr: validate(contents, "blank", value),
        codeArr: valueArr,
      });
    });
  };
  return (
    <>
      <ToastContainer />
      <div className="expert-content-block-section">
        <div className="first-section">
          <div className="form-content-blk">
            <Header />
            <div className="expert-content-block justify-center items-center m-auto">
              <div className="w-full">
                <h4>{contents?.inviteTxt ?? messages?.inviteTxt}</h4>
                <div className="signin mobile">
                  <span className="pr-2">{contents?.alreadyHaveAccount ?? messages?.alreadyHaveAccount}</span>
                  <a href="/login">{contents?.signIn ?? messages?.signIn}</a>
                </div>
                <p className="invite-des">{contents?.inviteDes ?? messages?.inviteDes}</p>
                <form onSubmit={submit} className="mb-5">
                  <div
                    id="register-form"
                    className="overflow-auto invite-code-input"
                  >
                    <div className="col-2 float-start">
                      <DefaultInput
                        type={"text"}
                        name={"code1"}
                        maxLength={1}
                        value={state.codeArr[0]}
                        onChange={(e) => setCodeArr(0, e.target.value)}
                        onPaste={inputPaste}
                      />
                    </div>
                    <div className="col-2 float-start">
                      <DefaultInput
                        type={"text"}
                        name={"code2"}
                        maxLength={1}
                        value={state.codeArr[1]}
                        onChange={(e) => setCodeArr(1, e.target.value)}
                        onPaste={inputPaste}
                      />
                    </div>
                    <div className="col-2 float-start">
                      <DefaultInput
                        type={"text"}
                        name={"code3"}
                        maxLength={1}
                        value={state.codeArr[2]}
                        onChange={(e) => setCodeArr(2, e.target.value)}
                        onPaste={inputPaste}
                      />
                    </div>
                    <div className="col-2 float-start">
                      <DefaultInput
                        type={"text"}
                        name={"code4"}
                        maxLength={1}
                        value={state.codeArr[3]}
                        onChange={(e) => setCodeArr(3, e.target.value)}
                        onPaste={inputPaste}
                      />
                    </div>
                    <div className="col-2 float-start">
                      <DefaultInput
                        type={"text"}
                        name={"code5"}
                        maxLength={1}
                        value={state.codeArr[4]}
                        onChange={(e) => setCodeArr(4, e.target.value)}
                        onPaste={inputPaste}
                      />
                    </div>
                    <div className="col-2 float-start">
                      <DefaultInput
                        type={"text"}
                        name={"code6"}
                        maxLength={1}
                        value={state.codeArr[5]}
                        onChange={(e) => setCodeArr(5, e.target.value)}
                        onPaste={inputPaste}
                      />
                    </div>
                  </div>
                  {/* <button style={styles.tenantImgNav} type="submit" className="btn btn-primary btn-lg btn-min">Next</button> */}
                  <div className="bg-black btn-block">
                    <button
                      type="submit"
                      disabled={state.loading}
                      className="btn btn-black btn-lg btn-min w-full"
                    >
                      {contents?.btnContinue ?? messages?.btnContinue}
                    </button>
                  </div>
                </form>
                <DefaultDropDown
                  placeholder={contents?.preferredLanguage ?? messages?.preferredLanguage}
                  name="preferredLanugage"
                  options={platformLanguages}
                  value={state.selectedPreferredLanguage}
                  className={"mt-5"}
                  noResultFound={contents?.noResultFound ?? messages?.noResultFound}
                  onChange={(value) => {
                    setState({ ...state, selectedPreferredLanguage: value });
                    localStorage.setItem("selectedLanguage", JSON.stringify( value.value ));
                    setSelectedLanguage(value.value)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="second-section right-img">
          <img src={Images.invitation} alt="" />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ScreenProps: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    checkInviteRequest: ({ from, tenant, code, InviteId }) => {
      dispatch(checkInviteA({ from, tenant, code, InviteId }));
    },
    tenantInfoRequest: ({ from, body }) => {
      dispatch(tenantInfoA({ from, body }));
    },
    defaultRequest: () => {
      dispatch(defaultA());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExpRegister);
