import messages from "constants/messages";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SwiperCore, {
  Keyboard, Navigation, Pagination, Scrollbar
} from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import cartGreen from "../../assets/images/cart-green.png";
import cart from "../../assets/images/cart.png";
import logo from "../../assets/images/GetBee-Logo.png";
import search from "../../assets/images/search.png";
import MyTimer from "../../components/Common/MyTimer";
import apiName from "../../constants/apiName";
import { getCollections, getProducts, goToSelectedStep, showShoppingLoader } from "../../redux/action/shopping";
import useSize from "../../utils/useSize";
import Card from "./Card.js";
SwiperCore.use([Keyboard, Scrollbar, Navigation, Pagination]);
const Catalog = () => {
  const dispatch = useDispatch()
  const { preferedLanguageContent: { contents } } = useSelector((state) => state);
  const loading = useSelector(state => state.shoppingReducer.loading)
  const allProducts = useSelector(state => state.shoppingReducer.allProducts)
  const totalProductsCount = useSelector(state => state.shoppingReducer.totalProductsCount)
  const recommendedProducts = useSelector(state => state.shoppingReducer.recommendation)
  const [currentTab, setCurrentTab] = useState("All Products")
  const activeStep = useSelector(state => state.shoppingReducer.activeStep)
  const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus)
  const tenantInfo = useSelector(state => state.tenantInfoR.value)
  const collections = useSelector(state => state.shoppingReducer.collections)
  const clearItems = useRef(false)
  const [searchCriteria, setSearchCriteria] = useState({
    searchText: "",
    selectedCollection: null,
    productsPage: 1
  })
  const handleChangeTab = (value, id) => {
    dispatch(showShoppingLoader(true))
    clearItems.current = true
    setSearchCriteria({
      searchText: "",
      selectedCollection: value === "All Products" ? null : id,
      productsPage: 1
    })
    setCurrentTab(value)
    setTimeout(() => {
      dispatch(showShoppingLoader(false))
    }, 400);
  }

  const filterProducts = (txt) => {
    clearItems.current = true
    setSearchCriteria({
      ...searchCriteria,
      searchText: txt,
      productsPage: 1
    })
  }

  const cartItems = useSelector(state => state.cartReducer.cartItems)
  const screen = useSize()
  
  useEffect(() => {
    if (activeStep === 0)
      clearItems.current = true
  }, [activeStep])

  useEffect(() => {
    if (activeStep === 0) {
      dispatch(getProducts(apiName.baseApi, sessionStatus.tenant, searchCriteria.selectedCollection, searchCriteria.searchText, searchCriteria.productsPage, (clearItems.current ? [] : allProducts)))
      clearItems.current = false
    }
  }, [searchCriteria.searchText, searchCriteria.selectedCollection, activeStep, searchCriteria.productsPage])

  useEffect(() => {
    if (Object.keys(sessionStatus).length > 0 && sessionStatus.tenant) {
      dispatch(getCollections(apiName.baseApi, sessionStatus.tenant))
      dispatch(getProducts(apiName.baseApi, sessionStatus.tenant, null, "", 1, []))
    }
  }, [sessionStatus])
  const loadMore = (e) => {
    e.stopPropagation();
    e.preventDefault();
    clearItems.current = false
    setSearchCriteria((old) => ({
      ...old,
      productsPage: old.productsPage + 1
    }))
  }
// debugger
  return (
    <section className={`s-home  relative h-full   mx-auto flex items-center flex-col`}>
      {/* TOP PART */}
      {/* SEARCH PART */}
      {screen.size === "sm" ? <div className="flex z-50 items-center pl-2 pr-2 pt-4 pb-4 w-full justify-between bg-white">
        <div className="flex items-center">
          <div className="s-video__liveDot" />
          <span className="session-time">{Object.keys(sessionStatus).length > 0 && sessionStatus.startTime && <MyTimer expiryTimestamp={sessionStatus.startTime} />}</span>
        </div>
        <div>
          <img style={{
            height: 'auto',
            maxHeight: '24px',
            width: 'auto',
            maxWidth: '120px',
          }} src={tenantInfo && tenantInfo.data.logoUrl ? tenantInfo.data.logoUrl : logo} alt="getbee-logo" />
        </div>
        <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(3))}>
          <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
      </div>
        : <div className="flex items-center mt-8 mr-4 z-50 w-full justify-end">
          <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(3))}>
            <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
        </div>}
      <div className="w-11/12 mx-auto mx-auto ">
        <div className="mt-4 s-cart__btnContainer z-99 relative w-full mx-auto  flex items-center">
          <button className={`transparent cursor-pointer s-cart__btn ${currentTab !== "recommended" && `selected-catalog`}`} onClick={() => setCurrentTab("All Products")}>
            {contents?.catalog ?? messages?.catalog}
          </button>
          <button className={`transparent s-cart__btn ${currentTab === "recommended" && `selected-catalog`}`} onClick={() => setCurrentTab("recommended")}>
            {contents?.recommending ?? messages?.recommending}
          </button>
        </div>
        {currentTab !== "recommended" && <>
          <div className="flex justify-between items-center mt-4 w-full  mx-auto">
            <div className="relative  s-home__input flex items-center">
              <img src={search} alt="search icon" />
              <input type="search" value={searchCriteria.searchText} className="ml-4" placeholder={contents?.search ?? messages?.search} onChange={(ev) => { filterProducts(ev.target.value) }} />
            </div>
          </div>
          <div className="w-full  mx-auto flex justify-between items-center mt-8 s-home__btnContainer">
            {/* FILTERS PART */}
            {collections && collections.length > 0 &&
              <Swiper
                slidesPerView={3}
                centeredSlides={false}
                slidesPerGroupSkip={1}
                grabCursor={true}
                keyboard={{
                  enabled: true
                }}
                breakpoints={{
                  "769": {
                    slidesPerView: 3,
                    slidesPerGroup: 2
                  }
                }}
                scrollbar={true}
                navigation={true}
                pagination={{
                  clickable: true
                }}
                className="mySwiper"
              >
                {collections && collections.length && collections.map(item => (
                  <SwiperSlide style={currentTab === item.title ? { borderBottom: "3px solid black", fontWeight: "900", color: "black" } : null}>
                    <button className={`mr-2 s-home__btn ${currentTab === item.title && `text-black`} flex items-center ${currentTab === item.title && ``}`} onClick={() => handleChangeTab(item.title, item.id)}>
                      <div dangerouslySetInnerHTML={{ __html: contents && item.title === "All Products" ? (contents?.allProducts ?? messages?.allProducts) : item.title }} />
                    </button>
                  </SwiperSlide>
                ))}
              </Swiper>
            }
          </div>
        </>}
        {/* CARDS PART */}
        <section className="w-full mx-auto mt-2">
          <div className="">
            <div className="mt-6 grid grid-cols-1  sm:grid-cols-1 md:grid-cols-1 ">
              {currentTab === "recommended" && recommendedProducts && recommendedProducts.map((product) => (<Card product={product} isRecommended={true} />))}
              {currentTab !== "recommended" && allProducts && (<>
                {allProducts.map((product) => (
                  <Card id={product.productId} key={product.productId} recommendedProducts={recommendedProducts} product={product} />
                ))}
                {totalProductsCount > allProducts.length && <Link onClick={loadMore} style={{ height: "20px", paddingBottom: "30px", textAlign: "center", color: "cornflowerblue" }}>
                  {contents.loadMore ?? messages?.loadMore}
                </Link>}
              </>)}
              {/* {loading && <Loader />} */}
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}
export default Catalog