import React from "react";
import PropTypes from "prop-types";

const InvalidFeedback = ({ message }) => {
  return <div className="invalid-feedback">{message}</div>;
};

InvalidFeedback.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default InvalidFeedback;
