import {
  addItemToCart,
  updateItemFromCartQuantity,
  removeItemFromCart,
  removeItemFromCartSession,
  addItemToCartSession,
} from "../action/cartUtils";
import {
  CLEAR_ITEM_FROM_CART,
  REMOVE_ITEM,
  ADD_ITEM,
  QUANTITY_CHANGED,
  ADD_TO_CART,
  ADD_PRODUCT_FROM_SESSION,
  REMOVE_PRODUCT_FROM_SESSION,
  SESSION_STATUS,
  CLEAN_SHOPPING,
} from "../constants/Index";
import { STORE_INTEGRATION } from "../constants/shopping";

const initialState = {
  hidden: true,
  cartItems: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case CLEAN_SHOPPING:
      return {
        ...state,
        cartItems: [],
      };
    case ADD_ITEM:
      return {
        ...state,
        cartItems: addItemToCart(state.cartItems, action.payload),
      };
    case CLEAR_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (cartItem) => cartItem.id !== action.payload.id
        ),
      };
    case REMOVE_ITEM:
      return {
        ...state,
        cartItems: removeItemFromCart(state.cartItems, action.payload),
      };
    case ADD_PRODUCT_FROM_SESSION:
      return {
        ...state,
        cartItems: addItemToCartSession(state.cartItems, action.payload),
      };
    case REMOVE_PRODUCT_FROM_SESSION:
      return {
        ...state,
        cartItems: removeItemFromCartSession(state.cartItems, action.payload),
      };
    case QUANTITY_CHANGED:
      return {
        ...state,
        cartItems: updateItemFromCartQuantity(state.cartItems, action.payload),
      };
    case SESSION_STATUS:
      let cartProducts = [];
      if (
        action.payload &&
        action.payload.cart &&
        action.payload.cart.length > 0
      ) {
        action.payload.cart.map((item) => {
          item.rawData.id = item.productInCartId;
          item.rawData.quantity = item.quantity;
          cartProducts.push(item.rawData);
        });
      }
      return {
        ...state,
        cartItems: cartProducts,
      };
    default:
      return state;
  }
};

export default cartReducer;
