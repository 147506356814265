import React, { useState, useEffect } from "react";
import Images from "../../constants/Images";
import { useLocation, useHistory } from "react-router-dom";
import { connect, useSelector } from 'react-redux';
import defaultA from "../../redux/action/defaultA";
import resendEmailA from "../../redux/action/resendEmailA";
import { SUCCESS, ERROR, LOADING } from '../../redux/constants/reduxConstant';
import { errorHandle } from "../../components/Common/errorHandle";
import { ToastContainer } from 'react-toastify';
import { showToast } from "../../components/Common/Common";
import messages from "../../constants/messages";
import { Header } from '../../components/Header/Header';

const EmailCheck = (props) => {
    const [state, setState] = useState({
        loading: false,
        email: ''
    })
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);
    const location = useLocation();
    const history = useHistory()

    useEffect(() => {
        if (location && location.state) {
            setState({...state, email: location.state.email})
        }

    }, [location, state]);

    useEffect(() => {
        const resendEmailPath = props.ScreenProps.resendEmailR;

        if (resendEmailPath.status === LOADING) {
            setState({ ...state, loading: true });
        }
        else if (resendEmailPath.status === SUCCESS) {
            console.log('resendEmail success res ', resendEmailPath);
            setState({ ...state, loading: false, isVerified: true });
            showToast(resendEmailPath.value.data.status, 'success');
            props.defaultRequest();
        }
        else if (resendEmailPath.status === ERROR) {
            console.log('resendEmail error res ', resendEmailPath);
            setState({ ...state, loading: false, status: contents?.verifyFail ?? messages.verifyFail });
            errorHandle(resendEmailPath, contents)
            props.defaultRequest();
        }
    }, [props.ScreenProps]);

    const goToSignin = () => {
        history.push({
            pathname: '/login'
        })
    }


    return (
        <>
            <ToastContainer />
            <div className="expert-content-block-section">
                <div className="first-section">
                    <div className="form-content-blk">
                        <Header />
                        <div className="expert-content-block justify-center items-center m-auto">
                            <div className="w-full">
                                <h4>{contents?.checkYour ?? messages?.checkYour}<br /> {contents?.emailAddress ?? messages?.emailAddress}</h4>
                                <p className="linkCautuin mb-4">{contents?.passwordInstructionsSent ?? messages?.passwordInstructionsSent} <strong>{state.email}</strong></p>
                                <p>{contents?.checkTheSubmittedMailCorrect ?? messages?.checkTheSubmittedMailCorrect}</p>
                                <div className="bg-black btn-block">
                                    <button
                                        type="button"
                                        className="btn btn-black btn-lg btn-min w-full"
                                        onClick={goToSignin}>
                                        {contents?.backToSignIn ?? messages?.backToSignIn}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-section right-img">
                    <img src={Images.emailSent} />
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resendEmailRequest: ({ from, email }) => {
            dispatch(resendEmailA({ from, email }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCheck);
