/* eslint-disable react-hooks/exhaustive-deps */
/*
  This component is initial journey of user where it fetches all required 
  Data from the api and dispatching to the the redux store such as 
  Tenant name will be passed from the url and will be send to remove 
  Server to get the information about the tenant such not active expert
  Logo etc...
  Check if there is something
  Remove this when you split the client and web application 
  This local storage is set when user finish the call 
*/
import { clientAPIStatus, componentsName } from "config/client-api-status";
import { clientHasVistedVideoCall, localStorageClientLanguageKey } from "config/Config";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import callFetchClientAppSettings from "redux/call-action/callFetchClientAppSetting";
import CallfetchTenantInfo from "redux/call-action/callfetchTenantInfo";
import { LOADING, NONE } from "redux/constants/status";
import LoadingBee from "views/client/common/Messages/LoadingBee";
import NotFound from "views/client/common/Messages/NotFound";
import ScheduleAndCallButtons from "views/client/common/ScheduleAndCallButtons";
import SchedulingTemplate from "views/client/common/SchedulingTemplate";
import "./initial-page.scss";
import MainContent from "./MainContent";
import useSignalR from "hooks/useSignalR";

MainContent.propTypes = {
  tenantInfo: PropTypes.shape({
    uniqueIdentifier: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.string,
    industry: PropTypes.string,
    numberOfEmployees: PropTypes.string,
    url: PropTypes.string,
    logoUrl: PropTypes.string,
    numberOfOnlineExperts: PropTypes.number,
    numberOfAvailableExperts: PropTypes.number,
    storeType: PropTypes.string,
    googleAnalyticsKey: PropTypes.string,
    avialableClientLanguages: PropTypes.arrayOf(PropTypes.string),
    clientDefaultLanguage: PropTypes.string,
    isSucceeded: PropTypes.bool,
    status: PropTypes.string,
    error: PropTypes.any,
  }),
};

const InitialPage = (props) => {
  const {
    tenantClientAppSettings,
    tenantInfo,
    preferedLanguageContent: { contents },
    tenantClientAppTextSettings,
  } = useSelector((state) => state);


  const {
    numberOfAvailableExperts,
    setNumberOfAvailableExperts
  } = useSignalR();


  const { STATUS, value } = tenantInfo;
  const { value: clientAppSetting } = tenantClientAppSettings;
  const { value: tenantClientAppTextSetting } = tenantClientAppTextSettings;
  const { tenant } = useParams();
  const dispatch = useDispatch();
  const isLanguageKeyExists = localStorage.getItem(
    localStorageClientLanguageKey
  );


  useEffect(()=>{
    if(tenantInfo?.value?.numberOfAvailableExperts) {
      setNumberOfAvailableExperts(tenantInfo?.value?.numberOfAvailableExperts)
    }
  },[tenantInfo])

  useEffect(() => {
    let fetchTenantInfo = async () => {
      await dispatch(CallfetchTenantInfo({ tenant }));
    };

    if (Object.entries(value).length === 0) {
      fetchTenantInfo();
    }
  }, [tenant, dispatch, value]);

  useEffect(() => {
    let fetchAppClientSettings = async () => {
      await dispatch(callFetchClientAppSettings(tenant));
    };

    if (Object.entries(clientAppSetting).length === 0) {
      fetchAppClientSettings();
    }
  }, [tenant, dispatch, clientAppSetting, isLanguageKeyExists]);

  const IsclientHasVistedVideoCall = localStorage.getItem(
    clientHasVistedVideoCall
  );

  useEffect(() => {
    if (!IsclientHasVistedVideoCall) {
      localStorage.setItem(
        localStorageClientLanguageKey,
        JSON.stringify(clientAppSetting.clientDefaultLanguage)
      );
    }
  }, [clientAppSetting, IsclientHasVistedVideoCall]);

  
  return (
    <>
      {" "}
      {STATUS === LOADING && <LoadingBee />}
      {value?.status === clientAPIStatus.TenantIsNotExist && <NotFound />}
      {[LOADING, NONE].indexOf(STATUS) === -1 &&
        value?.status !== clientAPIStatus.TenantIsNotExist && (
          <SchedulingTemplate
            main={<MainContent numberOfAvailableExperts={numberOfAvailableExperts} tenantInfo={value} contents={contents} />}
            actionButtons={
              <ScheduleAndCallButtons
                classSlug="flex-cols-buttons"
                uniqueIdentifier={value?.uniqueIdentifier}
                contents={contents}
                clientAppSetting={clientAppSetting}
                from={componentsName.initialPage}
                displayCallButton={true}
                numberOfOnlineExperts={numberOfAvailableExperts}
              />
            }
            tenantInfo={value}
            clientAppSetting={clientAppSetting}
            contents={contents}
            rowClass="height-100vh"
            tenantClientAppTextSetting={tenantClientAppTextSetting}
          ></SchedulingTemplate>
        )}
    </>
  );
};

InitialPage.propTypes = {};

export default InitialPage;
