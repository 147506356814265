import {  useEffect, useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";
import { isUserLoggedIn, getUserData } from "./../utils/utils";
import useSignalR from "hooks/useSignalR";

const Router = () => {
  const location = useLocation();
  const userData = getUserData();
  const role = "admin";
  const [loading, setLoading] = useState(true);

  const {
    signalRPublicConnection, initPublicSignalR,
  } = useSignalR();


  useEffect(() => {
    if (!signalRPublicConnection && loading) {
      setLoading(false);
      initPublicSignalR(isUserLoggedIn() ? userData?.tenant : location.pathname.split('/')[1]);
    }
  }, [signalRPublicConnection,initPublicSignalR, loading, userData, location]);
  const getRoutes = (routes) => {
    const AllRoutes = routes;

    return routes.map((prop, key) => {
      const roles = prop.meta.roles;
      if (
        isUserLoggedIn() &&
        prop.meta &&
        prop.meta.authRoute === true &&
        Array.isArray(roles) &&
        prop.path === location.pathname &&
        !roles.find((element) => element === role)
      ) {
        return <Redirect to="/login" key={key}/>;
      }

      if (
        prop.meta &&
        prop.meta.authRoute === true &&
        !isUserLoggedIn() &&
        prop.path === location.pathname
      ) {
        return <Redirect to="/login" key={key} />;
      }

      if (
        isUserLoggedIn() &&
        (!userData.tenants || userData.tenants.length === 0) &&
        (location.pathname === "/login" || location.pathname === "/admin-home")
      ) {
        return <Redirect to="/company-setup?confirmed=1" key={key} />;
      }

      if (isUserLoggedIn() && location.pathname === "/login") {
        return <Redirect to="/admin-home" key={key}/>;
      }

      var path = location.pathname;
      if (path[path.length - 1] === "/") path = path.slice(0, -1);
      var clientScreens = [
        "/client-home",
        "/video-call",
        "/error",
        "/client-start-consultation",
        "/list-experts",
        "/expert-profile",
        "/speak-with-our-specialists",
        "/speak-with-specific-expert",
        "/schedule-call-with-specific-expert",
        "/select-date-to-schedule-call",
        "/cancel-meeting"
      ];
      if (
        clientScreens.filter((x) => location.pathname.indexOf(x) === -1)
          .length === clientScreens.length && // not in the front
        AllRoutes.filter((x) => x.path === path).length === 0
      ) {
        // not in the back
        return <Redirect to={path + "/client-home"} key={key} />;
      }

      return (
        <Route
          path={prop.path}
          render={(props) => <prop.component {...props} />}
          key={key}
        />
      );
    });
  };
  

  return (
    <>
      <div className="gb-content">
        <Switch>
          {getRoutes(Routes)}
          <Redirect from="/" to="/login" />
        </Switch>
      </div>
    </>
  );
};

export default Router;
