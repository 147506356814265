import { put, post, get } from '../../../utils/Api';
import axios from 'axios';
import { checkInviteLoading, checkInviteSuccess, checkInviteError } from '../../constants/reduxConstant';
import apiName from '../../../constants/apiName';
import udid from '../../../components/Common/udid';

const apiLoading = () => ({ type: checkInviteLoading });
const apiSucceed = (payload) => ({ type: checkInviteSuccess, payload });
const apiError = (payload) => ({ type: checkInviteError, payload });

const checkInviteA = ({ from, tenant, code, InviteId }) => async (dispatchEvent, getState) => {
    let body = {
        id: udid(),
        tenant: tenant,
        code: code,
        InviteId: InviteId
    };
    if (from == 'expRegister') {
        dispatchEvent(apiLoading());
        try {
            post(apiName.checkInvite, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    };
}

export default checkInviteA