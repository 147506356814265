import { combineReducers } from "redux";
import authReducer from "./Auth";
import userDetailReducer from "./UserDetails";

//shopping

import shoppingReducer from "./shopping";

// auth
import loginR from "./loginR";
import logoutR from "./logoutR";
import { registerR, expCreatePassR } from "./registerR";
import verifyEmailR from "./verifyEmailR";
import resendEmailR from "./resendEmailR";

//common
import { imgUploadR, imgUploadExpR, imgUploadInR } from "./common/imgUploadR";
import { industryR, industryInR } from "./common/industryR";
import userInfoR from "./common/userInfoR";
import { updateProfileR, updateProfileInR } from "./common/updateProfileR";
import forgotPasswordR from "./common/forgotPasswordR";
import resetPasswordR from "./common/resetPasswordR";

// admin
import onlineR from "./onlineR";
import offlineR from "./offlineR";
import requestCallR from "./requestCallR";
import tenantInfoR from "./tenantInfoR";
import tenantCustomizationR from "./tenantCustomizationR"
import expertAcceptR from "./expertAcceptR";
import createWebUrlR from "./admin/createWebUrlR";
import { inviteExpR, inviteExpAdminHomeR } from "./admin/inviteExpR";
import nameExistsR from "./expert/nameExistsR";
import revokeInvitationR from "./admin/revokeInvitationR";

// Cart
import cartReducer from "./cart";

//expert
import checkInviteR from "./expert/checkInviteR";
import expertsTeamR from "./admin/expertsTeamR";
import expertsTeamPendingInvitesR from "./admin/expertsTeamPendingInvitesR";
import deleteExpertProfileR from "./admin/deleteExpertProfileR";

import requestExpertConnectionR from "./requestExpertConnectionR";
import sessionConnectionsReducer from "./sessionConnectionsReducer";
import teamMembers from "redux/reducer/admin/teamMembers";
import preferedLanguageContent  from "redux/reducer/admin/preferedLanguageContent";
import  integrations  from "./admin/integrations";
import tenantInfo from "redux/reducer/client/tenantInfo";
import tenantClientAppSettings from "redux/reducer/client/tenantClientAppSettings";
import tenantExperts from "redux/reducer/client/tenantExperts";
import  singalRConnectionToken  from "redux/reducer/client/singalRConnectionToken";
import randomSingalRConnectionToken from "./client/randomSingalRConnectionToken";
import tenantClientAppTextSettings from "redux/reducer/client/tenantClientAppTextSettings";
const rootReducer = combineReducers({
  authReducer,
  cartReducer,
  userDetailReducer,
  shoppingReducer,
  sessionConnectionsReducer,
  registerR,
  verifyEmailR,
  resendEmailR,
  imgUploadR,
  loginR,
  logoutR,
  industryR,
  createWebUrlR,
  onlineR,
  offlineR,
  requestCallR,
  tenantInfoR,
  tenantCustomizationR,
  inviteExpR,
  inviteExpAdminHomeR,
  userInfoR,
  expertAcceptR,
  checkInviteR,
  expCreatePassR,
  imgUploadExpR,
  updateProfileR,
  nameExistsR,
  updateProfileInR,
  forgotPasswordR,
  resetPasswordR,
  expertsTeamR,
  expertsTeamPendingInvitesR,
  deleteExpertProfileR,
  requestExpertConnectionR,
  imgUploadInR,
  industryInR,
  revokeInvitationR,
  teamMembers,
  preferedLanguageContent,
  integrations,
  tenantInfo,
  tenantClientAppSettings,
  tenantExperts,
  singalRConnectionToken,
  randomSingalRConnectionToken,
  tenantClientAppTextSettings
});

export default rootReducer;
