import { SUCCESS, ERROR, LOADING, NONE } from "../../constants/reduxConstant";
import {
  teamMembersLoading,
  teamMembersFetched,
  teamMemberFetchError,
  fetchPendingTeamMembers,
  fetchPendingTeamMembersLoading,
  fetchPendingTeamMembersError,
  teamMemberUpdated,
  invokeInvitation,
  inviteTeamMembers,
  deleteTeamMember,
} from "redux/constants/reduxConstant";
const initialState = {
  allMembers: { status: NONE, value: {} },
  pendingInvitation: { status: NONE, value: {} },
};
export default function teamMembers(state = initialState, action) {
  switch (action.type) {
    case teamMembersLoading:
      return { ...state, allMembers: { status: LOADING } };
    case teamMembersFetched:
      return {
        ...state,
        allMembers: { status: SUCCESS, value: action.payload },
      };
    case teamMemberFetchError:
      return { ...state, allMembers: { status: ERROR, error: action.payload } };
    case fetchPendingTeamMembersLoading:
      return { ...state, pendingInvitation: { status: LOADING } };
    case fetchPendingTeamMembers:
      return {
        ...state,
        pendingInvitation: { status: SUCCESS, value: action.payload },
      };
    case fetchPendingTeamMembersError:
      return {
        ...state,
        pendingInvitation: { status: ERROR, error: action.payload },
      };
    case teamMemberUpdated:
      let { allMembers } = state;
      let { value } = allMembers;
      const updatedTeamMembers = value.experts.map((team) => {
        if (
          team?.email?.toLowerCase() === action.payload?.email.toLowerCase()
        ) {
          return action.payload;
        }
        return team;
      });
      return {
        ...state,
        allMembers: { status: SUCCESS, value: { experts: updatedTeamMembers } },
      };

    case invokeInvitation:
      const { pendingInvitation } = state;
      const { value: pendingInvtValue } = pendingInvitation;
      const updatedInvitation = pendingInvtValue.experts.filter(
        (team) => team.invitationId !== action.payload
      );

      console.log("updatedInvitation", updatedInvitation);
      return {
        ...state,
        pendingInvitation: {
          status: SUCCESS,
          value: { experts: updatedInvitation },
        },
      };
    case inviteTeamMembers:
      const { pendingInvitation: pendingInvitationR } = state;
      const { value: valueRenamed } = pendingInvitationR;

      const updateInviteTeamMembers = [
        ...valueRenamed.experts,
        ...action.payload.experts,
      ];
      return {
        ...state,
        pendingInvitation: {
          status: SUCCESS,
          value: { experts: updateInviteTeamMembers },
        },
      };
    case deleteTeamMember:
      const {
        allMembers: { value: tvalue },
      } = state;
      const filterAllMembers = tvalue.experts.filter(
        (teamMember) => teamMember.email !== action.payload
      );
      
      return {
        ...state,
        allMembers: { value: { experts: filterAllMembers } },
      };

    default:
      return state;
  }
}
