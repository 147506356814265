import { post } from '../../utils/Api';
import axios from 'axios';
import { expertAcceptLoading, expertAcceptSuccess, expertAcceptError } from '../constants/reduxConstant';
import apiName from '../../constants/apiName';
import udid from '../../components/Common/udid';

const apiLoading = () => ({ type: expertAcceptLoading });
const apiSucceed = (payload) => ({ type: expertAcceptSuccess, payload });
const apiError = (payload) => ({ type: expertAcceptError, payload });

const expertAcceptA = ({ from, body }) => async (dispatchEvent, getState) => {
    // body.id = udid()
    console.log("BODY ===<>", body);
    if (from == 'incomming-model') {
        dispatchEvent(apiLoading());
        try {
            post(apiName.expertAccept, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    };
}

export default expertAcceptA