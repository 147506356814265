import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Navbar/AdminNavbar";
import messages from "../../constants/messages";

const ErrorPage = (props) => {
    let { code } = useParams();
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);
    return (
        <>
            <Header showGB={true} showInvite={true} />
            <ToastContainer />
            <div className="admin-wrapper-inner admin-form-blk">
                <div className="admin-main-block-section">
                    <div className="container">
                        <div className="expert-form-block">
                            <div className="row justify-content-center">
                                <div className="col-md-7">
                                    <div className="expert-form-content-block">
                                        <h4 className="mb-4">{contents?.anErrorOccured ?? messages?.anErrorOccured??"An error has occured"}</h4>
                                    </div>
                                    <p className="mb-4 center">{(contents && contents[`Error_${code}`]) ? contents[`Error_${code}`] : (messages && messages[`Error_${code}`] ? messages[`Error_${code}`] : code)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
};

export default ErrorPage;
