import React from "react";
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import Select, { components } from 'react-select'
import colors from "../../constants/colors";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import ErrorText from "./Common";
const disabledStyles = {
  input: (provided, state) => ({
    ...provided,
    backgroundColor: 'gray !important', 
    cursor: 'not-allowed' 
  })
}

export const DefaultInputForm = (props) => {
  return (
    <div className={`${props.className} form-group flex flex-col`} >
      {props.label && <label className='input-label'>{props.label}</label>}
      <input
        className='form-control outline-none'
        type={props.type}
        style={{ 
          cursor: (props.disabled ? 'not-allowed' : '') 
        }}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onBlur={props.onBlur}
        onChange={props.onChange}
        required={props.required}
        readOnly={props.readOnly}
        disabled={props.disabled}
        maxLength={props.maxLength}
        minLength={props.minLength}
        onPaste={props.onPaste}
      />
      {props.error ? <ErrorText title={props.error} /> : null}
    </div>
  );
};

export const DefaultDropDownForm = (props) => {
  return (
    <div className="form-group">
      {props.label && <label>{props.label}</label>}
      <Select
        isMulti={props.isMulti ? props.isMulti : false}
        defaultValue={props.defaultValue}
        options={props.options}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        closeMenuOnSelect={props.closeMenuOnSelect}
        hideSelectedOptions={false}
        components={{ DropdownIndicator }}
        className={props.className}
        noOptionsMessage={() => props.noResultFound ?? "No Result Found" } 
        formatOptionLabel={props.formatOptionLabel}
      />
      {props.error ? <ErrorText title={props.error} /> : null}
    </div>
  );
};

export const InputAreaForm = (props) => {
  return (
    <>
      <div className="form-group">
        <label className='input-label'>{props.label}</label>
        <textarea
          type={props.type}
          className={props.className ? props.className : "form-control w-full outline-none"}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          onBlur={props.onBlur}
          onChange={props.onChange}
          required={props.required}
          readOnly={props.readOnly}
          disabled={props.disabled}
          maxLength={props.maxLength}
          minLength={props.minLength}
          rows={props.rows}
        />
        {props.error ? <ErrorText title={props.error} /> : null}
      </div>
    </>
  );
};

export const DefaultInputWithIconForm = (props) => {
  return (
    <div className={props.className}>
      <div className="input-field">
        <div className="relative flex">
          {props.label && <label className='absolute left-0  input-label'>{props.label}</label>}
          <input
            type={props.type}
            className='bg-gray outline-none w-full'
            style={{ 
              padding: '5px',
              cursor: (props.disabled ? 'not-allowed' : '') 
            }}
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            onBlur={props.onBlur}
            onChange={props.onChange}
            required={props.required}
            readOnly={props.readOnly}
            disabled={props.disabled}
            onPaste={props.onPaste}
          />
          {props.icon && <img className='icon cursor-pointer' src={props.icon} onClick={props.clickIcon} />}
        </div>
        {props.error ? <ErrorText title={props.error} /> : null}
      </div>
    </div>
  );
};

////////////////////////////////////////////////////////////////////////////

export const defaultInput = (props) => {
  return (
    <div className={`${props.className} flex flex-col input-field`} >
      {props.label && <label className='input-label'>{props.label}</label>}
      <input
        className='bg-gray outline-none'
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onBlur={props.onBlur}
        onChange={props.onChange}
        required={props.required}
        readOnly={props.readOnly}
        disabled={props.disabled}
        maxLength={props.maxLength}
        minLength={props.minLength}
        onPaste={props.onPaste}
      />
      {props.error ? <ErrorText title={props.error} /> : null}
    </div>
  );
};
export const PhoneNumberControl = (props) => {
  return (
    <div className={`${props.className} flex flex-col input-field`} >
      {props.label && <label className='input-label'>{props.label}</label>}
      
      <PhoneInput
        className='bg-gray outline-none'
        placeholder={props.placeholder ?? "Enter phone number"}
        international
        value={props.value}
        isValid={props.isValid}
        onChange={props.onChange}
      />
      {props.error ? <ErrorText title={props.error} /> : null}
    </div>
  );
};

export const defaultInputWithIcon = (props) => {
  return (
    <div className={props.className}>
      <div className="input-field">
        <div className="relative">
          {props.label && <label className=''>{props.label}</label>}
          <div className="flex">
          <input
            type={props.type}
            className='bg-gray outline-none w-full'
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            onBlur={props.onBlur}
            onChange={props.onChange}
            required={props.required}
            readOnly={props.readOnly}
            disabled={props.disabled}
            onPaste={props.onPaste}
          />
          {props.icon && <img className='icon cursor-pointer' src={props.icon} onClick={props.clickIcon} />}
          </div>
     
        </div>
        {props.error ? <ErrorText title={props.error} /> : null}
      </div>
    </div>
  );
};

export const InputArea = (props) => {
  return (
    <>
      <div className="form-group">
        <label className='input-label'>{props.label}</label>
        <textarea
          type={props.type}
          className={props.className ? props.className : "input-field w-full outline-none"}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          onBlur={props.onBlur}
          onChange={props.onChange}
          required={props.required}
          readOnly={props.readOnly}
          disabled={props.disabled}
          maxLength={props.maxLength}
          minLength={props.minLength}
          rows={props.rows}
        />
        {props.error ? <ErrorText title={props.error} /> : null}
      </div>
    </>
  );
};

export const DefaultDropDown = (props) => {
  return (
    <>
      <div className="form-group">
        {props.label && <label className='input-label'>{props.label}</label>}
        <Select
          styles={customStyles}
          isMulti={props.isMulti ? props.isMulti : false}
          defaultValue={props.defaultValue}
          options={props.options}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          closeMenuOnSelect={props.closeMenuOnSelect}
          hideSelectedOptions={false}
          noOptionsMessage={() => props.noResultFound ?? "No Result Found" } 
          components={{ DropdownIndicator }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: colors.borderBlue,
            },
          })}
          className={props.className}
          formatOptionLabel={props.formatOptionLabel}
        />
        {props.error ? <ErrorText title={props.error} /> : null}
      </div>
    </>
  );
};

// down icon
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={Images.drops} />
    </components.DropdownIndicator>
  );
};


const customStyles = {
  option: (provided, state) => ({
    color: state.isSelected ? '#1a1a1c' : '#6a6d7a',
    padding: '16px 13px 8px 20px !important',
    fontFamily: state.isSelected ? 'InterBold' : 'InterRegular',
    fontSize: '13px',
    position: 'relative',
    ':after': {
      content: '" "',
      display: 'inline-block',
      height: 12,
      width: 12,
      position: 'relative',
      top: 2,
      marginLeft: 10,
      backgroundImage: state.isSelected ? `url(${Images.selectedMark})` : ''
    },
  }),
  container: (provided, state) => ({
    ...provided,
    width: '100%'
  }),
  input: (provided, state) => ({
    ...provided,
    height: 20,
  }),
  indicatorSeparator: (provided, state) => ({
    display: 'none'
  }),
  indicatorsContainer: (provided, state) => ({
    paddingRight: 3
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    "svg": {
      fill: colors.black,
    },
    height: '17px !important',
    marginTop: 5
  }),
  control: (provided, state) => ({
    ...provided,
    paddingLeft: 0,
    paddingBottom: '14px !important',
    fontSize: 13,
    color: '#6a6d7a',
    fontWeight: '500',
    fontFamily: 'InterRegular',
    backgroundColor: 'transparent',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderRadius: 0,
    margin: 0
    
    // borderWidth: state.isFocused ? 3 : 1,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 13,
    color: '#a1a1a1',
    fontFamily: 'InterRegular'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  }
}