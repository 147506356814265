import React, { useState, useEffect } from "react"
import logo from "../../assets/images/GetBee-Logo.png";
import cart from "../../assets/images/cart.png";
import cartGreen from "../../assets/images/cart-green.png";
import send from "../../assets/images/send.png";
import checkWhite from "../../assets/images/check-white.png";
import vector from "../../assets/images/vector.png";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader";
import NoResults from "./NoResults";
import { goToSelectedStep, sendPaymentLink, } from "../../redux/action/shopping";
import CartItem from "./CartItem";
import useSize from "../../utils/useSize";
import MyTimer from "../../components/Common/MyTimer";
import messages from "constants/messages";
import apiName from "constants/apiName";
import { defaultInput as DefaultInput } from "../../components/Common/Input";
import { Validate as validate } from "utils/validate_wrapper";

const CartScreen = () => {
    const dispatch = useDispatch()
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);
    const loading = useSelector(state => state.shoppingReducer.loading)
    const [sendToClient, setSendToClient] = useState(false)
    const cartItems = useSelector(state => state.cartReducer.cartItems)
    const recommendedProducts = useSelector(state => state.shoppingReducer.recommendation)
    const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus)
    const connection = useSelector(state => state.shoppingReducer.shoppingConnection)
    const url = new URL(window.location.href);
    const tenantInfo = useSelector(state => state.tenantInfoR.value)
    const [buyerEmail, setBuyerEmail] = useState({
        state: false,
        mail: sessionStatus?.clientInfo?.emailAutoGenerated ? '' : sessionStatus?.clientInfo?.email,
        err: ''
    })
    const extractUrlValue = (key, url) => {
        if (typeof (url) === 'undefined')
            url = window.location.href;
        var match = url.match('[?&]' + key + '=([^&]+)');
        return match ? match[1] : null;
    }
    let sessionId = extractUrlValue("session_id", url.sessionId)
    const [total, setTotal] = useState(0);
    useEffect(() => {
        if (cartItems && cartItems.length > 0) {
            calculateTotal(cartItems);
        }
    }, [cartItems])
    const askToProceedToCheckout = async () => {
        try {
            if (connection && Object.keys(sessionStatus).length > 0) {
                const payload = {
                    sessionId: sessionId,
                    tenant: sessionStatus.tenant,
                }
                const result = await connection.invoke("AskClientProceedToCheckout", payload)
                console.log(result)
                if (result.isSucceeded) {
                    setSendToClient(true)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
    const calculateTotal = cartDetail => {
        const totalPrice = cartDetail.reduce((prev, item) => {
            if (item && item.selectedVariant) {
                return prev + item.quantity * item.selectedVariant[0].price;
            } else {
                return prev + item.quantity * item.variants[0].price;
            }
        }, 0);
        setTotal(totalPrice)
    };
    const checkValidation = () => {
        var emailErr = validate(contents, 'email', buyerEmail.mail);
        if (emailErr) {
            setBuyerEmail({ ...buyerEmail, err: emailErr })
            return false
        }
        return true
    }
    const sendEmail = () => {
        if (!checkValidation()) {
            return;
        }
        let payload = {
            sessionId: sessionId,
            email: buyerEmail.mail
        }
        setBuyerEmail({ ...buyerEmail, state: false })
        dispatch(sendPaymentLink(apiName.baseApi, payload, contents))
    };
    const screen = useSize()
    return (
        <section className="s-home  relative h-full w-full mx-auto flex items-center flex-col s-card">
            {/* TOP PART */}
            {/* SEARCH PART */}
            {screen.size === "sm" ? <div className="flex items-center  z-50 pl-2 pr-2 pt-4 pb-4 w-full justify-between bg-white">
                <div className="flex items-center">
                    <div className="s-video__liveDot" />
                    <span className="session-time">{Object.keys(sessionStatus).length > 0 && sessionStatus.startTime && <MyTimer expiryTimestamp={sessionStatus.startTime} />}</span>
                </div>
                <div>
                    <img style={{
                        height: 'auto',
                        maxHeight: '24px',
                        width: 'auto',
                        maxWidth: '120px',
                    }} src={tenantInfo && tenantInfo.data.logoUrl ? tenantInfo.data.logoUrl : logo} alt="getbee-logo" />
                </div>
                <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(3))}>
                    <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span>
                </div>
            </div>
                : <div className="flex justify-between items-center z-50 w-11/12 mx-auto mt-8 border-b s-product-view__topBar">
                    <button class="relative  s-home__input flex items-center" onClick={() => dispatch(goToSelectedStep(0))}>
                        <img src={vector} alt="search icon" />
                        <p className="text-black ml-2">{contents?.btnBack ?? messages?.btnBack}</p>
                    </button>
                    <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(3))}>
                        <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
                </div>}
            {/* CAROUSEL PART */}
            
            {cartItems && cartItems.length > 0 && <>
                <div className="w-11/12 mx-auto relative flex flex-col items-center mt-8 ">
                    <div className="s-cart__price flex justify-between items-end">
                        <div>
                            <p className="text-black s-product-view__header">{sessionStatus && sessionStatus.clientInfo && (sessionStatus.clientInfo.name ? sessionStatus.clientInfo.name : (contents?.client ?? messages?.client))}'S CART</p>
                            <h1 className="font-bold">{Object.keys(sessionStatus).length > 0 && sessionStatus.storeInfo && sessionStatus.storeInfo.currencyInfo && sessionStatus.storeInfo.currencyInfo.currencySymbol} {total}</h1>
                        </div>
                        <p>{contents?.excludesTaxesDelivery ?? messages?.excludesTaxesDelivery}</p>
                    </div>
                    <div className="mt-4 s-cart__btnContainer flex-col w-full flex items-center">
                        {sendToClient ? <button className={`s-cart__btn--black s-cart__btn justify-center opacity-50 flex items-center`}>
                            <span>{contents?.checkoutReqSent ?? messages?.checkoutReqSent}</span> <img src={checkWhite} alt="check" />
                        </button> : <button className="s-cart__btn--black s-cart__btn" onClick={() => askToProceedToCheckout()}>
                            {contents?.askToCheckout ?? messages?.askToCheckout}
                        </button>}
                        {!buyerEmail.state && <button className="s-cart__btn--transparent s-cart__btn"
                            onClick={() => setBuyerEmail({ ...buyerEmail, state: true })}>
                            <span>{contents?.sendByMail ?? messages?.sendByMail}</span><img src={send} alt="send icon" />
                        </button>}
                    </div>
                    {buyerEmail.state && <div className="flex flex-row pt-3 md:pt-8 justify-space-between w-100" method="post" >
                        <DefaultInput
                            placeholder={contents?.emailAddress ?? messages?.emailAddress}
                            type={"text"}
                            name={"buyerEmail"}
                            maxLength={100}
                            value={buyerEmail.mail}
                            className={"justify-center form-input-pad-10"}
                            onChange={(e) => {
                                let emailErr = validate(contents, 'email', e.target.value)
                                setBuyerEmail({ ...buyerEmail, err: emailErr, mail: e.target.value })
                            }}
                            error={buyerEmail.err}
                        />
                        <button style={{ padding: '7px' }} className="s-cart__btn--black" onClick={() => sendEmail()}> <p>{contents?.send ?? messages?.send}</p></button>
                        <button style={{ padding: '7px' }} className="s-cart__btn--white" onClick={() => setBuyerEmail({ ...buyerEmail, state: false })}> <p>{contents?.btnCancel ?? messages?.btnCancel}</p></button>
                    </div>}
                </div>
            </>}
            {/* CARDS PART */}
            <section className="w-11/12 mx-auto mt-2 ">
                <div className="mt-6 grid grid-cols-1   sm:grid-cols-1 md:grid-cols-1 ">
                    {!loading ?
                        cartItems && cartItems.length > 0 ? cartItems.map((product) => (
                            <CartItem recommendedProducts={recommendedProducts} product={product} />
                        )) : <NoResults />
                        : <Loader />}
                </div>
                {screen.size === "sm" && <div className="flex items-center  z-50 pl-2 pr-2 pt-4 pb-4 w-full justify-between bg-white">
                    <button class="relative  s-home__floatingBtn flex items-center" onClick={() => dispatch(goToSelectedStep(0))}>
                        <img src={vector} alt="search icon" />
                        <p className="text-black ml-2">{contents?.btnBack ?? messages?.btnBack}</p>
                    </button>
                </div>}
            </section>
        </section>
    )
}
export default CartScreen