import messages from "constants/messages";
import { showToast } from "./Common";

export const copyLink = (contents, profileUrl) => {
  console.log(profileUrl);
  navigator.clipboard.writeText(profileUrl);
  showToast(
    contents?.urlCopy ?? messages?.urlCopy ?? "URL copied to clipboard",
    "info"
  );
};
