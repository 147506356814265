//schedule-call-with-specific-expert
import * as signalR from "@microsoft/signalr";
import callVideoIcon from "assets/svg/call-icon.svg";
import { showToast } from "components/Common/Common";
import { copyLink } from "components/Common/copyLink";
import CountrySelect from "components/Common/CountrySelect";
import Translate from "components/Common/Translate";
import udid from "components/Common/udid";
import InvalidFeedback from "components/ErrorHandler/InvalidFeedback";
import { startCallFormRegrex } from "components/ErrorHandler/regrex";
import { validateFormAtOnce } from "components/ErrorHandler/validateFormAtOnce";
import validateErrors from "components/ErrorHandler/ValidateFrom";
import { componentsName } from "config/client-api-status";
import { localStorageClientLanguageKey } from "config/Config";
import connectToSignalR from "config/connectToSignalR";
import { signalRResponseStatus } from "config/events/SinglalRResponseStatus";
import { typeOfCalls } from "config/TabConfig";
import messages from "constants/messages";
import useFetchExpertsForClient from "hooks/fetchExpertsForClient";
import useFetchClientAppSettings from "hooks/useFetchClientAppSettings";
import { addInvalidClass } from "pureFunctions/addInvalidClass";
import { getRequiredFields } from "pureFunctions/getRequiredFields";
import { isAllFormElementDisabled } from "pureFunctions/isAllFormElementDisabled";
import { isJsonString } from "pureFunctions/isJSONString";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import callFetchSingalRConnectionToken from "redux/call-action/callFetchSingalRConnectionToken";
import { ERROR } from "redux/constants/status";
import RequestCallScreen from "views/client/RequestCallScreen";
import "views/client/ScheduleCallWithSpecificExpert/schedule-call-with-specific-expert.scss";
import ExpertAvatarName from "views/client/SelectDateToScheduleCall/ExpertAvatarName";
import { clientButtonColor } from "../common/clientButtonColor";
import SchedulingTemplate from "../common/SchedulingTemplate";


const randomEmail = `anonymous${udid().replace(/-/g, "")}@getbee.com`;

const Main = ({
  experts,
  connectionToken,
  connectionStatus,
  contents,
  clientAppSetting,
  setRequestingToMakeCall,
  requestingToMakeCall,
  setSingleRConnection,
  setSessionId,
  allFormDisabled,
  setFormData,
  formData,
  setCallCancelled,
  selectedExpert
}) => {
  const dispatch = useDispatch();
 
  const { email, tenant } = useParams();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();

  useLayoutEffect(() => {
    const autoRequestToken = async () => {
      if (allFormDisabled) {
      
        const body = {
          name: "Someone",
          email: randomEmail,
          phone: null,
          emailAutoGenerated: true,
          tenant,
          id: udid,
          userId: randomEmail,
        };

        setFormData(body);
        await dispatch(callFetchSingalRConnectionToken(body));
        setIsFormSubmitted(true);
      }
    };
    autoRequestToken();
  }, [allFormDisabled, dispatch, tenant, setFormData]);

  const onChangeHandler = useCallback(
    (e) => {
      validateErrors(
        formData,
        setFormData,
        setFormErrors,
        formErrors,
        e,
        startCallFormRegrex,
        "personalInfo"
      );
    },
    [formData, setFormData, setFormErrors, formErrors]
  );

  const isEnabled = useCallback(
    (field) => {
      return clientAppSetting?.basicFieldsSettings?.[field]?.showField;
    },
    [clientAppSetting]
  );

  const submitFormHandler = useCallback(async () => {
    if (allFormDisabled) {
      return;
    }

    // Get required fileds
    const requiredFileds = getRequiredFields(
      clientAppSetting?.basicFieldsSettings
    );

    const errors = await validateFormAtOnce(
      formData,
      startCallFormRegrex,
      requiredFileds
    );

    if (Object.entries(errors).length !== 0) {
      setFormErrors(errors);
      return;
    }
    const body = {
      ...formData,
      emailAutoGenerated: false,
      tenant,
      id: udid(),
      userId: formData.email,
    };
    // Send the request to get the token
    await dispatch(callFetchSingalRConnectionToken(body));
    setIsFormSubmitted(true);
  }, [
    allFormDisabled,
    dispatch,
    formData,
    tenant,
    clientAppSetting?.basicFieldsSettings,
  ]);

  useEffect(() => {
    const connectWithSignalR = async () => {
      if (!connectionToken) return;

      if (connectionStatus === ERROR) {
        showToast(connectionToken, "error");
      }

      try {
        const connection = await connectToSignalR(connectionToken);
        await connection.start();
        setSingleRConnection(connection);

        var phone;
        if(formData.phone !== undefined) {
          phone = `+${formData.countryCode} ${formData.phone}`;
        }

        const payload = {
          email: formData.email,
          emailAutoGenerated: false,
          phoneNumber: phone,
          name: formData.name,
          tenant: tenant,
          expert: window.atob(email),
          id: udid(),
        };

        let result = await connection.invoke("CallExpert", payload);

        if (result?.isSucceeded) {
          setRequestingToMakeCall(true);
          setSessionId(result.sessionId);
        }

        if (!result?.isSucceeded) {
          setIsFormSubmitted(false);
          const getStatusKey = signalRResponseStatus()[result.status];
          showToast(
            contents?.[getStatusKey] ?? messages?.[getStatusKey],
            "error"
          );
          return;
        }

        connection.on("CallStarted", (message) => {
          localStorage.setItem("userRole", "client");
          localStorage.setItem("tenant", message.tenant);
          localStorage.setItem("signalRClient", connectionToken);
          localStorage.setItem("clientToken", connectionToken);
          localStorage.setItem("loggingClient", signalR.LogLevel.Information);
          const getItem = isJsonString(
            localStorage.getItem(localStorageClientLanguageKey)
          );
          const path = `/${message.tenant}/video-call`;
          var searchparam = `?session_id=${message.sessionId}&lng=${getItem}`;
          window.location.href = `${window.location.origin}${path}${searchparam}`;
        });

        connection.on("CallCancelled", (message) => {
          setCallCancelled(true);
        });
      } catch (err) {
        console.error(err);
      }
    };

    if (isFormSubmitted) {
      connectWithSignalR();
    }
  }, [
    connectionToken,
    email,
    connectionStatus,
    tenant,
    formData,
    setRequestingToMakeCall,
    setSessionId,
    setSingleRConnection,
    isFormSubmitted,
    setCallCancelled,
    contents
  ]);

  const shareProfileHandler = () => {
    const url = `${window.location.host}/${tenant}/expert-profile/${email}`;
    copyLink(contents, url);
  };

  const onCancelHandler = () => {
    history.push(`/${tenant}/client-start-consultation`);
  };

  return (
    <>
      <section className="expert-intro">
        <ExpertAvatarName
          {...selectedExpert}
          shareProfileHandler={shareProfileHandler}
          onCancelHandler={onCancelHandler}
          contents={contents}
        />
        {!allFormDisabled && (
          <>
            <div className="row">
              <form className="schedule-call">
                {isEnabled("name") && (
                  <div className="form-group flex-col">
                    <label for="name">{contents?.name ?? messages?.name}</label>
                    <input
                      name="name"
                      type="text"
                      id="name"
                      // placeholder= {contents?.name ?? messages?.name}
                      onChange={onChangeHandler}
                      value={formData.name || ""}
                      className={addInvalidClass(formErrors?.name)}
                    />
                    {formErrors?.name && (
                      <InvalidFeedback
                        message={
                          <Translate
                            translatedContent={contents?.enterFirstName}
                            fallbacktranslatedContent="Please enter valid first name"
                          />
                        }
                      />
                    )}
                  </div>
                )}

                {isEnabled("email") && (
                  <div className="form-group flex-col">
                    <label for="email">
                      {contents?.mail ?? messages?.mail}
                    </label>
                    <input
                      name="email"
                      type="email"
                      id="email"
                      // placeholder={contents?.mail ?? messages?.mail}
                      onChange={onChangeHandler}
                      value={formData.email || ""}
                      className={addInvalidClass(formErrors?.email)}
                    />
                    {formErrors?.email && (
                      <InvalidFeedback
                        message={
                          <Translate
                            translatedContent={
                              contents?.vEmail || messages?.vEmail
                            }
                            fallbacktranslatedContent="Please enter valid email"
                          />
                        }
                      />
                    )}
                  </div>
                )}

                {isEnabled("phone") && (
                  <div className="form-group">
                    <div className="col country-code">
                      <CountrySelect
                        formData={formData}
                        setFormData={setFormData}
                        props="phone"
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                      />
                      {formErrors?.countryCode && (
                        <InvalidFeedback
                          message={
                            <Translate
                              translatedContent={
                                contents?.selectPhoneCode ||
                                messages?.selectPhoneCode
                              }
                              fallbacktranslatedContent="Please select valid phone code"
                            />
                          }
                        />
                      )}
                    </div>
                    <div className="col phone">
                      <input
                        name="phone"
                        type="phone"
                        id="phone"
                        placeholder={
                          contents?.phoneNumber ?? messages?.phoneNumber
                        }
                        onChange={onChangeHandler}
                        value={formData.phone || ""}
                        className={addInvalidClass(formErrors?.phone)}
                      />
                      {formErrors?.phone && (
                        <InvalidFeedback
                          message={
                            <Translate
                              translatedContent={contents?.incorrectPhoneNumber}
                              fallbacktranslatedContent="Please enter valid phone number"
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              </form>
            </div>
            <div className="row">
              <div className="actions">
                <button
                  className="btn btn--primary schedule-btn"
                  onClick={submitFormHandler}
                  style={{
                    backgroundColor: clientButtonColor(clientAppSetting),
                  }}
                >
                  <span>
                    <img src={callVideoIcon} alt="" />
                  </span>
                  <span>{contents?.startCall ?? messages?.startCall}</span>
                </button>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

const SpeakWithSpecificExpert = (props) => {
  const { tenant } = useParams();

  const {
    singalRConnectionToken: {
      STATUS: connectionStatus,
      value: { connectionToken },
    },
    tenantExperts: { value, STATUS },
    preferedLanguageContent: { contents },
    tenantClientAppSettings,
    tenantClientAppTextSettings,
  } = useSelector((state) => state);

  const { value: clientAppSetting } = tenantClientAppSettings;
  const { value: tenantClientAppTextSetting } = tenantClientAppTextSettings;

  useFetchClientAppSettings({
    tenant,
    clientAppSetting,
  });

  const [requestingToMakeCall, setRequestingToMakeCall] = useState(false);
  const [callCancelled, setCallCancelled] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [singleRConnection, setSingleRConnection] = useState(null);
  const [callOptions, setCallOptions] = useState({
    audio: true,
    video: true,
    endCall: false,
  });

  const isEnabled = useCallback(
    (field) => {
      return clientAppSetting?.basicFieldsSettings?.[field]?.showField;
    },
    [clientAppSetting]
  );

  const isEmailFieldEnabled = useMemo(() => {
    return !isEnabled("email")
      ? `anonymous${udid().replace(/-/g, "")}@getbee.com`
      : "";
  }, [isEnabled]);

  const [formData, setFormData] = useState({
    email: isEmailFieldEnabled,
    countryCode: "1",
  });

  // If router is refereshed then there will be no experts is fetched
  // Therere run the fetch tenants experts as well
  useFetchExpertsForClient({
    tenant,
    experts: value,
  });

  const allFormDisabled = useMemo(() => {
    return isAllFormElementDisabled(clientAppSetting?.basicFieldsSettings);
  }, [clientAppSetting?.basicFieldsSettings]);

  const { experts } = value || {};
  

  let mainProps = { experts, connectionToken, connectionStatus, contents };

  const [selectedExpert, setSelectedExpert] = useState({});
  const { email } = useParams();
  useLayoutEffect(() => {
    if (!experts) {
      return;
    }
    setSelectedExpert(
      ...experts.filter((expert) => expert.email === window.atob(email))
    );
  }, [experts, email]);

  return (
    <>
      {!requestingToMakeCall && (
        <SchedulingTemplate
          main={
            <Main
              {...mainProps}
              clientAppSetting={clientAppSetting}
              setRequestingToMakeCall={setRequestingToMakeCall}
              requestingToMakeCall={requestingToMakeCall}
              setCallCancelled={setCallCancelled}
              callCancelled={callCancelled}
              setSessionId={setSessionId}
              sessionId={sessionId}
              setSingleRConnection={setSingleRConnection}
              singleRConnection={singleRConnection}
              allFormDisabled={allFormDisabled}
              setFormData={setFormData}
              formData={formData}
              selectedExpert={selectedExpert}
            />
          }
          clientAppSetting={clientAppSetting}
          contents={contents}
          backButton={true}
          rowClass="height-100vh"
          from={componentsName.speakWithSpecificExpert}
          tenantClientAppTextSetting={tenantClientAppTextSetting}
        ></SchedulingTemplate>
      )}

      {requestingToMakeCall && (
        <RequestCallScreen
          email={formData?.email || "bharatrose1@gmail.com"}
          tenant={tenant}
          sessionId={sessionId}
          connection={singleRConnection}
          callOptions={callOptions}
          setCallOptions={setCallOptions}
          callCancelled={callCancelled}
          typeOfCall={typeOfCalls.direct}
          
        />
      )}
    </>
  );
};

SpeakWithSpecificExpert.propTypes = {};

export default SpeakWithSpecificExpert;
