import React, { useState, useEffect, useMemo, useRef } from "react";
import Images from "../../constants/Images";
import createWebUrlA from "../../redux/action/admin/createWebUrlA";
import { connect, useSelector } from 'react-redux';
import defaultA from "../../redux/action/defaultA";
import { SUCCESS, ERROR, LOADING, NONE, } from '../../redux/constants/reduxConstant';
import { errorHandle } from "../../components/Common/errorHandle";
import ImageUpload from "../../components/Common/ImageUpload";
import Loader from "../../components/Common/loader";
import { showToast, showToast as ShowToast } from "../../components/Common/Common";
import { ToastContainer } from 'react-toastify';
import messages from "../../constants/messages";
import { DefaultInputForm, DefaultDropDownForm } from "../../components/Common/Input";
import { Validate as validate} from "../../utils/validate_wrapper";
import userInfoA from "../../redux/action/common/userInfoA";
import imgUploadA from "../../redux/action/common/imgUploadA";
import industryA from "../../redux/action/common/industryA";

const Company = (props) => {
    const inputRef = useRef(null)
    let randomKey = Math.random().toString(36)
    const { preferedLanguageContent: { contents, countriesList } } = useSelector((state) => state);
    
    const [state, setState] = useState({
        loading: false,
        userInfo: "",
        firstName: '',
        lastName: '',
        email: '',
        profile_image: '',
        role: '',
        selectedCountry: [],
        selectedLanguage: [],
        showModal: false,
        rangeValue: [1],
        isEdit: true,
        isTeam: true,

        industryArr: [],
        selectedIndustry: '',
        empNoArr: [
            { value: '1-10', label: '1-10' },
            { value: '11-50', label: '11-50' },
            { value: '51-100', label: '51-100' },
            { value: '101-200', label: '101-200' },
            { value: '201-500', label: '201-500' },
            { value: '501-1000', label: '501-1000' },
            { value: '1000-5000', label: '1000-5000' },
            { value: '5001-10000', label: '5001-10000' },
            { value: '10000+', label: '10000+' },
        ],
        selectedEmpNo: '',
        rangeValue: [1],
        showModal: false,
    });

    useEffect(() => {
        const createWebUrlPath = props.ScreenProps.createWebUrlR;
        const industryPath = props.ScreenProps.industryInR;

        if (industryPath.status == LOADING) {
            // setState({ ...state, loading: true });
        }
        else if (industryPath.status == SUCCESS) {
            console.log('industrypath success res ', industryPath);
            // debugger
            let temp = industryPath.value.data.result
            temp.map(i => {
                i.label = i.value
            })
            setState({ ...state, loading: false, industryArr: temp });
            props.defaultRequest();
        }
        else if (industryPath.status == ERROR) {
            console.log('industrypath error res ', industryPath);
            setState({ ...state, loading: false });
            props.defaultRequest();
        }
        else if (createWebUrlPath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (createWebUrlPath.status == SUCCESS) {
            console.log('Update Company Details ', createWebUrlPath);
            setState({ ...state, loading: false });
            showToast(createWebUrlPath.value.data.status, 'success');
        }
        else if (createWebUrlPath.status == ERROR) {
            console.log('Update Company Details ', createWebUrlPath);
            setState({ ...state, loading: false });
            errorHandle(createWebUrlPath, contents)
            props.defaultRequest();
        }
    }, [props.ScreenProps]);

    useEffect(() => {
        props.industryRequest({ from: 'company' })
    }, []);
    const [options, setOptions] = useState([])
    useEffect(() => {
        if(countriesList && Object.keys(countriesList).length > 0)
            setOptions(Object.keys(countriesList).map(x => ({ value: x, label: countriesList[x] })))
    }, [countriesList])
    useEffect(() => {
        let temp = props.ScreenProps.tenantInfoR && props.ScreenProps.tenantInfoR.value && props.ScreenProps.tenantInfoR.value.data
        if (temp != state.tenantInfo && temp != undefined) {
            console.log('tenant data is ', temp)

            let noEmployees = { label: temp.numberOfEmployees, value: temp.numberOfEmployees }
            let industry = { label: temp.industry, value: temp.industry }
            let location = []
            .map(i => {
                if (i.value == temp.country) {
                    location.push(i)
                }
            })

            setState({
                ...state,
                tenantInfo: temp,
                companyName: temp.name,
                companyUrl: temp.uniqueIdentifier,
                selectedEmpNo: noEmployees,
                selectedIndustry: industry,
                selectedCountry: location.length > 0 ? location[0] : [],
                logo: temp.logoUrl,
            })
        }
    });

    const chooseFile = () => {
        inputRef.current.click();
    }

    // validate form data
    const checkValidation = () => {
        var companyUrlErr = validate(contents, 'companyUrl', state.companyUrl);

        if (companyUrlErr) {
            return false;
        } else {
            return true;
        }
    };

    // clean error state
    const clearError = () => {
        setState({
            ...state,
            firstNameErr: '',
            lastNameErr: '',
            countryErr: '',
            languageErr: '',
        })
    }

    const submit = () => {
        clearError()
        if (!checkValidation()) {
            return;
        }
        props.createWebUrlRequest({
            from: 'updateCompanyProfile',
            tenantName: state.companyName,
            uniqueIdentifier: state.companyUrl,
            industry: state.selectedIndustry.value,
            country: state.selectedCountry.value,
            numberOfEmployees: state.selectedEmpNo.value,
            logoFile: state.logo,
        })
    };

    return (
        <>
            <ToastContainer />
            <>
                <div className="dashboard-right-menu expert-profile-res">
                    <div className="container-fluid">
                        <div className="row expert-profile-res-blk">
                            <div className="col-md-8">
                                <div className="expert-dashboard-profile">
                                    <div className="expert-db-profile-head">
                                        <h4>{messages.companyDetails}</h4>
                                        <span className="file-action"><i className="ri-checkbox-circle-fill"></i> {messages.changesSaved}</span>
                                    </div>
                                    <p>{messages.companyPictureCaution}</p>
                                    <form className="mb-5" >
                                        <DefaultInputForm
                                            label={messages.lblCompanyName}
                                            placeholder={messages.lblCompanyName}
                                            type={"text"}
                                            name={"companyName"}
                                            maxLength={200}
                                            value={state.companyName}
                                            onChange={(e) => setState({ ...state, companyName: e.target.value ? e.target.value.trimLeft() : "", companyErr: validate(contents, 'companyName', e.target.value) })}
                                            error={state.companyErr} />
                                        <DefaultDropDownForm
                                            label={messages.lblIndustry}
                                            placeholder={messages.lblIndustry}
                                            name="Industry"
                                            options={state.industryArr}
                                            value={state.selectedIndustry}
                                            onChange={(value) => setState({ ...state, selectedIndustry: value, industryErr: validate(contents, 'blank', value) })}
                                            error={state.industryErr} />
                                        <DefaultDropDownForm
                                            label={messages.lblEmployeesCount}
                                            placeholder={messages.lblEmployeesCount}
                                            name="empNo"
                                            options={state.empNoArr}
                                            value={state.selectedEmpNo}
                                            onChange={(value) => setState({ ...state, selectedEmpNo: value, empNoErr: validate(contents, 'blank', value) })}
                                            error={state.empNoErr} />
                                        <DefaultDropDownForm
                                            label={messages.lblCountry}
                                            placeholder={messages.lblCountry}
                                            name="country"
                                            options={options}
                                            value={state.selectedCountry}
                                            onChange={(value) => setState({ ...state, selectedCountry: value, countryErr: validate(contents, 'blank', value) })}
                                            error={state.countryErr} />
                                        <DefaultInputForm
                                            label={messages.lblUniqueUrl}
                                            placeholder={messages.lblUniqueUrl}
                                            name={"companyUrl"}
                                            maxLength={100}
                                            disabled={true}
                                            value={state.companyUrl}
                                            onChange={(e) => setState({ ...state, companyUrl: e.target.value ? e.target.value.trimRight() : '', companyUrlErr: validate(contents, 'companyUrl', e.target.value) })}
                                            error={state.companyUrlErr} />
                                            <span>{`${window.location.origin}/${state.companyUrl}`}</span>
                                    </form>
                                    <div className="btn-group">
                                    <button className="btn btn-primary btn-lg d-block btn-min" onClick={submit}>Save</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="expert-profile-update-blk">
                                    <h4>{messages.changeCompanyLogo}</h4>
                                    <div className={state.logo ? "expert-profile submitted-profile" : "expert-profile"}>
                                        <figure className={state.logo ? 'company-logo-size-medium' : ''}>
                                            <img src={(state.logo == null || state.logo == '') ? Images.dummy : state.logo} />
                                        </figure>
                                        <button onClick={chooseFile} className="btn edit-btn waves-effect waves-light">
                                            <label ><i className="fas fa-camera"></i></label>
                                            <input
                                                onChange={e => {
                                                    console.log('file of image is ', e)
                                                    setState({ ...state, image: e.target.files[0], showModal: true })
                                                }}
                                                id="select-file"
                                                key={randomKey}
                                                type="file"
                                                accept="image/*"
                                                ref={inputRef}
                                            />
                                        </button>
                                    </div>

                                    <p>{messages.companyLogoDes}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
            {state.showModal && <ImageUpload
                image={state.image}
                avatarProps={{
                    image: state.image,
                    width: 150,
                    height: 50,
                    border: 0,
                    color: [255, 255, 255, 0.6],
                    rotate: 0,
                    borderRadius: 0,
                }}
                from="dashboard7"
                requestProp="imgUploadR"
                closeUploadModal={() => setState({ ...state, showModal: false })}
                onImageUploadSuccess={(logo) => {
                    setState({ ...state, loading: false, logo, showModal: false })
                }}
                onImageUploadError={() => setState({ ...state, loading: false })}
                onImageUploadLoad={() => setState({ ...state, loading: true })}
            />}
            
            {state.loading && <Loader />}
        </>
    )
};


const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        industryRequest: ({ from }) => {
            dispatch(industryA({ from }));
        },
        createWebUrlRequest: ({ from, tenantName, uniqueIdentifier, industry, country, numberOfEmployees, logoFile, code }) => {
            dispatch(createWebUrlA({ from, tenantName, uniqueIdentifier, industry, country, numberOfEmployees, logoFile, code }));
        },
        userInfoRequest: ({ from, tenant }) => {
            dispatch(userInfoA({ from, tenant }));
        },
        imgUploadRequest: ({ from, image }) => {
            dispatch(imgUploadA({ from, image }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);