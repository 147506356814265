import { post } from '../../utils/Api';
import axios from 'axios';
import { resendEmailLoading, resendEmailSuccess, resendEmailError } from '../constants/reduxConstant';
import apiName from '../../constants/apiName';
import udid from '../../components/Common/udid';

const apiLoading = () => ({ type: resendEmailLoading });
const apiSucceed = (payload) => ({ type: resendEmailSuccess, payload });
const apiError = (payload) => ({ type: resendEmailError, payload });

const resendEmailA = ({ from, email }) => async (dispatchEvent, getState) => {
    let body = {
        email: email,
        id: udid()
    };
    if (from == 'emailSent') {
        dispatchEvent(apiLoading());
        try {
            post(apiName.resendEmail, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    };
}

export default resendEmailA