import { camelToSnakeCase } from "pureFunctions/camelToSnakeCase";

const profileRegrex = {
  firstName: /^.{1,150}$/,
  lastName: /^.{1,150}$/,
  location: /^.{1,150}$/,
  description: /^.{0,1000}$/,
  role: /^.{0,1000}$/
};

export const messageTypes = (name) => {
  return {
    personalInfo: `Please enter valid ${camelToSnakeCase(name.replace(/_/g, " "))}`,
    security: `${name.replace(
      /_/g,
      " "
    )} must have 8-20 characters including uppercase, lowercase, number, special symbol combination`,
  };
};

const passwordsAreNotIdentical = (control, formData) => {
  return (control.name === "newPasswordConfirmation" && control.value !== formData.newPassword) ||
         (control.name === "newPassword" && formData.newPasswordConfirmation && control.value !== formData.newPasswordConfirmation)
}

const validateErrors = (
  formData,
  setFormData,
  setFormErrors,
  formErros,
  e,
  regrex = profileRegrex,
  type
) => {
  const { name, value } = e.target

  let tmbFormErrors = formErros
  
  // for the new password, we are just checking if it's identical with the original one
  if (name !== "newPasswordConfirmation")
  if (!regrex[name].test(value)) {
    tmbFormErrors = { ...tmbFormErrors, [name]: messageTypes(name)[type] }
  } else {
    delete tmbFormErrors[name];
  }
  
  if(passwordsAreNotIdentical(e.target, formData))
    tmbFormErrors = { ...tmbFormErrors, newPasswordConfirmation: "Both password did not matched" }
  else
    delete tmbFormErrors["newPasswordConfirmation"];

  setFormErrors(tmbFormErrors)

  return setFormData({ ...formData, [name]: value });
};

export default validateErrors;
