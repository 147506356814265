import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const option = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  
};
export const showToast = (text, type) => {
  if (type === "success") toast.success(text, option);
  else if (type === "info") toast.info(text, option);
  else if (type === "warn") toast.warn(text, option);
  else if (type === "error") toast.error(text, option);
  else toast.error(text, option);
};
const errorText = (props) => {
  return (
    <>
      <div>
        <p className={props.className ? props.className : "errorText"}>
          {props.title}
        </p>
      </div>
    </>
  );
};
export default errorText;
