import {
  fetchTenantClientAppSettins,
  fetchTenantClientAppSettinsLoading,
  fetchTenantClientAppSettinsError,
} from "redux/constants/reduxConstant";

/**
 * @param  {} payload
 * @param  {fetchTenantAppSettins} =>{return{type
 * @returns fetchTenantAppSettins
 
 * /** @typedef payload json
 * @property {null} title
 * @property {null} buttonText
 * @property {null} descriptionHTML
 * @property {string} buttonColour
 * @property {string} logo
 * @property {string} image
 * @property {object} basicFieldsSettings
 * @property {object} basicFieldsSettings.name
 * @property {boolean} basicFieldsSettings.name.showField
 * @property {boolean} basicFieldsSettings.name.isRequired
 * @property {object} basicFieldsSettings.email
 * @property {boolean} basicFieldsSettings.email.showField
 * @property {boolean} basicFieldsSettings.email.isRequired
 * @property {object} basicFieldsSettings.phone
 * @property {boolean} basicFieldsSettings.phone.showField
 * @property {boolean} basicFieldsSettings.phone.isRequired
 * @property {string} uniqueIdentifier
 * @property {string} name
 * @property {string} country
 * @property {string} industry
 * @property {string} numberOfEmployees
 * @property {string} url
 * @property {string} logoUrl
 * @property {number} numberOfOnlineExperts
 * @property {number} numberOfAvailableExperts
 * @property {string} storeType
 * @property {string} googleAnalyticsKey
 * @property {string[]} avialableClientLanguages
 * @property {string} clientDefaultLanguage
 * @property {boolean} isSucceeded
 * @property {string} status
 * @property {null} error
 */

export const fetchTenantClientAppSettinsAction = (payload) => {
  return {
    type: fetchTenantClientAppSettins,
    payload,
  };
};

/**
 * @param  {fetchTenantClientAppSettinsLoading}}} =>{return{type
 * @returns fetchTenantClientAppSettinsLoading
 */
export const fetchTenantClientAppSettinsLoadingAction = () => {
  return {
    type: fetchTenantClientAppSettinsLoading,
  };
};
/**
 * @param  {} payload
 * @param  {fetchTenantClientAppSettinsError} =>{return{type
 * @returns fetchTenantClientAppSettinsError
 */
export const fetchTenantClientAppSettinsErrorAction = (payload) => {
  return {
    type: fetchTenantClientAppSettinsError,
    payload,
  };
};
