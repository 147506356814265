import * as signalR from "@microsoft/signalr";
import AgoraRTC, { AgoraVideoPlayer } from "agora-rtc-react";
import ErrorBoundry from "components/ErrorHandler/ErrorBoundry";
import Toast from "light-toast";
import React, { useEffect, useMemo, useRef, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorText from "../../components/Common/Common";
import Controls from "../../components/Common/Controls";
import apiName from "../../constants/apiName";
import Images from "../../constants/Images";
import {
  addToCart,
  cleanShoppingReducer,
  getAgoraUser,
  getSessionStatus,
  goToSelectedStep,
  handleShowBuyerProducts,
  handleShowCatalog,
  requestConnectionToken,
  sendInvitationSession,
} from "../../redux/action/shopping";
import { extractUrlValue, isUserLoggedIn } from "../../utils/utils";
import { Validate as validate } from "../../utils/validate_wrapper";
import catalogIcon from "./../../assets/images/Catalog.png";
import check from "./../../assets/images/check.png";
import iconClose from "./../../assets/images/icon-close.svg";
import iconSetting from "./../../assets/images/icon-setting.svg";
import ellipseLoader from "./../../assets/images/Loader.png";
import noImage from "./../../assets/images/no-photo.png";
import userIcon from "./../../assets/images/union.png";
import vectorWhite from "./../../assets/images/vector-white.png";
import CallEndedScreen from "./CallEndedScreens/index";
//chatDeactive.svg
import { sessionTypes } from "components/Config/session";
import ChatModel from "components/TabContents/Meetings/model/chat/ChatModel";
import messages from "constants/messages";
import { useCallback } from "react";
import Webcam from "react-webcam";
import InvitationSidebar from "views/instant-call/InvitationSidebar";
import { showToast } from "../../components/Common/Common";
import MyTimer from "../../components/Common/MyTimer";
import {
  changeProductQuantity,
  clearItemFromCart,
} from "../../redux/action/cartAction";
import {
  addToRecommendation,
  createShopppingConnection,
  removeFromRecommendation,
  switchToVideoScreen,
} from "../../redux/action/shopping";
import tenantInfoA from "../../redux/action/tenantInfoA";
import useSize from "../../utils/useSize";
import BuyerScreen from "../shopping/BuyerScreen";
import ExpertScreen from "../shopping/ExpertScreen";
import { isJsonString } from "pureFunctions/isJSONString";
import { parseJSON } from "date-fns";
import preferedLanguageContent from "redux/reducer/admin/preferedLanguageContent";
import { fetchLanguageAction } from "redux/action/admin/language";
import useLanguage from "hooks/useLanguage";
import {
  localStorageClientLanguageKey,
  newMessageIsAvailableLocalStorage,
} from "config/Config";
import { useParams } from "react-router-dom";
import { Client, Connected, Expert } from "config/signalRStatus";
import { SendChatMessage } from "config/events/listen";
import { request } from "utils/request";
import { getError } from "components/ErrorHandler/getError";
import ParticipantsModel from "components/TabContents/Meetings/model/participants/ParticipantsModel";

//share-active.svg
//stop-sharing.svg

function reducer(state, action) {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        chatHistory: [...state.chatHistory, ...action.payload],
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

const config = { mode: "live", codec: "vp8" };
const appId = "3ec1aa29ffdd4c28b5475c63d95d31cf";
const VideoCall = (props) => {
  const webcamRef = React.useRef(null);
  const sessionStatus = useSelector(
    (state) => state.shoppingReducer.sessionStatus
  );
  const [mediaState, setMediaState] = useState(() => {
    try {
      return (
        JSON.parse(localStorage["mediaState"]) ?? { audio: true, video: false }
      );
    } catch (e) {
      return { audio: true, video: false };
    }
  });
  const [audioChecked, setAudioChecked] = useState(false);
  const [videoChecked, setVideoChecked] = useState(false);
  const tenantInfo = useSelector((state) => state.tenantInfoR.value);
  const url = new URL(window.location.href);
  let sessionId = extractUrlValue("session_id", url.sessionId);
  let inviteUrl = `${window.location.origin}/${sessionStatus.tenant}/client-start-consultation?session_id=${sessionId}`;
  const expertData = useSelector((state) => state.userInfoR);
  const showCatalog = useSelector((state) => state.shoppingReducer.showCatalog);
  const showInvitationScreen = useSelector(
    (state) => state.shoppingReducer.showInvitationScreen
  );
  const showBuyerProducts = useSelector(
    (state) => state.shoppingReducer.showBuyerProducts
  );
  const dispatch = useDispatch();
  const [leaveChannel, setLeaveChannel] = useState(false);
  const [settingModel, setSettingModel] = useState(false);
  const [audioInput, setAudioInput] = useState([]);
  const [audioOutput, setAudioOutput] = useState([]);
  const [videoInput, setVideoInput] = useState([]);
  const [otherUserAudio, setOtherUserAudio] = useState(null);
  const [otherUserVideo, setOtherUserVideo] = useState(null);
  const [otherUserUID, setOtherUserUID] = useState(null);
  const [localSessionState, setLocalSessionState] = useState(null);
  const rtc = useRef({
    client: null,
    getDevicesTrials: 0,
    localAudioTrack: null,
    localVideoTrack: null,
  });
  const localConnection = useRef();
  const [localVideoTrack, setLocalVideoTrack] = useState();
  const [callEnded, setCallEnded] = useState(false);
  const [callAutoCompleted, setAutoCompletedCall] = useState(false);
  const [audioInputStore, setAudioInputStore] = useState("");
  const [videoInputStore, setVideoInputStore] = useState("");
  const [audioOutputStore, setAudioOutputStore] = useState("");
  const connectionToken = useSelector(
    (state) => state.shoppingReducer.connectionToken
  );
  const [noDeviceModal, setNoDeviceModel] = useState(false);
  const [noCameraModal, setNoCameraModal] = useState(false);
  const [joinCompletedCall, setJoinCompletedCall] = useState(false);
  const [pingInterval, setPingInterval] = useState(null);
  const [signalRRestarting, setSignalRRestarting] = useState(false);
  const [shareScreenActive, setShareShreenActive] = useState(false);
  const [showChatMode, setShowChatMode] = useState(false);
  const [showParticipantsMode, setShowParticipantsMode] = useState(false);
  const {
    preferedLanguageContent: { contents },
  } = useSelector((state) => state);
  const getFilteredDevices = (fullList, type) => {
    var filtered = fullList.filter((x) => x.kind == type);
    if (filtered.length == 0) return { filtered: [], defaultVal: null };
    if (localStorage[type] != null) {
      let defaults = filtered.filter((x) => x.deviceId == localStorage[type]);
      if (defaults.length > 0) return { filtered, defaultVal: defaults[0] };
    }
    let defaults = filtered.filter((x) => x.deviceId == "default");
    if (defaults.length > 0) {
      localStorage.setItem(type, defaults[0].deviceId);
      return { filtered, defaultVal: defaults[0] };
    }
    localStorage.setItem(type, filtered[0].deviceId);
    return { filtered, defaultVal: filtered[0] };
  };
  const getDevices = async () => {
    try {
      var devices = await AgoraRTC.getDevices();

      if (devices.length == 0) throw "no devices";
      let { filtered: audioInputDevice, defaultVal: defualtAudioInput } =
        getFilteredDevices(devices, "audioinput");
      setAudioInput(audioInputDevice);
      setAudioInputStore(defualtAudioInput);
      let { filtered: audioOutputDevices, defaultVal: defualtAudioOutput } =
        getFilteredDevices(devices, "audiooutput");
      setAudioOutput(audioOutputDevices);
      setAudioOutputStore(defualtAudioOutput);
      let { filtered: videoInputDevices, defaultVal: defaulVideoInput } =
        getFilteredDevices(devices, "videoinput");
      setVideoInputStore(defaulVideoInput);
      setVideoInput(videoInputDevices);
    } catch (ex) {
      if (rtc.current.getDevicesTrials >= 3) {
        setNoDeviceModel(true);
      } else {
        rtc.current.getDevicesTrials++;
        setTimeout(async () => {
          await getDevices();
        }, 5000);
      }
    }
  };

  useEffect(() => {
    var lockResolver;
    if (navigator) {
      navigator.getUserMedia(
        { audio: true, video: true },
        function (stream) {
          stream.getTracks().forEach((x) => x.stop());
        },
        (err) => console.log(err, "can't get devices ...")
      );
      if (navigator.locks && navigator.locks.request) {
        const promise = new Promise((res) => {
          lockResolver = res;
        });
        navigator.locks.request("unique_lock_name", { mode: "shared" }, () => {
          return promise;
        });
      }
    }
    if (url) {
      const tenantName = url.pathname.split("/")[1];
      let formData = {
        tenant: tenantName,
      };
      dispatch(tenantInfoA({ body: formData }));
    }
    window.addEventListener("online", () => start());
    window.addEventListener("beforeunload", async () => {
      if (localConnection.current) await localConnection.current.stop();
    });
  }, []);

  const initAgora = async () => {
    setAgoraConnected(1); //connecting
    await getDevices();
    rtc.current.client = await AgoraRTC.createClient(config);
    dispatch(getAgoraUser(rtc.current.client));
    try {
      rtc.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    } catch (e) {
      showToast(
        contents?.micNotConnected ?? messages?.micNotConnected,
        "success"
      );
    }
    try {
      // rtc.current.localVideoTrack.setEnabled(true);
      rtc.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      setLocalVideoTrack(rtc.current.localVideoTrack);
    } catch (ex) {
      showToast(
        contents?.cameraNotConnected ?? messages?.cameraNotConnected,
        "success"
      );
    }
    assignAgoraEvents();

    let getPrifix = "";

    await rtc.current.client.setClientRole("host");

    console.log("expert agorauid: " + sessionStatus.expertInfo.agoraUID);
    console.log("client agorauid: " + sessionStatus.clientInfo.agoraUID);

    const uid = await rtc.current.client.join(
      appId,
      sessionStatus.sessionId,
      sessionStatus.myRole === "Expert"
        ? sessionStatus.expertRoomToken.token
        : sessionStatus.clientRoomToken.token,
      sessionStatus.myRole === "Expert"
        ? sessionStatus.expertInfo.agoraUID
        : sessionStatus.clientInfo.agoraUID
    );

    await publishAudioVideoTracks();
    setAgoraConnected(2);
  };

  const initSignalR = async () => {
    setSignalRConnected(1);
    let signalRUrl =
      sessionStatus.myRole === "Expert"
        ? `${apiName.signalR}expertHub?GETBEE-AUTH-TOKEN=${connectionToken}`
        : `${apiName.signalR}clientHub?GETBEE-AUTH-TOKEN=${connectionToken}`;
    localConnection.current = await new signalR.HubConnectionBuilder()
      .withUrl(signalRUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .configureLogging(2)
      .build();

    localConnection.current.on("ChatMessage", (res) => {
      localStorage.setItem(newMessageIsAvailableLocalStorage, "1");
      setNewMessageAvailble(true);
    });

    if (localConnection.current._closedCallbacks.length == 0) {
      localConnection.current.onclose(async () => {
        await start();
      });
      localConnection.current.onreconnected(() => {
        const payload = {
          sessionId: sessionId,
        };
        dispatch(getSessionStatus(apiName.baseApi, payload));
      });
      localConnection.current.onreconnecting(onreconnecting);
      localConnection.current.on("CallCompleted", onCallCompleted);
      localConnection.current.on("AddProductToCart", onAddProductToCart);
      if (sessionStatus.myRole === "Expert") {
        // localConnection.current.on("AddProductToRecommendations", onExpertAddProductToRecommendations);
        localConnection.current.on(
          "RemoveProductFromRecommendations",
          onExpertRemoveProductFromRecommendations
        );
        localConnection.current.on("UserLeftCall", onExpertUserLeftCall);
        localConnection.current.on("QuantityChanged", onExpertQuantityChanged);
        localConnection.current.on("OrderPaid", onExpertOrderPaid);
        localConnection.current.on(
          "RemoveProductFromCart",
          onExpertRemoveProductFromCart
        );
        localConnection.current.on(
          "ClientWaitingForApproval",
          onClientWaitingForApproval
        );
        localConnection.current.on(
          "ClientLeftWaitingList",
          onClientLeftWaitingList
        );
      } else {
        localConnection.current.on(
          "AddProductToRecommendations",
          onClientAddProductToRecommendations
        );
        localConnection.current.on("QuantityChanged", onClientQuantityChanged);
        localConnection.current.on("UserLeftCall", onClientUserLeftCall);
        localConnection.current.on(
          "ExpertAskClientProceedToCheckout",
          onClientExpertAskClientProceedToCheckout
        );
        localConnection.current.on("OrderPaid", onClientOrderPaid);
        localConnection.current.on(
          "RemoveProductFromCart",
          onClientRemoveProductFromCart
        );
        localConnection.current.on(
          "RemoveProductFromRecommendations",
          onClientRemoveProductFromRecommendations
        );
      }
    }
    await start();
    setSignalRConnected(2);
  };

  useEffect(() => {
    if (sessionId) {
      const payload = {
        sessionId: sessionId,
      };
      dispatch(getSessionStatus(apiName.baseApi, payload));
    }
  }, [sessionId]);
  const [agoraConnected, setAgoraConnected] = useState(0);
  const [signalRConnected, setSignalRConnected] = useState(0);
  useEffect(() => {
    if (Object.keys(sessionStatus).length > 0) {
      switch (sessionStatus.sessionState) {
        case "Completed":
          if (
            sessionStatus.sessionSubState == "ExpertDisconnected" ||
            sessionStatus.sessionSubState == "ClientDisconnected"
          )
            setAutoCompletedCall(true);
          else setJoinCompletedCall(true);
          break;
        default:
          if (signalRConnected == 0 && connectionToken) initSignalR();

          if (
            agoraConnected == 0 &&
            ((sessionStatus.myRole == "Expert" &&
              sessionStatus?.expertRoomToken?.token) ||
              (sessionStatus.myRole == "Client" &&
                sessionStatus?.clientRoomToken?.token))
          )
            initAgora();
          localStorage.setItem("userRole", sessionStatus.myRole.toLowerCase());
          setWaitingList(
            sessionStatus.waitingList.filter((x) => x.status == "Pending")
          );
          break;
      }
    }
  }, [
    sessionStatus,
    connectionToken,
    localConnection?.current?._connectionState,
  ]);

  // console.log("session status", sessionStatus);

  useEffect(() => {
    if (
      Object.keys(sessionStatus).length > 0 &&
      sessionStatus.myRole === "Expert"
    ) {
      const payload = {
        tenant: sessionStatus.tenant,
        userId: sessionStatus.expertInfo.email,
      };
      dispatch(requestConnectionToken(apiName.baseApi, payload, "Expert"));
      setLocalSessionState(sessionStatus);
    } else if (
      Object.keys(sessionStatus).length > 0 &&
      sessionStatus.myRole === "Client"
    ) {
      const payload = {
        tenant: sessionStatus.tenant,
        userId: sessionStatus.clientInfo.email, //TODO: check this one
      };
      dispatch(requestConnectionToken(apiName.baseApi, payload, "Client"));
    }
  }, [sessionStatus]);
  useEffect(() => {
    const connectionStateChanged = async () => {
      if (
        localConnection.current &&
        ["Connected", "Disconnected"].includes(
          localConnection.current?._connectionState
        )
      ) {
        console.info(
          localConnection.current?._connectionState,
          "SignalR Connection State Changed"
        );
        if (localConnection.current._connectionState === "Connected") {
          // indexOf("InstantSessionsdf")
          if (
            sessionStatus.myRole == "Expert" &&
            sessionTypes.indexOf(sessionStatus.sessionType) !== -1
          ) {
            await localConnection.current.invoke("JoinInstantCall", {
              tenant: sessionStatus.tenant,
              sessionId: sessionId,
            });
            dispatch(
              getSessionStatus(apiName.baseApi, {
                sessionId: sessionId,
              })
            );
          }
          if (pingInterval) clearInterval(pingInterval);
          let tmbInterval = setInterval(async () => {
            if (localConnection.current?._connectionState === "Connected") {
              const payload = {
                sessionId: sessionId,
              };
              const result = await localConnection.current.invoke(
                "PingSession",
                payload
              );

              if (result.isSucceeded) {
                if (result.sessionInfo.sessionState == "Paused")
                  setOtherUserUID(null);
                else if (result.sessionInfo.sessionState == "InSession")
                  setOtherUserUID(getOtherUserName(result.sessionInfo));
                else if (
                  result.sessionInfo.sessionSubState == "ExpertDisconnected" ||
                  result.sessionInfo.sessionSubState == "ClientDisconnected"
                )
                  setAutoCompletedCall(true);
                else if (result.sessionInfo.sessionState == "Completed")
                  setCallEnded(true);
                else setOtherUserUID(getOtherUserName(result.sessionInfo));

                setWaitingList(
                  result.sessionInfo.waitingList.filter(
                    (x) => x.status == "Pending"
                  )
                );

                if (result.sessionInfo)
                  setLocalSessionState(result.sessionInfo);
              } else {
                if (
                  "OldSignalrConnection" === result.status &&
                  sessionStatus?.sessionState != "Pending"
                ) {
                  await onRedirectUser();
                } else if (result.status !== "SessionActiveOnOtherTab")
                  await start();
              }
            } else {
              await start();
            }
          }, 5000);
          setPingInterval(tmbInterval);
        } else if (
          localConnection.current._connectionState == "Disconnected" &&
          !signalRRestarting
        ) {
          await start();
        }
      }
    };
    connectionStateChanged();
  }, [localConnection.current, localConnection.current?._connectionState]);

  const assignAgoraEvents = () => {
    rtc.current.client.on("user-published", async (user, mediaType) => {
      // alert("user published the video")
      await rtc.current.client.subscribe(user, mediaType);
      setOtherUserUID(user.uid);

      if (mediaType === "video") {
        setOtherUserVideo(user.videoTrack);

        user.videoTrack.on("player-status-change", function (evt) {
          console.info(
            "AGORA - player-status-change: " +
              evt.isErrorState +
              evt.state +
              evt.reason
          );
        });
      }
      if (mediaType === "audio") {
        setOtherUserAudio(user.audioTrack);
        user.audioTrack?.play();
      }
    });
    rtc.current.client.on("user-unpublished", (user, type) => {
      // console.log("user unpublished the screen");
      //  alert("user-unpublished");
      setOtherUserUID(user.uid);
      if (type === "audio") {
        setOtherUserAudio(null);
        user.audioTrack?.stop();
      }
      if (type === "video") {
        setOtherUserVideo(null);
      }
    });
    rtc.current.client.on("user-left", async (user) => {
      // setOtherUserUID(null)
      // alert("user-left");
      setOtherUserAudio(null);
      setOtherUserVideo(null);
    });

    rtc.current.client.on("track-ended", () => {
      // setShareShreenActive(prevState => !prevState);
      // console.log("ended tack");
      alert("track ended");
    });
  };

  const start = async () => {
    if (Object.keys(sessionStatus).length > 0 && connectionToken) {
      setSignalRRestarting(true);
      if (localConnection.current?._connectionState === "Connected") {
        await localConnection.current.stop();
      }
      await startSignalRConnection();
    } else {
      setTimeout(async () => {
        await start();
      }, 3000);
    }
  };

  const startSignalRConnection = async () => {
    if (localConnection.current._connectionState === "Disconnected") {
      await localConnection.current.start().then(async () => {
        let payload = {
          sessionId: sessionId,
        };
        dispatch(getSessionStatus(apiName.baseApi, payload));

        setSignalRRestarting(false);
      });
      dispatch(createShopppingConnection(localConnection.current));
    } else if (signalRRestarting) {
      setTimeout(async () => {
        await startSignalRConnection();
      }, 3000);
    }
  };
  const saveVideoSetting = async () => {
    if (audioInputStore) {
      rtc.current.localAudioTrack
        .setDevice(audioInputStore.deviceId)
        .then(() => {
          localStorage.setItem("audioinput", audioInputStore.deviceId);
        })
        .catch((e) => {
          console.error(e, "set audio device error");
          showToast(
            contents?.micNotConnected ?? messages?.micNotConnected,
            "success"
          );
        });
    }
    if (videoInputStore && rtc.current.localVideoTrack) {
      rtc.current.localVideoTrack
        .setDevice(videoInputStore.deviceId)
        .then(() => {
          localStorage.setItem("videoinput", audioInputStore.deviceId);
        })
        .catch((e) => {
          console.error(videoInputStore, "set video device error", e);
          showToast(
            contents?.cameraNotConnected ?? messages?.cameraNotConnected,
            "error"
          );
        });
    }
    if (audioOutputStore) {
      otherUserAudio &&
        otherUserAudio
          .setPlaybackDevice(audioOutputStore.deviceId)
          .then(() => {
            localStorage.setItem("audiooutput", audioInputStore.deviceId);
          })
          .catch((e) => {
            console.error(e, "audio output can't be changed");
          });
    }
    toggleSettingModel(false, false);
  };
  const toggleSettingModel = async (state, reset) => {
    if (state) {
      await getDevices();
      setSettingModel(true);
    } else {
      setSettingModel(false);
      if (reset) {
        if (rtc.current.localAudioTrack) {
          let selectedPreAudio = audioInput.filter(
            (x) => x.label == rtc.current.localAudioTrack._deviceName
          )[0];
          setAudioInputStore(selectedPreAudio);
          rtc.current.localAudioTrack.setDevice(selectedPreAudio.deviceId);
        }
        if (rtc.current.localVideoTrack) {
          let selectedPreVideo = videoInput.filter(
            (x) => x.label == rtc.current.localVideoTrack._deviceName
          )[0];
          setVideoInputStore(selectedPreVideo);
          rtc.current.localVideoTrack.setDevice(selectedPreVideo.deviceId);
        }
      }
    }
  };
  const publishAudioVideoTracks = async () => {
    await saveVideoSetting();
    try {
      if (rtc.current.localAudioTrack) {
        await rtc.current.client.publish([rtc.current.localAudioTrack]);
        if (!mediaState.audio) rtc.current.localAudioTrack.setEnabled(false);
      } else {
        throw "No Microphone";
      }
    } catch (ex) {
      console.error(ex, "Microphone is not connected properly");
      Toast.info(
        contents?.micNotConnected ?? messages?.micNotConnected,
        1000,
        () => {
          setNoDeviceModel(true);
        }
      );
    } finally {
      setAudioChecked(true);
    }
    try {
      if (rtc.current.localVideoTrack) {
        await rtc.current.client.publish([rtc.current.localVideoTrack]);
        if (!mediaState.video) rtc.current.localVideoTrack.setEnabled(false);
      } else {
        throw "No Camera";
      }
    } catch (ex) {
      console.error(ex, "Camera is not connected properly");
      setNoCameraModal(true);
    } finally {
      setVideoChecked(true);
    }
  };

  const disableAudioVideoTracks = async () => {
    if (rtc.current.localVideoTrack) {
      await rtc.current.localVideoTrack.setEnabled(false);
      rtc.current.localVideoTrack.stop();
    }
    if (rtc.current.localAudioTrack) {
      await rtc.current.localAudioTrack.setEnabled(false);
      rtc.current.localAudioTrack.stop();
    }
  };
  const onRedirectUser = async () => {
    setNoDeviceModel(false);
    const tenantName = url.pathname.split("/")[1];
    if (rtc.current.client) {
      await rtc.current.client.leave();
      await rtc.current.client.removeAllListeners();
      await disableAudioVideoTracks();
    }
    Toast.info(
      contents?.redirectingYou ?? messages?.redirectingYou,
      1000,
      () => {
        dispatch(cleanShoppingReducer());
        if (
          Object.keys(sessionStatus).length > 0 &&
          sessionStatus.myRole === "Expert"
        ) {
          window.location.href = `${window.location.origin}${`/admin-home`}`;
        } else {
          const path = `/${tenantName}/client-home`;
          //props.history.push(path);
          window.location.href = `${window.location.origin}${path}`;
          // localStorage.setItem(localStorageClientLanguageKey, JSON.stringify(lng));
        }
      }
    );
  };
  const leaveChannel1 = async () => {
    await rtc.current.client.leave();
    await rtc.current.client.removeAllListeners();
    if (pingInterval) clearInterval(pingInterval);
    await disableAudioVideoTracks();
    let isExpert =
      isUserLoggedIn() && localStorage.getItem("userRole") == "expert";
    let tenant = localStorage.getItem("tenant");
    localStorage.removeItem("mediaState");
    localStorage.removeItem("callId");
    localStorage.removeItem("agoraToken");
    localStorage.removeItem("email");
    localStorage.removeItem("tenant");
    localStorage.removeItem("userRole");
    localStorage.removeItem("email");
    if (isExpert) {
      window.location.href = `${window.location.origin}${`/admin-home`}`;
    } else {
      const path = `/${tenant}/client-home`;
      window.location.href = `${window.location.origin}${path}`;
    }
  };

  const InvokeCompleteCall = async () => {
    const payload = {
      clientEmail: sessionStatus?.clientInfo?.email,
      tenant: sessionStatus.tenant,
      sessionId: sessionId,
    };
    const result = await localConnection.current.invoke(
      "CompleteCall",
      payload
    );
    // await leaveAndStopAllTrack();
    console.info(localConnection.current, "Complete Call SignalR Connection");
    console.info(result, "Complete Call");
  };
  const screen = useSize();
  const onreconnecting = (error) => {};
  const onCallCompleted = (message) => {
    if (!message.sessionState === "Pending")
      if (
        message &&
        message.sessionState === "Completed" &&
        (message.sessionSubState === "ExpertDisconnected" ||
          message.sessionSubState === "ClientDisconnected")
      ) {
        localStorage.setItem("online", true);
        setAutoCompletedCall(true);
      } else {
        setCallEnded(true);
      }
  };
  const onAddProductToCart = (message) => {
    console.log(message, "ADDED");
    let newProduct = message.rawData;
    newProduct.id = message.productInCartId;
    dispatch(addToCart(newProduct));
    showToast(
      contents?.productAddedToCart ?? messages?.productAddedToCart,
      "success"
    );
  };
  const onExpertRemoveProductFromRecommendations = (message) => {
    // console.log("RemoveProductToRecommendations *********--------->", message);
    Toast.success(
      contents?.productRemovedFromRecommendations ??
        messages?.productRemovedFromRecommendations,
      1000,
      () => {}
    );
  };
  const onExpertUserLeftCall = (message) => {
    Toast.info(
      contents?.clientDisconnected ?? messages?.clientDisconnected,
      3000,
      () => {
        // do something after the toast disappears
      }
    );
  };
  const onExpertQuantityChanged = (message) => {
    message.rawData.id = message.productInCartId;
    message.rawData.quantity = message.quantity;
    dispatch(changeProductQuantity(message.rawData));
  };
  const onExpertOrderPaid = (message) => {
    dispatch(goToSelectedStep(5));
    const payload = {
      sessionId: sessionId,
    };
    dispatch(getSessionStatus(apiName.baseApi, payload));
  };
  const onExpertRemoveProductFromCart = (message) => {
    console.log("remove", message);
    message.rawData.id = message.productInCartId;
    dispatch(clearItemFromCart(message.rawData));
    Toast.success(
      contents?.productRemovedFromCart ?? messages?.productRemovedFromCart,
      1000,
      () => {}
    );
  };
  const [waitingList, setWaitingList] = useState([]);
  const [waitingUser, setTheWaitingUser] = useState(null);
  const onClientWaitingForApproval = (message) => {
    setTheWaitingUser({ ...message, add: 1 });
  };
  const onClientLeftWaitingList = (message) => {
    setTheWaitingUser({ ...message, add: 0 });
  };
  useEffect(() => {
    if (waitingUser) {
      var lst = waitingList;
      if (waitingUser.add == 1) {
        if (!lst.includes((x) => x.email == waitingUser.email))
          lst.push(waitingUser);
        setWaitingList(lst);
      } else {
        setWaitingList((old) =>
          old.filter((x) => x.email != waitingUser.email)
        );
      }
    }
  }, [waitingUser]);
  const onClientAddProductToRecommendations = (message) => {
    dispatch(addToRecommendation(message.rawData));
    Toast.success(
      contents?.productRecommended ?? messages?.productRecommended,
      1000,
      () => {}
    );
  };
  const onClientQuantityChanged = (message) => {
    message.rawData.id = message.productInCartId;
    message.rawData.quantity = message.quantity;
    dispatch(changeProductQuantity(message.rawData));
  };
  const onClientUserLeftCall = (message) => {
    setOtherUserUID(null);
    Toast.info(
      contents?.expertDisconnected ?? messages?.expertDisconnected,
      3000,
      () => {}
    );
  };
  const onClientExpertAskClientProceedToCheckout = (message) => {
    Toast.success(
      contents?.cartReadyForCheckout ?? messages?.cartReadyForCheckout,
      2000,
      () => {
        dispatch(goToSelectedStep(7));
      }
    );
  };
  const onClientOrderPaid = (message) => {
    dispatch(goToSelectedStep(8));
    const payload = {
      sessionId: sessionId,
    };
    dispatch(getSessionStatus(apiName.baseApi, payload));
  };
  const onClientRemoveProductFromCart = (message) => {
    message.rawData.id = message.productInCartId;
    dispatch(clearItemFromCart(message.rawData));
    Toast.success(
      contents?.productRemovedFromCart ?? messages?.productRemovedFromCart,
      1000,
      () => {}
    );
  };
  const onClientRemoveProductFromRecommendations = (message) => {
    dispatch(removeFromRecommendation(message.productId));
    Toast.success(
      contents?.productRemovedFromRecommendations ??
        messages?.productRemovedFromRecommendations,
      1000,
      () => {}
    );
  };
  const getOtherUserName = (sessionData) => {
    if (sessionData) {
      if (sessionData.myRole === "Expert") {
        return (
          sessionData?.clientInfo?.name ?? contents?.client ?? messages?.client
        );
      } else {
        return sessionData?.expertInfo.name;
      }
    }
    return "";
  };

  const showToltip = useMemo(() => {
    if (showBuyerProducts || showCatalog) {
      return false;
    }

    return true;
  }, [showBuyerProducts, showCatalog]);

  const getMainContent = useCallback(() => {
    if (otherUserUID && otherUserVideo) {
      return (
        <AgoraVideoPlayer
          className="vid vid-flip"
          videoTrack={otherUserVideo}
          key={otherUserUID}
          config={{ mirror: true, fit: "contain" }}
        />
      );
    } else if (otherUserUID) {
      return (
        <div className="icon-position">
          {/* <p>{getOtherUserName(localSessionState)}</p> */}
          {screen.size === "sm" && (
            <>
              <p>{!showToltip && getOtherUserName(localSessionState)}</p>
            </>
          )}

          {screen.size !== "sm" && <p>{getOtherUserName(localSessionState)}</p>}
        </div>
      );
    } else {
      return (
        <figure>
          <div className="contact-finding">
            <img className="img-spin" src={ellipseLoader} alt="Loader" />
          </div>
        </figure>
      );
    }
  }, [
    getOtherUserName,
    localSessionState,
    otherUserUID,
    otherUserVideo,
    screen.size,
    showToltip,
  ]);

  const respondToClientJoinRequest = async (clientId, isApproved) => {
    var response = await localConnection.current.invoke(
      "RespondToClientJoinRequest",
      {
        clientEmail: clientId,
        sessionId: sessionId,
        isApproved: isApproved,
        tenant: sessionStatus.tenant,
      }
    );
    if (response.isSucceeded) {
      let lst = waitingList.filter((x) => x.email != clientId);
      setWaitingList(isApproved ? [] : lst);
    } else {
      Toast.error(
        contents?.err_InternalError ?? messages?.err_InternalError,
        1000,
        () => {}
      );
    }
  };

  const [showInviteParticipantPopup, setShowInviteParticipantPopup] =
    useState(false);
  const [participantMail, setParticipantMail] = useState({
    Email: "",
    EmailErr: "",
  });
  const sendPatricipantInvitationMail = () => {
    var emptyErr = validate(contents, "blank", participantMail.Email);
    if (emptyErr) {
      setParticipantMail({ ...participantMail, EmailErr: emptyErr });
      return;
    }

    let emailErr = validate(contents, "email", participantMail.Email);
    if (emailErr) {
      setParticipantMail({ ...participantMail, EmailErr: emailErr });
      return;
    }

    setParticipantMail({ ...participantMail, EmailErr: "" });
    let payload = {
      session: sessionId,
      email: participantMail.Email,
      sessionUrl: inviteUrl,
    };
    setShowInviteParticipantPopup(false);
    dispatch(sendInvitationSession(apiName.baseApi, payload, contents));
  };

  const shareScreen = async () => {
    try {
      setShareShreenActive(true);
      rtc.current.localVideoTrack.setEnabled(false);
      rtc.current.localVideoTrack.stop();
      rtc.current.client.unpublish(rtc.current.localVideoTrack);
      rtc.current.localVideoTrack.close();
      const captureScreen = await AgoraRTC.createScreenVideoTrack({
        screenSourceType: "screen",
      });
      rtc.current.localVideoTrack = captureScreen;
      setLocalVideoTrack(rtc.current.localVideoTrack);
      rtc.current.client.publish([captureScreen]);

      if (captureScreen) {
        captureScreen.on("track-ended", () => {
          setShareShreenActive(false);
          stopSharing();
        });
      }

      return captureScreen;
    } catch (err) {
      stopSharing();
      setShareShreenActive(false);
      console.log("Can not created screen track", err);
    }
  };

  const stopSharing = async () => {
    setShareShreenActive(false);
    rtc.current.localVideoTrack.stop();
    rtc.current.client.unpublish(rtc.current.localVideoTrack);
    rtc.current.localVideoTrack.close();
    rtc.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    setLocalVideoTrack(rtc.current.localVideoTrack);
    await rtc.current.client.publish([rtc.current.localVideoTrack]);
    rtc.current.localVideoTrack.setEnabled(false);

    if (mediaState.video) {
      rtc.current.localVideoTrack.setEnabled(true);
    }
  };

  useEffect(() => {
    const publishOrNot = async () => {
      if (mediaState.video) {
        rtc?.current?.localVideoTrack?.setEnabled(true);
      } else {
        rtc?.current?.localVideoTrack?.setEnabled(false);
      }
    };
    publishOrNot();
  }, [mediaState]);

  const [shouldFetchLng, setShouldFetchLng] = useState(false);

  const params = new URLSearchParams(props.location.search);
  const lng = params.get("lng"); // bar

  useLanguage({ lng, shouldFetchLanguage: shouldFetchLng, fromClient: true });

  useEffect(() => {
    const fetchLanguageForTheClient = () => {
      if (sessionStatus.myRole === "Client") {
        localStorage.setItem(
          localStorageClientLanguageKey,
          JSON.stringify(lng)
        );
        setShouldFetchLng(true);
      }
      return null;
    };
    fetchLanguageForTheClient();
  }, [sessionStatus, lng]);

  const isNewMessageAvailableLSK = localStorage.getItem(
    newMessageIsAvailableLocalStorage
  );
  const [newMessageAvailble, setNewMessageAvailble] = useState(() =>
    isNewMessageAvailableLSK === "1" ? true : false
  );

  return (
    <>
      <ErrorBoundry>
        {showChatMode && !callEnded && (
          <ChatModel
            showModel={showChatMode}
            setShowModel={setShowChatMode}
            localConnection={localConnection}
            sessionStatus={sessionStatus}
            sessionId={sessionId}
            role={sessionStatus?.myRole}
            expertInfo={localSessionState?.expertInfo}
            clientInfo={localSessionState?.clientInfo}
            contents={contents}
            setNewMessageAvailble={setNewMessageAvailble}
            newMessageAvailble={newMessageAvailble}
          />
        )}
        {showParticipantsMode && !callEnded &&
        Object.keys(sessionStatus).length > 0 &&
        sessionStatus.myRole === "Expert" &&
        sessionTypes.indexOf(sessionStatus.sessionType) !== -1 &&
        !callEnded &&
        !callAutoCompleted &&
        !joinCompletedCall &&
        (
          <ParticipantsModel
            contents={contents}
            showModel={showParticipantsMode}
            setShowModel={setShowParticipantsMode}
            inviteUrl={inviteUrl}
            waitingList={waitingList}
            clientData={localSessionState?.clientInfo}
            expertData={sessionStatus.expertInfo}
            showInvitePopup={setShowInviteParticipantPopup}
            respondToClientJoinRequest={respondToClientJoinRequest}
          />
        )}
      </ErrorBoundry>

      <section className="flex items-center s-desktop ">
        <section
          className={`gb-z-10 gb-video-call w-full ${
            (callEnded || callAutoCompleted || joinCompletedCall) && `hidden`
          } `}
        >
          <div className="wraper s-video w-full">
            <header
              id="fixed-header"
              className={`header-main ${
                screen.size === "sm" &&
                (showCatalog || showBuyerProducts) &&
                `hide-section`
              }   `}
            >
              <nav className="navbar flex items-center navbar-expand-lg navbar-light">
                <div className="nav-items ">
                  <a className="navbar-brand">
                    <img
                      src={
                        tenantInfo && tenantInfo.data.logoUrl
                          ? tenantInfo.data.logoUrl
                          : null
                      }
                    />
                  </a>
                </div>
                <div className="nav-items items-center justify-end ml-auto flex items-right">
                  <div className="s-video__liveDot" />
                  <span className="session-time">
                    {localSessionState &&
                      localSessionState.startTime &&
                      localSessionState?.sessionState !== "Pending" && (
                        <MyTimer
                          expiryTimestamp={localSessionState.startTime}
                        />
                      )}
                  </span>
                </div>
                <div className="nav-items item-right  xl:ml-0 ml-4">
                  <button
                    className="btn btn-action"
                    onClick={() => toggleSettingModel(true, false)}
                  >
                    <img src={iconSetting} />
                  </button>
                </div>
              </nav>
            </header>
          </div>
          <div className="s-video__wrapper">
            <div className="session-detail-block">
              <div
                className={`session-contact-blk ${
                  (callEnded || callAutoCompleted || joinCompletedCall) &&
                  `hidden`
                } ${showCatalog && `height-none`} ${
                  showBuyerProducts && `height-none`
                } relative`}
              >
                <div
                  className={`relative ${
                    screen.size === "sm" && (showCatalog || showBuyerProducts)
                      ? `modal-position s-video__expert ${
                          showCatalog && `bottom-o`
                        } ${showBuyerProducts && `bottom-o`}`
                      : `s-video__client displayDesktop`
                  } `}
                  onClick={() =>
                    screen.size === "sm" && dispatch(switchToVideoScreen(true))
                  }
                >
                  {getMainContent()}
                </div>
                <button
                  className="s-video__settings absolute"
                  onClick={() => toggleSettingModel(true, false)}
                >
                  <img src={iconSetting} />
                </button>
                <div
                  className={`relative modal-position s-video__expert ${
                    screen.size === "sm" && showBuyerProducts && `hidden`
                  } ${screen.size === "sm" && showCatalog && `hidden`}  ${
                    showCatalog && `bottom-o`
                  } ${showBuyerProducts && `bottom-o`} ${
                    (callEnded || callAutoCompleted || joinCompletedCall) &&
                    `hidden`
                  }`}
                  onClick={() => dispatch(switchToVideoScreen(true))}
                >
                  {localVideoTrack &&
                  localVideoTrack._enabled &&
                  mediaState.video ? (
                    <AgoraVideoPlayer
                      className="vid "
                      key={"localTrack_2"}
                      videoTrack={rtc.current.localVideoTrack}
                    />
                  ) : (
                    <img src={noImage} />
                  )}
                </div>
                {settingModel && (
                  <>
                    <div className="session-popup-blk s-video__setting">
                      <div className="session-popup">
                        <div className="session-popup-head">
                          <h4>
                            {contents?.videoSettings ?? messages?.videoSettings}
                          </h4>
                          <button
                            className="btn btn-action"
                            onClick={() => toggleSettingModel(false, true)}
                          >
                            <img src={iconClose} />
                          </button>
                        </div>
                        <div className="mt-4 flex items-center justify-between s-video__setting__camera">
                          <div className="flex flex-col ">
                            <h3>{contents?.camera ?? messages?.camera}</h3>
                            {videoInput &&
                              videoInput.length > 0 &&
                              videoInput.map((item) => (
                                <button
                                  className={
                                    item.deviceId ===
                                      videoInputStore.deviceId && `active`
                                  }
                                  onClick={() => setVideoInputStore(item)}
                                  key={item.deviceId}
                                >
                                  <span>{item.label}</span>
                                  {item.deviceId ===
                                    videoInputStore.deviceId && (
                                    <img
                                      className="ml-2"
                                      src={check}
                                      alt="check"
                                    />
                                  )}
                                </button>
                              ))}
                          </div>
                          <div className="hide-section">
                            <Webcam
                              className="vid-model rounded-full"
                              style={{
                                transform: "rotateY(180deg)",
                                objectFit: "cover",
                              }}
                              audio={false}
                              ref={webcamRef}
                              videoConstraints={{
                                deviceId: videoInputStore?.deviceId,
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-2 flex flex-col items-center justify-between s-video__setting__camera">
                          <div className="flex flex-col ">
                            <h3>
                              {contents?.microphone ?? messages?.microphone}
                            </h3>
                            {audioInput &&
                              audioInput.length > 0 &&
                              audioInput.map((item) => (
                                <button
                                  className={
                                    item.deviceId ===
                                      audioInputStore.deviceId && `active`
                                  }
                                  onClick={() => setAudioInputStore(item)}
                                >
                                  <span>{item.label}</span>
                                  {item.deviceId ===
                                    audioInputStore.deviceId && (
                                    <img
                                      className="ml-2"
                                      src={check}
                                      alt="check"
                                    />
                                  )}
                                </button>
                              ))}
                          </div>
                        </div>
                        <div className="mt-4 mb-4 flex flex-col items-center justify-between s-video__setting__camera">
                          <div className="flex flex-col">
                            <h3>
                              {contents?.audioOutput ?? messages?.audioOutput}
                            </h3>
                            {audioOutput &&
                              audioOutput.length > 0 &&
                              audioOutput.map((item) => (
                                <button
                                  className={
                                    item.deviceId ===
                                      audioOutputStore.deviceId && `active`
                                  }
                                  onClick={() => setAudioOutputStore(item)}
                                >
                                  <span>{item.label}</span>
                                  {item.deviceId ===
                                    audioOutputStore.deviceId && (
                                    <img
                                      className="ml-2"
                                      src={check}
                                      alt="check"
                                    />
                                  )}
                                </button>
                              ))}
                          </div>
                        </div>
                        <button
                          className="light-text btn btn-primary btn-min popup-btn session-popup-blk__btn"
                          onClick={saveVideoSetting}
                        >
                          {contents?.applyVideoSettings ??
                            messages?.applyVideoSettings}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="s-video__user flex items-center">
                {otherUserUID === null ? (
                  <p className="">{`${
                    contents?.connecting ?? messages?.connecting
                  } `}</p>
                ) : (
                  <div className="flex items-center">
                    <img src={userIcon} className="mr-2" alt="" />
                    <span>{getOtherUserName(localSessionState)}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`flex justify-between s-video__bottomPart ${
              showCatalog && "hide-section"
            } ${showBuyerProducts && "hide-section"}`}
          >
            <div className="session-action-blk">
              {localConnection.current?._connectionState === "Connected" && (
                <Controls
                  setLeaveChannel={setLeaveChannel}
                  leaveChannel={leaveChannel}
                  waitingList={waitingList}
                  invokeCallComplete={InvokeCompleteCall}
                  expert={expertData}
                  mediaState={mediaState}
                  audioChecked={audioChecked}
                  videoChecked={videoChecked}
                  messages={contents}
                  sessionData={localSessionState}
                  isExpert={sessionStatus.myRole === "Expert"}
                  showInvitationScreen={showInvitationScreen}
                  changeAudioStatus={(status) => {
                    rtc.current.localAudioTrack.setEnabled(status);
                    let tmbMediaState = { ...mediaState, audio: status };
                    localStorage.setItem(
                      "mediaState",
                      JSON.stringify(tmbMediaState)
                    );
                    setMediaState(tmbMediaState);
                  }}
                  changeVideoStatus={async (status) => {
                    rtc.current.localVideoTrack.setEnabled(status);
                    let tmbMediaState = { ...mediaState, video: status };
                    localStorage.setItem(
                      "mediaState",
                      JSON.stringify(tmbMediaState)
                    );
                    setMediaState(tmbMediaState);
                  }}
                  shareScreenActive={shareScreenActive}
                  setShareShreenActive={setShareShreenActive}
                  stopSharing={stopSharing}
                  shareScreen={shareScreen}
                  showChatMode={showChatMode}
                  setShowChatMode={setShowChatMode}
                  setShowParticipantsMode={setShowParticipantsMode}
                  setNewMessageAvailble={setNewMessageAvailble}
                  newMessageAvailble={newMessageAvailble}
                />
              )}
            </div>
            {tenantInfo?.data?.storeType !== "Unknown" && (
              <div style={{ width: "150px" }} className="product-or-cat">
                {Object.keys(sessionStatus).length > 0 &&
                  !leaveChannel &&
                  tenantInfo?.data?.storeType !== "Unknown" && (
                    <>
                      {sessionStatus.myRole === "Expert" && (
                        <button
                          style={
                            showCatalog
                              ? { background: "black" }
                              : { background: "white" }
                          }
                          className={
                            "hide flex items-center ml-2 justify-center text-center"
                          }
                          onClick={() =>
                            dispatch(handleShowCatalog(!showCatalog))
                          }
                        >
                          {showCatalog ? (
                            <>
                              <img src={vectorWhite} />
                              <p className="ml-2 text-white">
                                {contents?.hideCatalog ?? messages?.hideCatalog}
                              </p>{" "}
                            </>
                          ) : (
                            <>
                              <img src={catalogIcon} />
                              <p className="ml-2 text-black">
                                {contents?.showCatalog ?? messages?.showCatalog}
                              </p>{" "}
                            </>
                          )}
                        </button>
                      )}
                      {sessionStatus.myRole === "Client" && (
                        <button
                          className="hide flex items-center ml-2 justify-center text-center "
                          style={
                            showBuyerProducts
                              ? { background: "black" }
                              : { background: "white" }
                          }
                          onClick={() =>
                            dispatch(
                              handleShowBuyerProducts(!showBuyerProducts)
                            )
                          }
                        >
                          {showBuyerProducts ? (
                            <>
                              <img src={vectorWhite} />
                              <p className="ml-2 text-white">
                                {" "}
                                {contents?.hideProducts ??
                                  messages?.hideProducts}
                              </p>{" "}
                            </>
                          ) : (
                            <>
                              <img src={catalogIcon} />
                              <p className="ml-2 text-black">
                                {contents?.prodcuts ?? messages?.prodcuts}
                              </p>{" "}
                            </>
                          )}
                        </button>
                      )}
                    </>
                  )}
              </div>
            )}
          </div>
        </section>
        {/*CALL ENDED SCREEN */}
        {callEnded && (
          <CallEndedScreen
            leaveChannel={leaveChannel1}
            logo={
              tenantInfo && tenantInfo.data.logoUrl
                ? tenantInfo.data.logoUrl
                : null
            }
          />
        )}
        {Object.keys(sessionStatus).length > 0 &&
        sessionStatus.myRole === "Expert" &&
        sessionTypes.indexOf(sessionStatus.sessionType) !== -1 &&
        showInvitationScreen &&
        !callEnded &&
        !callAutoCompleted &&
        !joinCompletedCall ? (
          <InvitationSidebar
            inviteUrl={inviteUrl}
            waitingList={waitingList}
            clientData={localSessionState?.clientInfo}
            expertData={sessionStatus.expertInfo}
            showInvitePopup={setShowInviteParticipantPopup}
            respondToClientJoinRequest={respondToClientJoinRequest}
          />
        ) : null}
        {Object.keys(sessionStatus).length > 0 &&
        sessionStatus.myRole === "Expert" &&
        showCatalog &&
        !callEnded &&
        !callAutoCompleted &&
        !joinCompletedCall ? (
          <ExpertScreen />
        ) : null}
        {Object.keys(sessionStatus).length > 0 &&
        sessionStatus.myRole === "Client" &&
        showBuyerProducts &&
        !callEnded &&
        !callAutoCompleted &&
        !joinCompletedCall ? (
          <BuyerScreen />
        ) : null}
        {noDeviceModal ? (
          <>
            <div className="session-popup-blk s-video__setting ">
              <div className="session-popup session-popup--small">
                <div className="session-popup-head ">
                  <h4>
                    {contents?.noAvailableDevices ??
                      messages?.noAvailableDevices}
                  </h4>
                </div>
                <div className="mt-4 flex items-center justify-between s-video__setting__camera">
                  <button
                    className="btn btn-primary btn-min popup-btn session-popup-blk__btn"
                    onClick={() => onRedirectUser()}
                  >
                    {contents?.ok ?? messages?.ok}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {noCameraModal ? (
          <>
            <div className="session-popup-blk s-video__setting ">
              <div className="session-popup session-popup--small">
                <div className="session-popup-head ">
                  <h4>
                    {contents?.cameraNotConnected ??
                      messages?.cameraNotConnected}
                  </h4>
                </div>
                <div className="mt-4 flex items-center justify-between s-video__setting__camera">
                  <button
                    className="light-text btn-primary btn-min popup-btn session-popup-blk__btn"
                    onClick={() => setNoCameraModal(false)}
                  >
                    {contents?.ok ?? messages?.ok}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {callAutoCompleted ? (
          <>
            <div className="session-popup-blk s-video__setting ">
              <div className="session-popup session-popup--small">
                <div className="session-popup-head ">
                  <h4>
                    {Object.keys(sessionStatus).length > 0 &&
                    sessionStatus.myRole === "Expert"
                      ? contents?.callEndedClientDisconnection ??
                        messages?.callEndedClientDisconnection
                      : contents?.callEndedExpertDisconnection ??
                        messages?.callEndedExpertDisconnection}
                  </h4>
                </div>
                <div className="mt-4 flex items-center justify-between s-video__setting__camera">
                  <button
                    className="light-text btn btn-primary btn-min popup-btn session-popup-blk__btn"
                    onClick={() => onRedirectUser()}
                  >
                    {contents?.ok ?? messages?.ok}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {joinCompletedCall && (
          <>
            <div className="session-popup-blk s-video__setting ">
              <div className="session-popup session-popup--small">
                <div className="session-popup-head ">
                  <h4>
                    {contents?.callAlreadyCompleted ??
                      messages?.callAlreadyCompleted}
                  </h4>
                </div>
                <div className="mt-4 flex items-center justify-between s-video__setting__camera">
                  <button
                    className="light-text btn btn-primary btn-min popup-btn session-popup-blk__btn"
                    onClick={() => onRedirectUser()}
                  >
                    {contents?.ok ?? messages?.ok}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {showInviteParticipantPopup ? (
          <>
            <div className="session-popup-blk s-video__setting ">
              <div className="session-popup session-popup--small">
                <div className="session-popup-head invitation-title">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {contents?.invitation ?? messages?.invitation}
                  </h5>
                  <button
                    onClick={() => setShowInviteParticipantPopup(false)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <img src={Images.closeIcon} />
                    </span>
                  </button>
                </div>
                <div className="session-popup-body">
                  <p>
                    {contents?.enterPersonMailToInvite ??
                      messages?.enterPersonMailToInvite}
                  </p>
                  <div className="form-group">
                    <label htmlFor="email">
                      {contents?.mail ?? messages?.mail}
                    </label>
                    <input
                      type="text"
                      value={participantMail.Email}
                      className="form-input"
                      id="mail"
                      onChange={(e) =>
                        setParticipantMail({
                          ...participantMail,
                          Email: e.target.value,
                        })
                      }
                    />
                    {participantMail.EmailErr ? (
                      <ErrorText title={participantMail.EmailErr} />
                    ) : null}
                  </div>
                </div>
                <div className="mt-4 session-popup-footer">
                  <button
                    className="btn btn-black"
                    onClick={() => sendPatricipantInvitationMail()}
                  >
                    {contents?.sendInvite ?? messages?.sendInvite}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </section>
    </>
  );
};
export default VideoCall;
