import { post } from '../../utils/Api';
import axios from 'axios';
import { requestCallLoading, requestCallSuccess, requestCallError } from '../constants/reduxConstant';
import apiName from '../../constants/apiName';
import udid from '../../components/Common/udid';

const apiLoading = () => ({ type: requestCallLoading });
const apiSucceed = (payload) => ({ type: requestCallSuccess, payload });
const apiError = (payload) => ({ type: requestCallError, payload });

const requestCallA = ({ from, body }) => async (dispatchEvent, getState) => {
    
    dispatchEvent(apiLoading());
    try {
        post(apiName.requestCall, {...body, userId: body.email})
            .then(async (res) => {
                if (res && res.data && res.data.isSucceeded) {
                    dispatchEvent(apiSucceed(res));
                    localStorage.setItem("clientToken", res.data.connectionToken)
                } else {
                    dispatchEvent(apiError(res));
                }
            })
            .catch((error) => {
                dispatchEvent(apiError(error));
            });
    } catch (e) {
        dispatchEvent(apiError(e));
    }
}

export default requestCallA