import { SHOW_BUYER_PRODUCTS, GET_AGORA_USER, CLEAN_SHOPPING, SESSION_STATUS, REQUEST_CONNECTION, SHOW_CATALOG, GET_PRODUCTS_COLLECTIONS, CLEAR_ITEM_FROM_PRODUCTS, SHOW_VIDEO_SCREEN, UPDATE_PRODUCTS, SHOPPING_CONNECTION, SELECTED_PRODUCT, ACTIVE_STEP, GET_PRODUCTS, LOADING_SHOPPING, ADD_TO_RECOMMENDATION, REMOVE_FROM_RECOMMENDATION, ADD_ITEM, REMOVE_QUANTITY, CLEAR_ITEM_FROM_CART } from "../constants/Index";
import { addItemToShop, removeItemFromShop, removeQuantityFromProduct } from "../action/shopUtils";
import { GET_PRODUCTS_COLLECTIONS_ERROR, GET_PRODUCTS_ERROR, SHOW_INVITATION_SCREEN, STORE_INTEGRATION } from "../constants/shopping";
const initialState = {
  cart: [],
  product: {},
  allProducts: [],
  alternateAllProducts: [],
  recommendation: [],
  loading: false,
  activeStep: 0,
  activeStepBuyer: 1,
  shoppingConnection: null,
  showCatalog: false,
  showInvitationScreen: false,
  showBuyerProducts: false,
  showVideoScreen: true,
  staticProductList: [],
  collections: [],
  collectionLoading: true,
  sessionStatus: {},
  connectionToken: "",
  agoraUser: null,
};
const shoppingReducer = (state = initialState, action) => {
  switch (action.type) {

    case ADD_TO_RECOMMENDATION:
      let filteredArr = state.recommendation
      if (action.payload && !state.recommendation.some(item => item.productId === action.payload.productId)) {
        filteredArr = [...state.recommendation, action.payload]
      } else {
        filteredArr = state.recommendation
      }
      return {
        ...state,
        recommendation: filteredArr
      }
    case CLEAN_SHOPPING:
      return {
        ...state,
        cart: [],
        product: {},
        allProducts: [],
        alternateAllProducts: [],
        totalProductsCount: 0,
        recommendation: [],
        loading: false,
        activeStep: 0,
        activeStepBuyer: 1,
        shoppingConnection: null,
        showCatalog: false,
        showBuyerProducts: false,
        showVideoScreen: true,
        staticProductList: [],
        collections: [],
        collectionLoading: true,
        sessionStatus: {},
        connectionToken: "",
        agoraUser: null,
      }
    case GET_AGORA_USER:
      return {
        ...state,
        agoraUser: action.payload
      }
    case CLEAR_ITEM_FROM_PRODUCTS:
      return {
        ...state,
        allProducts: removeQuantityFromProduct(state.allProducts, action.payload),
        //  recommendation: removeQuantityFromProduct(state.recommendation, action.payload)
      }
    case REMOVE_QUANTITY:
      return {
        ...state,
        allProducts: removeItemFromShop(state.allProducts, action.payload),
        // recommendation: removeItemFromShop(state.recommendation, action.payload)

      };
    case SESSION_STATUS:
      let recommendationProducts = []
      if (action.payload && action.payload.recommendations && action.payload.recommendations.length > 0) {
        action.payload.recommendations.map(item => (
          recommendationProducts.push(item.rawData)


        ))
      }
      return {
        ...state,
        sessionStatus: action.payload,
        recommendation: recommendationProducts
      }
    case REQUEST_CONNECTION:
      return {
        ...state,
        connectionToken: action.payload
      }
    case UPDATE_PRODUCTS:

      return {
        ...state,
        allProducts: addItemToShop(state.allProducts, action.payload),
        // recommendation: addItemToShop(state.recommendation, action.payload)

      };
    case STORE_INTEGRATION:
      return {
        ...state,
        storeIntegration: action.payload
      }
    case SHOPPING_CONNECTION:
      return {
        ...state,
        shoppingConnection: action.payload
      }
    case SHOW_BUYER_PRODUCTS:
      return {
        ...state,
        showBuyerProducts: action.payload,
        showVideoScreen: false
      }
    case SHOW_INVITATION_SCREEN:
      return {
        ...state,
        showInvitationScreen: action.payload,
        showCatalog: false,
        showVideoScreen: false
      }  
    case SHOW_CATALOG:
      return {
        ...state,
        showCatalog: action.payload,
        showInvitationScreen: false,
        showVideoScreen: false
      }
    case LOADING_SHOPPING:
      return {
        ...state,
        loading: action.payload
      }
    case SELECTED_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case SHOW_VIDEO_SCREEN:
      return {
        ...state,
        showVideoScreen: false,
        showCatalog: false,
        showBuyerProducts: false
      }

    case ACTIVE_STEP: {
      return {
        ...state,
        activeStep: action.payload,
        activeStepBuyer: action.payload
      }
    }

    case GET_PRODUCTS_COLLECTIONS: {
      return {
        ...state,
        collections: action.payload,
        collectionLoading: false
      }
    }
    case REMOVE_FROM_RECOMMENDATION:
      let updatedList

      console.log(action.payload)
      if (action.payload) {
        updatedList = state.recommendation.filter(item => item.productId !== action.payload)
      }

      return {
        ...state,
        recommendation: updatedList
      }
    case GET_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload.products,
        totalProductsCount: action.payload.totalCount,
        staticProductList: action.payload.products
      }
    case GET_PRODUCTS_ERROR:
      console.error(action.payload)
      return {
        ...state,
        getProductsError: action.payload
      }
    case GET_PRODUCTS_COLLECTIONS_ERROR:
      console.error(action.payload)
      return {
        ...state,
        getProductsCollectionError: action.payload
      }
    default:
      return state;
  }
};
export default shoppingReducer;
