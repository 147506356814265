import * as signalR from "@microsoft/signalr";
import { showToast } from "components/Common/Common";
import { getError } from "components/ErrorHandler/getError";
import apiName from "constants/apiName";

const connectToSignalR = async (connectionToken) => {
  try {
    var connection = await new signalR.HubConnectionBuilder()
      .withUrl(
        `${apiName.signalR}clientHub?GETBEE-AUTH-TOKEN=${connectionToken}`,
        {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        }
      )
      .configureLogging(signalR.LogLevel.Information)
      .build();
    return connection;
  } catch (err) {
    showToast(getError(err), "error");
    console.error(err);
  }
};

export default connectToSignalR;
