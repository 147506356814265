import makeStyles from "@material-ui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { utcToISODate } from "pureFunctions/utcToISODate";
import en from 'date-fns/locale/en-GB';
const useStyle = makeStyles({
  root: {
    "& .MuiPickerStaticWrapper-root": {
      display: "none !important",
    },
    "& .MuiDialogActions-root": {
      display: "none !important"
    }
  },
});
export default function StaticDatePickerLandscape({
  setter,
  getter,
  monthChangeHandler,
  availabilitiesDays,
  setActiveComponent,
}) {
  const classes = useStyle();
  const date = new Date();
  function disableWeekends(date) {
    return availabilitiesDays.indexOf(date.getDate()) > -1 ? false : true;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
      <StaticDatePicker
      
      showToolbar={false}
        disablePast
        maxDate={new Date(date.setMonth(date.getMonth() + 6))}
        minDate={new Date()}
        className={classes.root}
        orientation="landscape"
        value={getter}
        shouldDisableDate={disableWeekends}
        onChange={(newValue) => {
          setter(utcToISODate(newValue.toISOString(), false));
          setActiveComponent("time");
        }}
        renderInput={(params) => <TextField {...params} />}
        onMonthChange={monthChangeHandler}
      />
    </LocalizationProvider>
  );
}
