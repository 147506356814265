import React, { useEffect } from "react";
import { connect, useSelector } from 'react-redux';
import defaultA from "../../redux/action/defaultA";
import expertAcceptA from "../../redux/action/expertAcceptA";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import phoneSvg from "../../assets/images/conversation.png"
import messages from "../../constants/messages";

const IncomingModel = (props) => {
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);
    const getStarted = async () => {
        props.acceptCall()
    }
    return (
        <>
            <section className="h-full s-modal">
                <div className="s-modal__top">
                    <div className="flex items-center s-modal__countdown">
                        <img className="s-modal__img mr-4" src={props.profileImage} />
                        <CountdownCircleTimer
                            isPlaying
                            duration={props.callData.timerDuration}
                            colors={[
                                ['#000000', 1],
                            ]}
                            size={120}>
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                    </div>
                    <h4 className="s-modal__name text-center">{props.callData.clientInfo.name ? props.callData.clientInfo.name : (contents?.someone ?? messages?.someone)}</h4>
                    <p className="s-modal__subtext text-center">{ contents?.calls ?? messages?.calls }</p>
                </div>
                <div className="s-modal__bottom">
                    <div className="w-80 mx-auto flex items-center">
                        <button className="s-modal__btn" onClick={getStarted}>
                            <img src={phoneSvg} alt="Svg" />
                            <p className="ml-4">{contents?.answer ?? messages?.answer}</p>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        expertAcceptRequest: ({ from, body }) => {
            dispatch(expertAcceptA({ from, body }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingModel);