import {
  fetchExpertsForClientLoadingAction,
  fetchExpertsForClientAction,
  fetchExpertsForClientErrorAction,
} from "redux/action/client/tenantExperts";
import { getError } from "components/ErrorHandler/getError";
import apiName from "constants/apiName";
import { request } from "utils/request";

export default function callFetchExpertsForClient(tenant) {
  return async (dispatch) => {
    function onSuccess(payload) {
      dispatch(fetchExpertsForClientAction(payload));
    }
    function isFetching() {
      dispatch(fetchExpertsForClientLoadingAction());
    }

    function onError(err) {
      dispatch(fetchExpertsForClientErrorAction(err));
    }

    try {
      isFetching();
      const data = await request({
        url: `${apiName.expertsTeam}/${tenant}`,
        method: "get",
      });
      return onSuccess(data);
    } catch (err) {
      console.error(err);
      return onError(getError(err));
    }
  };
}
