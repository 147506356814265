export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const clientConnectionToken = () => localStorage.getItem("clientToken")
export const optionsHasAllSelectedVariants = (options, selectedVar) => {
    for (let i = 0; i < selectedVar.length; i++) {
        if (options.filter(x => x.value === selectedVar[i].option).length === 0)
            return false
    }
    return true
}
export const extractUrlValue = (key, url) => {
    if (typeof url === "undefined") url = window.location.href;
    var match = url.match("[?&]" + key + "=([^&]+)");
    return match ? match[1] : null;
}