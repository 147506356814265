export function utcToISODate(date, iso) {
  if (iso) {
    return date.split("T")[0];
  }

  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);
  return newDate.toISOString().toString().split("T")[0];
}

export const getYesterdayDate = () => {
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  return currentDate.toISOString().toString().split("T")[0];
};


export const getDateToString = (selectedDay) => {
  return new Date(selectedDay).toDateString();
};