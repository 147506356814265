
export const Validate = (messages, fieldName, value, value2 = null) => {
  const commonFormatWithSpecialChar = {
		pattern: /^.{1,150}$/,
		message: messages?.valid
	}
	const validation = {
		common: {
			presence: {
				message: messages?.fieldReq
			},
			format: commonFormatWithSpecialChar,
		},
		companyName: {
			presence: {
				message: messages?.fieldReq
			},
			format: commonFormatWithSpecialChar,
			length: {
				minimum: 2,
				maximum: 200,
				message: messages?.max2_200
			}
		},
		country: {
			presence: {
				message: messages?.fieldReq
			},
		},
		blank: {
			presence: {
				message: messages?.fieldReq
			}
		},
		companyUrl: {
			presence: {
				message: messages?.fieldReq
			},
			format: {
				pattern: /^[a-z A-Z 0-9]*$/,
				message: messages?.valid
			},
			length: {
				minimum: 3,
				maximum: 100,
				message: messages?.max3_100
			}
		},
		image: {
			presence: {
				message: messages?.img
			}
		},
		email: {
			presence: {
				message: messages?.enterEmail
			},
			format: {
				pattern: /^(([^<>()[\]\\.,;:"!#$%^&*?|=\s@"]+(\.[^<>()[\]\\.,;:"!#$%^&*?\-|=\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,8}))$/,
				message: messages?.vEmail
			}
		},
		emailFormat: {
			presence: {
				message: ""
			},
			format: {
				pattern: /^(([^<>()[\]\\.,;:"!#$%^&*?|=\s@"]+(\.[^<>()[\]\\.,;:"!#$%^&*?\-|=\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/,
				message: messages?.vEmail
			}
		},
		confirm_password: {
			presence: {
				message: messages?.conPassReq
			},
			length: {
				minimum: 1,
				message: ""
			},
			match: {
				message: messages?.conPassSame
			}
		},
		valid: {
			presence: {
				message: ""
			},
			format: commonFormatWithSpecialChar,
		},
		password: {
			presence: {
				message: messages?.pleaseEnterPassword
			},
			format: {
				pattern: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,20}$/,
				message: messages?.passwordInstructions
			}
		},
		new_password: {
			presence: {
				message: messages?.pleaseEnterNewPassword
			},
			format: {
				pattern: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,20}$/,
				message: messages?.passwordInstructions
			}
		},
		login_password: {
			presence: {
				message: messages?.pleaseEnterPassword
			}
		},
		old_password: {
			presence: {
				message: messages?.pleaseEnterPassword
			}
		}
	};
  let resp = "";
  if (validation.hasOwnProperty(fieldName)) {
    let v = validation[fieldName];
    if (value == "" || value == null) {
      resp = v["presence"]["message"];
    } else if (
      v.hasOwnProperty("format") &&
      !v["format"]["pattern"].test(value)
    ) {
      resp = v["format"]["message"];
    } else if (v.hasOwnProperty("length")) {
      let l = v["length"];
      if (l.hasOwnProperty("minimum") && value.length < l["minimum"]) {
        resp = l["message"];
      } else if (l.hasOwnProperty("maximum") && value.length > l["maximum"]) {
        resp = l["message"];
      } else {
        if (v.hasOwnProperty("match")) {
          let l = v["match"];
          if (value != value2) {
            resp = l["message"];
          } else {
          }
        } else {
        }
      }
    } else {
      resp = "";
    }
  } else {
    resp = "";
  }

  return resp;
}
