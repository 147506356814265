import {
  NONE,
  fetchTenantClientAppSettins,
  fetchTenantClientAppSettinsLoading,
  fetchTenantClientAppSettinsError,
  SUCCESS,
  LOADING,
  ERROR,
} from "redux/constants/reduxConstant";

const initialState = { STATUS: NONE, value: {} };

const tenantClientAppSettings = (state = initialState, action) => {
  switch (action.type) {
    case fetchTenantClientAppSettinsLoading:
      return { ...state, STATUS: LOADING };
    case fetchTenantClientAppSettins:
      return { ...state, STATUS: SUCCESS, value: action.payload };
    case fetchTenantClientAppSettinsError:
      return { ...state, STATUS: ERROR, value: action.payload };
    default:
      return state;
  }
};

export default tenantClientAppSettings;
