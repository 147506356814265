//SETTING BASE URL
let BASE_URL = "";
let Env = "devbee";
let SIGNALR_URL = "";

const LOCAL_DOMAINS = ["localhost", "devbee"];
const TEST_DOMAINS = ["testbee"];
const STAGE_DOMAINS = ["staging"];

if (
  LOCAL_DOMAINS.filter((x) => window.location.hostname.includes(x)).length > 0
) {
  
  BASE_URL = "https://api.devbee.me/v1/";
  SIGNALR_URL = "https://api.devbee.me/";
  
  Env = "devbee";
  //BASE_URL =  "https://localhost:44308/v1/";
  //SIGNALR_URL = "https://localhost:44308/";
} else if (
  TEST_DOMAINS.filter((x) => window.location.hostname.includes(x)).length > 0
) {
  BASE_URL = "https://api.testbee.me/v1/";
    SIGNALR_URL = "https://api.testbee.me/";
  Env = "testbee";
} else if (
  STAGE_DOMAINS.filter((x) => window.location.hostname.includes(x)).length > 0
) {
  BASE_URL = "https://staging-api.getbee.com/v1/";
    SIGNALR_URL = "https://staging-api.getbee.com/";
  Env = "stagingGetbee";
} else {
  BASE_URL = "https://api.getbee.com/v1/";
    SIGNALR_URL = "https://api.getbee.com/";
  Env = "getbee"
}

export const Environment = Env;

export const usersURL = BASE_URL + "Users/";
export const imgURL = BASE_URL + "Upload/";
export const lookupURL = BASE_URL + "Lookup/";
export const tenantURL = BASE_URL + "Tenant/";
export const expertURL = BASE_URL + "Expert/";
export const clientURL = BASE_URL + "Client/";
export const sessionURL = BASE_URL + "Session/";
export const scheduleURL = BASE_URL + "Schedule/"
export const reportsURL = BASE_URL + "Reports/"

const apiName = {
  baseApi: BASE_URL,
  signalR:SIGNALR_URL,

  // users
  adminRegister: usersURL + "registration",
  verifyEmail: usersURL + "registration/verify",
  resendEmail: usersURL + "registration/verify/send-code",
  imgUpload: imgURL + "Image/",
  login: usersURL + "login",
  logout: usersURL + "logout",
  userInfo: usersURL + "getInfo",
  forgotPassword: usersURL + "forget-password",
  resetPassword: usersURL + "reset-password",
  changePassword: usersURL + "changePassword",
  registerDevice: usersURL + "registerDevice",

  // expert
  setTenant: expertURL + "setTenant",
  online: expertURL + "online",
  offline: expertURL + "offline",
  updateProfile: expertURL + "updateProfile", // the expert updates his own profile data
  expertsTeam: expertURL + "getAll",
  expertsTeamPending: expertURL + "getInvited",
  requestExpertConnection: expertURL + "requestConnectionToken",
  getExpertInfo: expertURL + "getExpertInfo",
  updateAvailabilitySchedule: expertURL + "updateAvailabilitySchedule",
  getAvailabilitySchedule: expertURL + "getAvailabilitySchedule",
  getExpertScheduledCalls: expertURL+"getExpertScheduledCalls",
  cancelAppointment: expertURL+ "cancelAppointment",
  getActiveSessions: expertURL + "getActiveSessions",

  // lookup
  industry: lookupURL + "Industries",

  // tenant
  createWebUrl: tenantURL + "create",
  updateExpertProfile: tenantURL + "updateExpertProfile", // the admin updates the data of one of the experts
  updateCompanyDetails: tenantURL + "update",
  inviteExp: tenantURL + "invite/expert",
  checkInvite: tenantURL + "invite/checkInvitation",
  tenantInfo: tenantURL + "info",
  tenantCustomization: tenantURL + "customization",
  nameExists: tenantURL + "nameExists",
  updateStoreIntegration: tenantURL + "updateStoreIntegration",
  deleteExpertProfile: tenantURL + "deleteExpertProfile",
  revokeInvitation: tenantURL + "invite/revokeInvitation",
  getStoreIntegration: tenantURL + "getStoreIntegration",

  //Client URLs
  requestCall: clientURL + "requestConnectionToken",
  expertAccept: expertURL + "DeclineCall",

  // Timezone
  timezone: BASE_URL + "Schedule/timezones",
  getNewInstantRoomURL: sessionURL + "createInstantSession",
  getSessionChatHistory: sessionURL + "GetSessionChatHistory",

  // Schedule
  getExpertAvailabilities: scheduleURL+"expert",
  book: scheduleURL+"book",
  cancel: scheduleURL+"cancel",

  // Reporting
  getSessionListReport:  reportsURL+"getSessionListReport",
  getGeneralDataReport: reportsURL+"getGeneralDataReport",
  getCSVWithCommand: reportsURL+"getCSVWithCommand",
  getExpertNameMapping: reportsURL+"getExpertNameMapping"
  
};

let STORAGE = "";
if (
  ["localhost", "devbee", "testbee"].filter((x) =>
    window.location.hostname.includes(x)
  ).length > 0
) {
  STORAGE = "https://testbeestorage.blob.core.windows.net/translations";
} else {
  STORAGE = "https://getbee.blob.core.windows.net/translations";
}
export const languageAPI = {
  set current(name) {
    this.getAPIName = `${STORAGE}/${name}.json`;
  },
  getAPIName: null,
};

export default apiName;
