import {
  fetchRandomSignalRToken,
  fetchRandomSignalRTokenLoading,
  fetchRandomSignalRTokenError,
  NONE,
  ERROR,
  LOADING,
  SUCCESS,
} from "redux/constants/reduxConstant";

const initialState = { STATUS: NONE, value: {} };

export const randomSingalRConnectionToken = (state = initialState, action) => {
  switch (action.type) {
    case fetchRandomSignalRTokenLoading:
      return { ...state, STATUS: LOADING };
    case fetchRandomSignalRToken:
      return { ...state, STATUS: SUCCESS, value: action.payload };
    case fetchRandomSignalRTokenError:
      return { ...state, STATUS: ERROR, value: action.payload };
    default:
      return state;
  }
};

export default randomSingalRConnectionToken;
