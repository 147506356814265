import { deleteExpertProfileLoading, deleteExpertProfileSuccess, deleteExpertProfileError, SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'

export default function deleteExpertProfileR(state = {}, action) {
    switch (action.type) {
        case deleteExpertProfileLoading:
            return Object.assign({}, state, { status: LOADING });
        case deleteExpertProfileSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case deleteExpertProfileError:
            return Object.assign({}, state, { status: ERROR, value: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}