import logo from '../assets/images/logo.svg'
import user2 from '../assets/images/user2.png'
import closeIcon from '../assets/images/icon-close.svg';
import adminLogo from '../assets/images/admin-logo.svg';
import appStore from '../assets/images/app-store.svg';
import beeShape from '../assets/images/bee-shape.svg';
import backY from '../assets/images/icon-back-y.svg';
import back from '../assets/images/icon-back.svg';
import emojiGlass from '../assets/images/icon-emoji-glass.svg';
import emojiSleep from '../assets/images/icon-emoji-sleep.svg';
import endCall from '../assets/images/icon-endcall.svg';
import eyeClose from '../assets/images/icon-eye-close.svg';
import eyeOpen from '../assets/images/eye.svg';
import micOff from '../assets/images/icon-mic-off.svg';
import mic from '../assets/images/icon-mic.svg';
import setting from '../assets/images/icon-setting.svg';
import videoOff from '../assets/images/icon-video-off.svg';
import videoWh from '../assets/images/icon-video-wh.svg';
import video from '../assets/images/icon-video.svg';
import inGradient from '../assets/images/incoming-gradient.png';
import logoIcon from '../assets/images/logo-icon.svg';
import logoWhite from '../assets/images/logo-white.svg';
import msgSend from '../assets/images/message-send-icon.png';
import noPhoto from '../assets/images/no-photo.png';
import playStore from '../assets/images/play-store.svg';
import selDropDown from '../assets/images/select-drop-down.svg';
import add from '../assets/images/add.svg'
import session1 from '../assets/images/session-1.png';
import session2 from '../assets/images/session-2.png';
import sideImg1 from '../assets/images/side-img-1.png';
import user1 from '../assets/images/user1.png';
import user3 from '../assets/images/user3.png';
import user4 from '../assets/images/user4.png';
import iconGlass from '../assets/images/icon-emoji-glass.svg'
import sleep from '../assets/images/sleep.png'
import dummy from '../assets/images/dummy.png'
import stats from '../assets/images/stats.svg'
import eyeOpened from '../assets/images/eye.svg'
import poweredBy from '../assets/images/poweredBy.svg'
import bgGradient from '../assets/images/bgGradient.png'
import emailSent from '../assets/images/emailSent.png'
import bgV1 from '../assets/images/BG-V1.png'
import vector from '../assets/images/vector.png'
import bgInvitation from '../assets/images/BG-invitation.png'
import invitation from '../assets/images/invitation.png'
import bgForgotpass from '../assets/images/BG-forgotpass.png'
import forgotpass from '../assets/images/forgotpass.png'
import bgCheck from '../assets/images/BG-check.png'
import bgNewpass from '../assets/images/BG-newpass.png'
import companyInfo from '../assets/images/companyinfo.png'
import companyDomain from '../assets/images/companydomain.png'
import drops from '../assets/images/drops.png'
import upload from '../assets/images/upload.png'
import inviteTeam from '../assets/images/inviteTeam.png'
import lancomeLogo from '../assets/images/Lancome-Logo.png'
import salesData from '../assets/images/sales-data.png'
import newPass from '../assets/images/new-pass.png'
import navigation from '../assets/images/navigation.png'
import selectedMark from '../assets/images/select.png'
import homepageIcon from '../assets/images/icons/homepage.png'
import teamSalesIcon from '../assets/images/icons/team-sales.png'
import teamMembersIcon from '../assets/images/icons/team-members.png'
import companyDetailsIcon from '../assets/images/icons/company-details.png'
import subscriptionIcon from '../assets/images/icons/subscription.png'
import personalizeIcon from '../assets/images/icons/personalize.png'
import integrationsIcon from '../assets/images/icons/integrations.png'
import linkIcon from '../assets/images/icons/link.png'
import handGreetingsIcon from '../assets/images/hand-greeting.svg'


const Images = {
    homepageIcon,
    teamSalesIcon,
    handGreetingsIcon,
    teamMembersIcon,
    personalizeIcon,
    linkIcon,
    subscriptionIcon,
    integrationsIcon,
    companyDetailsIcon,
    logo: logo,
    user2: user2,
    closeIcon: closeIcon,
    poweredBy:poweredBy,
    emailSent:emailSent,
    adminLogo: adminLogo,
    bgGradient:bgGradient,
    appStore: appStore,
    beeShape: beeShape,
    backY: backY,
    back: back,
    emojiGlass: emojiGlass,
    emojiSleep: emojiSleep,
    endCall: endCall,
    eyeClose: eyeClose,
    eyeOpen: eyeOpen,
    eyeOpened:eyeOpened,
    micOff: micOff,
    mic: mic,
    setting: setting,
    videoOff: videoOff,
    videoWh: videoWh,
    video: video,
    inGradient: inGradient,
    logoIcon: logoIcon,
    logoWhite: logoWhite,
    msgSend: msgSend,
    noPhoto: noPhoto,
    playStore: playStore,
    selDropDown: selDropDown,
    session1: session1,
    session2: session2,
    sideImg1: sideImg1,
    user1: user1,
    user3: user3,
    user4: user4,
    dummy: dummy,
    iconGlass: iconGlass,
    sleep: sleep,
    stats:stats,
    bgV1:bgV1,
    vector: vector,
    bgInvitation: bgInvitation,
    invitation: invitation,
    bgForgotpass: bgForgotpass,
    forgotpass: forgotpass,
    bgCheck: bgCheck,
    bgNewpass: bgNewpass,
    drops: drops,
    upload: upload,
    companyInfo: companyInfo,
    companyDomain: companyDomain,
    inviteTeam: inviteTeam,
    lancomeLogo: lancomeLogo,
    salesData: salesData,
    newPass: newPass,
    navigation: navigation,
    add: add,
    selectedMark: selectedMark
};

export default Images;
