import { useEffect } from "react";
import { useDispatch } from "react-redux";
import callFetchExpertsForClient from "redux/call-action/callFetchExpertsForClient";

export default function useFetchExpertsForClient({ tenant, experts }) {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchExpertsForClient = async () => {
      await dispatch(callFetchExpertsForClient(tenant));
    };

    if (!experts || Object.entries(experts).length === 0) {
      fetchExpertsForClient();
    }
  }, [tenant, dispatch, experts]);
}
