import messages from "constants/messages";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import getBeeIcon from "../../../assets/images/logo-icon.svg";
const ExpertCompletedScreen = ({ logo, leaveChannel }) => {
  const [, setTotal] = useState(0);
  const cartItems = useSelector((state) => state.cartReducer.cartItems);
  const {
    preferedLanguageContent: { contents },
  } = useSelector((state) => state);
  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      calculateTotal(cartItems);
    }
  }, [cartItems]);

  const calculateTotal = (cartDetail) => {
    const totalPrice = cartDetail.reduce((prev, item) => {
      if (item && item.selectedVariant) {
        return prev + item.quantity * item.selectedVariant[0].price;
      } else {
        return prev + item.quantity * item.variants[0].price;
      }
    }, 0);

    setTotal(totalPrice);
  };

  const onRedirect = async () => {
    localStorage.setItem("online", true);
    await leaveChannel();
  };

  return (
    <div class="w-full flex flex-wrap h-screen s-client">
      <div class={`w-full flex flex-col s-completed-call`}>
        <div class="call-ended-flex-legacy flex justify-center items-center pt-10 md:pl-12 md:-mb-24 bg-main">
          <img
            class=" text-white font-bold text-xl p-4 company-size-logo-big"
            src={logo}
          />
        </div>
        <div class="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
          <p class="text-center text-3xl s-client__title">
            {contents?.conversationEnded ?? messages?.conversationEnded}
            <br />
          </p>
          <div class="flex flex-col pt-2 md:pt-4">
            <button onClick={() => onRedirect()} className="btn btn--large">
              <p>{contents?.toHomePage ?? messages?.toHomePage}</p>
            </button>
          </div>
        </div>
        <footer className="flex justify-center items-center left-0 right-0">
          <div className="container-fluid">
            <a href="javascript:void(0);" className="footer-logo-item"></a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ExpertCompletedScreen;
