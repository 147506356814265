import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  showToast
} from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import {
  defaultInput as DefaultInput
} from "../../components/Common/Input";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import forgotPasswordA from "../../redux/action/common/forgotPasswordA";
import defaultA from "../../redux/action/defaultA";
import { ERROR, LOADING, SUCCESS } from "../../redux/constants/reduxConstant";
import { Validate as validate } from "../../utils/validate_wrapper";

const ExpForgetPass = (props) => {
  const history = useHistory();
  const { preferedLanguageContent: { contents } } = useSelector((state) => state);;
  const [state, setState] = useState({
    loading: false,
    email: "",
  });

  const submit = (e) => {
    e.preventDefault();
    clearError();
    if (!checkValidation()) {
      return;
    }

    let formData = {
      email: state.email,
    };

    props.forgotPasswordRequest({ from: "expForgetPass", body: formData });
    goToConfirmScreen()
  };

  const goToConfirmScreen = () => {
    history.push({
        pathname: '/email-check',
        state: {
            email: state.email
        },
    })
  }

  // validate form data
  const checkValidation = () => {
    var emailErr = validate(contents, 'email', state.email);

    if (emailErr) {
      setState({
        ...state,
        emailErr: emailErr,
      });
      return false;
    } else {
      return true;
    }
  };

  // clean error state
  const clearError = () => {
    setState({ ...state, emailErr: "" });
  };

  useEffect(() => {
    const forgotPasswordPath = props.ScreenProps.forgotPasswordR;
    // console.log("forgotPassword Path", forgotPasswordPath);
    if (forgotPasswordPath.status == LOADING) {
      setState({ ...state, loading: true });
    } else if (forgotPasswordPath.status == SUCCESS) {
      console.log("forgotPasswordRes succcess res ", forgotPasswordPath.value.data);
      setState({ ...state, loading: false, email: "" });
      showToast(SUCCESS, "success");
      props.defaultRequest();
      // forgotPasswordPath.value.data.role = 'expert';
      // localStorage.setItem('userData', JSON.stringify(forgotPasswordPath.value.data))
      // history.push({
      //     pathname: '/admin-home'
      // })
    } else if (forgotPasswordPath.status == ERROR) {
      console.log("forgotPassword error res ", forgotPasswordPath);
      setState({ ...state, loading: false });
      errorHandle(forgotPasswordPath, contents);
      props.defaultRequest();
    }
  }, [props.ScreenProps]);

  const goToSignin = () => {
    history.push({
      pathname: '/login'
    })
  }

  return (
    <>
      <ToastContainer />
      <div className="expert-content-block-section">
        <div className="first-section">
          <div className="form-content-blk">
            <Header />
            <div className="expert-content-block justify-center items-center back-btn-block m-auto">
              <div className="w-full">
                <button onClick={() => props.history.goBack()} className="expert-back-btn">
                  <img src={Images.vector} className="vector-icon" />
                  <span className="back-text pl-2">{contents?.btnBack ?? messages?.btnBack}</span>
                </button>
                <h4>{contents?.forgetPassword ?? messages?.forgetPassword}</h4>
                <p className="linkCautuin">
                  {contents?.sendPasswordInstructions ?? messages?.sendPasswordInstructions}
                </p>
                <form onSubmit={submit} className="register-form mb-3">
                  <DefaultInput
                    type={"email"}
                    placeholder={"Email"}
                    name={"email"}
                    value={state.email}
                    onChange={(e) =>
                        setState({
                          ...state,
                          email: e.target.value ? e.target.value.trim() : "",
                          emailErr: validate(contents, 'email', e.target.value),
                        })
                      }
                    error={state.emailErr}
                  />
                  <div className="btn-block send-instruction">
                    <button
                      type="button"
                      className="btn btn--fifth"
                      onClick={goToSignin}>
                      {contents?.btnCancel ?? messages?.btnCancel}
                    </button>
                    <button
                      type="submit"
                      disabled={props.isSubmitting}
                      className="btn btn--primary">
                      {contents?.btnSendInstructions ?? messages?.btnSendInstructions}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="second-section right-img">
          <img src={Images.forgotpass} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ScreenProps: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPasswordRequest: ({ from, body }) => {
      dispatch(forgotPasswordA({ from, body }));
    },
    defaultRequest: () => {
      dispatch(defaultA());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpForgetPass);
