import {
  fetchLanguage,
  SUCCESS,
  ERROR,
  LOADING,
  NONE,
  fetchLanguageLoading,
  fetchLanguageError,
  changeCountriesList,
} from "redux/constants/reduxConstant";

const initialState = { STATUS: NONE, contents: {}, countriesList: {}, lng: "en" };

const preferedLanguageContent = (state = initialState, action) => {
  switch (action.type) {
    case fetchLanguageLoading:
      return { ...state, STATUS: LOADING };
    case changeCountriesList:
        return { ...state, countriesList: action.payload };
    case fetchLanguage:
      return {
        ...state,
        STATUS: SUCCESS,
        lng: action.payload.lng,
        contents: action.payload.json,
      };
    case fetchLanguageError:
      return { ...state, STATUS: ERROR };
    default:
      return state;
  }
};

export default preferedLanguageContent;
