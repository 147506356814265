import messages from "constants/messages";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cartGreen from "../../assets/images/cart-green.png";
import cart from "../../assets/images/cart.png";
import logo from "../../assets/images/GetBee-Logo.png";
import MyTimer from "../../components/Common/MyTimer";
import { goToSelectedStep } from "../../redux/action/shopping";
import useSize from "../../utils/useSize";
import BuyerCard from "./BuyerCard";
import BuyerLoader from "./BuyerLoader";
const Shop = () => {
  const dispatch = useDispatch()
  const { preferedLanguageContent: { contents } } = useSelector((state) => state)
  const recommendedProducts = useSelector(state => state.shoppingReducer.recommendation)
  const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus)
  const cartItems = useSelector(state => state.cartReducer.cartItems)
  const tenantInfo = useSelector(state => state.tenantInfoR.value)
  const screen = useSize()
  return (
    <section className={`s-buyer s-home  relative h-full   mx-auto flex items-center flex-col`}>
      {/* TOP PART */}
      {/* SEARCH PART */}
      {screen.size === "sm" ? <div className="flex items-center z-50 pl-2 pr-2 pt-4 pb-4 w-full justify-between bg-white">
        <div className="flex items-center">
          <div className="s-video__liveDot" />
          <span className="session-time">{Object.keys(sessionStatus).length > 0 && sessionStatus.startTime && <MyTimer expiryTimestamp={sessionStatus.startTime} />}</span>
        </div>
        <div>
          <img style={{
            height: 'auto',
            maxHeight: '24px',
            width: 'auto',
            maxWidth: '120px'
          }} src={tenantInfo && tenantInfo.data.logoUrl ? tenantInfo.data.logoUrl : logo} alt="getbee-logo" />
        </div>
        <div className="flex items-center  cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(7))}>
          <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
      </div>
        : <div className={`flex justify-between items-center z-50 w-11/12 mx-auto  mt-8 ${recommendedProducts && recommendedProducts.length > 0 && `s-buyer__list`}`}>
          <div class="relative  s-home__input flex items-center">
          </div>
          <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(7))}>
            <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
        </div>}
      {/* CARDS PART */}
      <section className="w-11/12 mx-auto mt-2">
        <div className="">
          <div className="mt-6 grid grid-cols-1  sm:grid-cols-1 md:grid-cols-1 ">
            {recommendedProducts && recommendedProducts.length > 0 ?
              recommendedProducts.map((product) => (
                <BuyerCard product={product} />
              ))
              : <BuyerLoader />}
          </div>
        </div>
      </section>
    </section>
  )
}
export default Shop