import React from "react"
import cart from "../../assets/images/cart.png";
import cartGreen from "../../assets/images/cart-green.png";
import vector from "../../assets/images/vector.png";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader";
import { goToSelectedStep, } from "../../redux/action/shopping";
import { Swiper, SwiperSlide } from "swiper/react";
import BuyerCardItem from "./BuyerCardItem";
import useSize from "../../utils/useSize";
import logo from "../../assets/images/GetBee-Logo.png";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, {
  Keyboard, Scrollbar, Navigation, Pagination
} from 'swiper';
import MyTimer from "../../components/Common/MyTimer";
import messages from "constants/messages";

SwiperCore.use([Keyboard, Scrollbar, Navigation, Pagination]);
const BuyerSingleProduct = () => {
  const dispatch = useDispatch()
  const { preferedLanguageContent: { contents }, tenantInfoR: {value} } = useSelector((state) => state)
  const {data: {logoUrl} = {}} = value || {};
  const loading = useSelector(state => state.shoppingReducer.loading)
  const recommendedProducts = useSelector(state => state.shoppingReducer.recommendation)
  const product = useSelector(state => state.shoppingReducer.product)
  const cartItems = useSelector(state => state.cartReducer.cartItems)
  const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus);

  
  

  const screen = useSize()
  return (
    <section className="s-home s-product-view relative h-full  mx-auto   mx-auto flex items-center flex-col s-card">
      {/* TOP PART */}
      {/* SEARCH PART */}
      {screen.size === "sm" ? <div className="flex items-center pl-2 pr-2 pt-4 pb-4 w-full justify-between bg-white">
        <div className="flex items-center">
          <div className="s-video__liveDot" />
          <span className="session-time">{Object.keys(sessionStatus).length > 0 && sessionStatus.startTime && <MyTimer expiryTimestamp={sessionStatus.startTime} />}</span>
        </div>
        <button class="relative  s-home__floatingBtn flex items-center" onClick={() => dispatch(goToSelectedStep(1))}>
          <img src={vector} alt="search icon" />
          <p className="text-black ml-2">{contents?.btnBack ?? messages?.btnBack}</p>
        </button>
        <div>
          <img src={logoUrl} alt="getbee-logo" />
        </div>
        <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(7))}>
          <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
      </div>
        : <div className="flex justify-between items-center mt-8 w-11/12 mx-autoborder-b s-product-view__topBar">
          <button class="relative  s-home__input flex items-center" onClick={() => dispatch(goToSelectedStep(1))}>
            <img src={vector} alt="search icon" />
            <p className="text-black ml-2">{contents?.btnBack ?? messages?.btnBack}</p>
          </button>
          <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(7))}>
            <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
        </div>}
      {/* CAROUSEL PART */}
      <div className="w-11/12 mx-auto relative flex flex-col items-center mt-8 s-card__carousel">
        <Swiper
          slidesPerView={3}
          centeredSlides={false}
          slidesPerGroupSkip={1}
          grabCursor={true}
          keyboard={{
            enabled: true
          }}
          breakpoints={{
            "769": {
              slidesPerView: 3,
              slidesPerGroup: 2
            }
          }}
          scrollbar={true}
          navigation={true}
          pagination={{
            clickable: true
          }}
          className="mySwiper"
        >
          {product && product.images.length && product.images.map(item => (
            <SwiperSlide>
              <img style={{ objectFit: 'contain', width: "190px", backgroundColor: '#fff', height: "190px", marginRight: "10px" }} src={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* CARDS PART */}
      <section className="md:w-full w-11/12 mx-auto mt-2 s-product-view__container">
        <div className="mt-6 grid grid-cols-1  sm:grid-cols-1 md:grid-cols-1 ">
          {!loading && product && Object.keys(product).length > 0 ?
            <BuyerCardItem recommendedProducts={recommendedProducts} product={product} />
            : <Loader />}
        </div>
      </section>
    </section>
  )
}
export default BuyerSingleProduct