import * as signalR from "@microsoft/signalr";
import defaultClientBackground from "assets/img/default-client-background-image.png";
import backIcon from "assets/svg/navigation-back.svg";
import messages from "constants/messages";
import useLanguage from "hooks/useLanguage";
import Toast from "light-toast";
import { isAllFormElementDisabled } from "pureFunctions/isAllFormElementDisabled";
import { isValidURL } from "pureFunctions/validURL";
import callVideoIcon from "assets/svg/call-icon.svg";
import ClientLanguageDropDown from "./common/ClientLanguageDropDown";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import {
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import logoutCall from "redux/action/logout";
import { getSessionStatus } from "redux/action/shopping";
import tenantCustomizationA from "redux/action/tenantCustomizationA";
import LoadingBee from "views/client/common/Messages/LoadingBee";
import ErrorText, { showToast } from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import { defaultInput as DefaultInput } from "../../components/Common/Input";
import udid from "../../components/Common/udid";
import apiName from "../../constants/apiName";
import defaultA from "../../redux/action/defaultA";
import requestCallA from "../../redux/action/requestCallA";
import { ERROR, LOADING, SUCCESS } from "../../redux/constants/reduxConstant";
import { extractUrlValue, isUserLoggedIn } from "../../utils/utils";
import { Validate as validate } from "../../utils/validate_wrapper";
import SchedulingTemplate from "./common/SchedulingTemplate";
import MainContent from "./InitialPage/MainContent";
import RequestCallScreen from "./RequestCallScreen";
import useSetGetLanguageForClient from "hooks/setGetLanguageForClient";
import useFetchClientAppSettings from "hooks/useFetchClientAppSettings";
import callFetchClientAppSettings from "redux/call-action/callFetchClientAppSetting";
import {
  localStorageClientLanguageKey,
  localStorageLanguageKey,
} from "config/Config";
import { getLngRowByLngCode } from "pureFunctions/getLngRowByLngCode";
import { preferredLanguage } from "components/Config/languages";
import { isJsonString } from "pureFunctions/isJSONString";
import callFetchClientAppTextSettings from "redux/call-action/callFetchClientAppTextSetting";
import MainContentClientNotification from "./InitialPage/MainContentClientNotification";
import { typeOfCalls } from "config/TabConfig";
import useSignalR from "hooks/useSignalR";

const ClientConsultation = (props) => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const {
    numberOfAvailableExperts
  } = useSignalR();

  const {
    preferedLanguageContent: { contents },
    tenantClientAppSettings,
    tenantClientAppTextSettings,
  } = useSelector((state) => state);
  const { value: clientAppSetting } = tenantClientAppSettings;
  const [newConnection, setNewConnection] = useState();
  const [sessionId, setSessionId] = useState("");
  const url = new URL(window.location.href);
  let instantRoomSessionId = extractUrlValue("session_id", url.sessionId);
  const [callOptions, setCallOptions] = useState({
    audio: true,
    video: true,
    endCall: false,
  });
  const [callCancelled, setCallCancelled] = useState(false);
  const [request, setRequest] = useState(false);
  const dispatch = useDispatch();
  const sessionStatus = useSelector(
    (state) => state.shoppingReducer.sessionStatus
  );
  const [state, setState] = useState({
    loading: false,
    name: "",
    email: "",
    emailAutoGenerated: false,
    country: "US",
    acceptTerms: true,
    acceptTermsError: "",
    channelName: "test",
    tenantInfo:
      location.state && location.state.tenantInfo
        ? location.state.tenantInfo
        : "",
  });
  useEffect(() => {
    window.addEventListener("beforeunload", async (e) => {
      e.preventDefault();
      if (
        instantRoomSessionId &&
        requestedToJoinInstantCall &&
        newConnection?._connectionState === "Connected"
      ) {
        await newConnection.invoke("LeaveWaitingList", {
          sessionId: instantRoomSessionId,
          tenant: state?.tenantInfo.uniqueIdentifier,
          email: state.email,
        });
      }
    });
  }, []);
  useEffect(() => {
    if (Object.keys(sessionStatus).length > 0) {
      if (sessionStatus.sessionState === "Completed") {
        let messagesData = {
          title: contents?.conversationEnded ?? messages?.conversationEnded,
          main: <>{contents?.comeBackTryLater ?? messages?.comeBackTryLater}</>,
          actionButtons: (
            <button className="btn btn--large">
              <Link to={`/${state?.tenantInfo.uniqueIdentifier}/client-home`}>
                <span>{contents?.tryAgain ?? messages?.tryAgain}</span>
              </Link>
            </button>
          ),
        };
        setInstantCallResponseMessage(messagesData);
        setMessageScreenData(messagesData);
        setRequestedToJoinInstantCall(true);
      } else if (isUserLoggedIn()) {
        const { email, sessionId: authSessionId } = JSON.parse(
          localStorage.getItem("userData")
        );
        const isTheSameAdminWhoCreatedTheInstantSession = (expertMail) => {
          return expertMail === email;
        };
        if (
          isTheSameAdminWhoCreatedTheInstantSession(
            sessionStatus?.expertInfo?.email
          )
        ) {
          const path = `/${params.tenant}/video-call`;
          var searchparam = `?session_id=${instantRoomSessionId}`;
          window.location.href = `${window.location.origin}${path}${searchparam}`;
        } else {
          dispatch(logoutCall(authSessionId));
          localStorage.clear();
        }
      }
    }
  }, [
    sessionStatus,
    contents,
    instantRoomSessionId,
    dispatch,
    state?.tenantInfo.uniqueIdentifier,
  ]);
  useEffect(() => {
    if (instantRoomSessionId) {
      const payload = {
        sessionId: instantRoomSessionId,
      };
      dispatch(getSessionStatus(apiName.baseApi, payload));
    } else if (isUserLoggedIn()) {
      history.replace("/admin-home");
    }

    localStorage.removeItem("mediaState");
    props.tenantCustomizationRequest({
      body: {
        tenant: params.tenant,
      },
    });
  }, [params.tenant]);

  const getAutoGeneratedMail = () =>
    `anonymous${udid().replace(/-/g, "")}@getbee.com`;

  useEffect(() => {
    const tenantCustomizationPath = props.ScreenProps.tenantCustomizationR;

    // //console.log("tenantInfo Path", tenantInfoPath.value);
    if (tenantCustomizationPath.status === LOADING) {
      setState({ ...state, loading: true });
    } else if (tenantCustomizationPath.status === SUCCESS) {
      //console.log("Info ====>", tenantCustomizationPath.value.data);
      let generateMail =
        !tenantCustomizationPath.value.data?.basicFieldsSettings?.email
          ?.showField ||
        !tenantCustomizationPath.value.data?.basicFieldsSettings?.email
          ?.isRequired;
      setState({
        ...state,
        loading: false,
        tenantInfo: tenantCustomizationPath.value.data,
        email: generateMail ? getAutoGeneratedMail() : "",
        emailAutoGenerated: generateMail,
      });

      // localStorage.setItem(
      //   "selectedLanguage",
      //   JSON.stringify(
      //     tenantCustomizationPath.value.data.clientDefaultLanguage ?? "en"
      //   )
      // );
      // showToast(tenantInfoPath.value.data.status, 'success');
      // setSelectedLanguage(
      //   tenantCustomizationPath.value.data.clientDefaultLanguage ?? "en"
      // );
      props.defaultRequest();
    } else if (tenantCustomizationPath.status === ERROR) {
      //console.log("tenantInfo error res ", tenantCustomizationPath);
      setState({ ...state, loading: false });
      // errorHandle(tenantInfoPath)
      props.defaultRequest();
      localStorage.clear();
      const path = `/`;
      history.push({ pathname: path });
    }
  }, [props.ScreenProps]);

  const submit = async (e) => {
    //console.log("submit ------>");

    e.preventDefault();

    clearError();

    if (!checkValidation()) {
      return;
    }
    let formData = {
      email: state.email,
      emailAutoGenerated: state.emailAutoGenerated,
      tenant: params.tenant,
      userId: state.email,
      id: udid(),
    };
    props.requestCallRequest({
      from: "client-home",
      body: { ...formData, userId: state.email },
    });
  };

  // validate form data
  const checkValidation = () => {
    let tmbMail = state.email;
    let emailAutoGenerated = state.emailAutoGenerated;
    let emailErr = "";
    if (
      !state.email &&
      (!state?.tenantInfo?.basicFieldsSettings?.email?.showField ||
        !state?.tenantInfo?.basicFieldsSettings?.email?.isRequired)
    ) {
      tmbMail = getAutoGeneratedMail();
      emailAutoGenerated = true;
    } else {
      if (state?.tenantInfo?.basicFieldsSettings?.email?.isRequired) {
        emailErr = validate(contents, "blank", tmbMail);
      }
      if (emailErr === "") {
        emailErr = validate(contents, "email", tmbMail);
      }
    }

    let nameErr = "";
    if (state?.tenantInfo?.basicFieldsSettings?.name?.showField) {
      if (state?.tenantInfo?.basicFieldsSettings?.name?.isRequired)
        nameErr = validate(contents, "blank", state.name);
    }

    let phoneErr = "";
    if (state?.tenantInfo?.basicFieldsSettings?.phone?.showField) {
      if (state?.tenantInfo?.basicFieldsSettings?.phone?.isRequired) {
        phoneErr = validate(contents, "blank", state.phoneNumber);
      }
      if (phoneErr === "Err") {
        if (!isValidPhoneNumber(state.phoneNumber))
          phoneErr =
            contents?.incorrectPhoneNumber ?? messages?.incorrectPhoneNumber;
      }
    }

    let acceptTermsError = state.acceptTerms
      ? ""
      : contents?.agreeTerm ?? messages?.agreeTerm;

    if (emailErr || nameErr || phoneErr || acceptTermsError) {
      setState({
        ...state,
        email: tmbMail,
        emailAutoGenerated,
        nameErr,
        emailErr,
        phoneErr,
        acceptTermsError: acceptTermsError,
      });
      return false;
    } else {
      setState({ ...state, email: tmbMail, emailAutoGenerated });
      return true;
    }
  };
  // clean error state
  const clearError = () => {
    setState({
      ...state,
      nameErr: "",
      emailErr: "",
      acceptTermsError: "",
    });
  };
  const [messageScreenData, setMessageScreenData] = useState({
    title: "",
  });

  const [instantCallRequestResponseSR, setInstantCallRequestResponseSR] =
    useState({});
  const [instantCallResponseMessage, setInstantCallResponseMessage] = useState({
    title: "",
    main: <></>,
  });

  const getActionButton = (buttonText, linkHref) => {
    return (
      <button className="btn btn--large">
        <Link to={`${linkHref}`}>
          <span>{buttonText}</span>
        </Link>
      </button>
    );
  };

  // My useeffect, It
  useEffect(() => {
    const updateTheInstantCallRequestResponse = () => {
      if (instantCallRequestResponseSR?.isSucceeded) {
        setInstantCallResponseMessage({
          title: (contents?.hello ?? messages?.hello) + " " + state.name,
          main:
            contents?.sentNotificationsToSpecialist ??
            messages?.sentNotificationsToSpecialist,
          actionButtons: <></>,
        });
      } else if (
        instantCallRequestResponseSR?.status === "TheInstantCallIsFull"
      ) {
        setInstantCallResponseMessage({
          main: contents?.comeBackTryLater ?? messages?.comeBackTryLater,
          title: contents.sessionFull ?? messages?.sessionFull,
          actionButtons: getActionButton(
            contents?.tryAgain ?? messages?.tryAgain,
            state?.tenantInfo.uniqueIdentifier
          ),
        });
      } else {
        setInstantCallRequestResponseSR({
          title: contents?.anErrorOccured ?? messages?.anErrorOccured,
          main: contents?.comeBackTryLater ?? messages?.comeBackTryLater,
          actionButtons: getActionButton(
            contents?.tryAgain ?? messages?.tryAgain,
            state?.tenantInfo.uniqueIdentifier
          ),
        });
      }
    };
    if (Object.entries(instantCallRequestResponseSR).length > 0) {
      updateTheInstantCallRequestResponse();
    }
  }, [
    instantCallRequestResponseSR,
    contents,
    state.name,
    state?.tenantInfo.uniqueIdentifier,
  ]);

  const AskToJoinInstantCall = async (
    newconnection,
    clientRequestData,
    contents
  ) => {
    clientRequestData.sessionId = instantRoomSessionId;

    let result = await newconnection.invoke(
      "RequestJoiningInstantCall",
      clientRequestData
    );

    setInstantCallRequestResponseSR(result);
    // alert()
    if (result?.isSucceeded) {
      setSessionId(result.sessionId);

      let messagesData = {
        title: (contents?.hello ?? messages?.hello) + " " + state.name,
        main: (
          <>
            {contents?.sentNotificationsToSpecialist ??
              messages?.sentNotificationsToSpecialist + "hello world"}
          </>
        ),
        actionButtons: <></>,
      };

      setMessageScreenData(messagesData);
      setRequestedToJoinInstantCall(true);
    } else if (result?.status === "TheInstantCallIsFull") {
      let messagesData = {
        title: contents.sessionFull ?? messages?.sessionFull,
        main: <>{contents?.comeBackTryLater ?? messages?.comeBackTryLater}</>,
        actionButtons: (
          <button className="btn btn--primary btn-lg">
            <Link to={`/${state?.tenantInfo.uniqueIdentifier}/client-home`}>
              <span>{contents?.tryAgain ?? messages?.tryAgain}</span>
            </Link>
          </button>
        ),
      };

      setMessageScreenData(messagesData);
      setRequestedToJoinInstantCall(true);
    } else {
      let messagesData = {
        title: contents?.anErrorOccured ?? messages?.anErrorOccured,
        main: <>{contents?.comeBackTryLater ?? messages?.comeBackTryLater}</>,
        actionButtons: (
          <button className="btn btn--primary btn-lg">
            <Link to={`/${state?.tenantInfo.uniqueIdentifier}/client-home`}>
              <span>{contents?.tryAgain ?? messages?.tryAgain}</span>
            </Link>
          </button>
        ),
      };

      setMessageScreenData(messagesData);
      setRequestedToJoinInstantCall(true);
    }
  };

  // //console.log("contents", contents);
  // console.log("instantCallRequestResponseSR", instantCallRequestResponseSR);

  const FindConsultant = async (newconnection, clientRequestData) => {
    let result = await newconnection.invoke("RequestCall", clientRequestData);

    if (result?.isSucceeded) {
      setRequest(true);

      setSessionId(result.sessionId);
    } else if (result?.status === "ThereAreNoExpertsOnline") {
      setCallCancelled(true);
      setRequest(true);
    } else if (result?.status === "WrongEmailAddress") {
      Toast.fail(
        contents?.emailInvalid ?? messages?.emailInvalid,
        1500,
        () => {}
      );
    } else {
      Toast.fail(
        contents?.err_NotFound ?? messages?.err_NotFound,
        1500,
        () => {}
      );
    }

    setState({ ...state, loading: false });
    if (result.status === "WrongEmailAddress")
      showToast(contents?.emailInvalid ?? messages?.emailInvalid, "error");
    else showToast(result.status, "success");
  };
  const CallOfActionEvent = async (newconnection, contents) => {
    let clientRequestData = {
      email: state.email,
      emailAutoGenerated: state.emailAutoGenerated,
      name: state.name,
      phoneNumber: state.phoneNumber ?? "",
      tenant: params.tenant,
      userId: state.email,
      id: udid(),
    };

    // Instant Room
    if (instantRoomSessionId) {
      await AskToJoinInstantCall(newconnection, clientRequestData, contents);
    } // Normal Room
    else {
      await FindConsultant(newconnection, clientRequestData);
    }
  };
  useEffect(() => {
    const checkScreenProps = async (requestCallPath) => {
      if (requestCallPath.status == LOADING) {
        setState({ ...state, loading: true });
      } else if (requestCallPath.status == SUCCESS) {
        props.defaultRequest();
        var signalRToken = requestCallPath.value.data;
        var newconnection = await new signalR.HubConnectionBuilder()
          .withUrl(
            `${apiName.signalR}clientHub?GETBEE-AUTH-TOKEN=${signalRToken.connectionToken}`,
            {
              skipNegotiation: true,
              transport: signalR.HttpTransportType.WebSockets,
            }
          )
          .configureLogging(signalR.LogLevel.Information)
          .build();

        await newconnection.start();
        setNewConnection(newconnection);
        newconnection.on("CallStarted", (message) => {
          localStorage.setItem("userRole", "client");
          localStorage.setItem("tenant", message.tenant);
          localStorage.setItem("signalRClient", signalRToken.connectionToken);
          localStorage.setItem("loggingClient", signalR.LogLevel.Information);
          const path = `/${message.tenant}/video-call`;
          const getItem = isJsonString(
            localStorage.getItem(localStorageClientLanguageKey)
          );
          var searchparam = `?session_id=${message.sessionId}&lng=${getItem}`;
          //console.log(searchparam, "SEARCH PARAM");

          window.location.href = `${window.location.origin}${path}${searchparam}`;
          console.log("waiting to join the call");
        });
        newconnection.on("CallCancelled", (message) => {
          setCallCancelled(true);
        });
        newconnection.on("InstantRoomJoinDecision", (message) => {
          if (message.isApproved) {
            Toast.success(
              contents?.expertApproved ?? messages?.expertApproved,
              1500,
              () => {
                //console.log("Call Started *********--------->", message);
                localStorage.setItem("userRole", "client");
                localStorage.setItem(
                  "tenant",
                  state?.tenantInfo.uniqueIdentifier
                );
                localStorage.setItem(
                  "signalRClient",
                  signalRToken.connectionToken
                );
                localStorage.setItem(
                  "loggingClient",
                  signalR.LogLevel.Information
                );
                const path = `/${state?.tenantInfo.uniqueIdentifier}/video-call`;
                var searchparam = `?session_id=${message.sessionId}`;
                //console.log(searchparam, "SEARCH PARAM");
                window.location.href = `${window.location.origin}${path}${searchparam}`;
              }
            );
          } else {
            let messagesData = {
              title: contents.sessionFull ?? messages?.sessionFull,
              main: (
                <>{contents?.comeBackTryLater ?? messages?.comeBackTryLater}</>
              ),
              actionButtons: (
                <button className="btn btn--primary btn-lg">
                  <Link
                    to={`/${state?.tenantInfo.uniqueIdentifier}/client-home`}
                  >
                    <span>{contents?.tryAgain ?? messages?.tryAgain}</span>
                  </Link>
                </button>
              ),
            };

            setMessageScreenData(messagesData);
            setRequestedToJoinInstantCall(true);
          }
        });
        await CallOfActionEvent(newconnection, contents);
      } else if (requestCallPath.status == ERROR) {
        //console.log("requestCall error res ", requestCallPath);
        // setState({ ...state, loading: false });
        errorHandle(requestCallPath, contents);
        props.defaultRequest();
      }
    };
    checkScreenProps(props.ScreenProps.requestCallR);
  }, [props.ScreenProps, contents, CallOfActionEvent, props, state]);

  const [countriesArr, setCountriesArr] = useState({});
  useEffect(() => {
    let countries = {};
    let lst = getCountries();
    for (let i = 0; i < lst.length; i++) {
      countries[lst[i]] = {
        primary: en[lst[i]],
        secondary: `+${getCountryCallingCode(lst[i])}`,
      };
    }
    setCountriesArr(countries);
  }, [getCountries]);

  const btnRef = useRef(null);

  const allFormDisabled = useMemo(() => {
    return isAllFormElementDisabled(state?.tenantInfo?.basicFieldsSettings);
  }, [state?.tenantInfo?.basicFieldsSettings]);

  useLayoutEffect(() => {
    // Check if user configuration is set otherwise simply click to the button
    //setState({...state, acceptTerms:true});
    if (allFormDisabled) {
      setTimeout(() => {
        btnRef?.current?.click();
      }, 1);
    }
  }, [allFormDisabled]);
  const [requestedToJoinInstantCall, setRequestedToJoinInstantCall] =
    useState(false);

  const backgroundImage = useMemo(() => {
    if (isValidURL(state?.tenantInfo?.image)) return state?.tenantInfo?.image;

    return defaultClientBackground;
  }, [state?.tenantInfo?.image]);

  const getHomePageURL = useMemo(() => {
    if (!state?.tenantInfo?.logoRedirectUrl) {
      return null;
    }
    return (
      <a
        href={state?.tenantInfo?.logoRedirectUrl}
        target="_blank"
        rel="noreferrer"
      >
        {" "}
        {contents?.visitOurWebsite ?? messages?.visitOurWebsite}
      </a>
    );
  }, [contents, state]);

  const { tenant } = useParams();

  useFetchClientAppSettings({tenant, clientAppSetting});

  const ref = useRef();

  const selectLanguageChangeHandler = async (lng) => {
    setSelectedLanguage(lng);
    setShowLngMenu(false);
    // await dispatch(callFetchClientAppSettings(tenant, lng.value));
    await dispatch(callFetchClientAppTextSettings(tenant, lng.value));
    // localStorage.setItem(localStorageClientLanguageKey, JSON.stringify(lng.value));
  };

  const { selectedLanguage, setSelectedLanguage, showLngMenu, setShowLngMenu } =
    useSetGetLanguageForClient();

  const getSelectedLanguage = useMemo(() => {
    return selectedLanguage;
  }, [selectedLanguage]);

  const tenantAvailableClientLanguages = useMemo(() => {
    return clientAppSetting.availableClientLanguages;
  }, [clientAppSetting]);

  useEffect(() => {
    // ISC = Instance Call
    const setLanguageInInitialLoadForISC = () => {
      localStorage.setItem(localStorageClientLanguageKey, JSON.stringify(clientAppSetting.clientDefaultLanguage));

      const getLng = getLngRowByLngCode(preferredLanguage, clientAppSetting.clientDefaultLanguage);
      setSelectedLanguage(getLng);
    }

    if(instantRoomSessionId) {
      setLanguageInInitialLoadForISC();
    }

  }, [clientAppSetting, instantRoomSessionId, setSelectedLanguage]);

  const { value: tenantClientAppTextSetting } = tenantClientAppTextSettings;

  // console.log("selected language", selectedLanguage);
  // console.log("contents", contents);

  // console.log("messageScreenData", messageScreenData);
  // console.log("contents", contents);
  // console.log("selected language", selectedLanguage)
  // console.log("clientAppSetting.clientDefaultLanguage", clientAppSetting)

  useLanguage({
    lng: getSelectedLanguage?.value,
    shouldFetchLanguage: true,
    fromClient: true
  });
  
 
  return (
    <>
      {allFormDisabled && <LoadingBee />}
      {/* <div className="icupu">{icupu}</div> */}

      <section>
        {!requestedToJoinInstantCall && (
          <div
            className={`${
              allFormDisabled || allFormDisabled === null
                ? "gb-unvisible-13"
                : "w-full flex flex-wrap h-screen s-client"
            }`}
          >
            <div
              className={`w-full s-client__left md:w-1/2 flex flex-col ${
                request && `hidden`
              }`}
            >
              <div className="custom12-gb flex items-center md:pt-10 md:pt-8 pt-4 px-8 md:px-24 ">
                <img
                  alt=""
                  className="text-white font-bold text-xl company-size-logo-big gb-csc-logo"
                  src={
                    state.tenantInfo && state.tenantInfo.logoUrl
                      ? state.tenantInfo.logoUrl
                      : ""
                  }
                />

                {/* {getHomePageURL} */}
                {tenantAvailableClientLanguages?.length > 0 && (
                  <ClientLanguageDropDown
                    ref={ref}
                    setShowLngMenu={setShowLngMenu}
                    showLngMenu={showLngMenu}
                    selectedLanguage={selectedLanguage}
                    tenantAvailableClientLanguages={
                      tenantAvailableClientLanguages
                    }
                    selectLanguageChangeHandler={selectLanguageChangeHandler}
                  />
                )}
              </div>

              <div className="flex flex-col justify-center md:justify-start md:my-auto xl:my-auto md:pt-8 px-8 md:px-24">
                <div className="gb-back navigations mb-gb-2">
                  <div onClick={() => history.goBack()} className="back-arrow">
                    <div
                      className="item back-logo"
                      onClick={() => history.goBack()}
                    >
                      <img alt="" src={backIcon} />
                    </div>
                    <div className="item back-title">Back</div>
                  </div>
                </div>

                <h1 className=" text-3xl s-client__title font-bold">
                  {instantRoomSessionId
                    ? contents?.readyToJoin ?? messages?.readyToJoin
                    : tenantClientAppTextSetting?.title ??
                      contents?.speakWithOurSpecialists ??
                      messages?.speakWithOurSpecialists}
                </h1>

                {!instantRoomSessionId && (
                  <div className="gb-child-row-foreign">
                    <MainContent
                      tenantInfo={state?.tenantInfo}
                      numberOfAvailableExperts={numberOfAvailableExperts || state?.value?.numberOfAvailableExperts}
                      contents={contents}
                      messages={messages}
                    />
                  </div>
                )}

                <form className="flex flex-col  md:pt-8" onSubmit={submit}>
                  {state?.tenantInfo?.basicFieldsSettings?.name?.showField && (
                    // required={state?.tenantInfo?.basicFieldsSettings?.name?.isRequired}
                    <DefaultInput
                      className="flex flex-col pt-4"
                      label={contents?.name ?? messages?.name}
                      type={"text"}
                      name={"name"}
                      value={state.name}
                      onChange={(e) =>
                        setState({
                          ...state,
                          name: e.target.value,
                          nameErr: validate(contents, "name", e.target.value),
                        })
                      }
                      error={state.nameErr}
                    />
                  )}
                  {state?.tenantInfo?.basicFieldsSettings?.email?.showField && (
                    // required={state?.tenantInfo?.basicFieldsSettings?.email?.isRequired}
                    <DefaultInput
                      className="flex flex-col pt-4"
                      label={contents?.emailAddress ?? messages?.emailAddress}
                      type={"email"}
                      name={"email"}
                      value={state.emailAutoGenerated ? "" : state.email}
                      onChange={(e) => {
                        if (e.target.value) {
                          setState({
                            ...state,
                            email: e.target.value,
                            emailAutoGenerated: false,
                            emailErr: validate(
                              contents,
                              "email",
                              e.target.value
                            ),
                          });
                        } else {
                          setState({
                            ...state,
                            email: getAutoGeneratedMail(),
                            emailAutoGenerated: true,
                            emailErr: validate(
                              contents,
                              "email",
                              e.target.value
                            ),
                          });
                        }
                      }}
                      error={state.emailErr}
                    />
                  )}
                  {state?.tenantInfo?.basicFieldsSettings?.phone?.showField && (
                    <div
                      className={`${props.className} flex flex-col pt-4 input-field`}
                    >
                      {(contents?.phoneNumber ?? messages?.phoneNumber) && (
                        <label className="input-label">
                          {contents?.phoneNumber ?? messages?.phoneNumber}
                        </label>
                      )}
                      <div className="flex flex-row">
                        <ReactFlagsSelect
                          searchable
                          showSelectedLabel={false}
                          placeholder={"Select"}
                          className="bg-gray phone-country-drop-down"
                          selected={state.country}
                          customLabels={countriesArr}
                          onSelect={(code) =>
                            setState({ ...state, country: code })
                          }
                        />
                        <PhoneInput
                          country={state.country}
                          className="bg-gray outline-none"
                          placeholder={
                            contents?.phoneNumber ?? messages?.phoneNumber
                          }
                          value={state.phoneNumber}
                          onChange={(phoneNumber) => {
                            let validPhoneNumber = "";

                            if (
                              phoneNumber &&
                              !isValidPhoneNumber(phoneNumber)
                            ) {
                              validPhoneNumber =
                                contents?.incorrectPhoneNumber ??
                                messages?.incorrectPhoneNumber;
                            }
                            setState({
                              ...state,
                              phoneNumber,
                              phoneErr: validPhoneNumber,
                            });
                          }}
                        />
                      </div>
                      {state.phoneErr ? (
                        <ErrorText title={state.phoneErr} />
                      ) : null}
                    </div>
                  )}

                  <div className="flex s-client__footer--mobile justify-center items-center">
                    <div className="container-fluid">
                      <a
                        href="javascript:void(0);"
                        className="footer-logo-item"
                      >
                        <span></span>
                      </a>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={state.loading}
                    style={{
                      backgroundColor:
                        state?.tenantInfo?.buttonColour ?? "black",
                    }}
                    className="s-client__btn "
                    id="gb-submit-btn"
                    ref={btnRef}
                  >
                    <img alt="" src={callVideoIcon} className="mr-4" />{" "}
                    <p id="call-now-btn-gb">
                      {instantRoomSessionId
                        ? contents?.joinCall ?? messages?.joinCall
                        : state?.tenantInfo?.buttonText ??
                          contents?.callNow ??
                          messages?.callNow}
                    </p>
                  </button>
                </form>
              </div>
            </div>

            <div
              className={`w-1/2 shadow-2xl object-cover md:flex s-client__hide flex-col text-center ${
                request && `hidden`
              }`}
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        )}
        {/* contents?.hello ?? messages?.hello */}

        {/* {requestedToJoinInstantCall && (
          <div className="s-modal-full-screen gb-marker-10">
            <SchedulingTemplate
              main={messageScreenData.main}
              actionButtons={messageScreenData.actionButtons}
              clientAppSetting={{
                ...state?.tenantInfo,
                 title: "client app settings",
              }}
              contents={contents}
              tenantClientAppTextSetting={{title: "Goodness",  ...messageScreenData}}
            ></SchedulingTemplate>
          </div>
        )} */}

        {requestedToJoinInstantCall && (
          <div className="s-modal-full-screen gb-marker-10">
            <SchedulingTemplate
              // main={messageScreenData.main}
              main={
                <MainContentClientNotification
                  tenantInfo={{}}
                  contents={{
                    notification: instantCallResponseMessage.main ?? "",
                  }}
                />
              }
              actionButtons={instantCallResponseMessage?.actionButtons}
              clientAppSetting={{
                ...state?.tenantInfo,
              }}
              contents={contents}
              //...messageScreenData
              tenantClientAppTextSetting={{
                title: instantCallResponseMessage?.title,
              }}
            ></SchedulingTemplate>
          </div>
        )}

        {request && (
          <RequestCallScreen
            email={state.email}
            tenant={params.tenant}
            sessionId={sessionId}
            connection={newConnection}
            callOptions={callOptions}
            setCallOptions={setCallOptions}
            callCancelled={callCancelled}
            typeOfCall={typeOfCalls.random}
          />
        )}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ScreenProps: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestCallRequest: ({ from, body }) => {
      dispatch(requestCallA({ from, body }));
    },
    defaultRequest: () => {
      dispatch(defaultA());
    },
    tenantCustomizationRequest: ({ from, body }) => {
      dispatch(tenantCustomizationA({ from, body }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientConsultation);
