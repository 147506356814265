import {
  fetchSignalRToken,
  fetchSignalRTokenLoading,
  fetchSignalRTokenError,
} from "redux/constants/reduxConstant";

// export const fetchSignalRToken = "fetchSignalRToken";
// export const fetchSignalRTokenLoding = "fetchSignalRTokenLoding";
// export const fetchSignalRTokenError = "fetchSignalRTokenError";

/** @typedef {object} json
 * @property {string} connectionToken
 * @property {null} error
 * @property {boolean} isSucceeded
 * @property {string} status
 */

/**
 * @param  {} payload
 * @param  {fetchSignalRToken} =>{return{type
 * @returns fetchSignalRToken
 */
export const fetchSignalRTokenAction = (payload) => {
  return {
    type: fetchSignalRToken,
    payload,
  };
};

/**
 * @param  {} payload, Boolean
 * @param  {fetchSignalRTokenLoading}}} =>{return{type
 * @returns fetchSignalRTokenLoading
 */
export const fetchSignalRTokenLoadingAction = (payload) => {
  return {
    type: fetchSignalRTokenLoading,
  };
};
/**
 * @param  {} payload
 * @param  {fetchSignalRTokenError} =>{return{type
 * @returns fetchSignalRTokenError
 */
export const fetchSignalRTokenErrorAction = (payload) => {
  return {
    type: fetchSignalRTokenError,
    payload,
  };
};
