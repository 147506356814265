import { Link } from "react-router-dom";
import messages from "constants/messages";
import Translate from "components/Common/Translate";

const MainContent = ({ tenantInfo, contents, numberOfAvailableExperts }) => {
  return (
    <>
      <div className="description">
        {numberOfAvailableExperts || 0 }{" "}
        <Translate
          translatedContent={contents?.specialistAreOnlien}
          fallbacktranslatedContent={messages?.specialistAreOnlien}
        />{" "}
         <br />
        {/* {tenantInfo?.numberOfOnlineExperts > 1 && <Translate
          translatedContent={contents?.weWillConnect}
          fallbacktranslatedContent={messages?.weWillConnect}
        />}  */}
      </div>
     
      {numberOfAvailableExperts ? <div className="know-connect">
        <div className="col know-your">
          <Translate
            translatedContent={contents?.knowYourSpecialist}
            fallbacktranslatedContent={messages?.knowYourSpecialist}
          />
        </div>
        <div className="col connect-with">
          <Link to={`/${tenantInfo?.uniqueIdentifier}/list-experts`}>
            <Translate
              translatedContent={contents?.connectWithSpe}
              fallbacktranslatedContent={messages?.connectWithSpe}
            />
          </Link>
        </div>
      </div> : <></>}
      
    </>
  );
};

export default MainContent;
