//Auth
import EmailSent from "views/auth/EmailSent";
import EmailCheck from "views/auth/EmailCheck";
import Register from "views/auth/Register";
import AdminDashboardStep7 from "views/auth/DashboardStep7";
import AdminDashboardStep11 from "views/auth/DashboardStep11";
import AdminDashboardStep12 from "views/auth/DashboardStep12";

// Expert
import ExpHome from "views/auth/ExpHome";
import ExpLogin from "views/auth/ExpLogin";
import ExpForgetPass from "views/auth/ExpForgetPass";
import ExpForgetPassSet from "views/auth/ExpForgetPassSet";
import ExpCreatePass from "views/auth/ExpCreatePass";
import ExpCompleteProfile from "views/auth/ExpCompleteProfile";
import ExpCompleteProfile2 from "views/auth/ExpCompleteProfile2";
import ExpRegister from "views/auth/ExpRegister";

// Admin
import AdminHome from "views/admin/AdminHome";
import Profile from "views/admin/Profile";
import ExpertList from "views/admin/ExpertList";
import Company from "views/admin/Company";

//client
import ClientHome from "views/client/clientHome";
import ClientStartConsultation from "views/client/clientStartConsultation";
import VideoCall from "views/client/videoCall";
import TermCondition from "views/auth/TermCondition";
import DashboardAdminExpert from "views/admin/DashboardAdminExpert";
import ErrorPage from "views/auth/ErrorPage";

import InitialPage from "views/client/InitialPage";
import ExpertProfile from "views/client/ExpertProfile";
import ListExperts from "views/client/ListExperts";
import SelectDateToScheduleCall from "views/client/SelectDateToScheduleCall";
import ScheduleCallWithSpecificExpert from "views/client/ScheduleCallWithSpecificExpert";
import CancelMeeting from "views/client/ScheduleCallWithSpecificExpert/CancelMeeting";
import SpeakWithSpecificExpert from "views/client/SpeakWithSpecificExpert";

export const Routes = [
  {
    path: "/login",
    name: "ExpertLogin",
    component: ExpLogin,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/2682e0be-d4b2-4836-98b2-5d7dcd04dab6",
    name: "Register",
    component: Register,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "TermCondition",
    component: TermCondition,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/expert-home",
    name: "ExpHome",
    component: ExpHome,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/email-sent",
    name: "EmailSent",
    component: EmailSent,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/email-check",
    name: "EmailCheck",
    component: EmailCheck,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/company-setup",
    name: "AdminDashboardStep7",
    component: AdminDashboardStep7,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/create-web-address",
    name: "AdminDashboardStep11",
    component: AdminDashboardStep11,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/invite-team",
    name: "AdminDashboardStep12",
    component: AdminDashboardStep12,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/expert-register",
    name: "ExpRegister",
    component: ExpRegister,
    meta: {
      publicRoute: true,
      // roles: ['Expert','Admin']
    },
  },
  {
    path: "/expert-create-password",
    name: "ExpCreatePass",
    component: ExpCreatePass,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/expert-login",
    name: "ExpLogin",
    component: ExpLogin,
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/forget-password",
    name: "ExpForgetPass",
    component: ExpForgetPass,
    meta: {
      // authRoute: true
    },
  },
  {
    path: "/change-password",
    name: "ExpForgetPassSet",
    component: ExpForgetPassSet,
    meta: {
      // authRoute: true
    },
  },
  {
    path: "/expert-complete-profile",
    name: "ExpCompleteProfile",
    component: ExpCompleteProfile,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/expert-complete-profile-step2",
    name: "ExpCompleteProfile2",
    component: ExpCompleteProfile2,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/:tenant/video-call",
    name: "VideoCall",
    component: VideoCall,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/:tenant/client-home",
    name: "InitialPage",
    component: InitialPage,
    meta: {
      publicRoute: true,
    },
  },
  // {
  //   path: "/:tenant",
  //   name: "ClientStartConsultation",
  //   component: ClientStartConsultation,
  //   meta: {
  //     publicRoute: true,
  //   },
  // },
  {
    path: "/:tenant/client-start-consultation",
    name: "ClientStartConsultation",
    component: ClientStartConsultation,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/:tenant/:email/select-date-to-schedule-call",
    name: "SelectDateToScheduleCall",
    component: SelectDateToScheduleCall,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/:tenant/schedule-call-with-specific-expert",
    name: "schedule-call-with-specific-expert",
    component: ScheduleCallWithSpecificExpert,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/:tenant/list-experts",
    name: "ListExperts",
    component: ListExperts,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/:tenant/expert-profile/:email",
    name: "ExpertProfile",
    component: ExpertProfile,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/:tenant/speak-with-our-specialists",
    name: "InitialPage",
    component: InitialPage,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/:tenant/:email/speak-with-specific-expert",
    name: "SpeakWithSpecificExpert",
    component: SpeakWithSpecificExpert,
    meta: {
      publicRoute: true,
    },
  },
  {
    path: "/:tenant/cancel-meeting",
    name: "CancelMeeting",
    component: CancelMeeting,
    meta: {
      publicRoute: true,
    },
  },

  // {
  //   path: "/:tenant/client-start-consultation",
  //   name: "ClientStartConsultation",
  //   component: ClientStartConsultation,
  //   meta: {
  //     publicRoute: true
  //   }
  // },
  {
    path: "/admin-home",
    name: "AdminHome",
    // component: AdminHome,
    component: DashboardAdminExpert,
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/expert-list",
    name: "ExpertList",
    component: ExpertList,
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/error/:code",
    name: "ErrorPage",
    component: ErrorPage,
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/company",
    name: "Company",
    component: Company,
    meta: {
      authRoute: true,
    },
  },
];
