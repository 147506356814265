
const styles = {
    camIcon: {
        position: 'absolute',
        bottom: 0
    },
    tenantImg: {
        width: 60,
        height: 60,
        borderRadius: 100
    },
    tenantImgNav: {
        width: 50,
        height: 50,
        borderRadius: 100
    },
    tenCon: {
        marginTop: 10
    },

}

export default styles;