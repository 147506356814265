import callVideoIcon from "assets/svg/call-icon.svg";
import iconCalendar from "assets/svg/icon-calendar.svg";
import { componentsName } from "config/client-api-status";
import { appColors } from "config/colors";
import messages from "constants/messages";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ScheduleAndCallButtons = ({
  classSlug,
  uniqueIdentifier,
  contents,
  clientAppSetting,
  from,
  email,
  displayCallButton,
  navigateToScheduleCallWithExpert,
  numberOfOnlineExperts
}) => {
  const buttonColor = () => {
    return clientAppSetting?.buttonColour !== ""
      ? clientAppSetting?.buttonColour
      : appColors.dark;
  };

  const onClickHandler = () => {
    if (from === componentsName.expertProfile) {
      return `/${uniqueIdentifier}/${window.btoa(email)}/speak-with-specific-expert`;
    }
    if (from === componentsName.initialPage) {
      return `/${uniqueIdentifier}/client-start-consultation`;
    }
    return null;
  };

  const getScheduleACallRouter = () => {
    if(from === componentsName.initialPage) {
      return `/${uniqueIdentifier}/list-experts`;
    }

    if(from === componentsName.expertProfile) {
      return `/${uniqueIdentifier}/${window.btoa(email)}/select-date-to-schedule-call`
    }
  }


  return (
    <div className={`buttons ${classSlug}`}>
      
      {numberOfOnlineExperts > 0 && displayCallButton && <Link
        className="btn btn--primary"
        style={{ backgroundColor: buttonColor()}}
        to={onClickHandler}
      >
        <span id = "call-now-btn-gb">
          <img src={callVideoIcon} alt="" />
        </span>
        <span>{contents?.callNow ?? messages.callNow}</span>
      </Link>}
      
      <Link
        className="btn btn--lighter btn--border-light"
        to={getScheduleACallRouter()}
      >
        <span>
          <img src={iconCalendar} alt="" />
        </span>
        <span>{contents?.scheduleACall ?? messages?.scheduleACall}</span>
      </Link>
    </div>
  );
};

ScheduleAndCallButtons.propTypes = {
  from: PropTypes.oneOf(Object.values(componentsName)),
};

export default ScheduleAndCallButtons;
