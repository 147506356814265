import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CallfetchTenantInfo from "redux/call-action/callfetchTenantInfo";

export default function useFetchTenantInfo({ tenant, tenantInfo }) {
  const dispatch = useDispatch();

  useEffect(() => {
    let fetchTenantInfo = async () => {
      await dispatch(CallfetchTenantInfo({ tenant }));
    };

    if (Object.entries(tenantInfo).length === 0) {
      fetchTenantInfo();
    }
  }, [tenantInfo, dispatch, tenant]);
}
