import { industryError, industrySuccess, industryLoading, SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'
import { industryInError, industryInSuccess, industryInLoading } from '../../constants/reduxConstant'

export function industryR(state = {}, action) {
    // console.log("Inside industry reducer", action)
    switch (action.type) {
        case industryLoading:
            return Object.assign({}, state, { status: LOADING });
        case industrySuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case industryError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}

export function industryInR(state = {}, action) {
    switch (action.type) {
        case industryInLoading:
            return Object.assign({}, state, { status: LOADING });
        case industryInSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case industryInError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}