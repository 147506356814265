export * from "./status";

export const defaultError = "defaultError";
export const defaultSuccess = "defaultSuccess";
export const defaultLoading = "defaultLoading";

export const registerError = "registerError";
export const registerSuccess = "registerSuccess";
export const registerLoading = "registerLoading";

export const expCreatePassError = "expCreatePassError";
export const expCreatePassSuccess = "expCreatePassSuccess";
export const expCreatePassLoading = "expCreatePassLoading";

export const AlreadyVerified = "verifyEmailAlreadyVerified";
export const verifyEmailError = "verifyEmailError";
export const verifyEmailSuccess = "verifyEmailSuccess";
export const verifyEmailLoading = "verifyEmailLoading";
export const OldVerificationCode = "OldVerificationCode";

export const resendEmailError = "resendEmailError";
export const resendEmailSuccess = "resendEmailSuccess";
export const resendEmailLoading = "resendEmailLoading";

export const imgUploadError = "imgUploadError";
export const imgUploadSuccess = "imgUploadSuccess";
export const imgUploadLoading = "imgUploadLoading";

export const loginLoading = "loginLoading";
export const loginError = "loginError";
export const loginSuccess = "loginSuccess";
export const logoutLoading = "logoutLoading";
export const logoutError = "logoutError";
export const logoutSuccess = "logoutSuccess";

export const industryError = "industryError";
export const industrySuccess = "industrySuccess";
export const industryLoading = "industryLoading";

export const createWebUrlError = "createWebUrlError";
export const createWebUrlSuccess = "createWebUrlSuccess";
export const createWebUrlLoading = "createWebUrlLoading";

export const deleteExpertProfileLoading = "deleteExpertProfileLoading";
export const deleteExpertProfileSuccess = "deleteExpertProfileSuccess";
export const deleteExpertProfileError = "deleteExpertProfileError";

export const revokeInvitationLoading = "revokeInvitationLoading";
export const revokeInvitationSuccess = "revokeInvitationSuccess";
export const revokeInvitationError = "revokeInvitationError";

export const onlineError = "onlineError";
export const onlineSuccess = "onlineSuccess";
export const onlineLoading = "onlineLoading";

export const offlineError = "offlineError";
export const offlineSuccess = "offlineSuccess";
export const offlineLoading = "offlineLoading";

export const requestCallError = "requestCallError";
export const requestCallSuccess = "requestCallSuccess";
export const requestCallLoading = "requestCallLoading";

export const tenantInfoError = "tenantInfoError";
export const tenantInfoSuccess = "tenantInfoSuccess";
export const tenantInfoLoading = "tenantInfoLoading";

export const tenantCustomizationError = "tenantCustomizationError";
export const tenantCustomizationSuccess = "tenantCustomizationSuccess";
export const tenantCustomizationLoading = "tenantCustomizationLoading";

export const inviteExpError = "inviteExpError";
export const inviteExpSuccess = "inviteExpSuccess";
export const inviteExpLoading = "inviteExpLoading";

export const inviteExpAdminHomeError = "inviteExpAdminHomeError";
export const inviteExpAdminHomeSuccess = "inviteExpAdminHomeSuccess";
export const inviteExpAdminHomeLoading = "inviteExpAdminHomeLoading";

export const userInfoError = "userInfoError";
export const userInfoSuccess = "userInfoSuccess";
export const userInfoLoading = "userInfoLoading";

export const expertAcceptError = "expertAcceptError";
export const expertAcceptSuccess = "expertAcceptSuccess";
export const expertAcceptLoading = "expertAcceptLoading";

export const checkInviteError = "checkInviteError";
export const checkInviteSuccess = "checkInviteSuccess";
export const checkInviteLoading = "checkInviteLoading";

export const imgUploadExpError = "imgUploadExpError";
export const imgUploadExpSuccess = "imgUploadExpSuccess";
export const imgUploadExpLoading = "imgUploadExpLoading";

export const updateProfileError = "updateProfileError";
export const updateProfileSuccess = "updateProfileSuccess";
export const updateProfileLoading = "updateProfileLoading";

export const nameExistsError = "nameExistsError";
export const nameExistsSuccess = "nameExistsSuccess";
export const nameExistsLoading = "nameExistsLoading";

export const updateProfileInError = "updateProfileInError";
export const updateProfileInSuccess = "updateProfileInSuccess";
export const updateProfileInLoading = "updateProfileInLoading";

export const forgotPasswordError = "forgotPasswordError";
export const forgotPasswordSuccess = "forgotPasswordSuccess";
export const forgotPasswordLoading = "forgotPasswordLoading";

export const resetPasswordError = "resetPasswordError";
export const resetPasswordSuccess = "resetPasswordSuccess";
export const resetPasswordLoading = "resetPasswordLoading";

export const expertsTeamError = "expertsTeamError";
export const expertsTeamSuccess = "expertsTeamSuccess";
export const expertsTeamLoading = "expertsTeamLoading";

export const expertsTeamPendingInvitesError = "expertsTeamPendingInvitesError";
export const expertsTeamPendingInvitesSuccess =
  "expertsTeamPendingInvitesSuccess";
export const expertsTeamPendingInvitesLoading =
  "expertsTeamPendingInvitesLoading";

export const requestExpertConnectionError = "requestExpertConnectionError";
export const requestExpertConnectionSuccess = "requestExpertConnectionSuccess";
export const requestExpertConnectionLoading = "requestExpertConnectionLoading";

export const imgUploadInError = "imgUploadInError";
export const imgUploadInSuccess = "imgUploadInSuccess";
export const imgUploadInLoading = "imgUploadInLoading";

export const industryInError = "industryInError";
export const industryInSuccess = "industryInSuccess";
export const industryInLoading = "industryInLoading";

export const teamMembersLoading = "teamMembersLoading";
export const teamMembersFetched = "teamMembersFetched";
export const teamMemberFetchError = "teamMemberFetchError";

export const fetchPendingTeamMembers = "fetchPendingTeamMembers";
export const fetchPendingTeamMembersError = "fetchPendingTeamMembersError";
export const fetchPendingTeamMembersLoading = "fetchPendingTeamMembersLoading";

export const teamMemberUpdated = "teamMemberUpdated";
export const invokeInvitation = "invokeInvitation";
export const inviteTeamMembers = "inviteTeamMembers";
export const deleteTeamMember = "deleteTeamMember";

// Constant for language
export const fetchLanguage = "fetchLanguage";
export const fetchLanguageLoading = "fetchLanguageLoading";
export const fetchLanguageError = "fetchLanguageError";

export const changeCountriesList = "fetchCountryList";

// Integrations
export const fetchSelectedIntegration = "fetchSelectedIntegration";
export const fetchSelectedIntegrationError = "fetchSelectedIntegrationError";
export const fetchSelectedIntegrationLoading =
  "fetchSelectedIntegrationLoading";

/* This section is used for the clinet side  */

// Client tenant
export const fetchTenantInfo = "fetchTenantInfo";
export const fetchTenantInfoLoading = "fetchTenantInfoLoading";
export const fetchTenantInfoError = "fetchTenantInfoError";

// Fetch Expert for specific tenant
export const fetchExpertsForTenant = "fetchExpertsForTenant";
export const fetchExpertsForTenantLoading = "fetchExpertsForTenantLoading";
export const fetchExpertsForTenantError = "fetchExpertsForTenantError";

// Fetch tenant client app settings
export const fetchTenantClientAppSettins = "fetchTenantClientAppSettins";
export const fetchTenantClientAppSettinsLoading =
  "fetchTenantClientAppSettinsLoading";
export const fetchTenantClientAppSettinsError =
  "fetchTenantClientAppSettinsError";


// Fetch experts by tenant name 
export const fetchExpertsForClient = "fetchExpertsForClient";
export const fetchExpertsForClientLoading = "fetchExpertsForClientLoading";
export const fetchExpertsForClientError = "fetchExpertsForClientError";
export const expertProfileUpdatedEvent = "expertProfileUpdatedEvent";

// Signal R connection token //
export const fetchSignalRToken = "fetchSignalRToken";
export const fetchSignalRTokenLoading = "fetchSignalRTokenLoading";
export const fetchSignalRTokenError = "fetchSignalRTokenError";

// Random signal R token connection //
export const fetchRandomSignalRToken = "fetchRandomSignalRToken";
export const fetchRandomSignalRTokenLoading = "fetchRandomSignalRTokenLoading";
export const fetchRandomSignalRTokenError = "fetchRandomSignalRTokenError";

//OneSignal Registration
export const registerOneSignalError = "registerOneSignalError";
export const registerOneSignalSuccess = "registerOneSignalSuccess";

// Fetch client app settings text, for exp: title etc
export const fetchTenantClientAppTextSettings = "fetchTenantClientAppTextSettings";
export const fetchTenantClientAppTextSettingsLoading = "fetchTenantClientAppTextSettingsLoading";
export const fetchTenantClientAppTextSettingsError = "fetchTenantClientAppTextSettingsError";
