import { showToast } from "components/Common/Common";
import { getError } from "components/ErrorHandler/getError";
import apiName from "constants/apiName";
import {
  fetchRandomSignalRTokenAction,
  fetchRandomSignalRTokenErrorAction,
  fetchRandomSignalRTokenLoadingAction,
} from "redux/action/client/randomSignalRConnectionToken";
import { request } from "utils/request";

export default function callFetchRandomSingalRConnectionToken(body) {
  return async (dispatch) => {
    function onSuccess(payload) {
      dispatch(fetchRandomSignalRTokenAction(payload, body.tenant));
    }
    function isFetching() {
      dispatch(fetchRandomSignalRTokenErrorAction());
    }

    function onError(err) {
      dispatch(fetchRandomSignalRTokenLoadingAction(err));
    }
    try {
      isFetching();
      const response = await request({
        url: apiName.requestCall,
        method: "post",
        body,
      });
      if (response.isSucceeded) {
        return onSuccess(response);
      }
      if (!response.isSucceeded) {
        showToast(response.error, "error");
      }
      return;
    } catch (err) {
      console.error(err);
      showToast(getError(err), "error");
      return onError(getError(err));
    }
  };
}
