import React, { useState, useEffect } from "react";
import check from "../../assets/images/check.png";
import like from "../../assets/images/like.png";
import likeGreen from "../../assets/images/like-green.png";
import cart from "../../assets/images/cart-white.png";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseQuantityProductLists,
  addToRecommendation,
  removeFromRecommendation,
  increaseQuantityProductLists,
} from "../../redux/action/shopping";
import { addItem, removeItem } from "../../redux/action/cartAction";
import ReactTooltip from "react-tooltip";
import { optionsHasAllSelectedVariants } from "../../utils/utils";
import messages from "constants/messages";
import colors from "constants/colors";

const CardItem = ({ product, isRecommended, recommendedProducts }) => {
  const [, setRecommend] = useState(false);
  const [alreadyInCart, setAlreadyInCart] = useState(false);
  const [outOfStock, setStock] = useState(false);
  const cartItems = useSelector((state) => state.cartReducer.cartItems);
  const [disabled, setDisabled] = useState(true);
  const [finalVariant, setFinalVariant] = useState([]);
  const dispatch = useDispatch();
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [showVariant, setShowVariant] = useState(true);
  const {
    preferedLanguageContent: { contents },
  } = useSelector((state) => state);
  const [showDescription, setShowDescription] = useState(true);
  const [quantity, setQuantity] = useState(0);
  const tenantInfo = useSelector((state) => state.tenantInfoR.value);
  const connection = useSelector(
    (state) => state.shoppingReducer.shoppingConnection
  );
  const sessionStatus = useSelector(
    (state) => state.shoppingReducer.sessionStatus
  );
  const userInfoR = useSelector((state) => state.userInfoR.value);
  const url = new URL(window.location.href);
  const extractUrlValue = (key, url) => {
    if (typeof url === "undefined") url = window.location.href;
    var match = url.match("[?&]" + key + "=([^&]+)");
    return match ? match[1] : null;
  };
  let sessionId = extractUrlValue("session_id", url.sessionId);
  const recommendProduct = async (item) => {
    try {
      if (connection && Object.keys(sessionStatus).length > 0) {
        const payload = {
          sessionId: sessionId,
          tenant: sessionStatus.tenant,
          email: sessionStatus.expertInfo.email,
          product: {
            productId: item.productId,
            rawData: item,
            productInCartId: item.productId,
          },
        };
        console.log(payload);
        const result = await connection.invoke(
          "AddProductToRecommendationList",
          payload
        );
        console.log(result);
        if (result && result.isSucceeded) {
          setRecommend(true);
          dispatch(addToRecommendation(item));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const deleteRecomendedProduct = async (item) => {
    try {
      if (connection && Object.keys(sessionStatus).length > 0) {
        const payload = {
          sessionId: sessionId,
          tenant: sessionStatus.tenant,
          productInCartId: item.productId,
          // email: userInfoR && userInfoR.data && userInfoR.data.email,
          productId: item.productId,
        };
        console.log(payload, "REMOVE");
        const result = await connection.invoke(
          "RemoveProductFromRecommendationList",
          payload
        );
        console.log(result);
        if (result && result.isSucceeded) {
          setRecommend(false);
          dispatch(removeFromRecommendation(item.productId));
        }
      }
    } catch (err) {
      console.log("Client SignalR Error =>", err);
    }
  };
  useEffect(() => {
    if (product && product.quantity) {
      setQuantity(product.quantity);
    }
  }, [product]);
  useEffect(() => {
    let finalArr;
    if (
      selectedVariant &&
      product &&
      product.options &&
      selectedVariant.length === product.options.length
    ) {
      finalArr = product.variants.filter((item) =>
        optionsHasAllSelectedVariants(item.options, selectedVariant)
      );
      setFinalVariant(finalArr);
      if (selectedVariant && cartItems && cartItems.length > 0) {
        cartItems.map((item) => {
          const myArrayFiltered = item.category.filter((el) => {
            return selectedVariant.some((f) => {
              return f.option === el.option && f.category === el.category;
            });
          });
          console.log(myArrayFiltered);
        });
      }
      if (finalArr && finalArr.length > 0 && finalArr[0].quantity > 0) {
        setDisabled(false);
        setStock(false);
      } else if (
        finalArr &&
        finalArr.length > 0 &&
        finalArr[0].quantity === 0
      ) {
        setStock(true);
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [selectedVariant, product, cartItems]);
  const handleAddVariant = (category, option) => {
    if (
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant.some((item) => item.option === option)
    ) {
      const filteredArray = selectedVariant.filter(
        (item) => item.option !== option
      );
      setSelectedVariant(filteredArray);
    } else if (
      selectedVariant &&
      selectedVariant.length > 0 &&
      selectedVariant.some((v) => v.category === category)
    ) {
      //Find index of specific object using findIndex method.
      let prevArray = selectedVariant.map((el) => {
        if (el.category === category)
          return Object.assign({}, el, { option: option });
        return el;
      });
      setSelectedVariant(prevArray);
    } else {
      setSelectedVariant([
        ...selectedVariant,
        { category: category, option: option },
      ]);
    }
  };
  const addToCartHandler = async (product) => {
    if (connection && Object.keys(sessionStatus).length > 0) {
      product.quantity = 1;
      product.category = selectedVariant;
      product.selectedVariant = finalVariant;
      product.variantId = finalVariant[0].variantId;
      const payload = {
        sessionId: sessionId,
        tenant: sessionStatus.tenant,
        email: sessionStatus.expertInfo.email,
        product: {
          productId: product.productId.toString(),
          quantity: 1,
          variantId: finalVariant[0].variantId,
          rawData: product,
          previousQuantity: 0,
          previousRawData: product,
        },
      };
      console.log(payload);
      const result = await connection.invoke("AddProductToCart", payload);
      console.log(result, "RESULT");
      if (result && result.product && result.isSucceeded) {
      }
    }
  };
  const increaseQuantity = async (product) => {
    try {
      dispatch(addItem(product));
      dispatch(increaseQuantityProductLists(product));
      setQuantity(quantity + 1);
      if (connection && tenantInfo && userInfoR) {
        const payload = {
          sessionId: sessionId,
          tenant:
            tenantInfo && tenantInfo.data && tenantInfo.data.uniqueIdentifier,
          email: userInfoR && userInfoR.data && userInfoR.data.email,
          productInCartId: product.productId.toString(),
          id: "fasdfsdfwe234-sdfsdrwerwe-sdfwe23432423sfd",
        };
        const result = await connection.invoke("IncreaseQuantity", payload);
        console.log(result);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const decreaseQuantity = async (product) => {
    try {
      dispatch(removeItem(product));
      dispatch(decreaseQuantityProductLists(product));
      setQuantity(quantity - 1);
      if (connection && tenantInfo && userInfoR) {
        const payload = {
          sessionId: sessionId,
          tenant:
            tenantInfo && tenantInfo.data && tenantInfo.data.uniqueIdentifier,
          email: userInfoR && userInfoR.data && userInfoR.data.email,
          productInCartId: product.productId.toString(),
          id: "fasdfsdfwe234-sdfsdrwerwe-sdfwe23432423sfd",
        };
        const result = await connection.invoke("DecreaseQuantity", payload);
        console.log(result);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (Object.keys(product).length > 0 && product.options.length === 1) {
      product.options.map((item) =>
        item.values.map((value) =>
          handleAddVariant(item.name, value, product.variants)
        )
      );
    }
  }, [product]);
  useEffect(() => {
    if (
      finalVariant &&
      finalVariant.length > 0 &&
      cartItems &&
      cartItems.length > 0
    ) {
      if (
        cartItems.filter(
          (product) =>
            product.variantId && product.variantId === finalVariant[0].variantId
        ).length
      ) {
        setDisabled(true);
        setAlreadyInCart(true);
      } else {
        setAlreadyInCart(false);
      }
    }
  }, [cartItems, finalVariant]);

  const getProductColors = () => {
    if (product?.options) {
      // Return [] if no color or
      const colors = product?.options?.filter(
        (option) => option.name === "Color"
      );
      
      if (colors[0]?.values) {
        return colors[0]?.values.map((color, key) => (
          <div key={key} className="gb-product-color" onClick={null}>
            {color}
          </div>
        ));
      }
    }

   
    return null;
  };

  console.log("product", JSON.stringify(product));
  return (
    <div class="w-full mx-auto   ">
      <h1 className="s-card__header w-11/12 mx-auto ">
        <div dangerouslySetInnerHTML={{ __html: product.title }} />
      </h1>
      <p className="mt-4 s-card__priceDescription text-left  w-11/12 mx-auto">
        {sessionStatus?.storeInfo?.currencyInfo?.currencySymbol}{" "}
        {finalVariant && finalVariant.length > 0
          ? finalVariant[0].price
          : product.variants[0].price}
      </p>
      {product.options &&
        product.options.length > 0 &&
        product.options[0].name !== "Title" && (
          <div
            className="s-card__section mt-4  w-11/12 mx-auto flex justify-between items-center"
            onClick={() => setShowVariant(!showVariant)}
          >
            <h4 className="s-card__section__title">
              {contents?.variants ?? messages?.variants}
            </h4>
            {showVariant ? (
              <p className="s-card__section__minus"></p>
            ) : (
              <p className="text-2xl text-black cursor-pointer">+</p>
            )}
          </div>
        )}
        
      {product.options &&
        showVariant &&
        product.options.length > 0 &&
        product.options[0].name !== "Title" &&
        product.options.map((item) => (
          <div className="mt-4 s-card__variant  w-11/12 mx-auto">
            <p className="s-card__variant__title">{item.name}</p>
          
            <div className="product-color-container">
              
              {/* <div className="gb-product-color" onClick={null}>
                Red
              </div> */}
              {/* {getProductColors()} */}
            </div>
            <div className="mt-2 flex items-center flex-wrap">
            {item.values.map(value => (
                <button  className={`${selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) && `selected-item`} s-variant__item flex items-center`} onClick={() => handleAddVariant(item.name, value)}>
                <span className="mr-2">{value}</span>{selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) && <img className="ml-2" src={check} alt="Check Icon" />}
                </button>
              ))}
            </div>
            {/* {item.name === "Color" || item.name === "Colour" ? <div className="mt-2 flex items-center flex-wrap">
           
             
              {item.values.map(value => (
                <button  className={`${selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) && `selected-item`} s-variant__item flex items-center`} onClick={() => handleAddVariant(item.name, value)}>
                <span className="mr-2">{value}</span>{selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) && <img className="ml-2" src={check} alt="Check Icon" />}
                </button>
              ))}
            </div> : <div className="mt-2 flex items-center flex-wrap">
              {item.values.map(value => (
                <button  className={`${selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) && `selected-item`} s-variant__item flex items-center`} onClick={() => handleAddVariant(item.name, value)}>
                  <span >{value}</span> {selectedVariant && selectedVariant.length > 0 && selectedVariant.some(item => item.option === value) && <img className="ml-2" src={check} alt="Check Icon" />}
                </button>
              ))}
            </div>} */}
          </div>
        ))}
      <section className="w-11/12 mx-auto">
        <div
          className="s-card__section mt-4 flex justify-between items-center"
          onClick={() => setShowDescription(!showDescription)}
        >
          <h4 className="s-card__section__title ">
            {contents?.description ?? messages?.description}
          </h4>
          {showDescription ? (
            <p className="s-card__section__minus"></p>
          ) : (
            <p className="text-2xl text-black cursor-pointer">+</p>
          )}
        </div>
        <p className="mt-4" style={{ overflow: "hidden" }}>
          {showDescription && (
            <div
              style={{ minHeight: "60vh" }}
              dangerouslySetInnerHTML={{
                __html: product.description
                  ? product.description
                  : contents?.noDescription ?? messages?.noDescription,
              }}
            />
          )}
        </p>
      </section>
      {/* {[...Array(10).keys()].map(() => <br />)} */}
      <div className={`flex items-center s-card__bottom `}>
        {isRecommended ? (
          <button
            data-tip={contents?.clickToUndo ?? messages?.clickToUndo}
            className="s-card__bottom__btn bg-white flex items-center justify-center s-card__bottom__title"
            onClick={() => deleteRecomendedProduct(product)}
          >
            <img src={likeGreen} />{" "}
            <span className="ml-2">
              {contents?.recommend ?? messages?.recommend}
            </span>
          </button>
        ) : (
          <>
            {" "}
            {recommendedProducts &&
            recommendedProducts.length > 0 &&
            recommendedProducts.some(
              (item) => item.productId === product.productId
            ) ? (
              <button
                data-tip={contents?.clickToUndo ?? messages?.clickToUndo}
                className="s-card__bottom__btn bg-white flex items-center justify-center s-card__bottom__title"
                onClick={() => deleteRecomendedProduct(product)}
              >
                <img src={likeGreen} />{" "}
                <span className="ml-2">
                  {contents?.recommend ?? messages?.recommend}
                </span>
              </button>
            ) : (
              <button
                data-tip={
                  contents?.clickToRecommend ?? messages?.clickToRecommend
                }
                className="s-card__bottom__btn bg-white flex items-center justify-center s-card__bottom__title"
                onClick={() => recommendProduct(product)}
              >
                <img src={like} />{" "}
                <span className="ml-2">
                  {contents?.recommend ?? messages?.recommend}
                </span>
              </button>
            )}{" "}
          </>
        )}
        <button
          disabled={disabled}
          className={`${
            disabled ? "mg-gb-dis-btn" : "s-card__bottom__btn--add"
          } s-card__bottom__btn flex items-center justify-center`}
          onClick={() => addToCartHandler(product)}
        >
          <img src={cart} alt="add" />{" "}
          <span className="ml-2">
            {outOfStock
              ? contents?.outOfStock ?? messages?.outOfStock
              : alreadyInCart
              ? contents?.alreadyInCart ?? messages?.alreadyInCart
              : contents?.addToCart ?? messages?.addToCart}
          </span>
        </button>
      </div>
      <ReactTooltip />
    </div>
  );
};
export default CardItem;
