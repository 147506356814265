import avatarGrayPNG from "assets/images/avatar-gray.png";
import calendarDarkIcon from "assets/svg/calendar-dark.svg";
import callVideoIcon from "assets/svg/call-icon.svg";
import PropTypes from "prop-types";
const GridItem = ({
  expert,
  navigateToToExpertProfile,
  speakWithSpecificExpert,
  navigateToScheduleCallWithExpert
}) => {
 

  return (
    <div
      className="grid__item item1"
      onClick={navigateToToExpertProfile(expert.email)}
    >
     
      <div className="row avatar gbmt-28">
        <img src={expert?.profileImage || avatarGrayPNG} alt="" />
      </div>
      <div className="row expert-name">
        <div className="name">{expert?.name}</div>

        <span
          className={`online-status ${
            expert.isOnline ? "avaibilities" : "unavailable"
          }`}
        ></span>
      </div>
      <p className="row about-me">{expert?.description || ""}</p>
      <div className="row expert-actions">
        
        <span className="button button--round button--light" onClick={navigateToScheduleCallWithExpert(expert?.email)}>
          <img src={calendarDarkIcon} alt="" />
        </span>
       

        {expert.isOnline && (
          <span
            className="button button--round button--dark"
            onClick={speakWithSpecificExpert(expert?.email)}
          >
            <img src={callVideoIcon} alt="" />
          </span>
        )}
      </div>
    </div>
  );
};

GridItem.propTypes = {
  expert: PropTypes.shape({
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    profileImage: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.string,
    platformLanguage: PropTypes.string,
    spokenLanguages: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    userIdentifier: PropTypes.string,
    isOnline: PropTypes.bool,
  }),
};

export default GridItem;
