import apiName from '../../constants/apiName';
import { post } from '../../utils/Api';
import { requestExpertConnectionError, requestExpertConnectionLoading, requestExpertConnectionSuccess } from '../constants/reduxConstant';

const apiLoading = () => ({ type: requestExpertConnectionLoading });
const apiSucceed = (payload) => ({ type: requestExpertConnectionSuccess, payload });
const apiError = (payload) => ({ type: requestExpertConnectionError, payload });

const requestExpertConnectionA = ({ from, body }) => async (dispatchEvent, getState) => {
    
    dispatchEvent(apiLoading());
    try {
        post(apiName.requestExpertConnection, body)
            .then(async (res) => {
                if (res && res.data && res.data.isSucceeded) {
                    dispatchEvent(apiSucceed(res));
                } else {
                    dispatchEvent(apiError(res));
                }
            })
            .catch((error) => {
                dispatchEvent(apiError(error));
            });
    } catch (e) {
        dispatchEvent(apiError(e));
    }
}

export default requestExpertConnectionA