import {
  fetchExpertsForClient,
  fetchExpertsForClientLoading,
  fetchExpertsForClientError,
  expertProfileUpdatedEvent,
  NONE,
  SUCCESS,
  ERROR,
  LOADING,
} from "redux/constants/reduxConstant";

const initialState = { STATUS: NONE, value: {} };
/**
 * @param  {} state=initialState
 * @param  {} action
 */
const tenantExperts = (state = initialState, action) => {
  switch (action.type) {
    case fetchExpertsForClientLoading:
      return { ...state, STATUS: LOADING };
    case fetchExpertsForClient:
      return { ...state, STATUS: SUCCESS, value: action.payload };
    case fetchExpertsForClientError:
      return { ...state, STATUS: ERROR };
    case expertProfileUpdatedEvent:
      // Get the experts
      const { email } = action.payload;
      const { value: { experts } = {} } = state;

      const updatedExperts = experts?.map((expert) => {
        if (expert.email === email) {
          return action.payload;
        }
        return expert;
      });
      return { ...state, value: updatedExperts };
    default:
      return state;
  }
};

export default tenantExperts;
