import React, { useEffect, useState } from "react";
import Highlighter from 'react-highlight-words';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { showToast } from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import { DefaultDropDown, defaultInput as DefaultInput } from "../../components/Common/Input";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import inviteExpA from "../../redux/action/admin/inviteExpA";
import defaultA from "../../redux/action/defaultA";
import { ERROR, LOADING, SUCCESS } from '../../redux/constants/reduxConstant';
import { getUserData } from "../../utils/utils";
import { Validate as validate } from "../../utils/validate_wrapper";

const AdminDashboardStep12 = (props) => {
    const location = useLocation();
    const history = useHistory()
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);
    const [state, setState] = useState({
        loading: false,
        selectedRole: { value: 'Expert', label: contents?.expert ?? messages?.expert },
        defaultRole: { value: 'Expert', label: contents?.expert ?? messages?.expert },
        email: '',
        expertArr: [{ email: "", role: { value: 'Expert', label:  contents?.expert ?? messages?.expert }, id: 0, error: "" }],
        expIndex: 0,
        showModal: false,
    })
    useEffect(() => {
		if(contents)
		{
			setState((s) => {
				return {
					...s, roles: [
                        { value: 'Admin', label: contents?.admin },
                        { value: 'Expert', label: contents?.expert }]
				}
			})
		}
	}, [contents])
    useEffect(() => {
        const inviteExpPath = props.ScreenProps.inviteExpR;

        if (inviteExpPath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (inviteExpPath.status == SUCCESS) {
            console.log('inviteExp success res ', inviteExpPath);
            setState({ ...state, loading: false, expertArr: [], email: '', showModal: true });
            // showToast(inviteExpPath.value.data.status, 'success');
            props.defaultRequest();
        }
        else if (inviteExpPath.status == ERROR) {
            console.log('inviteExp error res ', inviteExpPath);
            if(inviteExpPath?.error?.data?.status == "OneOrMoreExpertsAlreadyRegistered")
            {
                setState({ ...state, loading: false, expertArr: [], email: '', showModal: true });
				showToast(contents?.oneOrMoreExpertsAlreadyRegistered ?? messages?.oneOrMoreExpertsAlreadyRegistered, 'warn');
            }
			else
            {
                setState({ ...state, loading: false });
				errorHandle(inviteExpPath, contents)
            }
			props.defaultRequest();
        }
    }, [props.ScreenProps]);

    const addExpert = (expIndex) => {
        let temp = state.expertArr
        temp.push({ email: "", role: state.defaultRole, id: expIndex + 1, error: "" })
        setState({ ...state, expertArr: temp, email: "", emailErr: "", expIndex: state.expIndex + 1 })
    }

    const renderExpert = (i, expIndex, index) => (
        <div className="row invite-data" key={expIndex}>
            <div className="col-6 pl-0">
                <DefaultInput
                    label={contents?.emailAddress ?? messages?.emailAddress}
                    placeholder={"name@example.com"}
                    value={i.email}
                    onChange={(e) => { updateEmail(expIndex, e.target.value) }}
                    error={i.error}
                />
            </div>
            <div className="col-4 pl-0">
                <DefaultDropDown
                    label={contents?.role ?? messages?.role}
                    options={state.roles}
                    defaultValue={state.defaultRole}
                    value={i.role}
                    onChange={(value) => { updateRole(expIndex, value) }}
                    error={state.roleErr}
                    className={"invite-dropdown"}
                    noResultFound={contents?.noResultFound ?? messages?.noResultFound}
                    formatOptionLabel={formatOptionLabel}
                />
            </div>
            <div className="col-1 p-0">
                <img src={Images.navigation} className="trash cursor-pointer" onClick={() => deleteExpert(expIndex, index)} />
            </div>
        </div>
    )

    const updateRole = (expIndex, value) => {
        let temp = state.expertArr
        temp.map(i => {
            if (i.id == expIndex) {
                console.log('the role of current user is ', i.role.value)
                value.value == 'Expert' ? (i.role = state.roles[1]) : (i.role = state.roles[0])
            }
        })
        setState({ ...state, expertArr: temp })
    }

    const updateEmail = (expIndex, value) => {
        let emailErr = validate(contents, 'email', value);
        let temp = state.expertArr

        let duplicateEmail = ""

        temp.map(i => {
            if (i.id !== expIndex) {
                i.email == value && (duplicateEmail = contents?.duplicateEmail ?? messages?.duplicateEmail)
            }
        })

        temp.map(i => {
            if (i.id == expIndex) {
                i.email = value;
                i.error = emailErr || duplicateEmail;
            }
        })

        setState({ ...state, expertArr: temp })

    }

    const deleteExpert = (expIndex) => {
        let temp = state.expertArr
        temp.splice(temp.findIndex(a => a.id === expIndex), 1)
        console.log('delete item is ', temp)
        setState({ ...state, expertArr: temp })
    }

    const submit = async (e) => {
        e.preventDefault()
        clearError()
        // alert(checkValidation(state.expertArr))
        if (!checkValidation(state.expertArr)) {
            return;
        }
        let userData = await getUserData()
        let temp = state.expertArr
        let experts = []
        temp.map(i => {
            var emailErr = validate(contents, 'emailFormat', i.email);
            if (emailErr == "" && i.email !== "") {
                experts.push({ email: i.email, role: i.role.value })
            }
        })
        props.inviteExpRequest({
            from: 'inviteExp',
            tenant: userData.tenant,
            experts: experts
        })
    };

    const checkValidation = (temp) => {
        var emailErr = ''
        let experts = []
        let emailArr = []
        temp.map(i => {
            emailErr = validate(contents, 'emailFormat', i.email);
            if (emailErr == "" && i.email !== "") {
                experts.push({ email: i.email, role: i.role.value })
                emailArr.push(i.email)
            }
        })
        var expErr = ""
        emailErr == "" ? (expErr = experts.length == 0 ? (contents?.addTeam ?? messages?.addTeam) : "") : (expErr = "")

        const duplicateEmail = emailArr.some((element, index) => {
            return emailArr.indexOf(element) !== index
        });

        if (emailErr || expErr || duplicateEmail) {
            setState({
                ...state,
                emailErr: emailErr + " " + expErr,
            })
            return false;
        } else {
            return true;
        }
    };

    const clearError = () => {
        setState({
            ...state,
            emailErr: '',
        })
    }

    const letGo = () => {
        setTimeout(() => {
            history.replace('/admin-home');
        }, 1500);
    }

    const formatOptionLabel = ({label}, {inputValue}) => {
        return (
          <Highlighter
            highlightClassName="option-highlight"
            searchWords={[inputValue]}
            textToHighlight={label}
          />
        );
    }

    return (
        <>
            <div className="expert-content-block-section">
                <div className="first-section">
                    <div className="form-content-blk">
                        <Header />
                        <div className="expert-content-block justify-center items-center overflow-block m-auto">
                            <div className="w-full">
                                <div className="progress-block">
                                    <div className="progress-bg-line"></div>
                                    <div className="progress-line col-12"></div>
                                    <span className="index">3 {contents?.outOf ?? messages?.outOf} 3</span>
                                </div>
                                <h4>{contents?.inviteYourTeam ?? messages?.inviteYourTeam}</h4>
                                <p className="linkCautuin">{contents?.youCanInviteLater ?? messages?.youCanInviteLater}</p>

                                <form onSubmit={submit} className="mb-3">
                                    {state.expertArr.map((i, index) => (
                                        renderExpert(i, i.id, index)
                                    ))}
                                    <p className="add-invite cursor-pointer" onClick={() => addExpert(state.expIndex)}>{contents?.addOneMore ?? messages?.addOneMore}</p>
                                    <div className="btn-block btn-flex">
                                        <button
                                            type="button"
                                            onClick={() => history.push('/admin-home')}
                                            className="btn btn--fifth">
                                            {contents?.btnSkip ?? messages?.btnSkip}
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={state.loading}
                                            className="btn btn--primary">
                                            {contents?.inviteTeam ?? messages?.inviteTeam}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-section right-img">
                    <img src={Images.inviteTeam} />
                </div>
            </div>
            {state.showModal &&
                <div className="modal viewmodal-profile fade  show show-model-box" id="viewmodal-profile"
                    role="dialog" aria-labelledby="viewmodal-profile"
                    aria-hidden="true">
                    <div className="modal-overlay"></div>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{contents?.congrats ?? messages?.congrats}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="exper-profile-add text-center">
                                    <p className="mb-4">{contents?.congratsDes ?? messages?.congratsDes}</p>
                                    <div className="full-width">
                                        <button onClick={() => { letGo() }} className="btn btn-black btn-lg btn-min w-full">{contents?.letGo ?? messages?.letGo}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};


const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        inviteExpRequest: ({ from, tenant, experts }) => {
            dispatch(inviteExpA({ from, tenant, experts }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardStep12);
