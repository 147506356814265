import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import "base.css";
import { devbeeGAKey, getbeeGAKey, instrumentationKey } from "config/Config";
import { SignalRProvider } from "context/SignalRProvider";
import useLanguage from "hooks/useLanguage";
import { useEffect, useRef } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import { store } from "redux/Store";
import Router from "router/Router";
import "scss/main.scss";
import "styles/index.scss";
import setAuthToken from "./utils/setAuthToken";

if (localStorage.userData) {
  setAuthToken();
}

function App() {
  const location = useLocation();
  const clientRefrence = useRef(0);
  const expertRefrence = useRef(0);
  const gaKey = localStorage.getItem("gaKey");
  if (location.pathname.indexOf("/client-home") > 0) {
    clientRefrence.current = clientRefrence.current + 1;
    expertRefrence.current = 0;
  }
  if (location.pathname === "/admin-home") {
    expertRefrence.current = expertRefrence.current + 1;
    clientRefrence.current = 0;
  }
  if (location.pathname.indexOf("/video-call") > 0) {
    expertRefrence.current = 0;
  }
  useEffect(() => {
    let key = window.location.hostname.includes("devbee")
      ? devbeeGAKey
      : getbeeGAKey;
    ReactGA.initialize(key);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }, []);
  useEffect(() => {
    if (gaKey) {
      ReactGA.ga("create", gaKey, "auto", { name: "tenantTracker" });
      ReactGA.ga("tenantTracker.send", "pageview", {
        page: window.location.pathname,
      });
    }
  }, [gaKey]);
  
  useLanguage({ lng: null, shouldFetchLanguage: true });

  return (
    <Provider store={store} value="">
      <SignalRProvider>
        <Route path="/" render={(props) => <Router {...props} />}></Route>
      </SignalRProvider>
    </Provider>
  );
}
export default App;
