import { post } from '../../utils/Api';
import { loginLoading, loginSuccess, loginError } from '../constants/reduxConstant';
import apiName from '../../constants/apiName';

const apiLoading = () => ({ type: loginLoading });
const apiSucceed = (payload) => ({ type: loginSuccess, payload });
const apiError = (payload) => ({ type: loginError, payload });

const loginA = ({ from, body }) => async (dispatchEvent, getState) => {
    if (from === 'login') {
        dispatchEvent(apiLoading());
        try {
            post(apiName.login, body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        
                        dispatchEvent(apiSucceed(res));
                        
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    };
}

export default loginA