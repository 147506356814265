import { put, post, get } from '../../../utils/Api';
import axios from 'axios';
import { inviteExpLoading, inviteExpSuccess, inviteExpError } from '../../constants/reduxConstant';
import { inviteExpAdminHomeLoading, inviteExpAdminHomeSuccess, inviteExpAdminHomeError } from '../../constants/reduxConstant';
import apiName from '../../../constants/apiName';
import udid from '../../../components/Common/udid';

const apiLoading = () => ({ type: inviteExpLoading });
const apiSucceed = (payload) => ({ type: inviteExpSuccess, payload });
const apiError = (payload) => ({ type: inviteExpError, payload });

const apiLoadingOne = () => ({ type: inviteExpAdminHomeLoading });
const apiSucceedOne = (payload) => ({ type: inviteExpAdminHomeSuccess, payload });
const apiErrorOne = (payload) => ({ type: inviteExpAdminHomeError, payload });

const inviteExpA = ({ from, tenant, experts }) => async (dispatchEvent, getState) => {
    let body = {
        id: udid(),
        tenant: tenant,
        experts: experts,
    };
    if (from == 'inviteExp') {
        dispatchEvent(apiLoading());
        try {
            post(apiName.inviteExp,  body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    }
    else if (from == 'adminHome') {
        dispatchEvent(apiLoadingOne());
        try {
            post(apiName.inviteExp,  body)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceedOne(res));
                    } else {
                        dispatchEvent(apiErrorOne(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiErrorOne(error));
                });
        } catch (e) {
            dispatchEvent(apiErrorOne(e));
        }
    }
}

export default inviteExpA