

import {
  TOGGLE_CART_HIDDEN,
  ADD_ITEM,
  CLEAR_ITEM_FROM_CART,
  REMOVE_ITEM,
  DELETE_ITEM,
  ADD_PRODUCT_FROM_SESSION,
  REMOVE_PRODUCT_FROM_SESSION,
  QUANTITY_CHANGED
} from '../constants/cart';
import { ADD_TO_CART } from '../constants/Index';

export const toggleCartHidden = () => ({
  type: TOGGLE_CART_HIDDEN
});

export const addItem = item => ({
  type: ADD_ITEM,
  payload: item
});

export const clearItemFromCart = item => ({
  type: CLEAR_ITEM_FROM_CART,
  payload: item
});

export const removeItem = item => ({
  type: REMOVE_ITEM,
  payload: item
});

export function addToCart(product) {
  return {
    type: ADD_TO_CART,
    payload: product
  };
}

export function addProductFromSession(product) {
  return {
    type: ADD_PRODUCT_FROM_SESSION,
    payload: product
  }
}

export const removeProductFromSession = item => ({
  type: REMOVE_PRODUCT_FROM_SESSION,
  payload: item
});

export const changeProductQuantity = item => ({
  type: QUANTITY_CHANGED,
  payload: item
})
