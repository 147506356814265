import React from "react";
import loaderSvg from "../../assets/images/loader-white.png";

// md:absolute
const Loader = () => {
  return (
    <div className="flex items-center left-0 bottom-0 right-0 justify-center flex-col h-full">
      <img src={loaderSvg} alt="Loader Img" />
    </div>
  )
}

export default Loader