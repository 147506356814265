import { request } from "utils/request";
import {
  fetchTenantClientAppSettinsAction,
  fetchTenantClientAppSettinsLoadingAction,
  fetchTenantClientAppSettinsErrorAction,
} from "redux/action/client/tenantClientAppSettings";
import { getError } from "components/ErrorHandler/getError";
import apiName from "constants/apiName";
import { localStorageLanguageKey } from "config/Config";

export default function callFetchClientAppSettings(tenant, languageCode) {
  
  languageCode = languageCode !== undefined ? languageCode : "";
  
  return async (dispatch) => {
    function onSuccess(payload) {
      dispatch(fetchTenantClientAppSettinsAction(payload));
    }
    function isFetching() {
      dispatch(fetchTenantClientAppSettinsLoadingAction());
    }

    function onError(err) {
      dispatch(fetchTenantClientAppSettinsErrorAction(err));
    }

    try {
      isFetching();
      const data = await request({
        url: `${apiName.tenantCustomization}?tenant=${tenant}&languageCode=${languageCode}`, method:"get",
      });
      // If language code is defined 
      if(languageCode) {
        console.log(`Writing local storage with key ${languageCode}`);
        localStorage.setItem(localStorageLanguageKey, JSON.stringify(languageCode));
      }
      return onSuccess(data);
    } catch (err) {
      console.error("error", err);
      return onError(getError(err));
    }
  };
}
