export const isAllFormElementDisabled = (settings) => {
  if (!settings) return null;
  let allFieldDisabled = true;

  for (let [, value] of Object.entries(settings)) {
    if (value.isRequired) {
      allFieldDisabled = false;
      break;
    }
  }
  return allFieldDisabled;
};

export const firstLetterUpperCase = (str) => {
  if (!str) return null;
  return str[0].toUpperCase() + str.substring(1, str.length);
};
