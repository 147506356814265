import {
  fetchSignalRToken,
  fetchSignalRTokenLoading,
  fetchSignalRTokenError,
  NONE,
  ERROR,
  LOADING,
  SUCCESS,
} from "redux/constants/reduxConstant";

const initialState = { STATUS: NONE, value: {} };

export const singalRConnectionToken = (state = initialState, action) => {
  switch (action.type) {
    case fetchSignalRTokenLoading:
      return { ...state, STATUS: LOADING };
    case fetchSignalRToken:
      return { ...state, STATUS: SUCCESS, value: action.payload };
    case fetchSignalRTokenError:
      return { ...state, STATUS: ERROR, value: action.payload };
    default:
      return state;
  }
};

export default singalRConnectionToken;
