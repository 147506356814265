import { put, post, get } from '../../../utils/Api';
import axios from 'axios';
import { expertsTeamLoading, expertsTeamSuccess, expertsTeamError } from '../../constants/reduxConstant';
import apiName from '../../../constants/apiName';
import udid from '../../../components/Common/udid';

const apiLoading = () => ({ type: expertsTeamLoading });
const apiSucceed = (payload) => ({ type: expertsTeamSuccess, payload });
const apiError = (payload) => ({ type: expertsTeamError, payload });

const expertsTeamA = ({ from , tenant }) => async (dispatchEvent, getState) => {
    if (from == 'expertsList') {
        console.log("expertsTeamA")
        dispatchEvent(apiLoading());
        try {
            // get(apiName.expertsTeam + '?tenant=' + tenant)
            get(apiName.expertsTeam + `/${tenant}`)
                .then(async (res) => {
                    if (res && res.data && res.data.isSucceeded) {
                        dispatchEvent(apiSucceed(res));
                    } else {
                        dispatchEvent(apiError(res));
                    }
                })
                .catch((error) => {
                    dispatchEvent(apiError(error));
                });
        } catch (e) {
            dispatchEvent(apiError(e));
        }
    };
}

export default expertsTeamA