import { verifyEmailError, verifyEmailSuccess, verifyEmailLoading, SUCCESS, ERROR, LOADING, NONE, AlreadyVerified, OldVerificationCode } from '../constants/reduxConstant'

export default function verifyEmailR(state = {}, action) {
    // console.log("Inside verifyEmail reducer", action)
    switch (action.type) {
        case verifyEmailLoading:
            return Object.assign({}, state, { status: LOADING });
        case verifyEmailSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case verifyEmailError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        case AlreadyVerified:
            return Object.assign({}, state, { status: AlreadyVerified, error: action.payload });
        case OldVerificationCode:
            return Object.assign({}, state, { status: OldVerificationCode, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}