import React, { useEffect, useState } from "react";
import Highlighter from 'react-highlight-words';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { errorHandle } from "../../components/Common/errorHandle";
import { DefaultDropDown, InputArea } from "../../components/Common/Input";
import udid from "../../components/Common/udid";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import updateProfileA from "../../redux/action/common/updateProfileA";
import defaultA from "../../redux/action/defaultA";
import { ERROR, LOADING, SUCCESS } from '../../redux/constants/reduxConstant';
import { langArr } from "../../redux/constants/StaticArr";
import { getUserData } from "../../utils/utils";
import { Validate as validate } from "../../utils/validate_wrapper";

const ExpCompleteProfile2 = (props) => {
    const location = useLocation();
    const history = useHistory()
    const { preferedLanguageContent: { contents, countriesList } } = useSelector((state) => state);;
    const [state, setState] = useState({
        loading: false,
        profileImg: '',
    })
    // get country list
    const [options, setOptions] = useState([])
    useEffect(() => {
        if(countriesList && Object.keys(countriesList).length > 0)
            setOptions(Object.keys(countriesList).map(x => ({ value: x, label: countriesList[x] })))
    }, [countriesList])

    useEffect(() => {
        if (location && location.state && location.state.firstName && location.state.lastName) {
            console.log('the profile step 2 is ', location.state)
            let temp = location.state
            setState({ ...state, firstName: temp.firstName, lastName: temp.lastName, profileImg: temp.profileImg, tenant: temp.tenant, logo: temp.logo, langOptions: langArr })
        }
        else
            setState({...state, langOptions: langArr})
    }, []);

    // handle api response
    useEffect(() => {
        const updateProfilePath = props.ScreenProps.updateProfileR;

        if (updateProfilePath.status === LOADING) {
            setState({ ...state, loading: true });
        }
        else if (updateProfilePath.status === SUCCESS) {
            console.log('updateProfile exp success res ', updateProfilePath);
            setState({ ...state, loading: false });
            // showToast(updateProfilePath.value.data.status, 'success');
            props.defaultRequest();
            props.history.replace({
                pathname: '/admin-home',
                state: { from: 'completeProfile' }
            })
        }
        else if (updateProfilePath.status === ERROR) {
            console.log('updateProfile exp error res ', updateProfilePath);
            setState({ ...state, loading: false });
            errorHandle(updateProfilePath, contents)
            props.defaultRequest();
        }

    }, [props.ScreenProps]);

    const submit = (e) => {
        e.preventDefault()
        clearError()
        if (!checkValidation()) {
            return;
        }

        let languages = []
        state.selectedLanguage.map(i => {
            languages.push(i.value)
        })
        let formData = {
            firstName: state.firstName,
            lastName: state.lastName,
            location: state.selectedCountry.value,
            spokenLanguages: languages,
            tenant: state.tenant,
            platformLanguage: JSON.parse(localStorage.getItem("selectedLanguage")) ?? "en",
            id: udid()
        };
        
        state.profileImg && (formData.imageUrl = state.profileImg)
        state.bio && (formData.description = state.bio)
        
        props.updateProfileRequest({ from: 'expCompleteProfile2', body: formData })
    };

    // validate form data
    const checkValidation = () => {
        var countryErr = validate(contents, 'blank', state.selectedCountry);
        var languageErr = validate(contents, 'blank', state.selectedLanguage);
        if (countryErr || languageErr) {
            setState({
                ...state,
                countryErr: countryErr,
                languageErr: languageErr,
            })
            return false;
        } else {
            return true;
        }
    };

    // clean error state
    const clearError = () => {
        setState({
            ...state,
            countryErr: '',
            languageErr: '',
        })
    }

    const letGo = async () => {
        let userData = await getUserData()
        history.replace({
            pathname: '/expert-complete-profile',
            state: {
                tenant: userData.tenant,
                logo: userData.tenants.filter(x => x.tenantIdentifier === userData.tenant)[0].tenantLogo
            }
        })
        // history.replace('/expert-complete-profile');
    }

    const handleLang = (value) => {
        let optionArr = [];
        for (let i = 0; i < value.length; i++) {
            optionArr.push(value[i])
        }
        for (let i = 0; i < langArr.length; i++) {
            if (value.indexOf(langArr[i]) === -1) {
                optionArr.push(langArr[i])
            }
        }
        console.log('value', value)
        setState({ ...state, selectedLanguage: value, languageErr: validate(contents, 'blank', value), langOptions: optionArr });
    }

    const formatOptionLabel = ({label}, {inputValue}) => {
        return (
          <Highlighter
            highlightClassName="option-highlight"
            searchWords={[inputValue]}
            textToHighlight={label}
          />
        );
    }

    console.log('state', state)

    return (
        <>
            <ToastContainer />
            <div className="expert-content-block-section">
                <div className="col-xl-7 m-auto">
                    <div className="form-content-blk">
                        <Header imgSrc={state.logo} />
                        <div className="expert-content-block justify-center items-center overflow-block m-auto additional-back-btn-block">
                            <div className="w-full backbtn-progress-block">
                                <button onClick={() => letGo()} className="expert-back-btn">
                                    <img src={Images.vector} className="vector-icon" />
                                    <span className="back-text pl-2">{contents?.btnBack ?? messages?.btnBack}</span>
                                </button>
                                <div className="progress-block">
                                    <div className="progress-bg-line"></div>
                                    <div className="progress-line col-12"></div>
                                    <span className="index">3 {contents?.outOf ?? messages?.outOf} 3</span>
                                </div>
                                <h4 className="exp-additional-account">{contents?.additionalInfo ?? messages?.additionalInfo}</h4>
                                <form onSubmit={submit} className="mb-3">
                                    <DefaultDropDown
                                        name="country"
                                        options={options}
                                        placeholder={contents?.whereLoc ?? messages?.whereLoc}
                                        value={state.selectedCountry}
                                        onChange={(value) => setState({ ...state, selectedCountry: value, countryErr: validate(contents, 'blank', value) })}
                                        error={state.countryErr}
                                        className="single-select"
                                        noResultFound={ contents?.noResultFound ?? messages?.noResultFound}
                                        formatOptionLabel={formatOptionLabel}
                                    />
                                    <DefaultDropDown
                                        name="language"
                                        options={state.langOptions}
                                        isMulti={true}
                                        placeholder={contents?.whatLang ?? messages?.whatLang}
                                        closeMenuOnSelect={false}
                                        value={state.selectedLanguage}
                                        onChange={(value) => { handleLang(value)}}
                                        error={state.languageErr}
                                        noResultFound={ contents?.noResultFound ?? messages?.noResultFound}
                                        formatOptionLabel={formatOptionLabel}
                                    />
                                    <InputArea
                                        type={"text"}
                                        placeholder={contents?.tellCust ?? messages?.tellCust}
                                        name={"bio"}
                                        maxLength={70}
                                        value={state.bio}
                                        onChange={(e) => setState({ ...state, bio: e.target.value && e.target.value.trimLeft(), bioErr: validate(contents, 'valid', e.target.value) })}
                                        error={state.bioErr}
                                        rows={2}
                                    />
                                    <div className="bg-black btn-block additional-btn">
                                        <button type="submit" disabled={state.loading} className="btn btn-black btn-lg btn-min w-full">{contents?.btnFinish ?? messages?.btnFinish}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateProfileRequest: ({ from, body }) => {
            dispatch(updateProfileA({ from, body }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpCompleteProfile2);
