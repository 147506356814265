import {
  fetchRandomSignalRToken,
  fetchRandomSignalRTokenLoading,
  fetchRandomSignalRTokenError,
} from "redux/constants/reduxConstant";

// export const fetchSignalRToken = "fetchSignalRToken";
// export const fetchSignalRTokenLoding = "fetchSignalRTokenLoding";
// export const fetchSignalRTokenError = "fetchSignalRTokenError";

/** @typedef {object} json
 * @property {string} connectionToken
 * @property {null} error
 * @property {boolean} isSucceeded
 * @property {string} status
 */

/**
 * @param  {} payload
 * @param  {fetchSignalRToken} =>{return{type
 * @returns fetchSignalRToken
 */
export const fetchRandomSignalRTokenAction = (payload, tenant) => {

  localStorage.setItem("signalRConnectionToken" + tenant, payload.connectionToken);
  localStorage.setItem("signalRCacheDate" + tenant, new Date());

  return {
    type: fetchRandomSignalRToken,
    payload,
  };
};

/**
 * @param  {} payload, Boolean
 * @param  {fetchSignalRTokenLoading}}} =>{return{type
 * @returns fetchSignalRTokenLoading
 */
export const fetchRandomSignalRTokenLoadingAction = (payload) => {
  return {
    type: fetchRandomSignalRTokenLoading,
  };
};
/**
 * @param  {} payload
 * @param  {fetchSignalRTokenError} =>{return{type
 * @returns fetchSignalRTokenError
 */
export const fetchRandomSignalRTokenErrorAction = (payload) => {
  return {
    type: fetchRandomSignalRTokenError,
    payload,
  };
};
