import React, { useState, useEffect } from "react"
import cart from "../../assets/images/cart.png";
import cartGreen from "../../assets/images/cart-green.png";
import vector from "../../assets/images/vector.png";
import roundedCheck from "../../assets/images/done.png"
import { useSelector, useDispatch } from "react-redux";
import { goToSelectedStep } from "../../redux/action/shopping";
import useSize from "../../utils/useSize";
import logo from "../../assets/images/GetBee-Logo.png";
import MyTimer from "../../components/Common/MyTimer";
import messages from "constants/messages";
const CheckoutCompleted = () => {
  const dispatch = useDispatch()
  const { preferedLanguageContent: { contents } } = useSelector((state) => state);
  const cartItems = useSelector(state => state.cartReducer.cartItems)
  const sessionStatus = useSelector(state => state.shoppingReducer.sessionStatus)
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      calculateTotal(cartItems);
    }
  }, [cartItems])
  const calculateTotal = cartDetail => {
    const totalPrice = cartDetail.reduce((prev, item) => {
      if (item && item.selectedVariant) {
        return prev + item.quantity * item.selectedVariant[0].price;
      } else {
        return prev + item.quantity * item.variants[0].price;
      }
    }, 0);
    setTotal(totalPrice)
  };
  const screen = useSize()
  return (
    <section className="s-home relative s-checkout relative h-full w-11/12 mx-auto mx-auto  sm:px-2 lg:px-4 mx-auto flex items-center flex-col s-card">
      {/* TOP PART */}
      {/* SEARCH PART */}
      {screen.size === "sm" ? <div className="flex items-center pl-2 pr-2 pt-4 pb-4 w-full justify-between bg-white">
        <div className="flex items-center">
          <div className="s-video__liveDot" />
          <span className="session-time">{Object.keys(sessionStatus).length > 0 && sessionStatus.startTime && <MyTimer expiryTimestamp={sessionStatus.startTime} />}</span>
        </div>
        <div>
          <img src={logo} alt="getbee-logo" />
        </div>
        <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(3))}>
          <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
      </div>
        : <div className="flex justify-between items-center mt-8 w-full border-b s-product-view__topBar">
          <button class="relative  s-home__input flex items-center" onClick={() => dispatch(goToSelectedStep(0))}>
            <img src={vector} alt="search icon" />
            <p className="text-black ml-2">{contents?.btnBack ?? messages?.btnBack}</p>
          </button>
          <p className="text-black s-product-view__header">{sessionStatus && sessionStatus.clientInfo && (sessionStatus.clientInfo.name ? sessionStatus.clientInfo.name : (contents?.client ?? messages?.client))}'s CART</p>
          <div className="flex items-center cart__icon cursor-pointer" onClick={() => dispatch(goToSelectedStep(3))}>
            <img src={cartItems && cartItems.length > 0 ? cartGreen : cart} alt="cart" /><span className="ml-2 mr-4 text-black">{contents?.cart ?? messages?.cart}</span><span className="bg-dark rounded-full text-white ">{cartItems && cartItems.length > 0 ? cartItems.length : 0}</span></div>
        </div>}
      {/* CAROUSEL PART */}
      {cartItems && cartItems.length > 0 && <>
        <div className="md:w-full w-11/12 mx-auto  relative flex flex-col items-center mt-8 ">
          <div className="s-cart__price flex justify-between items-center">
            <h1 className="font-bold">{Object.keys(sessionStatus).length > 0 && sessionStatus.storeInfo && sessionStatus.storeInfo.currencyInfo && sessionStatus.storeInfo.currencyInfo.currencySymbol} {total}</h1>
            <p>{contents?.excludesTaxesDelivery ?? messages?.excludesTaxesDelivery}</p>
          </div>
        </div>
      </>}
      {/* CARDS PART */}
      <section className="md:w-full w-11/12 mx-auto mt-2 s-product-view__container">
        <div className="mt-6 flex flex-col items-center justify-center ">
          <h2 className="s-checkout__title">{contents?.paymentSuccess ?? messages?.paymentSuccess}</h2>
          {sessionStatus.orderInfo && sessionStatus.orderInfo.orderId && <p className="mb-4 text-black s-checkout__subtitle">{contents?.orderNo ?? messages?.orderNo} #{sessionStatus.orderInfo.orderId}</p>}
          <img className="mb-4" src={roundedCheck} alt="check-icon-green" />
          <button onClick={() => dispatch(goToSelectedStep(0))}><p className="underline">{contents?.goToCatalog ?? messages?.goToCatalog}</p></button>
        </div>
      </section>
    </section>
  )
}
export default CheckoutCompleted