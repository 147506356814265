import React, { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import ErrorText from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import { defaultInput as DefaultInput, defaultInputWithIcon as DefaultInputWithIcon } from "../../components/Common/Input";
import udid from "../../components/Common/udid";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import defaultA from "../../redux/action/defaultA";
import registerA from "../../redux/action/registerA";
import { ERROR, LOADING, SUCCESS } from '../../redux/constants/reduxConstant';
import { Validate as validate } from "../../utils/validate_wrapper";

const ExpCreatePass = (props) => {
    const location = useLocation();
    const history = useHistory()
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);;
    const [state, setState] = useState({
        loading: false,
        inviteCode: '',
        acceptTerms: false
    })

    useEffect(() => {
        if (location && location.state && location.state.inviteCode) {
            console.log('the history state is ', location.state)
            let temp = location.state
            setState({ ...state, inviteCode: temp.inviteCode, email: temp.email, tenant: temp.tenant, logo: temp.logo })
        }
    }, []);

    // handle api response
    useEffect(() => {
        const registerPath = props.ScreenProps.expCreatePassR;

        if (registerPath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (registerPath.status == SUCCESS) {
            console.log('register exp success res ', registerPath);
            setState({ ...state, loading: false });
            // showToast(registerPath.value.data.status, 'success');
            let temp = registerPath.value.data
            temp.tenant = state.tenant
            let role = []
            temp.tenants.map(i => {
                i.tenantIdentifier == state.tenant && (role = i.roles)
            })
            temp.role = role
            temp.email = state.email

            console.log('before setting to the local ', temp)
            localStorage.setItem('userData', JSON.stringify(temp))
            props.defaultRequest();
            history.replace({
                pathname: '/expert-complete-profile',
                state: {
                    tenant: state.tenant,
                    logo: state.logo
                },
            })
        }
        else if (registerPath.status == ERROR) {
            console.log('register exp error res ', registerPath);
            setState({ ...state, loading: false });
            errorHandle(registerPath, contents)
            props.defaultRequest();
        }

    }, [props.ScreenProps]);

    const submit = (e) => {
        e.preventDefault()
        clearError()
        if (!checkValidation()) {
            return;
        }
        
        let lang = JSON.parse(localStorage.getItem("selectedLanguage"));
        let formData = {
            email: state.email,
            password: state.password,
            invitationId: state.inviteCode,
            preferredLanguage: lang,
            id: udid()
        };
        props.registerRequest({ from: 'expCreatePass', body: formData })
    };

    // validate form data
    const checkValidation = () => {
        var emailErr = validate(contents, 'email', state.email);
        var passwordErr = validate(contents, 'blank', state.password)
        if(!passwordErr) 
            passwordErr = validate(contents, 'password', state.password);
        var acceptTermsError = state.acceptTerms ? '' : (contents?.agreeTerm ?? messages?.agreeTerm)
        if (emailErr || passwordErr || acceptTermsError) {
            setState({
                ...state,
                emailErr: emailErr,
                passwordErr: passwordErr,
                acceptTermsError: acceptTermsError
            })
            return false;
        } else {
            return true;
        }
    };

    // clean error state
    const clearError = () => {
        setState({
            ...state,
            emailErr: '',
            passwordErr: '',
            acceptTermsError: ''
        })
    }

    const showSetPass = () => {
        setState({ ...state, showPass: !state.showPass });
    }

    return (
        <>
            <ToastContainer />
            <div className="expert-content-block-section">
                <div className="col-xl-7 m-auto pb-5">
                    <div className="form-content-blk">
                        <Header imgSrc={state.logo} />
                        <div className="expert-content-block justify-center items-center overflow-block m-auto">
                            <div className="w-full">
                                <div className="progress-block">
                                    <div className="progress-bg-line"></div>
                                    <div className="progress-line col-4"></div>
                                    <span className="index">1 {contents?.outOf ?? messages?.outOf} 3</span>
                                </div>
                                <h4 className="exp-create-account">{contents?.createAccount ?? messages?.createAccount}</h4>
                                <form onSubmit={submit} className="register-form mb-3">
                                    <DefaultInput
                                        label={contents?.emailAddress ?? messages?.emailAddress}
                                        // type={"email"}
                                        name={"email"}
                                        maxLength={200}
                                        disabled={true}
                                        value={state.email}
                                        onChange={(e) => setState({ ...state, email: e.target.value })}
                                        error={state.emailErr}
                                    />
                                    <DefaultInputWithIcon
                                        type={state.showPass ? "text" : "password"}
                                        placeholder={contents?.password ?? messages?.password}
                                        name={"password"}
                                        icon={state.showPass ? Images.eyeClose : Images.eyeOpen}
                                        value={state.password}
                                        onChange={(e) => setState({ ...state, password: e.target.value && e.target.value.trim(), passwordErr: validate(contents, 'password', e.target.value) })}
                                        error={state.passwordErr}
                                        clickIcon={showSetPass}
                                    />
                                    <div className="form-group remember-box">
                                        <label className="coustom-check-box">
                                            <input type="checkbox" onChange={(e) => setState({ ...state, acceptTerms: !state.acceptTerms, acceptTermsError: (!state.acceptTerms ? '' : contents?.agreeTerm ?? messages?.agreeTerm) })} />
                                            <span className="checkmark"></span>
                                            <p>
                                            {contents?.AgreeTo ?? messages?.AgreeTo}<a href="https://www.getbee.com/terms" target="_blank">{contents?.termsTxt ?? messages?.termsTxt}</a>.
                                            </p>
                                        </label>
                                        {state.acceptTermsError && <ErrorText title={state.acceptTermsError} />}
                                    </div>
                                    <div className="bg-black btn-block">
                                        <button type="submit" disabled={state.loading} className="btn btn-black btn-lg btn-min w-full">{contents?.btnContinue ?? messages?.btnContinue}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        registerRequest: ({ from, body }) => {
            dispatch(registerA({ from, body }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpCreatePass);
