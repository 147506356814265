import { getError } from "components/ErrorHandler/getError";
import apiName from "constants/apiName";
import {
  fetchTenantClientAppTextSettingsAction, fetchTenantClientAppTextSettingsErrorAction, fetchTenantClientAppTextSettingsLoadingAction
} from "redux/action/client/tenantClientAppTextSettings";
import { request } from "utils/request";

export default function callFetchClientAppTextSettings(tenant, languageCode, showPublishEvent) {
  
  languageCode = languageCode !== undefined ? languageCode : "";
  
  return async (dispatch) => {
    function onSuccess(payload) {
      dispatch(fetchTenantClientAppTextSettingsAction(payload));
    }
    function isFetching() {
      dispatch(fetchTenantClientAppTextSettingsLoadingAction());
    }

    function onError(err) {
      dispatch(fetchTenantClientAppTextSettingsErrorAction(err));
    }

    try {
      isFetching();
      const data = await request({
        url: `${apiName.tenantCustomization}?tenant=${tenant}&languageCode=${languageCode}`, method:"get",
      });
      const {title} = data ?? {};
  
      return onSuccess({title});
    } catch (err) {
      console.error("error", err);
      return onError(getError(err));
    }
  };
}
