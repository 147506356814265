import { CHANGE_EXPERT_STATUS, CHANGE_INCALL_STATUS } from "../constants/Auth";

const initialState = {
  expertOnlineStatus: false,
  expertInCall: false
};

const sessionConnectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_EXPERT_STATUS:
      return {
        ...state,
        expertOnlineStatus: action.payload
      }
    case CHANGE_INCALL_STATUS:
      return {
        ...state,
        expertInCall: action.payload
      }
    default:
      return state;
  }
};

export default sessionConnectionsReducer;
