import React, { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { showToast } from "../../components/Common/Common";
import { errorHandle } from "../../components/Common/errorHandle";
import { Header } from '../../components/Header/Header';
import Images from "../../constants/Images";
import messages from "../../constants/messages";
import defaultA from "../../redux/action/defaultA";
import resendEmailA from "../../redux/action/resendEmailA";
import { ERROR, LOADING, SUCCESS } from '../../redux/constants/reduxConstant';

const EmailSent = (props) => {
    const [state, setState] = useState({
        loading: false,
        oldCodeUsed: false
    })

    const location = useLocation();
    const { preferedLanguageContent: { contents } } = useSelector((state) => state);
    useEffect(() => {
        if (location && location.state) {
            if (location.state.email && location.state.oldCodeUsed)
            {
                setState({...state, email: location.state.email, oldCodeUsed: location.state.oldCodeUsed})
            }
            else if (location.state.oldCodeUsed)
            {
                setState({...state, oldCodeUsed: location.state.oldCodeUsed})
            }
            else if (location.state.email)
            {
                setState({...state, email: location.state.email})
            }
        }
    }, []);

    useEffect(() => {
        const resendEmailPath = props.ScreenProps.resendEmailR;

        if (resendEmailPath.status == LOADING) {
            setState({ ...state, loading: true });
        }
        else if (resendEmailPath.status == SUCCESS) {
            console.log('resendEmail success res ', resendEmailPath);
            setState({ ...state, loading: false, isVerified: true });
            showToast(resendEmailPath.value.data.status, 'success');
            props.defaultRequest();
        }
        else if (resendEmailPath.status == ERROR) {
            console.log('resendEmail error res ', resendEmailPath);
            setState({ ...state, loading: false, status: contents?.verifyFail ?? messages?.verifyFail });
            errorHandle(resendEmailPath, contents)
            props.defaultRequest();
        }
    }, [props.ScreenProps]);

    const resend = () => {
        location && location.state && location.state.email ? props.resendEmailRequest({ from: 'emailSent', email: location.state.email }) : showToast(contents?.emailNFound ?? messages?.emailNFound)
    }

    return (
        <>
            <ToastContainer />
            <div className="expert-content-block-section">
                <div className="first-section">
                    <div className="form-content-blk">
                        <Header />
                        <div className="expert-content-block justify-center items-center m-auto">
                            <div className="w-full">
                                <h4>
                                    {!state.oldCodeUsed ? (contents?.verifyMail ?? messages?.verifyMail) : (contents?.wrongCode ?? messages?.wrongCode)}
                                </h4>
                                <p className="mb-4">{state.oldCodeUsed ? (contents?.weSentAnotherMailTo ?? messages?.weSentAnotherMailTo) : (contents?.weSentMailTo ?? messages?.weSentMailTo)} <strong>{state.email}</strong>
                                    {contents?.checkMailClickActivationLink ?? messages?.checkMailClickActivationLink}</p>
                                <p>{contents?.clickLinkResend ?? messages?.clickLinkResend}
                                </p>
                                <div className="bg-black btn-block">
                                    <button
                                        type="button"
                                        onClick={() => resend()}
                                        className="btn btn-black btn-lg btn-min w-full">
                                        {contents?.btnResend ?? messages?.btnResend}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-section right-img">
                    <img src={Images.emailSent} />
                </div>
            </div>
            {/*<PreLoginLayout>
                <div className='register-form-container m-auto flex flex-col'>
                    <span className='font-TenorSansRegular text-4xl text-black mb-6 mt-48'>Verify your e-mail</span>
                    <span className='text-sm font-InterRegular text-black mb-12'>
                        We have sent an email to 
                        <span className='font-bold'> andrewferguson@gmail.com</span>, 
                        please check it and click the activation link in that email to begin using GetBEE.
                        Click the button below if your haven’t received the link.
                    </span>
                    <PrimarytButton  title='Resend link'/>
                </div>
                
            </PreLoginLayout>*/}
        </>
    )
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        ScreenProps: state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resendEmailRequest: ({ from, email }) => {
            dispatch(resendEmailA({ from, email }));
        },
        defaultRequest: () => {
            dispatch(defaultA());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSent);
