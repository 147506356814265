import { useEffect } from "react";
import { useDispatch } from "react-redux";
import callFetchRandomSingalRConnectionToken from "redux/call-action/callFetchRandomSingalRConnectionToken";

export default function useFetchRandomSingleRToken({
  tenant,
  connectionToken,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    const dispatchRandomTokenSignalRToken = async () => {
      if (!tenant) return null;
      // let email = getUniqueUsername() + "@gmail.com";
      let email = "anonymous@getbee.com";
      let body = { email, tenant };
      await dispatch(callFetchRandomSingalRConnectionToken(body));
    };

    var cacheDate = new Date(localStorage.getItem("signalRCacheDate" + tenant));
    var newDateObj = new Date(cacheDate.getTime() + 60*60000);
    var now = new Date();
    if(newDateObj > now) {
      connectionToken = localStorage.getItem("signalRConnectionToken"  + tenant);
    }

    
    if (!connectionToken) {
      dispatchRandomTokenSignalRToken();
    } 
      
  }, [dispatch, tenant, connectionToken]);
}

