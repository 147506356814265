import LoadingMessage from "components/Common/LoadingMessage";
import ErrorBoundry from "components/ErrorHandler/ErrorBoundry";
import { tabIds } from "config/TabConfig";
import { lazy, Suspense, useMemo, useState } from "react";
import NavBar from "./nav-bar";

const tabsContent = {
  [tabIds.homePage]: lazy(() => import("components/TabContents/Homepage")),
  [tabIds.teamMembers]: lazy(() =>
    import("components/TabContents/TeamMembers")
  ),
  [tabIds.teamSalesData]: lazy(() =>
    import("components/TabContents/TeamSalesData")
  ),
  [tabIds.subscription]: lazy(() =>
    import("components/TabContents/Subscription")
  ),
  [tabIds.personalize]: lazy(() =>
    import("components/TabContents/Personalize")
  ),
  [tabIds.integrations]: lazy(() =>
    import("components/TabContents/Integrations")
  ),
  [tabIds.companyDatails]: lazy(() =>
    import("components/TabContents/CompanyDetails")
  ),
  [tabIds.meetings]: lazy(() =>
    import("components/TabContents/Meetings")
  ),
  [tabIds.reporting]: lazy(() =>
    import("components/TabContents/Reporting")
  ),
};

const Index = () => {
  const [selectedLi, setSelectedLi] = useState(tabIds.homePage);
  const [showModel, setShowModel] = useState(false);

  let CurrentTabContent = useMemo(() => {
    return tabsContent[selectedLi];
  }, [selectedLi]);

  return (
    <div className="dashboard">
  
      <NavBar
        selectedLi={selectedLi}
        setSelectedLi={setSelectedLi}
        setShowModel={setShowModel}
        showModel={showModel}
      />
      <div className="dashboard-main">
        <Suspense fallback={<LoadingMessage />}>
          <div
            className={
              selectedLi !== tabIds.homePage ? "animate-container" : ""
            }
          >
            <ErrorBoundry>
              <CurrentTabContent
                activeTab={selectedLi}
                setShowModel={setShowModel}
                showModel={showModel}
              />
            </ErrorBoundry>
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default Index;
