import apiName from '../../constants/apiName';
import { post } from '../../utils/Api';
import { tenantInfoError, tenantInfoLoading, tenantInfoSuccess } from '../constants/reduxConstant';

const apiLoading = () => ({ type: tenantInfoLoading });
export const apiSucceed = (payload) => ({ type: tenantInfoSuccess, payload });
const apiError = (payload) => ({ type: tenantInfoError, payload });

const tenantInfoA = ({ from, body }) => async (dispatchEvent, getState) => {
    dispatchEvent(apiLoading());
    
    try {
        post(apiName.tenantInfo, body)
            .then(async (res) => {
                if (res && res.data && res.data.isSucceeded) {
                    dispatchEvent(apiSucceed(res));
                } else {
                    dispatchEvent(apiError(res));
                }
            })
            .catch((error) => {
                dispatchEvent(apiError(error));
            });
    } catch (e) {
        dispatchEvent(apiError(e));
    }
}

export default tenantInfoA