import { useSelector } from "react-redux";
import SchedulingTemplate from "../common/SchedulingTemplate";
import "./schedule-call-with-specific-expert.scss";
import useFetchTenantInfo from "hooks/useFetchTenantInfo";
import useFetchClientAppSettings from "hooks/useFetchClientAppSettings";
import { useParams } from "react-router-dom";

const Main = () => {
  return (
    <div className="expert-intro">
      <div className="row">
        <div className="selected-date">Monday, May 4th 2022, 14:00 - 15:00</div>
      </div>
      <div className="row please-enter">
        Please add explanation for the client why you want to cancel this
        meeting.
      </div>

      <div className="row reason-input">
        <textarea name="reason" placeholder="Reason to cancel">
          
        </textarea>
      </div>
      <div className="row">
        <div className="actions yes-cancel">
          <button className="btn btn--primary schedule-btn">
            {" "}
            Yes, cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const CancelMeeting = (props) => {
  const {
    preferedLanguageContent: { contents },
    tenantClientAppSettings,
    tenantInfo
  } = useSelector((state) => state);
  let { tenant } = useParams();

  const {value } = tenantInfo;
  const { value: clientAppSetting } = tenantClientAppSettings;

  useFetchTenantInfo({tenant, tenantInfo});
  useFetchClientAppSettings({ tenant, clientAppSetting });


  return (
    <SchedulingTemplate
      main={<Main />}
      title="Cancel meeting?"
      tenantInfo={value}
            clientAppSetting={clientAppSetting}
            contents={contents}
    ></SchedulingTemplate>
  );
};

CancelMeeting.propTypes = {};

export default CancelMeeting;
