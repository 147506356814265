import { offlineError, offlineSuccess, offlineLoading, SUCCESS, ERROR, LOADING, NONE } from '../constants/reduxConstant'

export default function offlineR(state = {}, action) {
    // console.log("Inside register reducer", action)
    switch (action.type) {
        case offlineLoading:
            return Object.assign({}, state, { status: LOADING });
        case offlineSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case offlineError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}