import { forgotPasswordError, forgotPasswordSuccess, forgotPasswordLoading, SUCCESS, ERROR, LOADING, NONE } from '../../constants/reduxConstant'

export default function forgotPasswordR(state = {}, action) {
    // console.log("Inside forgotPassword reducer", action)
    switch (action.type) {
        case forgotPasswordLoading:
            return Object.assign({}, state, { status: LOADING });
        case forgotPasswordSuccess:
            return Object.assign({}, state, { status: SUCCESS, value: action.payload });
        case forgotPasswordError:
            return Object.assign({}, state, { status: ERROR, error: action.payload });
        default:
            return Object.assign({}, state, { status: NONE });
    }
}